import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";

const Allowances = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=employee_allowances`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const setcurrentAllowance = (allowance) => {
    // Set the employees current allowance to the current allowance
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentAllowance: allowance,
    });
  };

  const createAllowance = () => {
    return {
      id: null,
      description: "",
      amount: { _decimal: 0 },
      is_taxable: true,
      is_itemised: false,
      auto_apply_to_pay: true,
      is_included_in_super_calculations: true,
      allowance_category: "Unclassified",
    };
  };

  const deleteAllowance = (idToDelete) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      allowances: adminContext.currentEmployee.allowances.filter(
        (allowance) => allowance.id !== idToDelete
      ),
      currentAllowance: false,
    });
    setSaving(true);
  };

  const updateErrorMessage = () => {
    if (adminContext.currentEmployee.allowances === undefined) {
      return;
    }
    let totalPercentage = adminContext.currentEmployee.allowances.reduce(
      (total, allowance) => {
        return total + Number(allowance.percentage);
      },
      0
    );

    if (totalPercentage > 1) {
      setErrorMessage(
        "The combined percentages of these allowances is greater than 100%"
      );
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    updateErrorMessage();
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.allowances]);

  const updateAllowances = (allowance) => {
    setSaving(true);
    let newAllowances = [];
    if (allowance.id === null) {
      newAllowances = [...adminContext.currentEmployee.allowances, allowance];
    } else {
      newAllowances = adminContext.currentEmployee.allowances.map((a) => {
        if (a.id === allowance.id) {
          return allowance;
        } else {
          return a;
        }
      });
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      allowances: newAllowances,
    });
  };

  const columns = [
    {
      label: "Description",
      accessor: "description",
      widget: "Text",
    },
    {
      label: "Amount",
      accessor: "amount",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Is Taxable?",
      accessor: "is_taxable",
      widget: "BooleanLabel",
    },
    {
      label: "Is Itemised?",
      accessor: "is_itemised",
      widget: "BooleanLabel",
    },
    {
      label: "STP Category",
      accessor: "allowance_category_string",
      widget: "Text",
    },
    {
      label: "Auto Apply to Pay?",
      accessor: "auto_apply_to_pay",
      widget: "BooleanLabel",
    },
    {
      label: "Included in Super Calculations?",
      accessor: "is_included_in_super_calculations",
      widget: "BooleanLabel",
    },
  ];

  const formSpec = {
    title: "Allowance",
    fields: [
      {
        label: "Description",
        accessor: "description",
        widget: "Text",
        required: true,
      },
      {
        label: "Amount",
        accessor: "amount",
        widget: "MoneyEdit",
      },
      {
        label: "STP Category",
        accessor: "allowance_category",
        widget: "ComboBox",
        args: {
          validateCallback: (allowance) => {
            if (allowance.allowance_category === "Unclassified") {
              return "Please select an STP category from the available options";
            }
            return "";
          },
          comboDataCallback: () => {
            return adminContext.constants.STPAllowanceCategories;
          },
        },
      },
      {
        label: "Is Taxable?",
        accessor: "is_taxable",
        widget: "CheckBox",
      },
      {
        label: "Is Itemised?",
        accessor: "is_itemised",
        widget: "CheckBox",
      },

      {
        label: "Auto Apply to Pay?",
        accessor: "auto_apply_to_pay",
        widget: "CheckBox",
      },
      {
        label: "Included in Super Calculations?",
        accessor: "is_included_in_super_calculations",
        widget: "CheckBox",
      },
    ],
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Allowances</h1>
                </Col>
              </Row>
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={
                        adminContext.currentEmployee.allowances
                          ? adminContext.currentEmployee.allowances
                          : []
                      }
                      columns={columns}
                      selected={adminContext.currentEmployee.currentAllowance}
                      setSelected={setcurrentAllowance}
                      appendRowCallback={createAllowance}
                      editRowSaveCallback={updateAllowances}
                      deleteRowCallback={deleteAllowance}
                      deleteConfirmationAttributes={["description", "amount"]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default Allowances;
