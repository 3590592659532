import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";

const DepartmentsTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const departmentsComboData = () => {
    let departments = {};
    adminContext.company.departments.forEach((department) => {
      departments[department.id] = department.name;
    });
    return departments;
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=employee_departments`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const setCurrentDepartment = (department) => {
    // Set the employees current department to the current department
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentDepartment: department,
    });
  };

  const createDepartment = () => {
    return {
      id: null,
      percentage: 1,
      department_id:
        adminContext.company.departments.length > 0
          ? adminContext.company.departments[0].id
          : "",
    };
  };

  const deleteDepartment = (idToDelete) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      employee_departments:
        adminContext.currentEmployee.employee_departments.filter(
          (department) => department.id !== idToDelete
        ),
      currentDepartment: false,
    });
    setSaving(true);
  };

  const updateErrorMessage = () => {
    if (adminContext.currentEmployee.employee_departments === undefined) {
      return;
    }
    let totalPercentage =
      adminContext.currentEmployee.employee_departments.reduce(
        (total, department) => {
          return total + Number(department.percentage);
        },
        0
      );

    if (totalPercentage > 1) {
      setErrorMessage(
        "The combined percentages of these departments is greater than 100%"
      );
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    updateErrorMessage();
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.employee_departments]);

  const updateDepartments = (department) => {
    setSaving(true);
    let newDepartments = [];
    if (department.id === null) {
      newDepartments = [
        ...adminContext.currentEmployee.employee_departments,
        department,
      ];
    } else {
      newDepartments = adminContext.currentEmployee.employee_departments.map(
        (d) => {
          if (d.id === department.id) {
            return department;
          } else {
            return d;
          }
        }
      );
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      employee_departments: newDepartments,
    });
  };

  const formSpec = {
    title: "Department",
    fields: [
      {
        label: "Department Name",
        accessor: "department_id",
        widget: "ComboBox",
        required: true,
        args: {
          comboDataCallback: departmentsComboData,
          idAlias: "departmentName",
          idAliasCallback: (id) => {
            let departmentName = "";
            adminContext.company.departments.forEach((department) => {
              if (Number(department.id) === Number(id)) {
                departmentName = department.name;
              }
            });
            return departmentName;
          },
        },
      },
      {
        label: "Percentage Assigned",
        accessor: "percentage",
        widget: "PercentageEdit",
      },
    ],
  };

  const getRows = () => {
    // Returns the table rows for the employee_departments table, however it needs to be modified
    // to include the departmentName value from the department object, using the department_id
    // value from the employee_department object.
    if (
      !adminContext.company.departments ||
      adminContext.currentEmployee.employee_departments === undefined
    )
      return [];
    let rows = [];
    let companyDepartments = adminContext.company.departments;
    adminContext.currentEmployee.employee_departments.forEach((department) => {
      companyDepartments.forEach((d) => {
        if (d.id === department.department_id) {
          department.departmentName = d.name;
        }
      });
      rows.push(department);
    });

    return rows;
  };

  const columns = [
    {
      label: "Department Name",
      accessor: "departmentName",
      widget: "Text",
    },
    {
      label: "Percentage Assigned",
      accessor: "percentage",
      widget: "PercentageLabel",
      align: "right",
    },
  ];

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ||
          adminContext.currentEmployee.employee_departments === undefined ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Departments</h1>
                </Col>
              </Row>
              {!adminContext.currentEmployee.currentDepartment && (
                <Row>
                  <Col className="mt-3 mx-4">
                    <p>
                      NOTE: If you want to add this employee to a department
                      that is not listed below, you need to create a new
                      department first under{" "}
                      <strong>{"Company >> Departments"}</strong>.
                    </p>
                    <p>
                      Departments cannot be deleted if they have employee or pay
                      history.
                    </p>
                  </Col>
                </Row>
              )}
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={getRows()}
                      columns={columns}
                      selected={adminContext.currentEmployee.currentDepartment}
                      setSelected={setCurrentDepartment}
                      appendRowCallback={createDepartment}
                      appendRowCallbackEnabled={
                        adminContext.company.departments &&
                        adminContext.company.departments.length > 0
                      }
                      editRowSaveCallback={updateDepartments}
                      deleteRowCallback={deleteDepartment}
                      deleteConfirmationAttributes={[
                        "departmentName",
                        "percentage",
                      ]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default DepartmentsTab;
