import moment from "moment";
import { useEffect, useState } from "react";

const FileSizeLabel = ({ value }) => {
  const [formattedSize, setFormattedSize] = useState(null);

  useEffect(() => {
    // Convert bytes to MBs as a decimal value
    if (value !== null) {
      setFormattedSize((value / 1024 / 1024).toFixed(2) + " MB");
    }
  }, [value]);

  return <div className="text-muted">{formattedSize}</div>;
};
export default FileSizeLabel;
