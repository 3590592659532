// reactstrap components
import { Row, Col, Container, Button, Modal } from "reactstrap";
import { useState, useContext, useEffect } from "react";
import TableWidget from "components/Widgets/TableWidget";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import EditForm from "../../components/Widgets/EditForm";
import { set } from "date-fns";

const UserManagement = () => {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [saving, setSaving] = useState(false);
  const [editingUser, setEditingUser] = useState(false);
  const [rows, setRows] = useState({
    companies: adminContext.companies,
  });
  const [showQRCode, setShowQRCode] = useState(false);

  const [alteredUsernameMessage, setAlteredUsernameMessage] = useState("");

  const [selectedRows, setSelectedRows] = useState({
    companies: [],
  });

  const initView = () => {
    const url = adminContext.constants.BASE_URL + "/settings/user-management";
    adminContext.getRequest(url, (response) => {
      if (response.data.users) {
        setUsers(response.data.users);
      }
      setLoading(false);
    });
  };

  useEffect(initView, []);

  // Update the companies whenever the user changes to use their companies
  useEffect(() => {
    if (!selectedUser.companies) {
      return;
    }
    // When a selected user is changed, update the selected rows to be the companies of the new user
    setSelectedRows({
      companies: selectedUser.companies,
    });

    // When a selected user is changed, update the companies to be the companies of the new user
    setCompanies(selectedUser.companies);
  }, [selectedUser]);

  // Select a default user if possible
  useEffect(() => {
    if (!users) {
      return;
    }
    // Check the the selectedUser is in the users list, and if not select the first user
    const selectedUserInUsers = users.find(
      (user) => user.id === selectedUser.id
    );
    if ((!selectedUser || !selectedUserInUsers) && users && users.length > 0) {
      setSelectedUser(users[0]);
    }
  }, [users]);

  // Select a default company if possible
  useEffect(() => {
    if (!companies) {
      return;
    }
    const selectedCompanyInCompanies = companies.find(
      (company) => company.id === selectedCompany.id
    );

    if (
      (!selectedCompany || !selectedCompanyInCompanies) &&
      companies &&
      companies.length > 0
    ) {
      setSelectedCompany(companies[0]);
    }
  }, [companies]);

  // If the selected user or selected company changes, update the permissions and selected company rows
  useEffect(() => {
    if (!selectedUser || !selectedUser.user_companies || !selectedCompany) {
      return;
    }
    const userCompany = selectedUser.user_companies.find(
      (userCompany) => userCompany.company_id === selectedCompany.id
    );

    if (
      selectedUser &&
      selectedUser.global_permissions &&
      userCompany &&
      userCompany.user_permissions
    ) {
      // permissions should be selectedUser.global_permissions + selectedCompany.user_permissions
      setPermissions([
        ...selectedUser.global_permissions,
        ...userCompany.user_permissions,
      ]);
    }
  }, [selectedUser, selectedCompany]);

  const updatePermission = (newPermission) => {
    const url =
      adminContext.constants.BASE_URL + "/settings/user-management/permissions";
    adminContext.postRequest(url, newPermission, (response) => {
      setLoading(false);
    });
  };

  const saveSelectedUser = () => {
    setSaving(true);
    setLoading(true);
    const url =
      adminContext.constants.BASE_URL + "/settings/user-management/user";
    const editedUser = {
      ...selectedUser,
      companies: selectedRows.companies,
    };
    adminContext.postRequest(url, editedUser, (response) => {
      setLoading(false);
      if (response.data.error) {
        adminContext.setAlertColor("warning");
        adminContext.setAlertMessage(response.data.error);
        return;
      } else {
        adminContext.setAlertColor("success");
        adminContext.setAlertMessage("Your changes were saved successfully.");
      }
      if (response.data.updated_user) {
        setSelectedUser(response.data.updated_user);
        setCompanies(response.data.updated_user.companies);
      }
      if (response.data.users) {
        setUsers(response.data.users);
      }
      if (
        response.data.altered_usernames &&
        response.data.altered_usernames.length > 0
      ) {
        setAlteredUsernameMessage(
          "The following usernames had to be altered slightly because they were already in use: " +
            response.data.altered_usernames
              .map(
                (user) => user.original_username + " -> " + user.new_username
              )
              .join(", ")
        );
      }
      setSaving(false);

      setEditingUser(false);
    });
  };

  const deleteUser = (idToDelete) => {
    setLoading(true);
    const url =
      adminContext.constants.BASE_URL +
      "/settings/user-management/user/" +
      idToDelete;
    adminContext.deleteRequest(url, (response) => {
      setLoading(false);
      if (response.data.error) {
        adminContext.setAlertColor("warning");
        adminContext.setAlertMessage(response.data.error);
        return;
      } else {
        adminContext.setAlertColor("success");
        adminContext.setAlertMessage("The user was deleted successfully.");
      }
      if (response.data.users) {
        setUsers(response.data.users);
      }
      setEditingUser(false);
    });
  };

  const loginAsUser = (user) => {
    setLoading(true);
    const url =
      adminContext.constants.BASE_URL +
      "/settings/user-management/user/" +
      user.id;
    adminContext.getRequest(url, (response) => {
      setLoading(false);
      if (response.data.error) {
        adminContext.setAlertColor("warning");
        adminContext.setAlertMessage(response.data.error);
        return;
      } else {
        adminContext.setAlertColor("success");
        adminContext.setAlertMessage(
          "You are now logged in as " + user.username
        );
        // Redirect to entity selection
        adminContext.history.push("/admin/entities/select");
      }
    });
  };

  const openShowQRCode = (user) => {
    setShowQRCode(true);
  };

  const isSelected = (selected, row) => {
    return selected.id === row.id;
  };

  const userColumns = [
    {
      label: "Username",
      accessor: "username",
      widget: "text",
    },
    {
      label: "Email",
      accessor: "email",
      widget: "text",
    },
    {
      label: "Companies",
      accessor: "num_companies",
      widget: "text",
      align: "center",
    },
    {
      label: "Login As User",
      accessor: "login_as_user",
      iconOnly: true,
      widget: "Button",
      args: {
        buttonIcon: "fa-regular fa-user fa-xl",
        onClickCallback: loginAsUser,
      },
    },

    {
      label: "Show Auth App QR Code",
      accessor: "show_qr_code",
      iconOnly: true,
      widget: "Button",
      args: {
        buttonIcon: "fa-regular fa-qrcode fa-xl",
        onClickCallback: openShowQRCode,
      },
    },
    {
      label: "Authenticator App Secret Key",
      accessor: "authenticator_secret",
      widget: "text",
    },
  ];

  const companyColumns = [
    {
      label: "Company",
      accessor: "name",
      widget: "text",
    },
  ];

  const permissionColumns = [
    {
      label: "Zone",
      accessor: "zone",
      widget: "text",
      titleCase: true,
    },
    {
      label: "Locked",
      accessor: "is_locked",
      widget: "CheckBoxBooleanLabel",
      align: "center",
    },
    {
      label: "Read Only",
      accessor: "is_read_only",
      widget: "CheckBoxBooleanLabel",
      align: "center",
    },
    {
      label: "Editable",
      accessor: "is_editable",
      widget: "CheckBoxBooleanLabel",
      align: "center",
    },
  ];

  const userFormSpec = {
    title: "User Details",
    fields: [
      {
        label: "Username",
        accessor: "username",
        widget: "text",
      },
      {
        label: "Email",
        accessor: "email",
        widget: "text",
      },

      {
        label: "Permitted Companies",
        accessor: "companies",
        widget: "ListWidget",
        args: {
          rows: rows,
          setRows: setRows,
          skipFilter: true,
          selectedRows: selectedRows,
          setSelectedRows: setSelectedRows,
          formatNameCallback: (company) => {
            return company.name;
          },
        },
      },
    ],
  };

  const addNewUser = () => {
    return {
      id: null,
      username: "",
      email: "",
      password1: "",
      password2: "",
      companies: [],
    };
  };

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <div className="mx-2 px-2">
        {!loading ? (
          <Container fluid>
            <Modal centered={true} isOpen={showQRCode}>
              <h2 className="text-center days-one">
                {adminContext.isTrial ? "Trial " : ""}Authenticator App QR Code
                for User: {selectedUser.username}
              </h2>
              <div className="text-center">
                <img
                  src={
                    "data:image/png;base64," +
                    selectedUser.base_64_encoded_otp_secret_img
                  }
                  alt="Authenticator QR Code"
                  className="img-fluid w-25 h-25"
                />
              </div>
              <Button
                color="secondary"
                className="float-right mb-2 mx-2"
                onClick={() => setShowQRCode(false)}
              >
                OK
              </Button>
            </Modal>
            {alteredUsernameMessage && (
              <div className="alert alert-warning mt-5">
                {alteredUsernameMessage}
              </div>
            )}
            {editingUser ? (
              <EditForm
                saveChanges={saveSelectedUser}
                formSpec={userFormSpec}
                cancelCallback={() => {
                  setEditingUser(false);
                }}
                setModelObj={setSelectedUser}
                modelObj={selectedUser}
              />
            ) : (
              <>
                {" "}
                <Row className="align-items-center py-4">
                  <Col>
                    <h1 className="my-3" style={{ fontWeight: "300" }}>
                      User Management
                    </h1>
                    <p className="text-muted">
                      This screen is where you can manage any non-admin users so
                      that they can log into your Lightning Payroll online
                      account.
                    </p>
                    <Button
                      className=" my-3 width-40-on-lg"
                      color="primary"
                      onClick={() => {
                        setSelectedUser(addNewUser());
                        setEditingUser(true);
                      }}
                    >
                      <i className="fa-regular fa-user"></i> Add New User
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="5" className="d-flex flex-column">
                    <TableWidget
                      autoMinWidth={true}
                      label="Users"
                      rows={users}
                      columns={userColumns}
                      deleteRowCallback={deleteUser}
                      deleteConfirmationAttributes={["username", "email"]}
                      selected={selectedUser}
                      setSelected={setSelectedUser}
                      rowClickCallback={(event, newSelectedUser) => {
                        setSelectedUser(newSelectedUser);
                      }}
                      rowStyleCallback={(row) => {
                        if (isSelected(selectedUser, row)) {
                          return "bg-lightblue";
                        }
                        return "";
                      }}
                      beginEditCallback={(editingUser) => {
                        setSelectedUser(editingUser);
                        setEditingUser(true);
                      }}
                    />
                  </Col>
                  <Col lg="3" className="d-flex flex-column">
                    <TableWidget
                      autoMinWidth={true}
                      label="Companies"
                      rowClickCallback={(event, newSelectedCompany) => {
                        setSelectedCompany(newSelectedCompany);
                      }}
                      rows={companies}
                      columns={companyColumns}
                      rowStyleCallback={(row) => {
                        if (isSelected(selectedCompany, row)) {
                          return "bg-lightblue";
                        }
                        return "";
                      }}
                    />
                  </Col>
                  <Col lg="4" className="d-flex flex-column">
                    <TableWidget
                      autoMinWidth={true}
                      label="Permissions"
                      rows={permissions}
                      bordered={true}
                      rowClickCallback={(event, newSelectedPermission) => {
                        if (!adminContext.editAccess("settings")) {
                          return;
                        }
                        setSaving(true);
                        const index = event.target.cellIndex;

                        // Do nothing if clicking on the zone column
                        if (index === 0) {
                          setSaving(false);
                          return;
                        }
                        const newPermissions = permissions.map((permission) => {
                          if (permission === newSelectedPermission) {
                            permission.is_locked = index === 1 ? true : false;
                            permission.is_read_only =
                              index === 2 ? true : false;
                            permission.is_editable = index === 3 ? true : false;
                          }
                          return permission;
                        });
                        setPermissions(newPermissions);
                        updatePermission(newSelectedPermission);
                      }}
                      columns={permissionColumns}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </fieldset>
  );
};

export default UserManagement;
