import { useEffect, useState, useContext } from "react";
import { Button, Card, CardBody, Modal } from "reactstrap";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const SuperStreamSummaryView = ({
  setNextEnabled,
  wizardInfo,
  setButtonsVisible,
  handleNext,
  loading,
  setLoading,
}) => {
  const adminContext = useContext(AdminContext);
  const [infoLabel, setInfoLabel] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [messagesSent, setMessagesSent] = useState(false);

  const init = () => {
    setNextEnabled(false);
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/superstream-summary/init`,
      wizardInfo,
      (response) => {
        setLoading(false);
        setInfoLabel(response.data.info_label);
      }
    );
  };

  useEffect(() => {
    init();
  }, []);

  const sendMessages = () => {
    wizardInfo.superstream_messages.forEach((message) => {
      setLoading(true);
      adminContext.postRequest(
        adminContext.constants.BASE_URL +
          `/super-fund-deposits/send-message/${message.id}`,
        wizardInfo,
        (response) => {
          setModalMessage(response.data.response_message);
          setLoading(false);
          setNextEnabled(true);
          setMessagesSent(true);
        }
      );
      adminContext.setSuperStreamMessages([]);
    });
  };

  const launchDirectEntryWizard = () => {
    adminContext.setSuperStreamMessages(wizardInfo.superstream_messages);
    adminContext.history.push("/admin/superstream/direct_entry");
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-left">
            <h1 className="text-center">SuperStream Summary</h1>
            <p>
              The SuperStream contribution messages are ready to be sent. You
              can choose to send the messages now or later via the SuperStream
              Messages Mailbox. As well as you can choose to create a Direct
              Entry (ABA) file for the messages that were created as
              DirectCredit messages (not BPay).
            </p>

            <div
              className="my-2"
              dangerouslySetInnerHTML={{ __html: infoLabel }}
            ></div>
            <div className="text-center">
              <Button
                className="semi-active-button my-2 width-40-on-lg mx-auto"
                color="success"
                disabled={messagesSent}
                onClick={sendMessages}
              >
                <i
                  className="fas fa-paper-plane fa-xl
                       "
                ></i>{" "}
                <br />
                <span className="text-lg">Send Messages</span>
              </Button>
            </div>
            <div className="text-center">
              <Button
                className="semi-active-button my-2 width-40-on-lg mx-auto"
                color="secondary"
                disabled={!messagesSent}
                onClick={launchDirectEntryWizard}
              >
                <i
                  className="fas fa-university fa-xl
                       "
                ></i>{" "}
                <br />
                <span className="text-lg">Direct Entry</span>
              </Button>
            </div>
          </CardBody>
        </Card>
      )}
      <Modal isOpen={!!modalMessage} centered={true} className="width-40-on-lg">
        <div className="mx-3 my-3 text-center">
          <h2>Response Message</h2>
          <div
            className="my-2"
            dangerouslySetInnerHTML={{ __html: modalMessage }}
          ></div>
          <br />
          <Button
            color="secondary"
            className="float-right my-2 mx-2"
            onClick={() => {
              setModalMessage("");
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SuperStreamSummaryView;
