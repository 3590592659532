import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";
import TableWidget from "../../../components/Widgets/TableWidget.js";

const SpecialUserDefinedPayRates = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);

    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=pay_rates`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const columns = [
    {
      label: "Description",
      accessor: "description",
      widget: "Text",
    },
    {
      label: "Value",
      accessor: "value",
      align: "right",
      widget: "Text",
    },
    {
      label: "Units",
      accessor: "units",
      widget: "Text",
    },
    {
      label: "Is Overtime?",
      accessor: "is_overtime",
      widget: "BooleanLabel",
    },
  ];

  const createPayRate = () => {
    return {
      id: null,
      description: "",
      rate: { _decimal: 0 },
      rate_type: "SPECIAL",
      multiplier: 1,
      units: "Hours",
      is_overtime: false,
    };
  };

  useEffect(() => {
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.pay_rates]);

  const updatePayRates = (payRate) => {
    let newPayRates = [];
    if (payRate.id === null) {
      newPayRates = [...adminContext.currentEmployee.pay_rates, payRate];
    } else {
      newPayRates = adminContext.currentEmployee.pay_rates.map((pr) => {
        if (pr.id === payRate.id) {
          return payRate;
        } else {
          return pr;
        }
      });
    }
    setSaving(true);
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      pay_rates: newPayRates,
    });
  };

  const formSpec = {
    title: "Pay Rate",
    fields: [
      {
        label: "Description",
        accessor: "description",
        widget: "Text",
        validate: {
          required: true,
        },
      },
      {
        label: "Rate Type",
        accessor: "rate_type",
        widget: "ComboBox",
        required: true,
        args: {
          comboDataCallback: () => {
            return {
              SPECIAL: "Special",
              NORMAL: "Normal",
            };
          },
        },
      },
      {
        label: "Rate",
        accessor: "rate",
        widget: "MoneyEdit",
        args: {
          decimalPrecision: 5,
          visibleCallback: (payRate) => {
            return payRate.rate_type === "SPECIAL";
          },
        },
      },
      {
        label: "Multiplier",
        accessor: "multiplier",
        widget: "MultiplierEdit",
        args: {
          visibleCallback: (payRate) => {
            return payRate.rate_type === "NORMAL";
          },
        },
      },
      {
        label: "Units",
        accessor: "units",
        widget: "Text",
      },
      {
        label: "Is Overtime?",
        accessor: "is_overtime",
        widget: "CheckBox",
      },
    ],
  };
  const deletePayRate = (idToDelete) => {
    setSaving(true);
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      pay_rates: adminContext.currentEmployee.pay_rates.filter(
        (payRate) => payRate.id !== idToDelete
      ),
    });
  };
  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading || adminContext.currentEmployee.pay_rates === undefined ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Special User-Defined Pay Rates</h1>
                </Col>
              </Row>
              {!adminContext.currentEmployee.currentPayRate && (
                <Row>
                  <Col className="mt-3 mx-4">
                    <p>
                      Custom pay rates can be defined below. These can be
                      selected on the <strong>Pays</strong> screen for the
                      relevant employee.
                    </p>
                  </Col>
                </Row>
              )}
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={adminContext.currentEmployee.pay_rates}
                      columns={columns}
                      appendRowCallback={createPayRate}
                      editRowSaveCallback={updatePayRates}
                      deleteRowCallback={deletePayRate}
                      deleteConfirmationAttributes={["description", "value"]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SpecialUserDefinedPayRates;
