import EditForm from "../EditForm";
import dayjs from "dayjs";

const DateRangeView = ({ setNextEnabled, wizardInfo, setWizardInfo }) => {
  return (
    <EditForm
      liveEdit={true}
      shortDescription="Select the date range for the payslips you want to find and generate."
      formSpec={{
        fixedTitle: true,
        title: "Select Date Range",

        fields: [
          {
            label: "Start Date",
            accessor: "start_date",
            widget: "DateEdit",
            validate: {
              required: true,
            },
          },
          {
            label: "End Date",
            accessor: "end_date",
            widget: "DateEdit",
            validate: {
              required: true,
            },
          },
        ],
      }}
      setModelObj={setWizardInfo}
      modelObj={wizardInfo}
    />
  );
};

export default DateRangeView;
