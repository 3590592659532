import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget";

const BankAccountsTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [activeAccountId, setActiveAccountId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [bankAccounts, setBankAccounts] = useState(
    adminContext.currentEmployee.bank_accounts
  );

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/${adminContext.currentEmployee.id}/bank-accounts`,
      bankAccounts,
      (response) => {
        setIsLoading(false);
        setSaving(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [bankAccounts]);

  // Update bank accounts if the currentEmployee is changed
  useEffect(() => {
    setBankAccounts(adminContext.currentEmployee.bank_accounts);
  }, [adminContext.currentEmployee]);

  const setCurrentBankAccount = (bankAccount) => {
    // Set the employees current bank account to the current bank account
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentBankAccount: bankAccount,
    });
  };

  const changeOrder = (bankAccount, direction) => {
    // Move the bank account table row up or down in the currentEmployee.bank_accounts array

    // Set the active styling for easier usage
    setActiveAccountId(parseInt(bankAccount.id));

    let newBankAccounts = [...bankAccounts];
    let index = newBankAccounts.findIndex(
      (ba) => ba.id === parseInt(bankAccount.id)
    );

    let newIndex = index + direction;
    if (newIndex < 0 || newIndex >= newBankAccounts.length) {
      return;
    }
    // Update the rank field for all the bank accounts, where index 0 (default) should be rank=null
    newBankAccounts[index].rank = newIndex;
    newBankAccounts[newIndex].rank = index;
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      bank_accounts: newBankAccounts,
    });

    setSaving(true);
    setBankAccounts(newBankAccounts);
  };

  const sortedBankAccounts = () => {
    // Sort the bank accounts by the rank field, null should be index 0
    let sortedBankAccounts = bankAccounts;
    if (!sortedBankAccounts) {
      return [];
    }
    sortedBankAccounts.sort((a, b) => {
      if (a.rank === null) {
        return -1;
      } else if (b.rank === null) {
        return 1;
      } else {
        return a.rank - b.rank;
      }
    });
    return sortedBankAccounts;
  };

  const updateBankAccounts = (bankAccount) => {
    setSaving(true);
    let newBankAccounts = [];
    if (bankAccount.id === null) {
      newBankAccounts = [
        ...adminContext.currentEmployee.bank_accounts,
        bankAccount,
      ];
    } else {
      newBankAccounts = adminContext.currentEmployee.bank_accounts.map((ba) => {
        if (ba.id === bankAccount.id) {
          return bankAccount;
        } else {
          return ba;
        }
      });
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      bank_accounts: newBankAccounts,
    });
    setSaving(true);
    setBankAccounts(newBankAccounts);
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading &&
          adminContext.currentEmployee.bank_accounts !== undefined ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Bank Accounts</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>Default Account</h3>
                  <p>
                    The default account is where all pay goes, unless additional
                    accounts are defined below. The remainder of pay always get
                    deposited into the default account.
                  </p>
                  <h3>Additional Accounts</h3>
                  <p>
                    Specify additional accounts here if an employee requires
                    their pay to be split into multiple accounts. Any money not
                    paid into the additional accounts will be deposited into the
                    default account.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TableWidget
                    total={false}
                    orderBy="Priority"
                    rowStyleCallback={(row) => {
                      if (row.id === activeAccountId) {
                        return "bg-light";
                      }
                      return "";
                    }}
                    rows={sortedBankAccounts()}
                    columns={[
                      {
                        label: "Priority",
                        accessor: "rank",
                        widget: "text",
                        valueCallback: (row) => {
                          if (
                            row.rank === null ||
                            row.rank === undefined ||
                            row.rank === "" ||
                            row.rank === 0
                          ) {
                            return "Default";
                          } else {
                            return row.rank;
                          }
                        },
                      },
                      {
                        label: "Move Up",
                        accessor: "move_up",
                        iconOnly: true,
                        widget: "Button",
                        args: {
                          buttonIcon: "fas fa-arrow-up",
                          onClickCallback: (bankAccount) =>
                            changeOrder(bankAccount, -1),
                        },
                      },
                      {
                        label: "Move Down",
                        accessor: "move_down",
                        iconOnly: true,
                        widget: "Button",
                        args: {
                          buttonIcon: "fas fa-arrow-down",
                          onClickCallback: (bankAccount) =>
                            changeOrder(bankAccount, 1),
                        },
                      },
                      {
                        label: "Account Name",
                        accessor: "account_name",
                        widget: "text",
                      },
                      {
                        label: "BSB",
                        accessor: "bsb",
                        widget: "text",
                      },
                      {
                        label: "Account Number",
                        accessor: "account_number",
                        widget: "text",
                      },
                      {
                        label: "Amount",
                        accessor: "amount",
                        widget: "MoneyLabel",
                      },
                      {
                        label: "Alt. Transaction Reference",
                        accessor: "alt_transaction_reference",
                        widget: "text",
                      },
                    ]}
                    selected={adminContext.currentEmployee.currentBankAccount}
                    setSelected={setCurrentBankAccount}
                    appendRowCallback={() => {
                      return { id: null };
                    }}
                    editRowSaveCallback={updateBankAccounts}
                    deleteRowCallback={(idToDelete) => {
                      const newBankAccounts =
                        adminContext.currentEmployee.bank_accounts.filter(
                          (bankAccount) => bankAccount.id !== idToDelete
                        );
                      adminContext.setCurrentEmployee({
                        ...adminContext.currentEmployee,
                        bank_accounts: newBankAccounts,
                        currentBankAccount: false,
                      });
                      setSaving(true);
                      setBankAccounts(newBankAccounts);
                    }}
                    deleteConfirmationAttributes={[
                      "account_name",
                      "bsb",
                      "account_number",
                    ]}
                    formSpec={{
                      title: "Bank Account",
                      fields: [
                        {
                          label: "Account Name",
                          accessor: "account_name",
                          widget: "text",
                          validate: {
                            required: {
                              value: true,
                            },
                            minLength: { value: 1 },
                            maxLength: { value: 32 },
                          },
                        },
                        {
                          label: "Account Number",
                          accessor: "account_number",
                          widget: "text",
                          validate: {
                            required: {
                              value: true,
                              errorMessage: "Account number is required",
                            },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Only numbers are allowed",
                            },
                            minLength: { value: 5 },
                            maxLength: { value: 18 },
                          },
                        },
                        {
                          label: "BSB",
                          accessor: "bsb",
                          widget: "text",
                          validate: {
                            required: {
                              value: true,
                              errorMessage: "BSB is required",
                            },
                            pattern: { value: "^[0-9]{3}-?[0-9]{3}$" },
                            minLength: { value: 6 },
                            maxLength: { value: 7 },
                          },
                        },
                        {
                          label: "Amount",
                          accessor: "amount",
                          widget: "MoneyEdit",
                          required: false,
                        },
                        {
                          label: "Alt. Transaction Reference",
                          accessor: "alt_transaction_reference",
                          widget: "text",
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default BankAccountsTab;
