import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import EditForm from "../../components/Widgets/EditForm";
import { useEffect, useState, useContext } from "react";

import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import BackButton from "../../components/Widgets/BackButton";

import AdminContext from "../../AdminContext";

import ComboBox from "../../components/Widgets/ComboBox";
import DateLabel from "../../components/Widgets/DateLabel";

const PayDates = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const [comboData, setComboData] = useState({
    relative_pay_period_type: {
      Closest: "Closest",
      Past: "Past",
      Present: "Present",
      Future: "Future",
    },
    weekly_pay_date: {},
    fortnightly_pay_date: {},
    monthly_pay_date: {},
  });

  const processResponse = (response) => {
    setIsLoading(false);
    setComboData({
      ...comboData,
      weekly_pay_date: response.data.weekly_pay_date,
      fortnightly_pay_date: response.data.fortnightly_pay_date,
      monthly_pay_date: response.data.monthly_pay_date,
    });
  };

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/pay_dates`,
      (response) => {
        processResponse(response);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setEditing(false);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}?route=attrs_only&set_current_pay_dates=true`,
      adminContext.company,
      (response) => {
        processResponse(response);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.relative_pay_period_type]);

  const formSpec = {
    title: "Company Pay Dates",
    shortDescription: (
      <>
        Edit the company pay dates for the company. Each company has three
        different pay cycles including weekly, fortnightly and monthly. You can
        use one or any combination of these.
        <br />
        Note: This screen lets you select the relative end day of your pay cycle
        and not the one that is currently shown on the pays screen.
      </>
    ),
    fields: [
      {
        label: "Weekly Pay Date",
        accessor: "weekly_pay_date",
        widget: "ComboBox",
        args: {
          skipSort: true,
          comboDataCallback: () => {
            return comboData["weekly_pay_date"];
          },
        },
      },
      {
        label: "Fortnightly Pay Date",
        accessor: "fortnightly_pay_date",
        widget: "ComboBox",
        args: {
          skipSort: true,
          comboDataCallback: () => {
            return comboData["fortnightly_pay_date"];
          },
        },
      },
      {
        label: "Monthly Pay Date",
        accessor: "monthly_day",
        widget: "ComboBox",
        args: {
          sortCallback: (a, b) => {
            return a - b;
          },

          comboDataCallback: () => {
            return comboData["monthly_pay_date"];
          },
        },
      },
    ],
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              {" "}
              <Row>
                <Col md="2">
                  {" "}
                  <BackButton />
                </Col>
                {!adminContext.nonDeletedEmployees() ||
                adminContext.nonDeletedEmployees().length === 0 ? (
                  <>
                    <Col col md="3">
                      <Button
                        onClick={() =>
                          adminContext.history.push(
                            "/admin/employees/details/personal"
                          )
                        }
                        color="primary"
                        className="semi-active-button mb-2"
                      >
                        Next, let's add an employee
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          adminContext.history.push(
                            "/admin/employees/import_employees"
                          );
                        }}
                        color="primary"
                        className="semi-active-button  mb-2"
                      >
                        Or upload a CSV file to import existing employee data
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className='mt-2'>
                <Col>
                  <h1 className="days-one">Pay Dates</h1>
                </Col>
              </Row>
              {!editing ? (
                <Row>
                  <Col className="mx-4 my-3">
                    {!saving ? (
                      <>
                        <Row>
                          <p>
                            Choose whether you want to enter pay data for the
                            closest, past, present or future pay period from
                            today. If you are unsure how this works please stick
                            with 'Closest' as it is usually the best option.
                          </p>
                        </Row>
                        <Row>
                          <Col>
                            <ComboBox
                              className="mx-3"
                              label="Award"
                              disabled={!adminContext.editAccess("company")}
                              stretch={true}
                              name="award"
                              comboData={comboData["relative_pay_period_type"]}
                              selectedComboItem={
                                adminContext.company.relative_pay_period_type
                              }
                              setSelectedComboItem={(data) => {
                                setSaving(true);
                                adminContext.setCompany({
                                  ...adminContext.company,
                                  relative_pay_period_type: data,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <p>
                            You will see pays ending on the following dates on
                            the Pays screen. If you only wish to use fortnightly
                            pays (for example) then you may ignore the weekly
                            and monthly pay dates.
                          </p>
                        </Row>
                        <Row className="mt-2">
                          <Col md="2">
                            <strong>Weekly Pay End Date</strong>
                          </Col>
                          <Col md="2">
                            <DateLabel
                              value={adminContext.company.weekly_pay_date}
                              format="DD MMMM YYYY"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md="2">
                            <strong>Fortnightly Pay End Date</strong>
                          </Col>
                          <Col md="2">
                            <DateLabel
                              value={adminContext.company.fortnightly_pay_date}
                              format="DD MMMM YYYY"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md="2">
                            <strong>Monthly Pay End Date</strong>
                          </Col>
                          <Col md="2">
                            <DateLabel
                              value={adminContext.company.monthly_pay_date}
                              format="DD MMMM YYYY"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <strong>Monthly Day</strong>
                          </Col>
                          <Col md="2">
                            <span>
                              {adminContext.company.monthly_day_friendly}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <Button
                              className="width-100 text-center mx-auto my-2"
                              disabled={!adminContext.editAccess("company")}
                              onClick={() => {
                                setEditing(true);
                              }}
                            >
                              {" "}
                              <i className="fa-regular fa-edit fa-xl mx-0"></i>
                              <br />
                              Edit
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
              ) : (
                <EditForm
                  saveChanges={saveChanges}
                  formSpec={formSpec}
                  cancelCallback={() => {
                    setEditing(false);
                  }}
                  modelObj={adminContext.company}
                  setModelObj={adminContext.setCompany}
                />
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default PayDates;
