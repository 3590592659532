import React from "react";
import { Modal, Button } from "reactstrap";

function PostCompleteModal({ messages, currentIndex, onNext }) {
  return (
    <Modal
      isOpen={messages.length > 0}
      centered={true}
      className="width-40-on-lg d-flex align-items-center"
    >
      <div className="m-3 text-center">
        <h1 className="my-3">
          <i className="fa-regular fa-triangle-exclamation text-warning fa-2xl my-3" />
        </h1>
        <h2>{messages[currentIndex][0]}</h2> {/* Title */}
        <div
          className="my-2 style-links"
          dangerouslySetInnerHTML={{ __html: messages[currentIndex][1] }}
        ></div>
        <Button className="my-2" onClick={onNext}>
          Continue
        </Button>
      </div>
    </Modal>
  );
}

export default PostCompleteModal;
