import { useState, useEffect, useContext } from "react";
import AdminContext from "../../AdminContext";

function DaysEdit(props) {
  const [text, setText] = useState("");
  const adminContext = useContext(AdminContext);

  function convertMsToDays(milliseconds) {
    // Returns the number of days to 5 decimal places.
    return (milliseconds / 1000 / 60 / 60 / 24).toFixed(5);
  }

  const isNegative = () => {
    return props.value < 0;
  };

  const processTime = () => {
    // Update props.setValue with the new value in milliseconds
    let milliseconds = Number(text) * 1000 * 60 * 60 * 24;
    props.setValue(milliseconds);
  };

  // Initialise the text value using props.value milliseconds
  useEffect(() => {
    setText(convertMsToDays(props.value));
  }, []);

  return (
    <input
      value={text}
      onBlur={processTime}
      className={
        isNegative()
          ? "form-control width-100 text-danger"
          : "form-control width-100"
      }
      onChange={(e) => setText(e.target.value)}
    />
  );
}

export default DaysEdit;
