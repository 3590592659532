// Import all ReportTemplates components
import Allowances from "../views/Reports/ReportTemplates/Allowances";
import BackupHistory from "../views/Reports/ReportTemplates/BackupHistory";
import Deductions from "../views/Reports/ReportTemplates/Deductions";
import Departmental from "../views/Reports/ReportTemplates/Departmental";
import DirectEntrySavedFile from "../views/Reports/ReportTemplates/DirectEntrySavedFile";
import EmailSentReport from "../views/Reports/ReportTemplates/EmailSentReport";
import EmployeeCompanyNotes from "../views/Reports/ReportTemplates/EmployeeCompanyNotes";
import EmployeeHours from "../views/Reports/ReportTemplates/EmployeeHours";
import EmployeeShiftRepeats from "../views/Reports/ReportTemplates/EmployeeShiftRepeats";
import EmployeeUserDefinedPayRates from "../views/Reports/ReportTemplates/EmployeeUserDefinedPayRates";
import ForecastLeaveBalance from "../views/Reports/ReportTemplates/ForecastLeaveBalance";
import GlPostingsWithDates from "../views/Reports/ReportTemplates/GlPostingsWithDates";
import GroupTax from "../views/Reports/ReportTemplates/GroupTax";
import HourlyLeaveAccrual from "../views/Reports/ReportTemplates/HourlyLeaveAccrual";
import LeaveTaken from "../views/Reports/ReportTemplates/LeaveTaken";
import LeaveCalendar from "../views/Reports/ReportTemplates/LeaveCalendar";
import MonthlyTax from "../views/Reports/ReportTemplates/MonthlyTax";
import NetBalancingReport from "../views/Reports/ReportTemplates/NetBalancingReport";
import PayRun from "../views/Reports/ReportTemplates/PayRun";
import PaySummary from "../views/Reports/ReportTemplates/PaySummary";
import PostTaxSuperContributions from "../views/Reports/ReportTemplates/PostTaxSuperContributions";
import Resc from "../views/Reports/ReportTemplates/Resc";
import SalarySacrifice from "../views/Reports/ReportTemplates/SalarySacrifice";
import StpFinalisedEmployees from "../views/Reports/ReportTemplates/StpFinalisedEmployees";
import SuperDeposits from "../views/Reports/ReportTemplates/SuperDeposits";
import SuperLevy from "../views/Reports/ReportTemplates/SuperLevy";
import SuperstreamContributions from "../views/Reports/ReportTemplates/SuperstreamContributions";
import UnpaidSuper from "../views/Reports/ReportTemplates/UnpaidSuper";
import Terminations from "../views/Reports/ReportTemplates/Terminations";
import Timeclock from "../views/Reports/ReportTemplates/Timeclock";
import WorkingHolidayTax from "../views/Reports/ReportTemplates/WorkingHolidayTax";
import PaySummaryDetailed from "../views/Reports/ReportTemplates/PaySummaryDetailed";

var reportNames = {
  allowances: {
    category: "Pay",
    name: "Allowances Report",
    short_description: "Shows allowances for pays made within chosen dates.",
    component: Allowances,
  },
  backup_history: {
    category: "General",
    name: "Backup History Report",
    component: BackupHistory,
    short_description:
      "Displays backup reation and restoration history between chosen dates.",
  },
  deductions: {
    category: "Pay",
    name: "Deductions Report",
    short_description: "Shows deductions from pays made within chosen dates.",
    component: Deductions,
  },
  departmental: {
    category: "Pay",
    name: "Departmental Report",
    short_description:
      "Shows pays made within chosen dates, grouped by department (if configured).",
    component: Departmental,
  },
  direct_entry_saved_file: {
    category: "General",
    name: "Direct Entry Saved File Report",
    short_description:
      "Displays direct entry file history/details for super and pays, between chosen dates.",
    component: DirectEntrySavedFile,
  },
  employee_company_notes: {
    category: "General",
    name: "Notes Report",
    component: EmployeeCompanyNotes,
    short_description:
      "Displays notes for employees and companies, between chosen dates.",
  },
  employee_hours: {
    category: "Pay",
    name: "Employee Hours Report",
    short_description: "Shows the hours worked and pay rates used within pays.",
    component: EmployeeHours,
  },
  employee_shift_repeats: {
    name: "Employee Shift Repeats",
    component: EmployeeShiftRepeats,
    short_description:
      "Shows which employee shifts have been set to repeat (be auto-rostered) on certain days.",
    category: "General",
  },
  employee_user_defined_pay_rates: {
    category: "Pay",
    name: "Special User Defined Pay Rates Report",
    short_description:
      "Displays the details of customised pay rates used for pays made between the chosen dates.",
    component: EmployeeUserDefinedPayRates,
  },
  gl_postings_with_dates: {
    category: "Pay",
    name: "Suggested GL postings",
    short_description:
      "Shows the general ledger postings information from pays, useful for entering into accounting software.",
    component: GlPostingsWithDates,
  },
  group_tax: {
    category: "Tax",
    name: "Group Tax Report",
    short_description:
      "Shows the breakdown of tax amounts (including HELP, TSL, etc.) per employee within a chosen timeframe.",
    component: GroupTax,
  },
  hourly_leave_accrual: {
    name: "Hourly Leave Accruals",
    component: HourlyLeaveAccrual,
    short_description:
      "Shows a breakdown of the hourly leave accruals and amounts for the three main leave types for employees within a chosen timeframe.",
    category: "Leave",
  },
  forecast_leave_balance: {
    name: "Forecast Leave Balance",
    component: ForecastLeaveBalance,
    short_description:
      "Based on their current settings, estimate what leave balance an employee should have at a future date.",
    category: "Leave",
  },
  leave_taken: {
    category: "Leave",
    name: "Leave Taken",
    short_description:
      "Shows both leave taken and leave accrued within the chosen timeframe for employees. Very useful for troubleshooting issues with leave accruals or balances.",
    component: LeaveTaken,
  },
  leave_calendar: {
    category: "Leave",
    name: "Leave Request Calendar",
    short_description:
      "Shows a calendar style view of upcoming leave requests.",
    component: LeaveCalendar,
  },
  monthly_tax: {
    category: "Tax",
    name: "YTD Monthly Tax Deductions",
    short_description: "Shows monthly YTD tax amounts between chosen dates.",
    component: MonthlyTax,
  },
  pay_run: {
    category: "Pay",
    short_description:
      "Shows a breakdown of pays within a chosen timeframe, grouped by pay period.",
    name: "Pay Run Report",
    component: PayRun,
  },
  pay_summary: {
    category: "Pay",
    short_description:
      "Shows a breakdown of pays within a chosen timeframe, grouped by employee.",
    name: "Pay Summary",
    component: PaySummary,
  },
  post_tax_super_contributions: {
    name: "Post-Tax Superannuation Contributions",
    component: PostTaxSuperContributions,
    short_description:
      "Shows any post-tax superannuation contributions (voluntary member contributions, not salary sacrifice) for employees within a chosen timeframe.",
    category: "Super",
  },
  resc: {
    name: "Reportable Employer Superannuation Contributions (RESC)",
    short_description:
      "Displays super sacrifice and non-sacrifice RESC amounts intended for Superannuation.",
    component: Resc,
    category: "Super",
  },
  salary_sacrifice: {
    name: "Salary Sacrifice Report",
    component: SalarySacrifice,
    short_description:
      "Shows salary sacrifice amounts within a chosen timeframe - this includes both super and non-super salary sacrifices.",
    category: "Pay",
  },
  stp_finalised_employees: {
    name: "STP Finalised Employees For Financial Year",
    component: StpFinalisedEmployees,
    short_description:
      "Shows Single Touch EOFY finalisation information for the selected employees between the chosen dates, useful for determining if employees have been finalised at the end of the financial year.",
    category: "General",
  },
  super_deposits: {
    category: "Super",
    name: "Super Fund Deposits",
    short_description:
      "Lists the super deposits made in the Super Fund Deposits screen within the chosen timeframe; deposits are picked up by deposit date a.k.a. the day you created the deposit.",
    component: SuperDeposits,
  },
  super_levy: {
    category: "Super",
    name: "Super Levy Report",
    short_description:
      "Shows super guarantee amounts for employees within chosen dates; does not include super sacrifice or RESC amounts.",
    component: SuperLevy,
  },
  superstream_contributions: {
    category: "Super",
    name: "SuperStream Contributions",
    component: SuperstreamContributions,
    short_description:
      "Displays details of SuperStream Contribution messages including ABA reference numbers and amounts, between the chosen dates.",
  },
  terminations: {
    category: "General",
    name: "Terminations Preview",
    component: Terminations,
    short_description:
      "Displays a breakdown of an employees termination payout details, useful to get a comprehensive description of an employees termination.",
  },
  timeclock: {
    category: "General",
    name: "Timeclock",
    component: Timeclock,
    short_description:
      "View raw timeclock/timesheet server data for shifts clocked online.",
  },
  unpaid_super: {
    category: "Super",
    name: "Super Liability Report",
    component: UnpaidSuper,
    short_description:
      "Can show super not yet in a deposit, which can be useful for determining how much super is owed to employees.",
  },

  working_holiday_tax: {
    name: "Working Holiday Tax Scale Pays",
    component: WorkingHolidayTax,
    short_description:
      "Shows any pays that used the Working Holiday Tax Scale within the chosen timeframe. Useful for spotting when the tax increases as the YTD is also shown.",
    category: "Tax",
  },
  pay_summary_detailed: {
    name: "Pay Summary (Detailed)",
    component: PaySummaryDetailed,
    short_description:
      "Shows a detailed overview of all pay items within a chosen timeframe, for selected employees.",
    category: "Pay",
  },
  net_balancing_report: {
    name: "Net Balancing Report",
    component: NetBalancingReport,
    short_description:
      "Shows a breakdown of how the net amount of pays within the chosen timeframe is calculated, by displaying the figures that take you from raw gross (pre-sacrifice gross) to net.",
    category: "Pay",
  },
  email_sent_report: {
    name: "Email Sent Report",
    component: EmailSentReport,
    short_description:
      "Displays details of emails sent out of Lightning Payroll, useful for determining if emails are being sent successfully, and for troubleshooting.",
    category: "General",
  },
};

export default reportNames;
