import { useState, useContext, useEffect } from "react";
import AdminContext from "../../AdminContext";
import { Button, Card, CardBody, Input, Container } from "reactstrap";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const ServerLogs = () => {
  const adminContext = useContext(AdminContext);
  const [logData, setLogData] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to fetch log data
  const fetchLogData = () => {
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/admin/log",
      (response) => {
        setLogData(response.data.log);
      }
    );
  };

  useEffect(() => {
    fetchLogData();
  }, []);

  const downloadLog = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/admin/download-log",
      (response) => {
        setLoading(false);
        const logData = "data:text/plain;base64," + response.data.log_data;

        const downloadLink = document.createElement("a");
        const fileName = "lp_web_app.log";
        downloadLink.href = logData;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    );
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <h1>Real-time Log Data </h1>
              <Button onClick={downloadLog} className="semi-active-button">
                <i className="fa-regular fa-cloud-arrow-down" /> Download
              </Button>
              <br />
              <pre className="my-3 p-3">{logData}</pre>
            </div>
          )}
        </CardBody>
      </Card>
      {/* Floating Refresh Button */}
      <Button
        style={{ position: "fixed", bottom: "20px", right: "20px" }}
        onClick={fetchLogData}
      >
        <i className="fa fa-refresh" /> Refresh
      </Button>
    </Container>
  );
};

export default ServerLogs;
