import React, { useState, useContext, useEffect } from "react";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";

import Wizard from "../../../../components/Widgets/Wizard";
import IntroView from "./IntroView";
import ReasonDateView from "./ReasonDateView";
import DateOfBirthView from "./DateOfBirthView";
import RedundancyView from "./RedundancyView";
import NonEtpPayoutOptionsView from "./NonEtpPayoutOptionsView";
import EtpPayoutOptionsView from "./EtpPayoutOptionsView";
import AdvancedSettingsView from "./AdvancedSettingsView";
import AdvancedEtpEditView from "./AdvancedEtpEditView";
import SummaryView from "./SummaryView";
import AdminContext from "../../../../AdminContext";

const TerminationWizard = (props) => {
  const adminContext = useContext(AdminContext);
  const [pay, setPay] = useState(adminContext.pay);
  const [payTermination, setPayTermination] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);

  const initPayTermination = async (setLoadingCallback) => {
    setLoadingCallback(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/pays/termination-init/${adminContext.pay.id}`,
      (response) => {
        setPayTermination(response.data.pay_termination);
        setLoadingCallback(false);
      }
    );
  };

  useEffect(() => {
    initPayTermination(setLoading);
  }, []);

  const refreshPayTermination = async (setLoadingCallback) => {
    setLoadingCallback(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/refresh-termination/${pay.id}`,
      payTermination,
      (response) => {
        setLoadingCallback(false);
        setPayTermination(response.data.pay_termination);
      }
    );
  };

  const savePayTermination = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        "/pays/save-termination/" +
        adminContext.pay.id,
      payTermination,
      (response) => {
        setLoading(false);
        props.cancelCallback();
      }
    );
  };

  const wizardSteps = [
    <IntroView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <ReasonDateView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <DateOfBirthView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <RedundancyView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <NonEtpPayoutOptionsView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <EtpPayoutOptionsView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <AdvancedSettingsView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <AdvancedEtpEditView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      setNextEnabled={setNextEnabled}
      refreshPayTermination={refreshPayTermination}
    />,
    <SummaryView
      pay={pay}
      payTermination={payTermination}
      setPayTermination={setPayTermination}
      refreshPayTermination={refreshPayTermination}
    />,
  ];

  const handleNext = () => {
    // We need to carefully set which step is appropriate as the next step, depending on values in the form
    if (step === 1) {
      // ReasonDateView - if the pay.employee.date_of_birth is invalid, go to the dateofbirth view, otherwise if  the reason is "Redundancy" then go to the RedundancyView, otherwise go to the NonEtpPayoutOptionsView
      if (payTermination.reason === "REDUNDANCY") {
        setStep(3);
      } else if (pay.employee.date_of_birth === null) {
        setStep(2);
      } else {
        setStep(4);
      }
    } else if (step === 2) {
      // DateOfBirthView - if  the reason is "Redundancy" then go to the RedundancyView, otherwise go to the NonEtpPayoutOptionsView
      if (payTermination.reason === "REDUNDANCY") {
        setStep(3);
      } else {
        setStep(4);
      }
    } else if (step == 5) {
      // EtpPayoutOptionsView - if the etp_payout_option is "Advanced" then go to the AdvancedSettingsView and then the advancedetpeditview, otherwise go to the SummaryView
      if (payTermination.is_manual_leave_amounts) {
        setStep(6);
      } else {
        setStep(8);
      }
    } else if (step < 8) {
      setStep(step + 1);
    } else {
      savePayTermination();
    }
  };

  const handleBack = () => {
    setPayTermination({
      ...payTermination,
      requires_tax_recalc: false,
    });
    // Clicking back needs to take into account the logic used above to skip over the unnecessary steps
    if (step === 3) {
      // RedundancyView - if the pay.employee.date_of_birth is invalid, go to the dateofbirth view, otherwise go to the ReasonDateView
      if (pay.employee.date_of_birth === null) {
        setStep(2);
      } else {
        setStep(1);
      }
    } else if (step === 4) {
      // NonEtpPayoutOptionsView - if the pay.employee.date_of_birth is invalid, go to the dateofbirth view, otherwise go to the ReasonDateView
      if (pay.employee.date_of_birth === null) {
        setStep(2);
      } else {
        setStep(1);
      }
    } else if (step === 8) {
      // SummaryView - if the etp_payout_option is "Advanced" then go to the AdvancedSettingsView, otherwise go to the EtpPayoutOptionsView
      if (payTermination.is_manual_leave_amounts) {
        setStep(7);
      } else {
        setStep(5);
      }
    } else if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Wizard
          wizardSteps={wizardSteps}
          handleNext={handleNext}
          handleBack={handleBack}
          cancelCallback={props.cancelCallback}
          nextEnabled={nextEnabled}
          isBackDisabled={() => step === 0 || step === 8}
          step={step}
          setStep={setStep}
          getLastStep={() => wizardSteps.length - 1}
        />
      )}
    </>
  );
};
export default TerminationWizard;
