import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const CustomAvField = ({ infoText, ...props }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const tooltipCloseTimeout = React.useRef(null);

  const showTooltip = () => {
    clearTimeout(tooltipCloseTimeout.current);
    setTooltipOpen(true);
  };

  const hideTooltip = () => {
    tooltipCloseTimeout.current = setTimeout(() => {
      setTooltipOpen(false);
    }, 3000); // Delay before the tooltip disappears
  };

  const fieldId = `field_${props.name}`;
  const tooltipId = `tooltip_${props.name}`;

  return (
    <div>
      <AvField
        id={fieldId}
        {...props}
        label={
          <>
            {props.label}
            {infoText && (
              <>
                {" "}
                <FontAwesomeIcon
                  id={tooltipId}
                  icon={faInfoCircle}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={tooltipId}
                  toggle={hideTooltip}
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                >
                  {infoText}
                </Tooltip>
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default CustomAvField;
