import TableWidget from "../../../components/Widgets/TableWidget";
import { useState, useEffect } from "react";

const EditPayLeaveBalances = ({ adminContext, updateBackendPay }) => {
  const [saving, setSaving] = useState(false);
  const saveLeaveBalanceRow = (leaveBalanceRow) => {
    setSaving(true);
    let newLeaveBalanceRows = adminContext.pay.leave_balance_rows.map((ba) => {
      if (ba.leave_type === leaveBalanceRow.leave_type) {
        return leaveBalanceRow;
      } else {
        return ba;
      }
    });

    adminContext.setPay({
      ...adminContext.pay,
      leave_balance_rows: newLeaveBalanceRows,
    });
  };

  useEffect(() => {
    if (saving) {
      updateBackendPay("leave_balances");
      setSaving(false);
    }
  }, [adminContext.pay]);

  return (
    <>
      <TableWidget
        autoMinWidth={true}
        label="Leave Balances"
        rows={adminContext.pay.leave_balance_rows.map((row) => {
          return {
            ...row,
            id: row.leave_type,
          };
        })}
        editRowSaveCallback={saveLeaveBalanceRow}
        columns={[
          {
            label: "Leave Type",
            accessor: "name",
            widget: "Text",
          },
          {
            label: "Balance",
            accessor: "amount",
            widget: "DurationLabel",
            align: "right",
          },
        ]}
        formSpec={{
          title: "Leave Balances",
          fields: [
            {
              label: "Leave Type",
              accessor: "name",
              widget: "ReadOnlyText",
            },
            {
              label: "Balance",
              accessor: "amount",
              widget: "DurationEdit",
            },
          ],
        }}
      />
    </>
  );
};
export default EditPayLeaveBalances;
