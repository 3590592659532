import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  Alert,
} from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ListWidget from "../../../components/Widgets/ListWidget.js";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import moment from "moment";
import AdminContext from "../../../AdminContext";

import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import CheckBox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

const OnlinePortalTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [refreshResponse, setRefreshResponse] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rows, setRows] = useState({
    working_days: adminContext.constants.WORKING_DAYS,
    leave_types: [
      { id: "HOLIDAY", format_name: "HOLIDAY" },
      { id: "SICK", format_name: "SICK" },
      { id: "LONG SERVICE", format_name: "LONG SERVICE" },
      { id: "OTHER", format_name: "OTHER" },
      { id: "CUSTOM", format_name: "CUSTOM" },
      { id: "OTHER", format_name: "OTHER" },
      { id: "PAID PARENTAL", format_name: "PAID PARENTAL" },
      {
        id: "EMPLOYER FUNDED PAID PARENTAL",
        format_name: "EMPLOYER FUNDED PAID PARENTAL",
      },
      { id: "WORKERS COMP", format_name: "WORKERS COMP" },
      { id: "COMPASSIONATE", format_name: "COMPASSIONATE" },
      { id: "ANCILLARY", format_name: "ANCILLARY" },
      { id: "PAID PUBLIC HOLIDAY", format_name: "PAID PUBLIC HOLIDAY" },
      { id: "UNPAID", format_name: "UNPAID" },
    ],
  });

  const [selectedRows, setSelectedRows] = useState({});

  const initView = async () => {
    let selectedRows = {};
    adminContext.company.employees.map((employee) => {
      let workingDays = rows.working_days.filter((day) =>
        employee.working_days.includes(day.id)
      );
      let leaveTypes = rows.leave_types.filter((type) =>
        employee.available_leave_request_types.includes(type.id)
      );

      selectedRows[employee.id] = {
        working_days: workingDays,
        leave_types: leaveTypes,
      };
    });
    setSelectedRows(selectedRows);

    setIsLoading(false);
  };
  useEffect(() => {
    initView();
  }, []);

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      // if timeclock or timecheet mode are ticked the other must be unticked as only one can be active at a time.
      if (
        event.target.name === "allow_edit_timeclock" &&
        event.target.checked
      ) {
        adminContext.setCurrentEmployee({
          ...adminContext.currentEmployee,
          allow_edit_timesheet: !event.target.checked,
          [event.target.name]: event.target.checked,
        });
      } else if (
        event.target.name === "allow_edit_timesheet" &&
        event.target.checked
      ) {
        adminContext.setCurrentEmployee({
          ...adminContext.currentEmployee,
          allow_edit_timeclock: !event.target.checked,
          [event.target.name]: event.target.checked,
        });
      } else {
        adminContext.setCurrentEmployee({
          ...adminContext.currentEmployee,
          [event.target.name]: event.target.checked,
        });
      }
    } else if (event.target.type === "date") {
      const newDate = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: newDate,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };

  const saveChanges = () => {
    setSaving(true);

    // Update the current employee's working_days and available_leave_request_types
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      working_days: selectedRows[
        adminContext.currentEmployee.id
      ].working_days.map((day) => day.id),
      available_leave_request_types: selectedRows[
        adminContext.currentEmployee.id
      ].leave_types.map((type) => type.id),
    });
  };

  // Update the API on submit
  const submitForm = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const refreshPortal = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/refresh-portal`,
      adminContext.currentEmployee,
      (response) => {
        console.log(response);
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.message) {
            setRefreshResponse(response.data.message);
          } else {
            setErrorMessage("");
            setSuccessMessage("Changes saved successfully");
          }
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  // Call submit form if saving and if the employee is changed
  useEffect(() => {
    if (saving) {
      submitForm();
      setSaving(false);
    }
  }, [adminContext.currentEmployee]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Online Portal Settings</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />
              {adminContext.currentEmployee.online_portal_error && (
                <Alert color="warning">
                  {adminContext.currentEmployee.online_portal_error} (Errors can
                  occur if an employee is set for timeclock/timesheet and no
                  shifts exist, or if they are missing an email or D.O.B.)
                </Alert>
              )}
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h2>General Settings</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="my-1">
                      <div key={adminContext.currentEmployee.id}>
                        <FormControlLabel
                          label={
                            <>
                              <strong>Activate </strong>
                              <span>this employee's </span>
                              <strong>online portal?</strong>
                            </>
                          }
                          control={
                            <CheckBox
                              checked={
                                adminContext.currentEmployee
                                  .employee_portal_active
                              }
                              className="mx-2"
                              onChange={handleChange}
                              type="checkbox"
                              name="employee_portal_active"
                            />
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  {adminContext.currentEmployee.employee_portal_active ? (
                    <>
                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to edit their </span>
                                  <strong>personal details</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_edit_account_details
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_edit_account_details"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to view their </span>
                                  <strong>payslips</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_view_payslips
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_view_payslips"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to edit their </span>
                                  <strong>bank accounts</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_edit_bank_accounts
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_edit_bank_accounts"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to edit their </span>
                                  <strong>timesheet</strong>? (Disables
                                  timeclock mode)
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_edit_timesheet
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_edit_timesheet"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>
                                    Allow employee to clock in and out through
                                    the{" "}
                                  </span>
                                  <strong>timeclock</strong>? (Disables
                                  timesheet mode)
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_edit_timeclock
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_edit_timeclock"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to create </span>
                                  <strong>leave requests</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_edit_leave_requests
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_edit_leave_requests"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>
                                    Do not warn when approving leave requests
                                    into a{" "}
                                  </span>
                                  <strong>negative balance</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_edit_leave_requests_when_negative
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_edit_leave_requests_when_negative"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to view their </span>
                                  <strong>annual/holiday leave balance</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_view_holiday_leave_balance
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_view_holiday_leave_balance"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to view their </span>
                                  <strong>
                                    personal/sick/carer's leave balance
                                  </strong>
                                  ?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_view_sick_leave_balance
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_view_sick_leave_balance"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="my-1">
                          <div key={adminContext.currentEmployee.id}>
                            <FormControlLabel
                              label={
                                <>
                                  <span>Allow employee to view their </span>
                                  <strong>long service leave balance</strong>?
                                </>
                              }
                              control={
                                <CheckBox
                                  checked={
                                    adminContext.currentEmployee
                                      .allow_view_long_service_leave_balance
                                  }
                                  className="mx-2"
                                  onChange={handleChange}
                                  type="checkbox"
                                  name="allow_view_long_service_leave_balance"
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </Col>
                {adminContext.currentEmployee.employee_portal_active &&
                adminContext.currentEmployee.allow_edit_leave_requests ? (
                  <Col>
                    {" "}
                    <Row>
                      <Col>
                        <h2>Available Leave Request Days</h2>
                        <p>
                          These are the days of the week on which an employee
                          can make leave requests within our online employee
                          portal.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div key={adminContext.currentEmployee.id}>
                          <ListWidget
                            name="working_days"
                            rows={rows}
                            setRows={setRows}
                            selectedRows={
                              selectedRows[adminContext.currentEmployee.id]
                            }
                            setSelectedRows={(newRows) => {
                              setSelectedRows({
                                ...selectedRows,
                                [adminContext.currentEmployee.id]: newRows,
                              });
                            }}
                            sortCallback={(a, b) => {
                              const daysOrder = [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                              ];
                              return (
                                daysOrder.indexOf(a.id) -
                                daysOrder.indexOf(b.id)
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              {adminContext.currentEmployee.employee_portal_active &&
              adminContext.currentEmployee.allow_edit_leave_requests ? (
                <>
                  {" "}
                  <Row>
                    <Col>
                      <h2>Available Leave Request Types</h2>
                      <p>
                        These are the leave types for which an employee can make
                        a leave request.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="my-1">
                      <Row>
                        <Col>
                          <div key={adminContext.currentEmployee.id}>
                            <ListWidget
                              name="leave_types"
                              rows={rows}
                              setRows={setRows}
                              selectedRows={
                                selectedRows[adminContext.currentEmployee.id]
                              }
                              setSelectedRows={(newRows) => {
                                setSelectedRows({
                                  ...selectedRows,
                                  [adminContext.currentEmployee.id]: newRows,
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Row>
                <Col className="my-1">
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <Button
                          name="refresh_portal"
                          color="success"
                          onClick={refreshPortal}
                        >
                          Refresh Employee Portal
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Modal isOpen={!!refreshResponse} centered>
                <div className="mx-4 my-4 ">
                  <div dangerouslySetInnerHTML={{ __html: refreshResponse }} />

                  <Button
                    color="secondary"
                    className="float-right mb-2 mx-2"
                    onClick={() => setRefreshResponse("")}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default OnlinePortalTab;
