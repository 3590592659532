import AdminContext from "../../AdminContext";
import { useState, useEffect, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { Card, CardBody } from "reactstrap";

const LoginToMyAccount = () => {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(false);

  const sendBackup = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/tools/instant-login-link`,
      (response) => {
        if (response.data.login_link) {
          window.open(response.data.login_link, "_blank");
          // go back to the previous page
          window.history.back();
        }
      }
    );
  };

  useEffect(() => {
    sendBackup();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Card className="bg-secondary mx-3 my-3 shadow border-0">
            <CardBody className="width-100 text-center">
              Login to your account here -
              <a href="https://www.lightningpayroll.com.au/account/login">
                https://www.lightningpayroll.com.au/account/login
              </a>
            </CardBody>
          </Card>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default LoginToMyAccount;
