import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Logout = () => {
  // Erase the token cookie and redirect to login page
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("token", "");
  }, []);

  history.push("/auth");

  return null;
};

export default Logout;
