import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

import TableWidget from "../../components/Widgets/TableWidget.js";

const CompanyLeaveBalances = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const updateLeaveBalances = (leave_balance) => {
    setIsLoading(true);
    setSaving(true);
    // Update the current leave_balances and also update the leave_balances array, addint the leave_balance if its ID is null
    let newLeaveBalances = [];
    if (leave_balance.id === null) {
      newLeaveBalances = [
        ...adminContext.company.active_leave_balances,
        leave_balance,
      ];
    } else {
      newLeaveBalances = adminContext.company.active_leave_balances.map((d) => {
        if (d.id === leave_balance.id) {
          return leave_balance;
        } else {
          return d;
        }
      });
    }
    adminContext.setCompany({
      ...adminContext.company,
      active_leave_balances: newLeaveBalances,
      currentLeaveBalance: false,
    });
  };

  const setCurrentLeaveBalance = (leave_balance) => {
    // Notice how this is different to the updateLeaveBalances function as it only sets the current leave_balance, not the leave_balances array
    // This is necessary in case edits get cancelled.
    adminContext.setCompany({
      ...adminContext.company,
      currentLeaveBalance: leave_balance,
    });
  };

  const handleChange = (event) => {
    let leave_balance = adminContext.company.currentLeaveBalance;
    leave_balance[event.target.name] = event.target.value;
    adminContext.setCompany({
      ...adminContext.company,
      currentLeaveBalance: leave_balance,
    });
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setCurrentLeaveBalance(false);
    setIsLoading(true);
    setSaving(false);

    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/company/${adminContext.company.id}`,
      adminContext.company,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const createLeaveBalance = () => {
    return {
      id: null,
      is_deleted: false,
      name: "",
      accrual_type: "PERIOD",
      amount: { milliseconds: 0 },
      leave_loading: 0,
      treat_as_overtime: false,
      is_time_in_lieu: false,
      pay_at_zero_dollars: false,
      delete_enabled: true,
      zero_balance: false,
      leave_balances: [],
      leave_accruals: [],
      company_id: adminContext.company.id,
    };
  };

  const deleteLeaveBalance = (idToDelete) => {
    adminContext.setCompany({
      ...adminContext.company,
      active_leave_balances: adminContext.company.active_leave_balances.filter(
        (leave_balance) => leave_balance.id !== idToDelete
      ),
      currentLeaveBalance: false,
    });
    setSaving(true);
  };

  const formSpec = {
    title: "Custom Leave Balance",
    fields: [
      {
        label: "Name",
        accessor: "name",
        widget: "Text",
        required: true,
        onChange: handleChange,
        validate: {
          required: { value: true },
          minLength: { value: 1 },
          maxLength: { value: 80 },
        },
        args: {
          validateCallback: (leaveBalance) => {
            let response = "";
            // Ensure no other leave balances have the same name, return a text error if so
            let leaveBalances = adminContext.company.active_leave_balances;
            let otherLeaveBalances = leaveBalances.filter(
              (lb) => lb.id !== leaveBalance.id
            );
            let otherLeaveBalanceNames = otherLeaveBalances.map(
              (lb) => lb.name
            );
            if (otherLeaveBalanceNames.includes(leaveBalance.name)) {
              response = "This name is already in use";
            }

            // We also have to check if the leave balance should be marked as time in lieu or not.
            // Check if the ['til', 'toil', 'time in lieu'] or similar is in the name
            let tilWords = ["til", "toil", " lieu"];

            let tilWordsFound = tilWords.filter((word) =>
              leaveBalance.name.toLowerCase().includes(word)
            );
            if (tilWordsFound.length > 0 && !leaveBalance.is_time_in_lieu) {
              response =
                "It seems you are entering a time in lieu balance. Please mark it as time in lieu.";
            }

            return response;
          },
        },
      },
      {
        label: "Accrual Type",
        accessor: "accrual_type",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              ORDINARY_HOURS: "System Ordinary Hours",
              ORDINARY_OVERTIME: "System Overtime Hours",
              ORDINARY_ALL: "System Hours",
              ALL_HOURS: "All Hours",
              PERIOD: "Pay Period",
              PAY_RATE: "Pay Rate",
            };
          },
        },
      },
      {
        label: "Amount Accrued (p/unit - depends on accrual type)",
        accessor: "amount",
        widget: "DurationEdit",
      },
      {
        label: "Leave Loading %",
        accessor: "leave_loading",
        widget: "PercentageEdit",
      },
      {
        label: "Is Time In Lieu?",
        accessor: "is_time_in_lieu",
        widget: "CheckBox",
      },
      {
        label: "Treat As Overtime?",
        accessor: "treat_as_overtime",
        widget: "CheckBox",
      },
      {
        label: "Pay At Zero Dollars?",
        accessor: "pay_at_zero_dollars",
        widget: "CheckBox",
      },
      {
        label: "No Balance/Accruals Required?",
        accessor: "zero_balance",
        widget: "CheckBox",
      },
    ],
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.active_leave_balances]);

  const columns = [
    {
      label: "Name",
      accessor: "name",
      widget: "Text",
    },
    {
      label: "Accrual Type",
      accessor: "accrual_type_display",
      widget: "Text",
    },
    {
      label: "Amount",
      accessor: "amount",
      widget: "DurationLabel",
    },
    {
      label: "Leave Loading",
      accessor: "leave_loading",
      widget: "PercentageLabel",
    },
  ];

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Custom Leave</h1>
                  </Col>
                </Row>
                {!adminContext.company.currentLeaveBalance && (
                  <Row>
                    <Col className="mt-3 mx-4">
                      <p>
                        Custom Leave Balances, which can be selected by the
                        employees within this company.
                      </p>
                    </Col>
                  </Row>
                )}
                {!saving ? (
                  <Row>
                    <Col>
                      <TableWidget
                        rows={adminContext.company.active_leave_balances}
                        setRows={(newRows) => {
                          adminContext.setCompany({
                            ...adminContext.company,
                            active_leave_balances: newRows,
                          });
                        }}
                        columns={columns}
                        selected={adminContext.company.currentLeaveBalance}
                        setSelected={setCurrentLeaveBalance}
                        appendRowCallback={createLeaveBalance}
                        editRowSaveCallback={updateLeaveBalances}
                        deleteRowCallback={deleteLeaveBalance}
                        deleteRowCallbackEnabled={(leave_balance) =>
                          leave_balance.delete_enabled
                        }
                        deleteConfirmationAttributes={["name"]}
                        formSpec={formSpec}
                      />
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default CompanyLeaveBalances;
