import TableWidget from "../../../components/Widgets/TableWidget";

const EditPayHours = ({
  adminContext,
  getSortedHours,
  createHoursRow,
  getPayRatesComboData,
  setPayCallback,
  deleteHoursRow,
}) => {
  return (
    <TableWidget
      label="Hours & Pay Rates"
      rows={getSortedHours()}
      total={false}
      appendRowCallback={createHoursRow}
      columns={[
        {
          label: "Pay Rate",
          accessor: "description",
          widget: "ComboBox",
          disabled:
            adminContext.pay.employee.locked ||
            !adminContext.editAccess("pays"),
          required: true,
          args: {
            skipSort: true,
            comboDataCallback: getPayRatesComboData,
            setRowCallback: setPayCallback,
          },
        },
        {
          label: "Value",
          accessor: "time",
          widget: "DurationEdit",
          args: {
            setRowCallback: setPayCallback,
          },
        },
        {
          label: "Units",
          accessor: "units",
          widget: "Text",
        },
      ]}
      deleteRowCallback={deleteHoursRow}
      hideForm={true}
    />
  );
};
export default EditPayHours;
