import React, { useState, useEffect, useContext } from "react";
import { Button, Card, CardBody } from "reactstrap";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const PreviewView = ({
  wizardInfo,
  setWizardInfo,
  setNextEnabled,
  setForceUpdateButtons,
  loading,
  setLoading,
}) => {
  const adminContext = useContext(AdminContext);

  const downloadPDF = async () => {
    setNextEnabled(true);
    const fileName = `direct_entry_${adminContext.payRun.end_date}.pdf`;
    await adminContext.handlePDFDownload(fileName, wizardInfo.pdf_data);
  };

  const generateDirectPaysReportPdf = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/direct-entry/pays-report`,
      wizardInfo,
      (response) => {
        setWizardInfo({
          ...wizardInfo,
          pdf_data:
            "data:application/pdf;base64," + response.data.pdf_file_data,
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (setForceUpdateButtons !== undefined) {
      setForceUpdateButtons(true);
    }
  }, [loading]);

  useEffect(() => {
    setNextEnabled(false);
    generateDirectPaysReportPdf();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-center">
            <h1 className="text-center days-one">
              Preview Payment Information
            </h1>
            <p>
              You must preview the payments to be made before creating the
              direct entry file.
            </p>
            <p>If all details are correct, click "Next" to proceed.</p>
            <Button
              disabled={
                !wizardInfo.selected_pays ||
                wizardInfo.selected_pays.length === 0
              }
              onClick={downloadPDF}
              className="btn btn-lg semi-active-button width-40-on-lg btn-primary mx-auto text-center"
            >
              Preview
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PreviewView;
