import { useState, useEffect, useContext } from "react";
import { Container, Card, CardBody } from "reactstrap";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import BackButton from "../../components/Widgets/BackButton";
import ListWidget from "../../components/Widgets/ListWidget";
import DateEdit from "../../components/Widgets/DateEdit";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";
import PostCompleteModal from "./PostCompleteModal";
import dayjs from "dayjs";

const CreatePaysView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rows, setRows] = useState({
    employees: [],
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });
  const adminContext = useContext(AdminContext);
  const [processedDate, setProcessedDate] = useState(new Date());
  const [createFullPay, setCreateFullPay] = useState(true);
  const [showTerminated, setshowTerminated] = useState(false);
  const [postCompletePromptMessages, setPostCompletePromptMessages] = useState(
    []
  );
  const [currentPostCompleteMessageIndex, setCurrentPostCompleteMessageIndex] =
    useState(0);

  const initView = () => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/pays/create/init?end_date=${adminContext.payRun.end_date}&company_id=${adminContext.company.id}&show_terminated=${showTerminated}`,
      (response) => {
        setIsLoading(false);
        console.log("response.data.emps");
        console.log(response.data.emps);
        setRows({
          employees: response.data.emps,
        });
      }
    );
  };

  useEffect(() => {
    initView();
  }, [showTerminated]);

  const handlePostCompleteModalNext = () => {
    if (
      currentPostCompleteMessageIndex <
      postCompletePromptMessages.length - 1
    ) {
      // Move to the next message
      setCurrentPostCompleteMessageIndex((prevIndex) => prevIndex + 1);
    } else {
      // No more messages, handle the redirect and reset the message index
      redirectToPays();
      setCurrentPostCompleteMessageIndex(0);
    }
  };

  const redirectToPays = () => {
    adminContext.history.push("/admin/pays");
  };

  const handleDateChange = (dateTime) => {
    if (!dayjs(dateTime).isValid()) {
      return;
    }
    setProcessedDate(dateTime);
  };

  const saveChanges = () => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/create",
      {
        employees: selectedRows.employees,
        processed_date: processedDate,
        company_id: adminContext.company.id,
        end_date: adminContext.payRun.end_date,
        pay_period: adminContext.payRun.pay_period,
        create_full_pay: createFullPay,
        show_terminated: showTerminated,
      },
      (response) => {
        if (
          response.data.post_complete_prompt_messages !== undefined &&
          response.data.post_complete_prompt_messages.length > 0
        ) {
          setPostCompletePromptMessages(
            response.data.post_complete_prompt_messages
          );
        } else {
          redirectToPays();
        }
      }
    );
  };

  return (
    <Container className="my-3 overflow-hidden" fluid>
      <Card>
        <CardBody>
          {!isLoading ? (
            <>
              <BackButton />
              <h1 className="mb-0 days-one">Create Pays</h1>
              <p>
                Create pays for employees <strong>within this pay run</strong>.{" "}
              </p>
              <p>
                <strong>
                  Note: To create pays for the past you need to navigate to the
                  past pay run via the <strong>Pays</strong> screen.
                </strong>
              </p>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
                disabledCallback={() => {
                  return (
                    selectedRows.employees.length === 0 ||
                    processedDate === undefined ||
                    processedDate === null
                  );
                }}
              />
              <DateEdit
                label="Processed/Paid Date For The New Pay/s:"
                value={processedDate}
                onChange={handleDateChange}
                name="processedDate"
              />
              <br />
              <FormControlLabel
                control={
                  <CheckBox
                    color="primary"
                    className="mx-2"
                    name={"showTerminated"}
                    checked={showTerminated}
                    onChange={(e) => setshowTerminated(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={"Show Terminated?"}
              />
              <br />
              <FormControlLabel
                control={
                  <CheckBox
                    color="primary"
                    className="mx-2"
                    name={"createFullPay"}
                    checked={createFullPay}
                    onChange={(e) => setCreateFullPay(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={"Create Full Pay (with normal hours/rates)?"}
              />
              <p>Select employees to create a pay for:</p>
              <ListWidget
                name="employees"
                rows={rows}
                setRows={setRows}
                selectedRows={selectedRows}
                setSelectedRows={(newRows) => {
                  setSelectedRows(newRows);
                }}
              />
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
      {postCompletePromptMessages.length > 0 && (
        <PostCompleteModal
          messages={postCompletePromptMessages}
          currentIndex={currentPostCompleteMessageIndex}
          onNext={handlePostCompleteModalNext}
        />
      )}
    </Container>
  );
};
export default CreatePaysView;
