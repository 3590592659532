import { useState, useEffect, useContext } from "react";
import AdminContext from "../../../../AdminContext";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";
import { Button, Row, Col, Card, CardBody, Alert } from "reactstrap";
import ComboBox from "../../../../components/Widgets/ComboBox.js";
import FontPicker from "../../../../components/Widgets/FontPicker.js";

const SingleTouchPreviewView = ({
  wizardInfo,
  setWizardInfo,
  loading,
  setLoading,
  setNextEnabled,
}) => {
  const adminContext = useContext(AdminContext);
  const [detailedReportPDFData, setDetailedReportPDFData] = useState(null);
  const [summaryReportPDFData, setSummaryReportPDFData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [fonts, setFonts] = useState([]);
  const [detailedFontFamily, setDetailedFontFamily] = useState(null);
  const [detailedFontSize, setDetailedFontSize] = useState(null);
  const [summaryFontFamily, setSummaryFontFamily] = useState(null);
  const [summaryFontSize, setSummaryFontSize] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const createSTPReports = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/single-touch/reports/create`,
      {
        ...wizardInfo,
        detailed_font_family: detailedFontFamily,
        detailed_font_size: detailedFontSize,
        summary_font_family: summaryFontFamily,
        summary_font_size: summaryFontSize,
      },
      (response) => {
        if (response.data.payment_summaries) {
          setWizardInfo({
            ...wizardInfo,
            payment_summaries: response.data.payment_summaries,
          });
        }
        if (response.data.stp_event) {
          setWizardInfo({
            ...wizardInfo,
            stp_event: response.data.stp_event,
          });
        }
        if (response.data.detailed_report_data) {
          setDetailedReportPDFData(
            "data:application/pdf;base64," + response.data.detailed_report_data
          );
        }
        if (response.data.summary_report_data) {
          setSummaryReportPDFData(
            "data:application/pdf;base64," + response.data.summary_report_data
          );
        }

        if (response.data.fonts) {
          setFonts(response.data.fonts);
        }

        if (detailedFontFamily === null && response.data.detailed_font_family) {
          setDetailedFontFamily(response.data.detailed_font_family);
        }

        if (detailedFontSize === null && response.data.detailed_font_size) {
          setDetailedFontSize(response.data.detailed_font_size);
        }

        if (summaryFontFamily === null && response.data.summary_font_family) {
          setSummaryFontFamily(response.data.summary_font_family);
        }

        if (summaryFontSize === null && response.data.summary_font_size) {
          setSummaryFontSize(response.data.summary_font_size);
        }

        setTimeout(() => {
          setInitialLoad(false);
        }, 4000);

        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setNextEnabled(false);
    createSTPReports();
  }, []);

  // If any of the font selections change we need to recreate the reports, but only if initialised
  useEffect(() => {
    if (!initialLoad) {
      createSTPReports();
    }
  }, [
    detailedFontFamily,
    detailedFontSize,
    summaryFontFamily,
    summaryFontSize,
  ]);

  const submitSTP = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/single-touch/submit`,
      wizardInfo,
      (response) => {
        if (response.data.errors) {
          setErrorMessage(response.data.errors);
        } else if (response.data.success) {
          setSuccessMessage(response.data.success);
          setSubmitted(true);
          setNextEnabled(true);
        }

        // Timeout the messages after 5 seconds
        setTimeout(() => {
          setErrorMessage(null);
          setSuccessMessage(null);
        }, 5000);

        setLoading(false);
      }
    );
  };

  const downloadPDF = async (mode) => {
    const fileName = `single_touch_${mode}_${adminContext.payRun.end_date}.pdf`;
    if (mode === "detailed") {
      await adminContext.handlePDFDownload(fileName, detailedReportPDFData);
    } else {
      await adminContext.handlePDFDownload(fileName, summaryReportPDFData);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h1 className="text-center days-one">
                    Single Touch Payroll Preview
                  </h1>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  Below is a preview of the STP information you have prepared
                  for submission to the ATO.
                </Col>
              </Row>
              <Row>
                {errorMessage || successMessage ? (
                  <Col>
                    {errorMessage && (
                      <Alert className="my-2 text-center" color="warning">
                        <strong>{errorMessage}</strong>
                      </Alert>
                    )}
                    {successMessage && (
                      <Alert className="my-2 text-center" color="success">
                        <strong>{successMessage}</strong>
                      </Alert>
                    )}
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col md="6" className="text-center d-flex align-items-end">
                  {" "}
                  <Button
                    className="semi-active-button my-3 mx-2 w-100"
                    onClick={() => downloadPDF("detailed")}
                  >
                    {" "}
                    Detailed Report
                  </Button>
                </Col>
                <Col md="3">
                  <FontPicker
                    fonts={fonts}
                    fontFamily={detailedFontFamily}
                    setFontFamily={setDetailedFontFamily}
                  />
                </Col>
                <Col md="3">
                  <ComboBox
                    className="mx-3"
                    label="Font Size"
                    stretch={true}
                    name="font_size"
                    selectedComboItem={detailedFontSize}
                    setSelectedComboItem={setDetailedFontSize}
                    comboData={adminContext.fontSizes()}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  className="text-center mx-auto d-flex align-items-end"
                  md="6"
                >
                  {" "}
                  <Button
                    className="semi-active-button my-3 mx-2 w-100"
                    onClick={() => downloadPDF("summary")}
                  >
                    {" "}
                    Summary Report
                  </Button>
                </Col>
                <Col md="3">
                  <FontPicker
                    fonts={fonts}
                    fontFamily={summaryFontFamily}
                    setFontFamily={setSummaryFontFamily}
                  />
                </Col>
                <Col md="3">
                  <ComboBox
                    className="mx-3"
                    label="Font Size"
                    stretch={true}
                    name="font_size"
                    selectedComboItem={summaryFontSize}
                    setSelectedComboItem={setSummaryFontSize}
                    comboData={adminContext.fontSizes()}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {" "}
                  <Button
                    color="primary"
                    disabled={submitted}
                    className="semi-active-button  my-3 mx-2 width-40-on-lg"
                    onClick={submitSTP}
                  >
                    {" "}
                    Submit
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default SingleTouchPreviewView;
