import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ComboBox from "../../../components/Widgets/ComboBox.js";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import AvForm from "availity-reactstrap-validation/lib/AvForm";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import { AvField } from "availity-reactstrap-validation";

const MedicareLevyExemption = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [comboData, setComboData] = useState({
    medicare_exemption: {
      NONE: "NONE",
      FULL: "FULL",
      HALF: "HALF",
    },
  });

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <AvForm>
              <Row>
                <Col>
                  <h1 className="days-one">Medicare Levy Exemption</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />

              <Row>
                <Col className="mx-4 my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={adminContext.currentEmployee.locked || !adminContext.editAccess("employees")}
                      className="mx-3"
                      name="medicare_exemption"
                      stretch={true}
                      label="Medicare Levy Exemption Level"
                      onChange={handleChange}
                      comboData={comboData["medicare_exemption"]}
                      selectedComboItem={
                        adminContext.currentEmployee.medicare_exemption
                      }
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          medicare_exemption: getKeyByValue(
                            comboData["medicare_exemption"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              {adminContext.currentEmployee.medicare_exemption !== "NONE" && (
                <>
                  <Row>
                    <Col className="mx-4 my-3">
                      <div key={adminContext.currentEmployee.id}>
                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={adminContext.currentEmployee.has_spouse}
                              onChange={handleChange}
                              name="has_spouse"
                            />
                          }
                          label={"Has Spouse?"}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mx-4 my-3">
                      <div key={adminContext.currentEmployee.id}>
                        <AvForm>
                          <AvField
                            name="num_dependent_children"
                            label="Number of dependant children"
                            value={
                              adminContext.currentEmployee
                                .num_dependent_children || 0
                            }
                            onChange={handleChange}
                            type="number"
                          />
                        </AvForm>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </AvForm>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default MedicareLevyExemption;
