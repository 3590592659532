import { useState, useEffect } from "react";
import TableWidget from "../../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";
import { Card, CardBody } from "reactstrap";

const AdvancedEtpEditView = ({
  payTermination,
  setPayTermination,
  refreshPayTermination,
}) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(true);

  useEffect(() => {
    setSaving(true);
    setPayTermination({
      ...payTermination,
      requires_tax_recalc: false,
    });
  }, []);

  useEffect(() => {
    if (saving) {
      refreshPayTermination(setLoading);
      setSaving(false);
    }
  }, [payTermination.requires_tax_recalc]);

  const columns = [
    {
      label: "ETP Code",
      accessor: "etp_code",
      widget: "Text",
    },
    {
      label: "Tax Withheld",
      accessor: "tax_withheld",
      widget: "MoneyLabel",
    },
    {
      label: "Taxable Component",
      accessor: "taxable_component",
      widget: "MoneyLabel",
    },
    {
      label: "Tax Free Component",
      accessor: "tax_free_component",
      widget: "MoneyLabel",
    },
    {
      label: "Lump Sum D",
      accessor: "lump_sum_d",
      widget: "MoneyLabel",
    },
  ];

  const formSpec = {
    fields: [
      {
        label: "ETP Code",
        accessor: "etp_code",
        widget: "ComboBox",
        required: true,

        args: {
          comboDataCallback: () => {
            return {
              R: "R: Redundancy, invalidity, early retirement, etc.",
              O: "O: Other reasons (ex-gratia, golden handshake, etc.)",
              S: "S: Split ETP Type R (multiple payments, different years)",
              P: "P: Split ETP Type O (multiple payments, different years)",
              D: "D: Dependant (death benefit to dependant)",
              B: "B: Split ETP Type N (multiple death benefits, different years)",
              N: "N: Non-Dependant (death benefit to non-dependant)",
              T: "T: Trustee of Deceased Estate (death benefit to trustee)",
            };
          },
        },
      },

      {
        label: "Tax Withheld",
        accessor: "tax_withheld",
        widget: "MoneyEdit",
      },
      {
        label: "Taxable Component",
        accessor: "taxable_component",
        widget: "MoneyEdit",
      },
      {
        label: "Tax Free Component",
        accessor: "tax_free_component",
        widget: "MoneyEdit",
      },
      {
        label: "Lump Sum D",
        accessor: "lump_sum_d",
        widget: "MoneyEdit",
      },
    ],
  };

  const editRowSaveCallback = (editedRow) => {
    let updatedTaxSummaries = payTermination.tax_summaries.map((taxSummary) => {
      if (taxSummary.id === editedRow.id) {
        return editedRow;
      }
      return taxSummary;
    });
    setPayTermination({
      ...payTermination,
      tax_summaries: updatedTaxSummaries,
    });
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody>
            <h1 className="text-center days-one">Advanced ETP Settings</h1>
            <TableWidget
              columns={columns}
              formSpec={formSpec}
              editRowSaveCallback={editRowSaveCallback}
              rows={payTermination.tax_summaries}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default AdvancedEtpEditView;
