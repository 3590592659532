import { Row, Col } from "reactstrap";
import DateEdit from "./DateEdit";

const DateRangeEdit = ({ startDateProps, endDateProps }) => {
  return (
    <Row>
      <Col md={6}>
        <DateEdit {...startDateProps} />
      </Col>
      <Col md={6}>
        <DateEdit {...endDateProps} />
      </Col>
    </Row>
  );
};

export default DateRangeEdit;
