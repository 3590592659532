import React from "react";
import AuthContext from "../AuthContext";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import { useHistory } from "react-router";
import { getBaseUrl, getHomepageUrl } from "../variables/Config";
import { Capacitor } from "@capacitor/core";

import routes from "../variables/routes.js";

const Auth = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [isIOS, setIsIOS] = React.useState(false);

  React.useEffect(() => {
    const checkIsIOS = async () => {
      const isApple = Capacitor.getPlatform() == "ios";
      setIsIOS(isApple);
    };

    checkIsIOS();
  }, []);

  // Function to extract query parameters
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  // Set initial email state to the email query parameter, if present
  const initialEmail = getQueryParam("email") || "";
  const initialReferralCode = getQueryParam("referral_code") || "";
  const initialGclid = getQueryParam("gclid") || "";
  const initialKeyword = getQueryParam("keyword") || "";
  const [email, setEmail] = React.useState(initialEmail);
  const [gclid, setGclid] = React.useState(initialGclid);
  const [referralCode, setReferralCode] = React.useState(initialReferralCode);
  const [keyword, setKeyword] = React.useState(initialKeyword);

  const mainContent = React.useRef(null);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  // We need to know if we are on the password reset screen. Check the url for 'reset-password'
  const isResetPasswordScreen = location.pathname.includes("reset-password");

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(routeProps) => (
              <prop.component
                {...routeProps}
                email={email}
                setEmail={setEmail}
                gclid={gclid}
                referralCode={referralCode}
                keyword={keyword}
                isIOS={isIOS}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar isIOS={isIOS} />
        <>
          <div className="header bg-gradient-info pt-8 py-lg-8">
            <Container>
              <div className="header-body text-center mt-2 pt-5">
                <Row className="justify-content-center">
                  <Col lg="7" md="8">
                    <h1 className="text-white days-one">Lightning Payroll</h1>
                    <p className="text-lead text-light days-one">
                      Please login to access Lightning Payroll
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt-lg--5">
            <Row className="justify-content-center">
              <AuthContext.Provider value={loading}>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="/auth/login" />
                </Switch>
              </AuthContext.Provider>
            </Row>

            <Row className="m-2 text-center">
              <Col lg={!isIOS ? "4" : "6"}>
                <Button
                  className="btn m-1 text-break text-light text-center width-80-on-lg"
                  color="primary"
                  onClick={() => {
                    history.push(`/auth/login?email=${email}`);
                  }}
                >
                  <i className="fa fa-user fa-lg mx-auto" />
                  <br />
                  Login
                </Button>
              </Col>
              <Col lg={!isIOS ? "4" : "6"}>
                <Button
                  className="btn m-1 text-break text-light text-center width-80-on-lg"
                  color="primary"
                  onClick={() => {
                    history.push(`/auth/forgot_password?email=${email}`);
                  }}
                >
                  <i className="fa fa-question-circle fa-lg mx-auto" />
                  <br />
                  Forgot password?
                </Button>
              </Col>
              {!isIOS ? (
                <Col lg="4">
                  <Button
                    className="btn m-1 text-break text-light text-center width-80-on-lg"
                    color="primary"
                    onClick={() => {
                      window.open(getHomepageUrl() + "/buy-now", "_blank");
                    }}
                  >
                    <i className="fa fa-shopping-cart fa-lg mx-auto" />
                    <br />
                    Purchase Subscription
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Container>
        </>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
