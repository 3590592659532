import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { Button, Modal, Row, Col } from "reactstrap";
import EditForm from "./EditForm";
import { useEffect, useContext } from "react";
import PercentageLabel from "./PercentageLabel";
import DateTimeLabel from "./DateTimeLabel";
import TimeLabel from "./TimeLabel";
import DurationLabel from "./DurationLabel";
import DurationEdit from "./DurationEdit";
import DateLabel from "./DateLabel";
import FloatEdit from "./FloatEdit";
import MoneyLabel from "./MoneyLabel";
import BooleanLabel from "./BooleanLabel";
import CheckBoxBooleanLabel from "./CheckBoxBooleanLabel";
import ComboBox from "./ComboBox";
import FileSizeLabel from "./FileSizeLabel";
import { Link } from "react-router-dom";
import AdminContext from "../../AdminContext";
import { styled } from "@mui/material/styles";
import { blueGrey, grey } from "@mui/material/colors";
import HoursLabel from "./HoursLabel";
import CheckBox from "@mui/material/Checkbox";
import Tooltip from "./Tooltip";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "bordered", // Prevent 'bordered' prop being forwarded to the DOM element
})(({ theme, bordered }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: blueGrey[400],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: "100px",
    whiteSpace: "normal",
    wordWrap: "break-word",
    border: bordered ? "1px solid rgba(224, 224, 224, 1)" : "none",
  },
  "@media (max-width: 600px)": {
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  },
}));

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "bordered", // Prevent 'bordered' prop being forwarded to the DOM element
})(({ className, bordered }) => {
  if (className) {
    return {}; // If className is present, return an empty style object
  }

  return {
    "&:nth-of-type(odd)": {
      backgroundColor: grey[100],
    },
    "&:last-child td, &:last-child th": {
      border: bordered ? "1px solid rgba(224, 224, 224, 1)" : 0,
    },
  };
});

const TotalsTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: blueGrey[100],
  fontSize: 20,
}));

function descendingComparator(a, b, orderBy, columns) {
  let valA = a[orderBy];
  let valB = b[orderBy];

  if (valA && valA._decimal !== undefined) {
    valA = valA._decimal;
  }
  if (valB && valB._decimal !== undefined) {
    valB = valB._decimal;
  }

  // Check if one or both values are null
  if (valA === null && valB === null) {
    return 0;
  } else if (valA === null) {
    return 1;
  } else if (valB === null) {
    return -1;
  }

  // Find the column definition for the current orderBy accessor
  const column = columns.find((col) => col.accessor === orderBy);
  const sortAlphaNumeric = column?.sortAlphaNumeric;

  if (sortAlphaNumeric === false) {
    // Perform numeric comparison
    const numA = parseFloat(valA);
    const numB = parseFloat(valB);

    if (isNaN(numA) && isNaN(numB)) return 0;
    if (isNaN(numA)) return 1;
    if (isNaN(numB)) return -1;

    if (numB < numA) {
      return -1;
    }
    if (numB > numA) {
      return 1;
    }
    return 0;
  } else {
    // Existing logic for alphanumeric comparison
    const isValADate = dayjs(valA).isValid();
    const isValBDate = dayjs(valB).isValid();

    if (isValADate && isValBDate) {
      valA = dayjs(valA).toISOString();
      valB = dayjs(valB).toISOString();
    }

    if (valB < valA) {
      return -1;
    }
    if (valB > valA) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy, columns) {
  return order === "Descending"
    ? (a, b) => descendingComparator(a, b, orderBy, columns)
    : (a, b) => -descendingComparator(a, b, orderBy, columns);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  // Notify the parent of the sorted array.
  const sortedArray = stabilizedThis.map((el) => el[0]);

  return sortedArray;
}

const headCells = (columns) => {
  return columns.map((column) => {
    return {
      accessor: column.accessor,
      numeric: column.align === "right" ? true : false,
      align: column.align,
      label: column.label,
    };
  });
};

const directionMap = {
  Ascending: "asc",
  Descending: "desc",
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    selectsDisplayed,
    multiSelectsEnabled,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    editRowSaveCallback,
    beginEditCallback,
    hideEdit,
    deleteRowCallback,
    deleteButtonLast,
    disableSorting,
  } = props;
  const createSortHandler = disableSorting
    ? null
    : (property) => (event) => {
        onRequestSort(event, property);
      };

  return (
    <TableHead>
      <StyledTableRow className="mt-1">
        {selectsDisplayed && multiSelectsEnabled ? (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </StyledTableCell>
        ) : selectsDisplayed ? (
          <StyledTableCell padding="checkbox"></StyledTableCell>
        ) : null}
        {beginEditCallback || (editRowSaveCallback && !hideEdit) ? (
          <StyledTableCell className="text-center">
            <strong>Edit</strong>
          </StyledTableCell>
        ) : null}
        {!props.deleteButtonLast ? (
          deleteRowCallback ? (
            <StyledTableCell>
              <strong>Delete</strong>
            </StyledTableCell>
          ) : null
        ) : null}
        {headCells(columns).map((headCell) => (
          <StyledTableCell
            key={headCell.accessor}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                ? "right"
                : "left"
            }
            sortDirection={
              !disableSorting && orderBy === headCell.accessor
                ? directionMap[order]
                : false
            }
          >
            {disableSorting ? (
              <strong>{headCell.label}</strong>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.accessor}
                direction={
                  orderBy === headCell.accessor ? directionMap[order] : "asc"
                }
                onClick={createSortHandler(headCell.accessor)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.accessor ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "Descending"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCell>
        ))}
        {props.deleteButtonLast ? (
          deleteRowCallback ? (
            <StyledTableCell>Delete</StyledTableCell>
          ) : null
        ) : null}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selectsDisplayed: PropTypes.bool.isRequired,
  multiSelectsEnabled: PropTypes.bool.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["Ascending", "Descending"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  editRowSaveCallback: PropTypes.func,
  deleteRowCallback: PropTypes.func,
  deleteButtonLast: PropTypes.bool,
  hideEdit: PropTypes.bool,
  beginEditCallback: PropTypes.func,
  disableSorting: PropTypes.bool,
};

function EnhancedTableToolbar(props) {
  const { numSelected, deleteSelectedEnabled, title } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 70%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <h2 className="days-one">{title}</h2>
      )}
      {numSelected > 0 && deleteSelectedEnabled ? (
        <Button color="danger" onClick={props.deleteSelectedCallback}>
          <i className="fa fa-trash"></i> Delete Selected
        </Button>
      ) : null}
      {props.addNew ? (
        <Button
          className="float-right my-3"
          color="primary"
          onClick={props.addNew}
          disabled={
            props.appendRowCallbackEnabled !== undefined
              ? !props.appendRowCallbackEnabled
              : false
          }
        >
          <Tooltip
            title="Create new row item"
            innerContent={
              <div>
                <span>
                  <i className="fa-regular fa-plus"></i>
                </span>
                {"  "}
                {props.addNewLabel ? props.addNewLabel : "Add New"}
              </div>
            }
          />
        </Button>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  deleteSelectedEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  addNew: PropTypes.func,
  addNewLabel: PropTypes.string,
  deleteSelectedCallback: PropTypes.func,
};

export default function TableWidget(props) {
  const adminContext = useContext(AdminContext);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  // Use this for editing
  const [internalCurrentSelected, setInternalCurrentSelected] =
    React.useState(false);
  const isControlled = props.currentSelected !== undefined;
  const currentSelected = isControlled
    ? props.currentSelected
    : internalCurrentSelected;
  const setCurrentSelected = isControlled
    ? props.setCurrentSelected
    : setInternalCurrentSelected;

  const [order, setOrder] = React.useState(
    props.order ? props.order : "Ascending"
  );
  const [orderBy, setOrderBy] = React.useState(
    props.orderBy ? props.orderBy : "id"
  );

  const [dense, setDense] = React.useState(props.dense ? props.dense : false);
  const [selectsDisplayed, setSelectsDisplayed] = React.useState(
    props.selectsDisplayed ? props.selectsDisplayed : false
  );
  const [multiSelectsEnabled, setMultiSelectsEnabled] = React.useState(
    props.multiSelectsEnabled ? props.multiSelectsEnabled : false
  );
  const [deleteSelectedEnabled, setDeleteSelectedEnabled] = React.useState(
    props.deleteSelectedEnabled ? props.deleteSelectedEnabled : false
  );
  const [showDevSwitches, setShowDevSwitches] = React.useState(false);

  useEffect(() => {
    const sortedRows = stableSort(
      props.rows,
      getComparator(order, orderBy, props.columns)
    );
    if (props.setRows) props.setRows(sortedRows);
  }, [order, orderBy]);

  const flipOrder = () => {
    if (order === "Ascending") {
      return "Descending";
    } else {
      return "Ascending";
    }
  };

  const handleRequestSort = (event, property) => {
    const newOrder = orderBy == property ? flipOrder() : order;
    setOrder(newOrder);
    setOrderBy(property);

    if (props.saveOrder) {
      props.saveOrder(newOrder, property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (!multiSelectsEnabled) {
      return;
    }

    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.id);
      props.setSelected(newSelected);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, rowId) => {
    // Check if multi selects are enabled.
    if (!multiSelectsEnabled) {
      return;
    }

    // Otherwise if multi selects are enabled, allow multiple selections
    if (!props.selected.indexOf) {
      return;
    }
    const selectedIndex = props.selected.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSelectsDisplayed = (event) => {
    setSelectsDisplayed(event.target.checked);
  };

  const handleMultiSelectsEnabled = (event) => {
    setMultiSelectsEnabled(event.target.checked);
  };

  const handleDeleteSelectedEnabled = (event) => {
    setDeleteSelectedEnabled(event.target.checked);
  };

  const isSelected = (id) => {
    if (!props.selected) {
      return false;
    }
    return props.selected && multiSelectsEnabled
      ? props.selected.indexOf(id) !== -1
      : false;
  };

  // Most rows are keyed by id, but some rows do not have an id attribute and may have useAsKey set to true on a particular column
  // Search columns for useAsKey and if found get the column.accessor. set altRowKeyAccessor to that accessor, otherwise default to 'id'
  const altRowKeyAccessor = props.columns.find(
    (column) => column.useAsKey === true
  )
    ? props.columns.find((column) => column.useAsKey === true).accessor
    : "id";
  const confirmDelete = (row) => {
    setDeleteConfirmationOpen(true);
    setIdToDelete(row[altRowKeyAccessor]);
  };

  const deleteCandidate = () => {
    if (!props.rows) {
      return;
    }
    return props.rows.find((row) => row[altRowKeyAccessor] === idToDelete);
  };

  const unsetCurrentSelected = () => {
    setCurrentSelected(false);
    if (props.setSelected) {
      props.setSelected(false);
    }
  };

  const saveChanges = () => {
    setSaving(true);
    props.editRowSaveCallback(currentSelected);
  };

  useEffect(() => {
    if (!saving) {
      return;
    }
    setSaving(false);
    unsetCurrentSelected();
  }, [props.rows]);

  const addNew = () => {
    let newObject = props.appendRowCallback();
    if (props.hideForm === undefined || !props.hideForm) {
      setCurrentSelected(newObject);
      if (props.setSelected !== undefined) {
        props.setSelected(newObject);
      }
    }
  };

  const handleChange = (event) => {
    // Check for checkbox/switch
    if (event.target.type === "checkbox") {
      setCurrentSelected({
        ...currentSelected,
        [event.target.name]: event.target.checked,
      });
      return;
    }

    setCurrentSelected({
      ...currentSelected,
      [event.target.name]: event.target.value,
    });
  };

  const columnFromAccessor = (accessor) => {
    let column = props.columns.find((col) => col.accessor === accessor);
    return column ? column : null;
  };

  const getCellWidget = (row, accessor) => {
    let widget = null;
    let column = columnFromAccessor(accessor);
    if (!column || !column.widget) {
      widget = row[accessor];
    } else if (column.widget.toLowerCase() === "percentagelabel") {
      widget = <PercentageLabel value={row[accessor]} />;
    } else if (column.widget.toLowerCase() === "button") {
      widget = (
        <Button
          disabled={
            column.args.disabledCallback
              ? column.args.disabledCallback(row)
              : false
          }
          variant="contained"
          color={
            column.args.colorCallback
              ? column.args.colorCallback(row)
              : column.args.color !== undefined
              ? column.args.color
              : "secondary"
          }
          onClick={() => column.args.onClickCallback(row)}
          className="d-flex align-items-center"
        >
          <i className={column.args.buttonIcon + " m-2"} />
          {column.iconOnly
            ? ""
            : column.args.labelCallback
            ? column.args.labelCallback(row)
            : column.label}
        </Button>
      );
    } else if (column.widget.toLowerCase() === "hourslabel") {
      widget = (
        <HoursLabel
          decimalPrecision={
            column.args !== undefined ? column.args.decimalPrecision : 6
          }
          value={row[accessor]}
        />
      );
    } else if (column.widget.toLowerCase() === "durationlabel") {
      widget = <DurationLabel value={row[accessor]} />;
    } else if (column.widget.toLowerCase() === "icon") {
      widget = <i className={column.args.icon} />;
    } else if (column.widget.toLowerCase() === "durationedit") {
      widget = (
        <DurationEdit
          value={row[accessor]}
          setValue={(data) => {
            column.args.setRowCallback({
              ...row,
              [column.accessor]: data,
            });
          }}
        />
      );
    } else if (column.widget.toLowerCase() === "datetimelabel") {
      widget = <DateTimeLabel value={row[accessor]} />;
    } else if (
      column.widget.toLowerCase() === "number" &&
      accessor.startsWith("number_of_")
    ) {
      let trueAccessor = accessor.replace("number_of_", "");
      widget = row[trueAccessor] ? row[trueAccessor].length : 0;
    } else if (column.widget.toLowerCase() === "datelabel") {
      widget = <DateLabel value={row[accessor]} />;
    } else if (column.widget.toLowerCase() === "dateedit") {
      widget = (
        <DatePicker
          className={`${column.args.className || "form-control width-100"}`}
          selected={new Date(row[accessor])}
          onChange={(date) => {
            column.args.setRowCallback({
              ...row,
              [column.accessor]: date,
            });
          }}
          dateFormat={["dd/MM/yyyy", "dd/MM/yy"]}
          parseDate={(dateString) => {
            const parts = dateString.split("/");
            if (parts.length === 3 && parts[2].length === 2) {
              dateString = `${parts[0]}/${parts[1]}/20${parts[2]}`;
            }
            return new Date(dateString);
          }}
          strictParsing={false}
          minDate={
            column.args.minDate ? new Date(column.args.minDate) : undefined
          }
          maxDate={
            column.args.maxDate ? new Date(column.args.maxDate) : undefined
          }
          disabled={column.args.disabled ? column.args.disabled : false}
          customInput={<input style={column.args.inputStyle} />}
        />
      );
    } else if (column.widget.toLowerCase() === "floatedit") {
      widget = (
        <FloatEdit
          value={parseFloat(row[accessor]).toFixed(column.args.decimalPlaces)}
          name={column.accessor}
          handleBlur={(event) => {
            const newValue = parseFloat(event.target.value);
            if (!isNaN(newValue)) {
              column.args.setRowCallback({
                ...row,
                [column.accessor]: newValue,
              });
            }
          }}
        />
      );
    } else if (column.widget.toLowerCase() === "filesizelabel") {
      widget = <FileSizeLabel value={row[accessor]} />;
    } else if (column.widget.toLowerCase() === "booleanlabel") {
      widget = <BooleanLabel value={row[accessor]} />;
    } else if (column.widget.toLowerCase() === "checkboxbooleanlabel") {
      widget = <CheckBoxBooleanLabel value={row[accessor]} />;
    } else if (column.widget.toLowerCase() === "html") {
      widget = <div dangerouslySetInnerHTML={{ __html: row[accessor] }} />;
    } else if (column.widget.toLowerCase() === "checkbox") {
      widget = (
        <FormControlLabel
          control={
            <CheckBox
              color="primary"
              className="mx-2"
              name={row[accessor]}
              checked={row[accessor]}
              onChange={(event) => {
                column.args.setRowCallback(row, event.target.checked);
              }}
            />
          }
          label={" " + column.label}
        />
      );
    } else if (column.widget.toLowerCase() === "combobox") {
      return (
        <ComboBox
          className="mx-auto my-auto width-100"
          disabled={column.disabled !== undefined ? column.disabled : false}
          key={column.accessor}
          name={column.accessor}
          skipSort={column.args.skipSort ? column.args.skipSort : false}
          label={""}
          onChange={handleChange}
          comboData={column.args.comboDataCallback()}
          selectedComboItem={row[column.accessor]}
          setSelectedComboItem={(data) => {
            let keyFromVal = adminContext.getKeyByValue(
              column.args.comboDataCallback(),
              data
            );

            column.args.setRowCallback({
              ...row,
              [column.accessor]: keyFromVal,
            });
          }}
        />
      );
    } else if (column.widget.toLowerCase() === "moneylabel") {
      let amount = 0;
      if (column.valueCallback) {
        amount = column.valueCallback(row);
      } else {
        amount = row[accessor];
      }
      widget = <MoneyLabel className="text-right" amount={amount} />;
    } else if (column.widget.toLowerCase() === "timelabel") {
      return <TimeLabel time={row[accessor] ? row[accessor] : null} />;
    } else if (column.widget.toLowerCase() === "link") {
      widget = (
        <Link
          className={column.args.className}
          to={column.args.href + "?id=" + row.id}
        >
          {column.args.icon ? (
            <>
              <i className={column.args.icon} /> {column.args.label}
            </>
          ) : (
            column.args.label
          )}
        </Link>
      );
    } else {
      if (column.valueCallback) {
        widget = column.valueCallback(row);
      } else {
        widget = column.titleCase
          ? adminContext.toTitleCase(row[accessor])
          : row[accessor];
      }
    }
    return widget;
  };

  const getDeleteCell = (row) => {
    if (props.deleteRowCallback !== undefined) {
      return (
        <StyledTableCell padding="checkbox">
          <Button
            aria-label="delete"
            color="danger"
            disabled={
              props.deleteRowCallbackEnabled
                ? !props.deleteRowCallbackEnabled(row)
                : false
            }
            className="mx-2 my-2"
            onClick={() => {
              props.deleteConfirmationAttributes !== undefined
                ? confirmDelete(row)
                : props.deleteRowCallback(row);
            }}
          >
            <Tooltip
              title="Delete"
              innerContent={<i className="fa fa-trash" />}
            />
          </Button>
        </StyledTableCell>
      );
    } else {
      return null;
    }
  };

  return props.formSpec && currentSelected ? (
    <EditForm
      saveChanges={saveChanges}
      formSpec={
        props.newFormSpec !== undefined && !currentSelected.id
          ? props.newFormSpec
          : props.formSpec
      }
      cancelCallback={unsetCurrentSelected}
      setModelObj={setCurrentSelected}
      modelObj={currentSelected}
      siblings={props.rows} // Can be used for validation against other rows
      getComboData={props.getComboData}
    />
  ) : (
    <>
      <Row>
        {props.label ? (
          <Col>
            <h2 className="days-one text-center text-lg-left">{props.label}</h2>
            {props.subtitle ? (
              <p className="text-center text-lg-left">{props.subtitle}</p>
            ) : null}
          </Col>
        ) : null}

        <Col className="d-none d-sm-block">
          {" "}
          <p className="mb-1 mx-3 text-right d-none d-sm-block">
            {props.rows && props.rows.length > 0
              ? `Showing ${props.rows.length} ${
                  props.title
                    ? props.title.toLowerCase()
                    : props.rows.length === 1
                    ? "row"
                    : "rows"
                }`
              : "Showing 0 rows"}
          </p>
        </Col>
      </Row>

      <Box sx={{ width: "100%", mt: 1 }} className="pr-3">
        <Paper sx={{ width: "100%", mb: 2 }}>
          {multiSelectsEnabled || props.appendRowCallback ? (
            <EnhancedTableToolbar
              numSelected={
                multiSelectsEnabled && props.selected
                  ? props.selected.length
                  : 0
              }
              deleteSelectedEnabled={deleteSelectedEnabled}
              title={props.title ? props.title : ""}
              addNew={props.appendRowCallback ? addNew : null}
              addNewLabel={props.addNewLabel}
              appendRowCallbackEnabled={props.appendRowCallbackEnabled}
              deleteSelectedCallback={props.deleteSelectedCallback}
            />
          ) : null}
          <TableContainer>
            <Table
              sx={
                props.autoMinWidth
                  ? { minWidth: "auto" }
                  : {
                      minWidth: 850,
                      "@media (max-width: 600px)": {
                        minWidth: "auto",
                      },
                    }
              }
              aria-labelledby="tableTitle"
              stickyHeader
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={
                  multiSelectsEnabled && props.selected
                    ? props.selected.length
                    : 0
                }
                disableSorting={props.setRows === undefined}
                order={order === "desc" ? "Descending" : order}
                orderBy={orderBy}
                selectsDisplayed={selectsDisplayed}
                multiSelectsEnabled={multiSelectsEnabled}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.rows ? props.rows.length : 0}
                columns={props.columns}
                editRowSaveCallback={props.editRowSaveCallback}
                deleteRowCallback={props.deleteRowCallback}
                deleteButtonLast={props.deleteButtonLast}
                hideEdit={props.hideEdit}
                beginEditCallback={props.beginEditCallback}
              />
              <TableBody>
                <>
                  {props.rows.map((row, index) => {
                    const isItemSelected = isSelected(row[altRowKeyAccessor]);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow
                        bordered={props.bordered}
                        onClick={
                          props.rowClickCallback
                            ? (event) => props.rowClickCallback(event, row)
                            : (event) =>
                                handleClick(event, row[altRowKeyAccessor])
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row[altRowKeyAccessor]}
                        className={
                          props.rowStyleCallback !== undefined
                            ? props.rowStyleCallback(row)
                            : ""
                        }
                        selected={isItemSelected}
                      >
                        {selectsDisplayed ? (
                          <StyledTableCell
                            bordered={props.bordered}
                            padding="checkbox"
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </StyledTableCell>
                        ) : null}
                        {props.beginEditCallback ||
                        (props.editRowSaveCallback && !props.hideEdit) ? (
                          <StyledTableCell
                            bordered={props.bordered}
                            padding="checkbox"
                          >
                            <Button
                              aria-label="edit"
                              className="mx-2 my-2"
                              onClick={() => {
                                setCurrentSelected(row);
                                if (props.setSelected !== undefined) {
                                  props.setSelected(row);
                                }
                                if (props.beginEditCallback) {
                                  props.beginEditCallback(row);
                                }
                              }}
                            >
                              <Tooltip
                                title="Edit"
                                innerContent={
                                  <i className="fa-regular fa-lg fa-edit" />
                                }
                              />
                            </Button>
                          </StyledTableCell>
                        ) : null}
                        {!props.deleteButtonLast ? getDeleteCell(row) : null}
                        {props.columns.map((column) => (
                          <StyledTableCell
                            key={column.accessor}
                            bordered={props.bordered}
                            scope="row"
                            padding="normal"
                            className={
                              column.cellStyleCallback !== undefined
                                ? column.cellStyleCallback(row)
                                : ""
                            }
                            align={column.align ? column.align : "left"}
                          >
                            {getCellWidget(row, column.accessor)}
                          </StyledTableCell>
                        ))}
                        {props.deleteButtonLast ? getDeleteCell(row) : null}
                      </StyledTableRow>
                    );
                  })}

                  {props.total !== undefined &&
                  props.total != false &&
                  props.rows &&
                  props.rows.length > 0 &&
                  props.columns.filter(
                    (column) => column.widget.toLowerCase() === "moneylabel"
                  ).length > 0 ? (
                    <TotalsTableRow>
                      {
                        // Add an empty table cell if delete is enabled
                        props.deleteRowCallback !== undefined &&
                        !props.deleteButtonLast ? (
                          <StyledTableCell padding="checkbox"></StyledTableCell>
                        ) : null
                      }
                      {props.columns.map((column) => (
                        <StyledTableCell
                          key={column.accessor}
                          bordered={props.bordered}
                          scope="row"
                          padding="normal"
                          align={column.align ? column.align : "left"}
                        >
                          {column.widget.toLowerCase() === "moneylabel" ? (
                            <MoneyLabel
                              amount={
                                props.rows.reduce((acc, row) => {
                                  // Check if the row should be omitted from the total
                                  const shouldOmit = props.totalOmitCallback
                                    ? props.totalOmitCallback(row)
                                    : false;
                                  if (shouldOmit) {
                                    return acc;
                                  }

                                  return (
                                    acc +
                                    (row[column.accessor] &&
                                    row[column.accessor]._decimal !== undefined
                                      ? row[column.accessor]._decimal
                                      : Number(row[column.accessor]))
                                  );
                                }, 0) || 0
                              }
                            />
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                      ))}
                    </TotalsTableRow>
                  ) : null}
                </>
              </TableBody>
            </Table>
            {props.rows === undefined || props.rows.length === 0 ? (
              <h2 className="text-center days-one my-6 mx-auto">
                Nothing to show...
              </h2>
            ) : null}
          </TableContainer>
        </Paper>
        {!multiSelectsEnabled &&
        deleteCandidate() &&
        props.deleteConfirmationAttributes ? (
          <Modal isOpen={deleteConfirmationOpen} centered={true}>
            <div className="mx-4 my-4 ">
              <p className="text-center">
                Are you sure you would like to delete the following item?
              </p>
              <ul className="text-muted">
                {props.deleteConfirmationAttributes.map((attribute) => (
                  <li key={attribute}>
                    {columnFromAccessor(attribute).label}:{" "}
                    {getCellWidget(deleteCandidate(), attribute)}
                  </li>
                ))}
              </ul>
              <Button
                color="warning"
                className="float-right mb-2 mx-2"
                onClick={() => {
                  if (props.secondaryDeleteAttribute !== undefined) {
                    props.deleteRowCallback(
                      idToDelete,
                      props.secondaryDeleteAttribute
                    );
                  } else {
                    props.deleteRowCallback(idToDelete);
                  }
                }}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                className="float-right mb-2 mx-2"
                onClick={() => setDeleteConfirmationOpen(false)}
              >
                No, cancel
              </Button>
            </div>
          </Modal>
        ) : null}
        {showDevSwitches ? (
          <>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Compact view?"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectsDisplayed}
                  onChange={handleSelectsDisplayed}
                />
              }
              label="Display selections?"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={multiSelectsEnabled}
                  onChange={handleMultiSelectsEnabled}
                />
              }
              label="Enable multi-selections?"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={deleteSelectedEnabled}
                  onChange={handleDeleteSelectedEnabled}
                />
              }
              label="Enable deleted selected?"
            />
          </>
        ) : null}
      </Box>
    </>
  );
}
