import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import BackButton from "../../components/Widgets/BackButton";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

const TimeclockRoundingOptions = ({ finishedEditing }) => {
  const adminContext = useContext(AdminContext);
  const [roundingOptions, setRoundingOptions] = useState([]);
  const [roundingTargetDict, setRoundingTargetDict] = useState({});
  const [roundWhatDict, setRoundWhatDict] = useState({});
  const [roundHowDict, setRoundHowDict] = useState({});
  const [isLoading, setLoading] = useState(true);

  const fetchRoundingOptions = () => {
    // Fetch the rounding options from the API
    // Replace with actual API call
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/rosters/" +
        adminContext.company.id +
        "/rounding-options",
      (response) => {
        setRoundingOptions(response.data.rounding_options);
        setRoundingTargetDict(response.data.rounding_target_dict);
        setRoundWhatDict(response.data.round_what_dict);
        setRoundHowDict(response.data.round_how_dict);
        setLoading(false);
      }
    );
  };

  // Fetch initial data
  useEffect(() => {
    fetchRoundingOptions();
  }, []);

  const formSpec = {
    title: "Shift Rounding Option",
    fields: [
      {
        label: "Rounding Target",
        accessor: "rounding_target",
        widget: "ComboBox",
        unique: true,
        args: {
          comboDataCallback: () => {
            return roundingTargetDict;
          },
        },
      },
      {
        label: "Round What",
        accessor: "round_what",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return roundWhatDict;
          },
        },
      },
      {
        label: "Round How",
        accessor: "round_how",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return roundHowDict;
          },
        },
      },
    ],
  };

  // Define columns for the TableWidget
  const columns = [
    { label: "Value to be rounded?", accessor: "rounding_target" },
    { label: "By how many minutes?", accessor: "round_what" },
    { label: "How it should be rounded?", accessor: "round_how" },
  ];

  const saveChanges = (roundingOption) => {
    setLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        "/rosters/" +
        adminContext.company.id +
        "/rounding-options",
      roundingOption,
      (response) => {
        fetchRoundingOptions();
      }
    );
  };

  const deleteRoundingOption = (roundingOption) => {
    setLoading(true);
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL +
        "/rosters/" +
        adminContext.company.id +
        "/rounding-options/" +
        roundingOption,
      (response) => {
        fetchRoundingOptions();
      }
    );
  };

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <BackButton goBack={finishedEditing} />
              <Row>
                <Col>
                  <h1>Timeclock/Timesheet Shift Automatic Rounding Options</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TableWidget
                    rows={roundingOptions}
                    columns={columns}
                    formSpec={formSpec}
                    editRowSaveCallback={saveChanges}
                    deleteRowCallback={deleteRoundingOption}
                    deleteConfirmationAttributes={[
                      "rounding_target",
                      "round_how",
                      "round_what",
                    ]}
                    appendRowCallback={() => {
                      return {
                        id: null,
                        rounding_target: "Duration",
                        round_what: 1,
                        round_how: "Closest",
                      };
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default TimeclockRoundingOptions;
