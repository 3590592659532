import AdminContext from "../../AdminContext";
import { useState, useContext } from "react";
import { Container, Card, CardBody, Alert, Button } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import BackButton from "../../components/Widgets/BackButton";
import FileUploadButton from "../../components/Widgets/FileUploadButton";

const ImportEmployees = () => {
  const [isLoading, setIsLoading] = useState(false);
  const adminContext = useContext(AdminContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [employees, setEmployees] = useState([]);

  const handleImport = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        "/employees/import-employees/" +
        adminContext.company.id,
      formdata,
      (response) => {
        if (response.data.error) {
          adminContext.setAlertColor("warning");
          adminContext.setAlertMessage(response.data.error);
          return;
        } else if (response.data.success) {
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage(response.data.success);
          return;
        }
        if (response.data.columns) {
          setColumns(response.data.columns);
        }
        if (response.data.employees) {
          setEmployees(response.data.employees);
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage("File uploaded successfully.");
        }
      }
    );
  };

  return (
    <>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            <h1 className="days-one my3">Import Employees</h1>
            <BackButton />

            {employees.length > 0 && columns ? (
              <>
                <h3>
                  The following {employees.length} employee records have been imported.
                </h3>
                <p>
                  Note: You can edit these employees after this import is
                  complete.
                </p>
                <TableWidget
                  title="Importable Employees"
                  rows={employees}
                  columns={columns}
                />
              </>
            ) : (
              <>
                <p>Upload a CSV file to import employees below. Please see <strong><a className='text-info' href='https://www.lightningpayroll.com.au/faq?selected=299' target="_blank">our support article on 
                employee importing</a></strong> for an example spreadsheet to use and information on each field.</p>
                <div className="my-2">
                  <FileUploadButton
                    selectedFile={selectedFile}
                    label="Upload CSV"
                    handleSubmission={handleImport}
                    setSelectedFile={setSelectedFile}
                  />
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default ImportEmployees;
