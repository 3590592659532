import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Container,
  Modal,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

const DeleteEntity = () => {
  const adminContext = useContext(AdminContext);
  const history = useHistory();
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [company, setCompany] = useState({});

  const closeConfirmation = () => {
    setConfirmationOpen(false);
    setCompany({});
  };

  const handleClick = (e) => {
    setCompany(adminContext.companies[e.currentTarget.value]);
    setConfirmationOpen(true);
  };

  const deleteCompany = async () => {
    setDeleteConfirmed(true);

    await adminContext.deleteRequest(
      adminContext.constants.BASE_URL + `/company/${company.id}`,
      (response) => {
        setConfirmationOpen(false);
        if (company.id === adminContext.company.id) {
          adminContext.setCompany(
            adminContext.companies ? adminContext.companies[0] : {}
          );
        }
        setCompany({});
        adminContext.setCompany(
          adminContext.companies ? adminContext.companies[0] : {}
        );
        history.push("/admin/entities/select");
      }
    );
  };

  const createCompanyCols = () => {
    return adminContext.companies.map((company, key) => {
      return (
        <Col key={key} className="my-3">
          <Button
            className={
              company === adminContext.company
                ? "text-muted bg-gradient-white border-primary"
                : "text-muted"
            }
            value={key}
            onClick={handleClick}
          >
            <i className="ni ni-shop" />
            <br />
            <h3>{company.name}</h3>
            <p>{"ABN: " + company.abn}</p>
            <i className="fa fa-times text-lg text-red" />
          </Button>
        </Col>
      );
    });
  };

  return (
    <Container className="my-4">
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <h1 className="mb-0 days-one text-center">
                Please Choose a Company/Business to{" "}
                <span className="text-red">Delete</span>
              </h1>
            </CardHeader>
            <CardBody>
              {adminContext.companies ? (
                <Row className="icon-examples">
                  {createCompanyCols(adminContext.companies)}
                </Row>
              ) : null}
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row>
        <Col>
          <div>
            <Modal isOpen={confirmationOpen} contentLabel="Delete Company">
              {company && deleteConfirmed ? (
                <>
                  <LoadingSpinner width="50%" height="50%" />
                  <p className="mx-2 my-5 text-center">
                    Please wait. Deleting a company can take a little while...
                  </p>
                </>
              ) : (
                <div className="mx-4 my-4 ">
                  <p className="text-center">
                    Are you sure you would like to delete{" "}
                    {company.name ? company.name : "the company"}?
                  </p>
                  <Button
                    color="warning"
                    className="float-right mb-2 mx-2"
                    onClick={deleteCompany}
                  >
                    Yes
                  </Button>
                  <Button
                    color="secondary"
                    className="float-right mb-2 mx-2"
                    onClick={closeConfirmation}
                  >
                    No, cancel
                  </Button>
                </div>
              )}
            </Modal>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};
export default DeleteEntity;
