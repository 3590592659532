import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import { useEffect, useContext } from "react";
import { Card, CardBody } from "reactstrap";
import AdminContext from "../../../AdminContext";

const RemoteValidationView = ({
  wizardInfo,
  setWizardInfo,
  loading,
  setLoading,
  validationItems,
  handleNext,
}) => {
  const adminContext = useContext(AdminContext);

  const init = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/remote-validation`,
      wizardInfo,
      (response) => {
        console.log(response.data.wizard_info);
        if (response.data.wizard_info) {
          setWizardInfo(response.data.wizard_info);
        }
        if (response.data.wizard_info.validation_message === null) {
          handleNext();
        }

        setLoading(false);
      }
    );
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-left">
            <h1 className="text-center days-one">Remote Validation</h1>
            <p>
              Remote validation is the final step for validating the SuperStream
              Contribution or Registration Messages that will be sent via the
              SuperStream network. This step contacts our servers for a robust
              set of error checking.
            </p>
            {wizardInfo.validation_message && (
              <p>Validation message: {wizardInfo.validation_message}</p>
            )}

            <TableWidget
              label=""
              rows={validationItems || []}
              columns={[
                {
                  label: "Super Fund",
                  accessor: "provider_name",
                },
                {
                  label: "ABA Transaction Reference",
                  accessor: "payment_reference",
                },
                {
                  label: "Payable Amount",
                  accessor: "total_amount",
                  widget: "MoneyLabel",
                },
              ]}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default RemoteValidationView;
