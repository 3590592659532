import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import { FormControlLabel } from "@mui/material";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import CheckBox from "@mui/material/Checkbox";
import PercentageEdit from "../../../components/Widgets/PercentageEdit";
import HoursEdit from "../../../components/Widgets/HoursEdit";
import DaysEdit from "../../../components/Widgets/DaysEdit";
import FloatEdit from "../../../components/Widgets/FloatEdit";

const LeaveSettings = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Update the employee on data change
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const getPercentage = (accessor) => {
    return (
      Math.round(Number(adminContext.currentEmployee[accessor]) * 100 * 100) /
      100
    );
  };

  const setPercentage = (accessor, percentage) => {
    let roundedPercentage = Math.round(percentage * 100) / 100 / 100;

    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      [accessor]: roundedPercentage,
    });
  };

  return (
    <Container className="my-3" fluid>
      {!isLoading ? (
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <AvForm onValidSubmit={saveChanges}>
                <CardBody>
                  <Row>
                    <Col>
                      <h1 className="days-one">Leave Settings</h1>
                    </Col>
                  </Row>
                  <SaveChangesMessageRow
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                  />
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={adminContext.currentEmployee.is_leave_enabled}
                        control={
                          <CheckBox
                            name="is_leave_enabled"
                            checked={
                              adminContext.currentEmployee.is_leave_enabled
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Are leave accruals enabled?"
                      />
                    </Col>
                  </Row>
                  {adminContext.currentEmployee.is_leave_enabled ? (
                    <>
                      <Row>
                        <Col>
                          <FormControlLabel
                            key={
                              adminContext.currentEmployee
                                .accrue_leave_on_hours_worked
                            }
                            control={
                              <CheckBox
                                name="accrue_leave_on_hours_worked"
                                checked={
                                  adminContext.currentEmployee
                                    .accrue_leave_on_hours_worked
                                }
                                onChange={handleChange}
                              />
                            }
                            label="Accrue leave based on actual hours paid (pro rata)?"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormControlLabel
                            key={
                              adminContext.currentEmployee
                                .accrue_leave_on_overtime_hours
                            }
                            control={
                              <CheckBox
                                name="accrue_leave_on_overtime_hours"
                                checked={
                                  adminContext.currentEmployee
                                    .accrue_leave_on_overtime_hours
                                }
                                onChange={handleChange}
                              />
                            }
                            label="Accrue leave on overtime?"
                          />
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <Row>
                    <Col>
                      <PercentageEdit
                        key="leave_loading_percentage"
                        label="Holiday/annual leave loading % (0 or 17.5 are most common)"
                        getPercentage={() =>
                          getPercentage("leave_loading_percentage")
                        }
                        setPercentage={(percentage) =>
                          setPercentage("leave_loading_percentage", percentage)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .include_leave_loading_in_super
                        }
                        control={
                          <CheckBox
                            name="include_leave_loading_in_super"
                            checked={
                              adminContext.currentEmployee
                                .include_leave_loading_in_super
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Include leave loading in super (OTE)?"
                      />
                    </Col>
                  </Row>
                  {adminContext.currentEmployee.is_leave_enabled ? (
                    <Row>
                      <Col>
                        <FormControlLabel
                          key={adminContext.currentEmployee.is_lsl_enabled}
                          control={
                            <CheckBox
                              name="is_lsl_enabled"
                              checked={
                                adminContext.currentEmployee.is_lsl_enabled
                              }
                              onChange={handleChange}
                            />
                          }
                          label="Accrue long service leave?"
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {adminContext.currentEmployee.is_leave_enabled ? (
                    adminContext.currentEmployee
                      .accrue_leave_on_hours_worked ? (
                      <>
                        <Row>
                          <Col>
                            <h3>Accrue leave per hour paid (pro rata)</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mx-4 my-3">
                            <div key={adminContext.currentEmployee.id}>
                              <div className="mb-1">
                                <label>
                                  Hours holiday leave accrued for each hour paid
                                </label>
                              </div>
                              <HoursEdit
                                decimalPrecision={6}
                                value={
                                  adminContext.currentEmployee
                                    .accrue_holiday_leave_per_hour
                                }
                                name="accrue_holiday_leave_per_hour"
                                setValue={(value) =>
                                  adminContext.setCurrentEmployee({
                                    ...adminContext.currentEmployee,
                                    accrue_holiday_leave_per_hour: value,
                                  })
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mx-4 my-3">
                            <div key={adminContext.currentEmployee.id}>
                              <div className="mb-1">
                                <label>
                                  Hours sick/personal leave accrued for each
                                  hour paid
                                </label>
                              </div>
                              <HoursEdit
                                decimalPrecision={6}
                                value={
                                  adminContext.currentEmployee
                                    .accrue_sick_leave_per_hour
                                }
                                name="accrue_sick_leave_per_hour"
                                setValue={(value) =>
                                  adminContext.setCurrentEmployee({
                                    ...adminContext.currentEmployee,
                                    accrue_sick_leave_per_hour: value,
                                  })
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        {adminContext.currentEmployee.is_lsl_enabled ? (
                          <Row>
                            <Col className="mx-4 my-3">
                              <div key={adminContext.currentEmployee.id}>
                                <div className="mb-1">
                                  <label>
                                    Hours long service leave accrued for each
                                    hour paid
                                  </label>
                                </div>
                                <HoursEdit
                                  decimalPrecision={6}
                                  value={
                                    adminContext.currentEmployee
                                      .accrue_lsl_per_hour
                                  }
                                  name="accrue_lsl_per_hour"
                                  setValue={(value) =>
                                    adminContext.setCurrentEmployee({
                                      ...adminContext.currentEmployee,
                                      accrue_lsl_per_hour: value,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <h3>
                          Accrue leave per year (E.g. Spread over 52 weeks, 26
                          fortnights or 12 months)
                        </h3>
                        <Row>
                          <Col>
                            <div key={adminContext.currentEmployee.id}>
                              <div className="mb-1">
                                <label>
                                  Holiday/annual leave <strong>days</strong>{" "}
                                  accrued per year (1 day ={" "}
                                  {(
                                    adminContext.currentEmployee
                                      .award_hours_day /
                                    adminContext.constants.MILLISECONDS_PER_HOUR
                                  ).toFixed(2)}{" "}
                                  hours)
                                </label>
                              </div>
                              <DaysEdit
                                value={
                                  adminContext.currentEmployee
                                    .holiday_days_per_year
                                }
                                hoursPerDay={
                                  adminContext.currentEmployee.award_hours_day
                                }
                                name="holiday_days_per_year"
                                setValue={(value) =>
                                  adminContext.setCurrentEmployee({
                                    ...adminContext.currentEmployee,
                                    holiday_days_per_year: value,
                                  })
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div key={adminContext.currentEmployee.id}>
                              <div className="mb-2 mt-3">
                                <label>
                                  Sick/personal leave <strong>days</strong>{" "}
                                  accrued per year (1 day ={" "}
                                  {(
                                    adminContext.currentEmployee
                                      .award_hours_day /
                                    adminContext.constants.MILLISECONDS_PER_HOUR
                                  ).toFixed(2)}{" "}
                                  hours)
                                </label>
                              </div>
                              <DaysEdit
                                value={
                                  adminContext.currentEmployee
                                    .sick_days_per_year
                                }
                                hoursPerDay={
                                  adminContext.currentEmployee.award_hours_day
                                }
                                name="sick_days_per_year"
                                setValue={(value) =>
                                  adminContext.setCurrentEmployee({
                                    ...adminContext.currentEmployee,
                                    sick_days_per_year: value,
                                  })
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        {adminContext.currentEmployee.is_lsl_enabled ? (
                          <Row>
                            <Col>
                              <div
                                className="mt-3"
                                key={adminContext.currentEmployee.id}
                              >
                                <h3>Long Service Leave Accrual</h3>
                                <Row>
                                  <Col md="1">
                                    <p>Accrue</p>
                                  </Col>
                                  <Col md="3">
                                    <FloatEdit
                                      value={
                                        adminContext.currentEmployee
                                          .lsl_accrued_x_years
                                      }
                                      handleChange={(e) => {
                                        adminContext.setCurrentEmployee({
                                          ...adminContext.currentEmployee,
                                          lsl_accrued_x_years: e.target.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col md="3">
                                    <p>weeks of long service leave every</p>
                                  </Col>
                                  <Col md="3">
                                    <FloatEdit
                                      value={
                                        adminContext.currentEmployee.lsl_x_years
                                      }
                                      handleChange={(e) => {
                                        adminContext.setCurrentEmployee({
                                          ...adminContext.currentEmployee,
                                          lsl_x_years: e.target.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col md="2">
                                    <p>years</p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </>
                    )
                  ) : null}
                </CardBody>
              </AvForm>
            </Card>
          </Col>
        </Row>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default LeaveSettings;
