import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

import TableWidget from "../../components/Widgets/TableWidget.js";

const CompanyDepartments = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const updateDepartments = (department) => {
    setIsLoading(true);
    setSaving(true);
    // Update the current departments and also update the departments array, addint the department if its ID is null
    let newDepartments = [];
    if (department.id === null) {
      newDepartments = [...adminContext.company.departments, department];
    } else {
      newDepartments = adminContext.company.departments.map((d) => {
        if (d.id === department.id) {
          return department;
        } else {
          return d;
        }
      });
    }
    adminContext.setCompany({
      ...adminContext.company,
      departments: newDepartments,
      currentDepartment: false,
    });
  };

  const setCurrentDepartment = (department) => {
    // Notice how this is different to the updateDepartments function as it only sets the current department, not the departments array
    // This is necessary in case edits get cancelled.
    adminContext.setCompany({
      ...adminContext.company,
      currentDepartment: department,
    });
  };

  const handleChange = (event) => {
    let department = adminContext.company.currentDepartment;
    department[event.target.name] = event.target.value;
    adminContext.setCompany({
      ...adminContext.company,
      currentDepartment: department,
    });
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setCurrentDepartment(false);
    setIsLoading(true);
    setSaving(false);

    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/company/${adminContext.company.id}`,
      adminContext.company,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const createDepartment = () => {
    return { id: null, company_id: adminContext.company.id };
  };

  const deleteDepartment = (idToDelete) => {
    adminContext.setCompany({
      ...adminContext.company,
      departments: adminContext.company.departments.filter(
        (department) => department.id !== idToDelete
      ),
      currentDepartment: false,
    });
    setSaving(true);
  };

  const formSpec = {
    title: "Department",
    fields: [
      {
        label: "Department Name",
        accessor: "name",
        widget: "text",
        required: true,
        onChange: handleChange,
        validate: {
          required: { value: true },
          minLength: { value: 1 },
          maxLength: { value: 80 },
        },
      },
      {
        label: "Notes",
        accessor: "notes",
        widget: "textarea",
        onChange: handleChange,
      },
    ],
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.departments]);

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Departments</h1>
                  </Col>
                </Row>
                {!adminContext.company.currentDepartment && (
                  <Row>
                    <Col className="mt-3 mx-4">
                      <p>
                        NOTE: If you want to add a department to this pay which
                        is not listed below, you need to add the employee to the
                        department first under{" "}
                        <strong>{"Employees >> Departments"}</strong>.
                      </p>
                      <p>
                        Departments cannot be deleted if they have employee or
                        pay history.
                      </p>
                    </Col>
                  </Row>
                )}
                {!saving ? (
                  <Row>
                    <Col>
                      <TableWidget
                        rows={adminContext.company.departments}
                        setRows={(departments) => {
                          adminContext.setCompany({
                            ...adminContext.company,
                            departments: departments,
                          });
                        }}
                        columns={formSpec.fields}
                        selected={adminContext.company.currentDepartment}
                        setSelected={setCurrentDepartment}
                        appendRowCallback={createDepartment}
                        editRowSaveCallback={updateDepartments}
                        deleteRowCallback={deleteDepartment}
                        deleteRowCallbackEnabled={(department) =>
                          department.delete_enabled
                        }
                        deleteConfirmationAttributes={["name", "notes"]}
                        formSpec={formSpec}
                      />
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default CompanyDepartments;
