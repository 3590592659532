import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useContext } from "react";
import AdminContext from "../../AdminContext";
const UserRestricted = () => {
  const adminContext = useContext(AdminContext);

  return (
    <Container
      maxWidth="sm"
      style={{ marginTop: "20vh", textAlign: "center", minHeight: "50vh" }}
    >
      <Typography variant="h4" gutterBottom>
        Access Restricted
      </Typography>
      <Typography variant="body1" paragraph>
        Sorry, your user profile does not have permission to view this page. If
        you believe you need access, please{" "}
        <a
          className="underline-link"
          href={"mailto:" + adminContext.primarySubscriptionEmail}
        >
          contact
        </a>{" "}
        your payroll administrator.
      </Typography>
      <Button variant="contained" color="primary" href="/admin/entities/select">
        Go Home
      </Button>
    </Container>
  );
};

export default UserRestricted;
