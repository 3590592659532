import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl } from "@mui/material";

function FontPicker({ fonts, fontFamily, setFontFamily }) {
  const handleChange = (event) => {
    setFontFamily(event.target.value);
  };

  useEffect(() => {
    // if font family is not in the list of fonts, set it to DejaVu Sans, or the first font in the list
    if (!fonts.includes(fontFamily)) {
      if (fonts.includes("DejaVu Sans")) {
        setFontFamily("DejaVu Sans");
      } else {
        setFontFamily(fonts[0]);
      }
    }
  }, [fonts]);

  if (fontFamily === undefined || !fonts.includes(fontFamily)) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <label className="form-label">Font Family</label>
      <Select
        value={fontFamily}
        onChange={handleChange}
        style={{ fontFamily: fontFamily }}
        className="mb-3 width-100 bg-light"
        label="Font Picker"
      >
        {fonts.map((font) => (
          <MenuItem key={font} value={font} style={{ fontFamily: font }}>
            {font}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FontPicker;
