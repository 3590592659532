import React from "react";
import AdminContext from "../../AdminContext";

const TimeLabel = ({ className, time }) => {
  const adminContext = React.useContext(AdminContext);
  const timeFormat = adminContext.appConfig.hours_display.value;
  const formatTimeDisplay = (date) => {
    if (!date) {
      return "";
    }

    // If date is a string, convert it to a Date object
    if (typeof date === "string") {
      // Date is a 24 hour time string I.e. 17:00:00
      // Split it into an array of [17, 00, 00]
      let time = date.split(":");
      // Create a new Date object
      date = new Date();
      // Set the hours, minutes and seconds of the new Date object
      date.setHours(time[0]);
      date.setMinutes(time[1]);
      date.setSeconds(time[2]);
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let suffix = "";

    if (timeFormat === "12 hr time") {
      suffix = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
    }

    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + suffix;
    return strTime;
  };

  return (
    <div className={className || ""}>
      {time ? formatTimeDisplay(time) : "N/A"}
    </div>
  );
};

export default TimeLabel;
