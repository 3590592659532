import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import { useState, useEffect, useContext } from "react";
import { Button, Card, CardBody } from "reactstrap";
import AdminContext from "../../../AdminContext";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";

const PayslipEmailView = ({
  wizardInfo,
  setNextEnabled,
  setForceUpdateButtons,
  loading,
  setLoading,
}) => {
  const [paysToEmail, setPaysToEmail] = useState([]);
  const adminContext = useContext(AdminContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [sendResults, setSendResults] = useState(false);

  const sendEmails = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/payslips/email/send`,
      wizardInfo,
      (response) => {
        setLoading(false);
        setNextEnabled(true);

        let results = {};
        if (response.data.success) {
          results = response.data.success.map((s) => {
            return {
              result: s,
              type: "Success",
            };
          });
        }
        if (response.data.error) {
          results = results.concat(
            response.data.error.map((e) => {
              return {
                result: e,
                type: "Error",
              };
            })
          );
        }

        setSendResults(results);

        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Emails sent successfully.");
        } else {
          setErrorMessage("Something went wrong. Unable to send emails.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const getEmailPays = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/payslips/email/pays`,
      wizardInfo,
      (response) => {
        setLoading(false);
        if (response.data.pays_to_email) {
          // We dont want to add a pay if the pay's employee is already in the list
          let noDuplicateEmployeePays = [];
          response.data.pays_to_email.forEach((pay) => {
            if (
              !noDuplicateEmployeePays.find(
                (p) => p.employee.id === pay.employee.id
              )
            ) {
              noDuplicateEmployeePays.push(pay);
            }
          });
          setPaysToEmail(noDuplicateEmployeePays);
        }
      }
    );
  };

  useEffect(() => {
    // Disable next until sending has completed
    setNextEnabled(false);

    // Get the pay ids to email
    getEmailPays();
  }, []);

  useEffect(() => {
    setForceUpdateButtons(true);
  }, [paysToEmail, sendResults]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-center">
            <h1 className="text-center days-one">Email Payslips</h1>

            <SaveChangesMessageRow
              errorMessage={errorMessage}
              successMessage={successMessage}
              saveChanges={sendEmails}
              label="Send Emails Now"
              icon="fa-envelope"
            />
            <TableWidget
              label="Employees to Email"
              rows={paysToEmail}
              columns={[
                {
                  label: "Employee",
                  accessor: "employee_format_name_shorter",
                },
                {
                  label: "Email",
                  accessor: "employee_email_address",
                },
              ]}
            />
            {sendResults ? (
              <TableWidget
                label="Send Results"
                rows={sendResults}
                columns={[
                  {
                    label: "Result",
                    accessor: "result",
                  },
                  {
                    label: "Type",
                    accessor: "type",
                  },
                ]}
                rowStyleCallback={(row) => {
                  if (row.type === "Error") {
                    return "bg-warning";
                  }
                  return "";
                }}
              />
            ) : null}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PayslipEmailView;
