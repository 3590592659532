import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
} from "reactstrap";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

import { useState, useContext } from "react";
import AdminContext from "../../AdminContext";

const ActionsButton = () => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const adminContext = useContext(AdminContext);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [secondDeleteConfirmationOpen, setSecondDeleteConfirmationOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteEmployee = () => {
    setIsLoading(true);
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL +
        `/employees/${adminContext.currentEmployee.id}`,
      (response) => {
        adminContext.setCurrentEmployee({});
        setIsLoading(false);
        closeModals();
      }
    );
  };

  const closeModals = () => {
    setDeleteConfirmationOpen(false);
    setSecondDeleteConfirmationOpen(false);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <ButtonDropdown
            className="mb-3 width-100"
            isOpen={actionsOpen}
            toggle={() => {
              setActionsOpen(!actionsOpen);
            }}
          >
            <DropdownToggle color="primary" className="rounded width-100">
              <strong>Actions</strong>
              <i className="ni ni-bold-down" />{" "}
            </DropdownToggle>
            <DropdownMenu className="rounded width-100 text-center">
              <DropdownItem
                onClick={() => {
                  adminContext.history.push("/admin/employees/new_employee");
                }}
                disabled={!adminContext.editAccess("employees")}
              >
                Add New Employee
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  adminContext.history.push(
                    "/admin/employees/import_employees"
                  );
                }}
                disabled={!adminContext.editAccess("employees")}
              >
                Import Employees From CSV
              </DropdownItem>
              <DropdownItem divider />
              {adminContext.companies.length > 1 &&
              adminContext.currentEmployee !== undefined &&
              adminContext.currentEmployee.id ? (
                <>
                  <DropdownItem
                    onClick={() => {
                      adminContext.history.push(
                        "/admin/employees/copy_employee"
                      );
                    }}
                  >
                    Copy Employee
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              ) : null}
              {adminContext.currentEmployee !== undefined &&
              adminContext.currentEmployee.is_terminated ? (
                <>
                  <DropdownItem
                    onClick={() => {
                      adminContext.history.push("/admin/employees/reinstate");
                    }}
                    className="text-success"
                  >
                    Reinstate Employee
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              ) : null}
              {adminContext.currentEmployee !== undefined &&
              adminContext.currentEmployee.id ? (
                <DropdownItem
                  onClick={() => {
                    setDeleteConfirmationOpen(true);
                  }}
                  disabled={!adminContext.editAccess("employees")}
                  className="text-danger"
                >
                  Delete Employee
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </ButtonDropdown>
          <Modal isOpen={secondDeleteConfirmationOpen}>
            <div className="mx-4 my-4 ">
              <p>
                This action cannot be undone. Are you sure you wish to erase,
                and not terminate?
              </p>
              <p>
                If you only wish to terminate the employee, do this in the{" "}
                <strong>PAYS </strong>
                screen. <br />
                Terminating an employee will keep their records and simply
                formalise their exit from the company.
              </p>
              <p className="font-heavy">
                Are you absolutely sure you wish to completely erase all history
                for this employee?
              </p>

              <Button
                color="warning"
                className="float-right mb-2 mx-2"
                onClick={deleteEmployee}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                className="float-right mb-2 mx-2"
                onClick={closeModals}
              >
                No, cancel
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteConfirmationOpen}>
            <div className="mx-4 my-4 ">
              <p>
                Are you sure you would like to delete the employee profile for{" "}
                <strong>
                  {adminContext.currentEmployee !== undefined
                    ? adminContext.currentEmployee.first_name +
                      " " +
                      adminContext.currentEmployee.last_name
                    : null}
                </strong>
                ?
              </p>
              <p>
                {" "}
                The Australian Tax Office requires that electronic records are
                kept for at least seven years.
              </p>

              <Button
                color="warning"
                className="float-right mb-2 mx-2"
                onClick={() => setSecondDeleteConfirmationOpen(true)}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                className="float-right mb-2 mx-2"
                onClick={closeModals}
              >
                No, cancel
              </Button>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <LoadingSpinner size="3rem" className="text-center mx-auto" />{" "}
          <p className="text-danger"> Deleting employee profile...</p>
        </>
      )}
    </>
  );
};
export default ActionsButton;
