import React, { useState, useContext, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, CardBody, Modal } from 'reactstrap';
import { AvForm } from "availity-reactstrap-validation";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Checkbox } from '@mui/material';

import AdminContext from "../../../AdminContext";
import DurationEdit from "../../../components/Widgets/DurationEdit";
import DurationLabel from "../../../components/Widgets/DurationLabel";
import TableWidget from "../../../components/Widgets/TableWidget";
import QuestionModal from "../../../components/Widgets/QuestionModal";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const LeaveCalculator = () => {
  const { paramLeaveType } = useParams();
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const [addToBalance, setAddToBalance] = useState(false);
  const [deductLeaveTaken, setDeductLeaveTaken] = useState(false);
  const [workPeriods, setWorkPeriods] = useState([]);
  const [defaultWorkPeriod, setDefaultWorkPeriod] = useState([]);
  const [newLeaveBalance, setNewLeaveBalance] = useState({ milliseconds: 0 });
  const [totalLeaveTaken, setTotalLeaveTaken] = useState({ milliseconds: 0 });
  const [successMessage, setSuccessMessage] = useState('');
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);

  const leaveTypes = ['holiday', 'sick', 'long_service'];
  const leaveType = leaveTypes.includes(paramLeaveType.replace('_leave_calculator', ''))
    ? paramLeaveType.replace('_leave_calculator', '')
    : 'holiday';

  const employee = adminContext.currentEmployee;

  const title = `Set ${
    leaveType === 'long_service'
      ? 'Long Service'
      : leaveType.charAt(0).toUpperCase() + leaveType.slice(1)
  } Leave Balance`;

  const shortDescription = [
    "The below form is used to enter periods of work that an employee has completed with the company.",
    "The settings for each work period can be changed to represent what the employee was working at that point in time.",
    'If you wish to add it to the current leave balance, check the box "Add to current balance?".',
    "Note: If an employee has changed their working hours over the course of their employment (from part-time to full-time) \
     and had different working hours during those periods, it will be represented by multiple lines within the form below.",
    "The periods of time below are raw values and don't take into account pays within the application."
  ].map((line, index) => <p key={index}>{line}</p>);

  const questionModalContent = `Are you sure you would like to proceed? You are about to adjust this employee's ${
    leaveType === 'long_service'
      ? 'long service'
      : leaveType.charAt(0).toUpperCase() + leaveType.slice(1)
  } Leave balance.`;

  const initData = async () => {
    setLoading(true);
    const url = `${adminContext.constants.BASE_URL}/employees/leave/${leaveType}_leave_calculator/${employee.id}`;
    
    try {
      const response = await adminContext.getRequest(url);
      if (isMounted.current) {
        const data = response.data.leave_data;
        const retrievedWorkPeriods = {
          start_date: data.start_date,
          end_date: moment().format('YYYY-MM-DD'),
          award_hours_week: data.award_hours_week.milliseconds,
          weeks_per_year: data.weeks_per_year,
          lsl_accrued_x_years: data.lsl_accrued_x_years || undefined,
          lsl_x_years: data.lsl_x_years || undefined,
          weeks_calculated: data.weeks_calculated || 'None',
        };
        setWorkPeriods([retrievedWorkPeriods]);
        const defaultWorkPeriod = {
          ...retrievedWorkPeriods,
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD')
        };
        setDefaultWorkPeriod(defaultWorkPeriod);
        setTotalLeaveTaken(data.total_leave_taken.milliseconds);
      }
    } catch (error) {
      console.error('Error getting work periods:', error);
    } finally {
      setLoading(false);
    }
  };

  const isValidWorkPeriod = (period) => {
    return period.start_date && period.end_date && moment(period.start_date).isValid() && moment(period.end_date).isValid();
  };
  
  const sanitizePeriod = (period) => {
    return {
      ...period,
      award_hours_week: period.award_hours_week || 0,
      weeks_per_year: period.weeks_per_year || 0,
      lsl_accrued_x_years: period.lsl_accrued_x_years || 0,
      lsl_x_years: period.lsl_x_years || 0,
    };
  };

  const fetchNewLeaveBalance = async () => {
    setLoading(true);
    const url = `${adminContext.constants.BASE_URL}/employees/leave/${leaveType}_leave_calculator/new_leave_balance/${employee.id}`;
    const validWorkPeriods = workPeriods.filter(isValidWorkPeriod).map(sanitizePeriod);
    const payload = { 
      addToBalance, 
      workPeriods: validWorkPeriods.map(period => ({
        ...period
      })), 
      deductLeaveTaken,
      totalLeaveTaken: totalLeaveTaken
    };
    
    try {
      const response = await adminContext.postRequest(url, payload);
      const { data } = response;
  
      setNewLeaveBalance(data.total_new_balance);
  
      // Update workPeriods with weeks_calculated from response
      const updatedWorkPeriods = workPeriods.map((period, index) => {
        if (isValidWorkPeriod(period)) {
          return {
            ...period,
            weeks_calculated: data.weeks_calculated_data[index].weeks_calculated
          };
        }
        return period;
      });

      setWorkPeriods(updatedWorkPeriods);
    } catch (error) {
      console.error('Error calculating new leave balance:', error);
    } finally {
      setLoading(false);
    }
  };  

  const cancelCallback = () => {
    adminContext.history.goBack();
  };

  const setBalanceCallback = async (confirmation) => {
    setConfirmationOpen(false);
      if (confirmation === "YES") {
      // Create a post request sending the new leave balance to the backend to update the employee's leave balance
      setLoading(true);
      const url = `${adminContext.constants.BASE_URL}/employees/leave/${leaveType}_leave_calculator/set_balance/${employee.id}`;
      const validWorkPeriods = workPeriods.filter(isValidWorkPeriod);
      const payload = { 
        addToBalance, 
        workPeriods: validWorkPeriods.map(period => ({
          ...period
        })), 
        deductLeaveTaken,
        totalLeaveTaken: totalLeaveTaken
      };

      try {
        adminContext.postRequest(url, payload).then((response) => {
          const successMessage ={
            success: response.data.success,
            title: response.data.title,
            message: response.data.message
          }
          setSuccessMessage(successMessage);
          setSuccessOpen(true);
        });
      } catch (error) {
        console.error('Error setting leave balance:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Sends user back after closing the success message
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    adminContext.history.goBack();
  };

  // Handle input changes in the table rows
  const handleInputChange = (updatedRow) => {
    const updatedWorkPeriods = workPeriods.map(row =>
      row.id === updatedRow.id ? updatedRow : row
    );
    setWorkPeriods(updatedWorkPeriods);
  };

  // Handle appending a new row
  const handleAppendRow = () => {
    const newId = workPeriods.length;
    const newRow = { ...defaultWorkPeriod, id: newId };
    setWorkPeriods([...workPeriods, newRow]);
  };

  // Handle deleting a row
  const handleDeleteRow = (idToDelete) => {
    // Filter out the row with the matching ID
    const updatedWorkPeriods = workPeriods.filter(row => row.id !== idToDelete);

    // Reassign IDs to maintain unique IDs based on array index
    const reindexedWorkPeriods = updatedWorkPeriods.map((row, index) => ({
      ...row,
      id: index
    }));

    setWorkPeriods(reindexedWorkPeriods);
  };

  const getColumns = () => {
    // Define the common columns without weeks_calculated initially
    const commonColumns = [
      {
        label: 'Start Date',
        accessor: 'start_date',
        widget: 'DateEdit',
        args: {
          setRowCallback: handleInputChange,
        },
      },
      {
        label: 'End Date',
        accessor: 'end_date',
        widget: 'DateEdit',
        args: {
          setRowCallback: handleInputChange,
        },
      },
      {
        label: 'Award Hours/Week',
        accessor: 'award_hours_week',
        widget: 'DurationEdit',
        args: {
          setRowCallback: handleInputChange,
        },
      },
    ];

    // Define the weeks_calculated column separately
    const weeksCalculatedColumn = {
      label: 'Weeks Calculated',
      accessor: 'weeks_calculated',
      widget: 'text',
      args: {
        setRowCallback: handleInputChange,
      },
    };

    // Append condition-specific columns
    let specificColumns;
    if (leaveType !== 'long_service') {
      specificColumns = [
        {
          label: 'Weeks/Year',
          accessor: 'weeks_per_year',
          widget: 'FloatEdit',
          args: {
            setRowCallback: handleInputChange,
            decimalPlaces: 2,
          },
        },
      ];
    } else {
      specificColumns = [
        {
          label: 'Weeks LSL',
          accessor: 'lsl_accrued_x_years',
          widget: 'FloatEdit',
          args: {
            setRowCallback: handleInputChange,
            decimalPlaces: 4,
          },
        },
        {
          label: 'Eligibility Years',
          accessor: 'lsl_x_years',
          widget: 'FloatEdit',
          args: {
            setRowCallback: handleInputChange,
            decimalPlaces: 2,
          },
        },
      ];
    }

    // Concatenate columns ensuring weeks_calculated is always last
    return [...commonColumns, ...specificColumns, weeksCalculatedColumn];
  };

  const columns = getColumns();

  useEffect(() => {
    initData();
    return () => {
      isMounted.current = false;
    };
  }, []);    

  useEffect(() => {
    if (workPeriods.length) {
      fetchNewLeaveBalance();
    }
  }, [JSON.stringify(workPeriods.map(({ weeks_calculated, ...rest }) => rest)), addToBalance, deductLeaveTaken, totalLeaveTaken]); 
  
  return (
    <Card className="shadow-sm">
      <CardBody>
        <AvForm className="mx-auto width-80-on-lg">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <h1 className="mb-4 days-one text-center">{`${title} for ${employee.format_name_shorter}`}</h1>
              <div>{shortDescription}</div>
              <TableWidget
                label="Work Periods"
                rows={workPeriods}
                appendRowCallback={handleAppendRow}
                columns={columns}
                deleteRowCallback={(row) => handleDeleteRow(row.id)}
              />
              <Row>
                <Col sm="auto" className="d-flex align-items-center">
                  <label className="form-label"><b>Total Leave Taken:</b></label>
                </Col>
                <Col sm={true} className="d-flex align-items-center">
                  <DurationEdit
                    value={totalLeaveTaken}
                    setValue={setTotalLeaveTaken}
                  />
                </Col>
                <Col sm={true} className="d-flex align-items-center gap-2">
                  <Checkbox
                    className="pl-0"
                    checked={deductLeaveTaken}
                    onChange={(event) => setDeductLeaveTaken(event.target.checked)}
                  />
                  <label className="mb-0">Deduct Leave Taken?</label>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="auto" className="d-flex align-items-center">
                  <label className="form-label"><b>New Leave Balance:</b></label>
                </Col>
                <Col sm="auto" className="d-flex align-items-center">
                  <DurationLabel
                    value={newLeaveBalance}
                  />
                </Col>
                <Col sm="auto" className="d-flex align-items-center gap-2">
                  <Checkbox
                    className="pl-0"
                    checked={addToBalance}
                    onChange={(event) => setAddToBalance(event.target.checked)}
                  />
                  <label className="mb-0">Add to current balance?</label>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button type="submit" color="primary" className="mr-2" onClick={() => setConfirmationOpen(true)}>Set Balance</Button>
                  <Button type="button" color="secondary" onClick={cancelCallback}>Cancel</Button>
                </Col>
              </Row>
              <QuestionModal
                isOpen={isConfirmationOpen}
                title="Confirm Manual Leave Adjustment"
                content={questionModalContent}
                onConfirm={() => setBalanceCallback("YES")}
                onDeny={() => setBalanceCallback("NO")}
              />
              <Modal
                className="width-40-on-lg d-flex align-items-center"
                isOpen={isSuccessOpen}
                centered={true}
              >
                <div className="mx-4 my-4">
                  <h2 className="text-center days-one">{`${successMessage.title}`}</h2>
                  {`${successMessage.message}`}
                  <Button
                    color="warning"
                    className="float-right mb-2 mx-2"
                    onClick={handleSuccessClose}
                  >
                    {"Close"}
                  </Button>
                </div>
              </Modal>
            </>
          )}
        </AvForm>
      </CardBody>
    </Card>
  );
}

export default LeaveCalculator;
