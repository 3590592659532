import React, { useContext, useState, useEffect } from "react";
import AdminContext from "../../../../AdminContext";
import { Row, Col, Alert } from "reactstrap";
import EditForm from "../../../../components/Widgets/EditForm";

const ReasonDateView = ({
  pay,
  payTermination,
  setPayTermination,
  setNextEnabled,
}) => {
  const adminContext = useContext(AdminContext);

  return (
    <div>
      <Row>
        <Col>
          <EditForm
            fixedTitle={true}
            liveEdit={true}
            formSpec={{
              title: "Termination Reason and Date",
              fields: [
                {
                  label: "Termination Date",
                  accessor: "termination_date",
                  widget: "DateEdit",
                  validate: {
                    required: true,
                  },
                  args: {
                    minDate: pay.employee.start_date,
                    validateCallback: (pt) => {
                      if (
                        pt.termination_date < new Date(pay.employee.start_date)
                      ) {
                        setNextEnabled(false);
                        return "Termination date cannot be before employee start date";
                      }
                      setNextEnabled(true);
                      return "";
                    },
                  },
                },
                {
                  label: "Tax Reason for Termination",
                  accessor: "reason",
                  widget: "ComboBox",
                  validate: {
                    required: true,
                  },
                  args: {
                    comboDataCallback: () =>
                      adminContext.constants.TERMINATION_REASONS,
                    skipSort: true,
                    updatedCallback: (payTermination) => {
                      setPayTermination(payTermination);
                    },
                  },
                },
                {
                  label: "STP Cessation Type",
                  accessor: "stp_termination_type",
                  widget: "ComboBox",
                  validate: {
                    required: true,
                  },

                  args: {
                    comboDataCallback: () =>
                      adminContext.constants.CESSATION_TYPES,
                    skipSort: true,
                    visibleCallback: (payTermination) =>
                      payTermination.reason == "TERMINATION",
                  },
                },
              ],
            }}
            setModelObj={setPayTermination}
            modelObj={payTermination}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReasonDateView;
