import { useState, useEffect } from "react";
import { AvField } from "availity-reactstrap-validation";

function HoursEdit(props) {
  const [text, setText] = useState("");

  function convertMsToHours(milliseconds) {
    // Returns the number of hours to 5 decimal places
    return (milliseconds / 1000 / 60 / 60).toFixed(
      props.decimalPrecision ? props.decimalPrecision : 5
    );
  }

  const isNegative = () => {
    return props.value < 0;
  };

  const processTime = (e) => {
    // Update props.setValue with the new value in milliseconds
    let milliseconds = Number(text) * 1000 * 60 * 60;
    props.setValue(milliseconds);
  };

  // call onBlur whenever props.value changes
  useEffect(() => {
    if (props.onBlur !== undefined) {
      props.onBlur({
        target: {
          name: props.name,
        },
      });
    }
  }, [props.value]);

  // Initialise the text value using props.value milliseconds
  useEffect(() => {
    setText(convertMsToHours(props.value));
  }, []);

  return (
    <AvField
      value={text}
      type="number"
      name={props.name}
      onBlur={processTime}
      className={
        isNegative()
          ? "form-control width-100 text-danger"
          : "form-control width-100"
      }
      onChange={(e) => setText(e.target.value)}
      validate={{
        pattern: {
          value: "^[0-9.]*$",
          errorMessage: "Please enter a number",
        },
      }}
    />
  );
}

export default HoursEdit;
