import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

const ReportableFringeBenefits = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/financial-years`,
      (response) => {
        if (response.data.financial_years.length > 0) {
          setFinancialYears(response.data.financial_years);
        }
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=fringe_benefits`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const saveReportableFringeBenefits = (newFringeBenefit) => {
    setSaving(true);
    let newReportableFringeBenefits = [];

    if (newFringeBenefit.id === null) {
      newReportableFringeBenefits = [
        ...adminContext.currentEmployee.tax_offsets,
        newFringeBenefit,
      ];
    } else {
      newReportableFringeBenefits =
        adminContext.currentEmployee.tax_offsets.map((tos) => {
          if (tos.id === newFringeBenefit.id) {
            return newFringeBenefit;
          } else {
            return tos;
          }
        });
    }

    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      tax_offsets: newReportableFringeBenefits,
    });
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
      setSaving(false);
    }
  }, [adminContext.currentEmployee.fringe_benefits]);

  const setCurrentEmpFinancialYearId = (currentEmpFinancialYearId) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentEmpFinancialYearId: currentEmpFinancialYearId,
    });
  };
  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Reportable Fringe Benefits</h1>
                  <p>
                    Below is where you can edit fringe benefit items, and mark
                    whether an employee is exempt from paying fringe benefits
                    tax for a particular financial year. Payment summaries will
                    display the total of these amounts for the relevant
                    financial year. For more information please visit{" "}
                    <strong>
                      <a
                        target="_blank"
                        href="https://www.ato.gov.au/General/Fringe-benefits-tax-(FBT)/In-detail/Employees/Reportable-fringe-benefits---facts-for-employees/"
                      >
                        the ATO website
                      </a>
                    </strong>
                    .
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div key={adminContext.currentEmployee.id} className="my-4">
                    <TableWidget
                      rows={financialYears}
                      order="desc"
                      columns={[
                        {
                          label: "Button",
                          accessor: "None",
                          widget: "Link",
                          args: {
                            label: "",
                            href: "/admin/employees/tax/reportable_fringe_benefits_edit",
                            color: "secondary",
                            icon: "fa-regular fa-edit fa-xl",
                            className: "btn btn-sm btn-secondary",
                          },
                        },
                        {
                          label: "Financial Year",
                          accessor: "financial_year",
                        },
                        {
                          label: "Is FBT Exempt?",
                          accessor: "is_fbt_exempt",
                          widget: "BooleanLabel",
                        },
                        {
                          label: "Fringe Benefit Total",
                          accessor: "fringe_benefit_total",
                          widget: "MoneyLabel",
                        },
                      ]}
                      selected={
                        adminContext.currentEmployee.currentEmpFinancialYearId
                      }
                      setSelected={setCurrentEmpFinancialYearId}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default ReportableFringeBenefits;
