import { Alert, Button, Col, Row, Modal, ModalBody } from "reactstrap";
import React, { useState } from "react";

const SaveChangesMessageRow = ({
  saveChanges,
  disabledCallback,
  label = "Save Changes",
  icon = "fa-save",
  errorMessage,
  successMessage,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <Row>
      <Col className="text-center">
        <Button
          className="my-2 width-50 btn-lg"
          color="primary"
          type="submit"
          onClick={saveChanges !== undefined ? saveChanges : () => toggle()}
          disabled={disabledCallback !== undefined ? disabledCallback() : false}
        >
          {label} <i className={`fa-regular fa-lg ${icon}`} />
        </Button>
        <Modal
          centered={true}
          isOpen={modal}
          toggle={toggle}
          className={"text-center"}
        >
          <ModalBody>
            <h2>Save Failed</h2>
            <p>Please check your form data for errors.</p>
            <p>
              <Button
                color="info"
                className="float-right mb-2 mx-2"
                onClick={toggle}
              >
                Close
              </Button>
            </p>
          </ModalBody>
        </Modal>
      </Col>
      {errorMessage || successMessage ? (
        <Col md="3">
          {errorMessage && (
            <Alert className="my-2 text-center" color="warning">
              <strong>{errorMessage}</strong>
            </Alert>
          )}
          {successMessage && (
            <Alert className="my-2 text-center" color="success">
              <strong>{successMessage}</strong>
            </Alert>
          )}
        </Col>
      ) : null}
    </Row>
  );
};

export default SaveChangesMessageRow;
