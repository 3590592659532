import TableWidget from "../../../components/Widgets/TableWidget";

const EditPayDeductions = ({ adminContext, setSaving }) => {
  return (
    <>
      <TableWidget
        appendRowCallbackEnabled={
          adminContext.pay.employee.deductions.length > 0
        }
        appendRowCallback={() => {
          let empDeduction = adminContext.pay.employee.deductions[0];
          return {
            id: null,
            pay_id: adminContext.pay.id,
            description: empDeduction.description,
            amount: empDeduction.amount,
            classification: empDeduction.classification,
            is_edited: false,
            is_post_tax: true,
            is_protected_earnings: empDeduction.is_protected_earnings,
            is_percentage: empDeduction.is_percentage,
            percentage: empDeduction.percentage,
            based_on: empDeduction.based_on,
          };
        }}
        label="Deductions"
        rows={adminContext.pay.deductions}
        editRowSaveCallback={(newDeduction) => {
          setSaving(true);
          let deductionRows;
          if (newDeduction.id === null) {
            deductionRows = adminContext.pay.deductions.concat([newDeduction]);
          } else {
            deductionRows = adminContext.pay.deductions.map((deduction) => {
              if (deduction.id === newDeduction.id) {
                return newDeduction;
              }
              return deduction;
            });
          }
          adminContext.setPay({
            ...adminContext.pay,
            deductions: deductionRows,
          });
        }}
        columns={[
          {
            label: "Deduction",
            accessor: "description",
          },
          {
            label: "Classification/Type",
            accessor: "readable_classification",
          },
          {
            label: "Amount",
            accessor: "amount",
            widget: "MoneyLabel",
          },
        ]}
        deleteConfirmationAttributes={["description", "amount"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            deductions: adminContext.pay.deductions.filter((deduction) => {
              return deduction.id !== idToDelete;
            }),
          });
        }}
        formSpec={{
          title: "Deduction",
          fields: [
            {
              label: "Deduction",
              accessor: "description",
              widget: "ComboBox",
              args: {
                visibleCallback: (row) => {
                  // Only show this combo if creating a new deduction, I.e. a non-numeric ID
                  return row.id === null;
                },
                updatedCallback: (row, setRow, value) => {
                  // Refresh all the attribute values based on the selected deduction
                  let empDeduction = adminContext.pay.employee.deductions.find(
                    (deduction) => deduction.description === value
                  );
                  if (empDeduction) {
                    setRow({
                      ...row,
                      amount: empDeduction.amount,
                      is_included_in_super_calculations:
                        empDeduction.is_included_in_super_calculations,
                      classification: empDeduction.classification,
                      description: empDeduction.description,
                      is_percentage: empDeduction.is_percentage,
                      percentage: empDeduction.percentage,
                      based_on: empDeduction.based_on,
                    });
                  }
                },
                comboDataCallback: () => {
                  let deductionComboData = {};
                  adminContext.pay.employee.deductions.forEach((deduction) => {
                    deductionComboData[deduction.description] =
                      deduction.description;
                  });
                  return deductionComboData;
                },
              },
            },
            {
              label: "Description",
              accessor: "description",
              widget: "ReadOnlyText",
              args: {
                visibleCallback: (row) => {
                  // Only show if editing an existing deduction, I.e. a numeric ID
                  return row.id !== null;
                },
              },
            },
            {
              label: "Classification",
              accessor: "classification",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => {
                  return {
                    NORMAL: "Normal",
                    "UNION FEES": "Union/Association Fees",
                    "CHARITY DONATION": "Charity/Workplace Giving",
                    "CHILD SUPPORT GARNISHEE": "Child Support Garnishee",
                    "CHILD SUPPORT DEDUCTION": "Child Support Deduction",
                  };
                },
              },
            },
            {
              label: "Amount",
              accessor: "amount",
              widget: "MoneyEdit",
              args: {
                visibleCallback: (row) => {
                  return !row.is_percentage;
                },
              },
            },
            {
              label: "Percentage",
              accessor: "percentage",
              widget: "PercentageEdit",
              args: {
                visibleCallback: (row) => {
                  return row.is_percentage;
                },
              },
            },
            {
              label: "Based On",
              accessor: "based_on",
              widget: "ComboBox",
              args: {
                visibleCallback: (row) => {
                  return row.is_percentage;
                },
                comboDataCallback: () => {
                  return {
                    GROSS: "Gross",
                    NET: "Net",
                  };
                },
              },
            },
            {
              label: "Is Percentage?",
              accessor: "is_percentage",
              widget: "CheckBox",
            },
          ],
        }}
      />
    </>
  );
};
export default EditPayDeductions;
