import TableWidget from "../../../components/Widgets/TableWidget";

const EditPayAllowances = ({ adminContext, setSaving }) => {
  return (
    <>
      <TableWidget
        label="Allowances"
        rows={adminContext.pay.allowances}
        editRowSaveCallback={(inputAllowance) => {
          setSaving(true);
          let allowanceRows;
          if (inputAllowance.id === null) {
            // New allowance
            allowanceRows = adminContext.pay.allowances.concat(inputAllowance);
          } else {
            // Existing allowance
            allowanceRows = adminContext.pay.allowances.map((allowance) => {
              if (allowance.id === inputAllowance.id) {
                return inputAllowance;
              }
              return allowance;
            });
          }
          adminContext.setPay({
            ...adminContext.pay,
            allowances: allowanceRows,
          });
        }}
        columns={[
          {
            label: "Allowance",
            accessor: "description",
            widget: "Text",
          },
          {
            label: "Amount p/unit",
            accessor: "amount",
            widget: "MoneyLabel",
          },
          {
            label: "Units",
            accessor: "units",
          },

          {
            label: "Total",
            accessor: "total_amount",
            widget: "MoneyLabel",
          },
        ]}
        deleteConfirmationAttributes={["description", "total_amount"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            allowances: adminContext.pay.allowances.filter((allowance) => {
              return allowance.id !== idToDelete;
            }),
          });
        }}
        appendRowCallbackEnabled={
          adminContext.pay.employee.company_allowances.concat(
            adminContext.pay.employee.allowances
          ).length > 0
        }
        appendRowCallback={() => {
          let empAllowance =
            adminContext.pay.employee.company_allowances.concat(
              adminContext.pay.employee.allowances
            )[0];
          return {
            id: null,
            pay_id: adminContext.pay.id,
            description: empAllowance.description,
            amount: empAllowance.amount,
            units: 1,
            is_edited: false,
            is_taxable: empAllowance.is_taxable,
            is_itemised: empAllowance.is_itemised,
            is_included_in_super_calculations:
              empAllowance.is_included_in_super_calculations,
            is_company_allowance:
              adminContext.pay.employee.company_allowances.find(
                (allowance) =>
                  allowance.description === empAllowance.description
              ) !== undefined,
            allowance_category: "Unclassified",
          };
        }}
        formSpec={{
          title: "Allowance",
          fields: [
            {
              label: "Allowance",
              accessor: "description",
              widget: "ComboBox",
              args: {
                visibleCallback: (row) => {
                  // Only show this combo if creating a new allowance, I.e. a non-numeric ID
                  return row.id === null;
                },
                updatedCallback: (row, setRow, value) => {
                  // Refresh all the attribute values based on the selected allowance
                  let empAllowance =
                    adminContext.pay.employee.company_allowances
                      .concat(adminContext.pay.employee.allowances)
                      .find((allowance) => allowance.description === value);
                  if (empAllowance) {
                    setRow({
                      ...row,
                      amount: empAllowance.amount,
                      is_taxable: empAllowance.is_taxable,
                      is_itemised: empAllowance.is_itemised,
                      is_included_in_super_calculations:
                        empAllowance.is_included_in_super_calculations,
                      allowance_category: empAllowance.allowance_category,
                      description: empAllowance.description,
                      is_company_allowance:
                        adminContext.pay.employee.company_allowances.find(
                          (allowance) => allowance.description === value
                        ) !== undefined,
                    });
                  }
                },
                comboDataCallback: () => {
                  let allowanceComboData = {};
                  adminContext.pay.employee.company_allowances
                    .concat(adminContext.pay.employee.allowances)
                    .forEach((allowance) => {
                      allowanceComboData[allowance.description] =
                        allowance.description;
                    });
                  return allowanceComboData;
                },
              },
            },
            {
              label: "Description",
              accessor: "description",
              widget: "ReadOnlyText",
              args: {
                visibleCallback: (row) => {
                  // Only show if editing an existing allowance, I.e. a numeric ID
                  return row.id !== null;
                },
              },
            },
            {
              label: "STP Category/Classification",
              accessor: "allowance_category",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => {
                  return adminContext.constants.STPAllowanceCategories;
                },
                validateCallback: (allowance) => {
                  if (allowance.allowance_category === "Unclassified") {
                    return "Please select a category for this allowance.";
                  }
                  return "";
                },
              },
            },
            {
              label: "Amount p/unit",
              accessor: "amount",
              widget: "MoneyEdit",
            },
            {
              label: "Units/Multiples",
              accessor: "units",
              widget: "MultiplierEdit",
              args: {
                strictLabel: true,
              },
            },
            {
              label: "Total Amount",
              accessor: "total_amount",
              widget: "MoneyLabel",
              valueCallback: (row) => {
                return Number(row.amount._decimal) * Number(row.units);
              },
            },
            {
              label: "Taxable?",
              accessor: "is_taxable",
              widget: "CheckBox",
            },
            {
              label: "Itemised?",
              accessor: "is_itemised",
              widget: "CheckBox",
            },
            {
              label: "Included In Super?",
              accessor: "is_included_in_super_calculations",
              widget: "CheckBox",
            },
          ],
        }}
      />
    </>
  );
};
export default EditPayAllowances;
