import moment from "moment";
import { useEffect, useState } from "react";

const DateTimeLabel = ({ value }) => {
  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    setFormattedDate(moment(value).format("DD/MM/YYYY hh:mm A"));
  }, [value]);

  return <>{formattedDate}</>;
};
export default DateTimeLabel;
