import { Container, Row, Col, Button, Alert, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import moment from "moment";
import AdminContext from "../../AdminContext";

const ShiftTabView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const nonDeletedEmployeesFilter = (employee) => {
    if (employee.is_deleted) {
      return false;
    }

    let isDeleted = false;
    if (employee.employee_shifts !== undefined) {
      for (let empShift of employee.employee_shifts) {
        if (
          empShift.company_shift === adminContext.company.currentShift &&
          empShift.is_deleted
        ) {
          isDeleted = true;
          break;
        }
      }
    }

    return !isDeleted;
  };

  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/shifts`,
      (response) => {
        if (response.data.shifts) {
          console.log(response.data.shifts);
          adminContext.setCompany({
            ...adminContext.company,
            shifts: response.data.shifts,
          });
        }
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    setSelectedRows({
      employees: [],
    });
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}?route=shifts`,
      adminContext.company,
      (response) => {
        setIsLoading(false);
        setSaving(false);
        initView();
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.shifts]);

  const durationCallback = (shift) => {
    return shift.end_time - shift.start_time - shift.break_duration;
  };

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Shifts</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      Below are where base shifts can be defined. The times and
                      pay items assigned here can be overridden at an employee
                      level under{" "}
                      <strong>{"Employees >> Details >> Shifts"}</strong>, or at
                      an individual shift level in the <strong>Rosters</strong>{" "}
                      section. They are just set here as a default/fallback
                      value. The main purpose of this area is to assign
                      employees to a shared shift type.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-1 my-3">
                    {!saving ? (
                      <Row>
                        <Col>
                          <TableWidget
                            deleteRowCallbackEnabled={(shift) =>
                              shift.delete_enabled
                            }
                            rows={adminContext.company.shifts}
                            columns={[
                              {
                                label: "Description",
                                accessor: "description",
                                widget: "text",
                              },
                              {
                                label: "Start Time",
                                accessor: "start_time",
                                widget: "TimeLabel",
                              },
                              {
                                label: "End Time",
                                accessor: "end_time",
                                widget: "TimeLabel",
                              },
                              {
                                label: "Break Duration",
                                accessor: "break_duration",
                                widget: "DurationLabel",
                              },
                              {
                                label: "Minimum Shift Duration For Break",
                                accessor: "minimum_shift_duration_for_break",
                                widget: "DurationLabel",
                              },
                              {
                                label: "Employees",
                                accessor: "number_of_employees",
                                widget: "number",
                              },
                            ]}
                            selected={adminContext.company.currentShift}
                            setSelected={(shift) => {
                              setSelectedRows({
                                employees: shift.employees,
                              });
                              adminContext.setCompany({
                                ...adminContext.company,
                                currentShift: shift,
                              });
                            }}
                            appendRowCallback={() => {
                              return {
                                id: null,
                                description: "",
                                company_id: adminContext.company.id,
                                start_time: moment()
                                  .hour(9)
                                  .minute(0)
                                  .second(0),
                                end_time: moment().hour(17).minute(0).second(0),
                                break_duration: 24 * 60000,
                                minimum_shift_duration_for_break: 0,
                                employees: [],
                              };
                            }}
                            editRowSaveCallback={(inputShift) => {
                              setSaving(true);
                              let shift = {
                                ...inputShift,
                                employees: selectedRows.employees
                                  ? selectedRows.employees
                                  : [],
                              };
                              let newShifts = [];
                              if (shift.id === null) {
                                newShifts = [
                                  ...adminContext.company.shifts,
                                  shift,
                                ];
                              } else {
                                newShifts = adminContext.company.shifts.map(
                                  (d) => {
                                    if (Number(d.id) === Number(shift.id)) {
                                      return shift;
                                    } else {
                                      return d;
                                    }
                                  }
                                );
                              }
                              adminContext.setCompany({
                                ...adminContext.company,
                                shifts: newShifts,
                              });
                            }}
                            deleteRowCallback={(idToDelete) => {
                              adminContext.setCompany({
                                ...adminContext.company,
                                shifts: adminContext.company.shifts.filter(
                                  (shift) => shift.id !== idToDelete
                                ),
                                currentShift: false,
                              });
                              setSaving(true);
                            }}
                            deleteConfirmationAttributes={[
                              "description",
                              "start_time",
                              "end_time",
                              "break_duration",
                              "minimum_shift_duration_for_break",
                            ]}
                            formSpec={{
                              title: "Shift",
                              fields: [
                                {
                                  label: "Description",
                                  accessor: "description",
                                  widget: "Text",
                                  validate: {
                                    required: {
                                      value: true,
                                      errorMessage: "Description is required",
                                    },
                                  },
                                },
                                {
                                  label: "Start Time",
                                  accessor: "start_time",
                                  widget: "TimeEdit",
                                  required: true,
                                  args: {
                                    validateCallback: (shift) => {
                                      if (shift.start_time === null) {
                                        return "Start time is required";
                                      }
                                      return "";
                                    },
                                  },
                                },
                                {
                                  label: "End Time",
                                  accessor: "end_time",
                                  widget: "TimeEdit",
                                  required: true,
                                  args: {
                                    validateCallback: (shift) => {
                                      if (shift.end_time === null) {
                                        return "End time is required";
                                      }
                                      return "";
                                    },
                                  },
                                },
                                {
                                  label: "Break Duration",
                                  accessor: "break_duration",
                                  widget: "DurationEdit",
                                  required: true,
                                },
                                {
                                  label: "Minimum Shift Duration For Break",
                                  accessor: "minimum_shift_duration_for_break",
                                  widget: "DurationEdit",
                                },
                                {
                                  label: "Default Pay Rate (Optional)",
                                  accessor: "default_rate_type",
                                  widget: "ComboBox",
                                  args: {
                                    comboDataCallback: () => {
                                      return {
                                        null: "",
                                        "Ordinary Time": "Ordinary Time",
                                        "Time and a half": "Time and a half",
                                        "Double time": "Double time",
                                      };
                                    },
                                  },
                                },
                                {
                                  label: "Default Allowance (Optional)",
                                  accessor: "company_allowance_id",
                                  widget: "ComboBox",
                                  args: {
                                    comboDataCallback: () => {
                                      let combo = { null: "" };
                                      adminContext.company.allowances.forEach(
                                        (allowance) => {
                                          combo[allowance.id] =
                                            allowance.description;
                                        }
                                      );
                                      return combo;
                                    },
                                    idAlias: "allowanceName",
                                    idAliasCallback: (id) => {
                                      let allowanceName = "";
                                      adminContext.company.allowances.forEach(
                                        (allowance) => {
                                          if (
                                            Number(allowance.id) === Number(id)
                                          ) {
                                            allowanceName = allowance.name;
                                          }
                                        }
                                      );
                                      return allowanceName;
                                    },
                                  },
                                },
                                {
                                  label: "Duration",
                                  accessor: "duration",
                                  widget: "TimeLabel",
                                  valueCallback: durationCallback,
                                },
                                {
                                  label: "Employees",
                                  accessor: "employees",
                                  widget: "EmployeeListWidget",
                                  args: {
                                    filterCallback: nonDeletedEmployeesFilter,
                                    rows: rows,
                                    setRows: setRows,
                                    selectedRows: selectedRows,
                                    setSelectedRows: setSelectedRows,
                                  },
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default ShiftTabView;
