import React from "react";
import { Button, Modal } from "reactstrap";

const QuestionModal = ({
  isOpen,
  title,
  content,
  innerHTML,
  onConfirm,
  onDeny,
  confirmText = "Yes",
  denyText = "No, cancel",
}) => {
  return (
    <Modal
      className="width-40-on-lg d-flex align-items-center"
      isOpen={isOpen}
      centered={true}
    >
      <div className="mx-4 my-4">
        <h2 className="text-center days-one">{title}</h2>
        {innerHTML ? (
          <div
            dangerouslySetInnerHTML={{
              __html: innerHTML,
            }}
          ></div>
        ) : (
          content
        )}
        <Button
          color="warning"
          className="float-right mb-2 mx-2"
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
        <Button
          color="secondary"
          className="float-right mb-2 mx-2"
          onClick={onDeny}
        >
          {denyText}
        </Button>
      </div>
    </Modal>
  );
};

export default QuestionModal;
