import EmployeesNav from "../EmployeesNav";

const AllowanceDeductionNav = (props) => {
  return (
    <EmployeesNav
      subComponent={props.subComponent}
      routesSubPath="allowance_deduction"
    />
  );
};
export default AllowanceDeductionNav;
