// reactstrap components
import { Button, CardBody, Card, CardHeader, Alert } from "reactstrap";
import React, { useState, useContext } from "react";
import AdminContext from "../../AdminContext";

import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const CreateBackup = () => {
  const adminContext = useContext(AdminContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async (fileName, fileData) => {
    await adminContext.handleFileDownload(fileName, fileData, 'application/octet-stream');
  };

  const createLocalBackup = () => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/tools/backup/local",
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.backup) {
            const downloadLink = document.createElement("a");

            // Create a neat filename for the backup using the current datetime
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");
            const fileName = `Backup_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.lpb`;

            const fileData = "data:text/plain;base64," + response.data.backup;
            handleDownload(fileName, fileData);
          }
          setErrorMessage("");
          setSuccessMessage("Backup created successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to create backup.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  return (
    <>
      {!isLoading ? (
        <>
          {errorMessage && (
            <Alert color="warning">
              <strong>{errorMessage}</strong>
            </Alert>
          )}
          {successMessage && (
            <Alert color="success">
              <strong>{successMessage}</strong>
            </Alert>
          )}

          <Card className="bg-secondary mx-3 my-3 shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center mt-2 mb-3">
                <h1 className="days-one">Create Backup File</h1>
                <p>
                  Download a backup file of the current database. <br />
                  (Includes all employees across all companies/entities)
                </p>
              </div>
            </CardHeader>
            <CardBody className="width-100 text-center">
              <Button
                className="my-4 btn btn-lg width-40-on-lg"
                color="primary"
                onClick={createLocalBackup}
              >
                Create Backup
              </Button>
            </CardBody>
          </Card>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default CreateBackup;
