// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Alert,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
import React, { useState, useContext, useEffect } from "react";
import TableWidget from "components/Widgets/TableWidget";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const DataSharing = () => {
  const adminContext = useContext(AdminContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [accessors, setAccessors] = useState([]);
  const [providers, setProviders] = useState([]);
  const [newAccessorsEmail, setNewAccessorsEmail] = useState("");

  const initView = () => {
    const url = adminContext.constants.BASE_URL + "/settings/data-sharing";
    adminContext.getRequest(url, (response) => {
      if (response.data.accessors) {
        setAccessors(response.data.accessors);
      }
      if (response.data.providers) {
        setProviders(response.data.providers);
      }
      setLoading(false);
    });
  };

  useEffect(initView, []);

  const emailAlreadyExists = () => {
    // Return True if the email already exists in the list of accessors
    return accessors.some((accessor) => accessor.email === newAccessorsEmail);
  };

  const submitEmail = (e) => {
    e.preventDefault();
    const url = adminContext.constants.BASE_URL + "/settings/data-sharing";
    adminContext.postRequest(url, { email: newAccessorsEmail }, (response) => {
      if (response.data.success) {
        setSuccessMessage(response.data.success);
      } else {
        setErrorMessage(response.data.error);
      }

      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
      initView();
    });
  };

  const downloadBackup = (customer_id) => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/tools/backup/local?customer_id=" +
        customer_id,
      (response) => {
        setLoading(false);
        if (response.data.backup) {
          const downloadLink = document.createElement("a");

          // Create a neat filename for the backup using the current datetime
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const seconds = String(date.getSeconds()).padStart(2, "0");
          const fileName = `Backup_customer_${customer_id}_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.lpb`;

          downloadLink.href = "data:text/plain;base64," + response.data.backup;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  };

  const accessorColumns = [
    {
      label: "Customer ID",
      accessor: "customer_id",
      widget: "text",
      useAsKey: true,
    },
    {
      label: "Company Name",
      accessor: "company_name",
      widget: "text",
    },
    {
      label: "Email",
      accessor: "email",
      widget: "text",
    },
  ];

  const providerColumns = [
    ...accessorColumns,
    {
      label: "Download Backup File",
      accessor: "download_backup",
      iconOnly: true,
      widget: "Button",
      args: {
        buttonIcon: "fa-regular fa-cloud-download fa-xl",
        onClickCallback: (cust) => downloadBackup(cust.customer_id),
      },
    },
  ];

  const deleteProvider = (customer_id) => {
    setLoading(true);
    const url =
      adminContext.constants.BASE_URL +
      "/settings/data-sharing/provider/" +
      customer_id;
    adminContext.deleteRequest(url, (response) => {
      if (response.data.success) {
        setSuccessMessage(response.data.success);
      } else {
        setErrorMessage(response.data.error);
      }

      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
      initView();
    });
  };

  const deleteAccessor = (customer_id) => {
    setLoading(true);
    const url =
      adminContext.constants.BASE_URL +
      "/settings/data-sharing/accessor/" +
      customer_id;
    adminContext.deleteRequest(url, (response) => {
      if (response.data.success) {
        setSuccessMessage(response.data.success);
      } else {
        setErrorMessage(response.data.error);
      }

      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
      initView();
    });
  };

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      {!loading ? (
        <>
          <Container className="mx-2 px-2" fluid>
            <Row>
              <Col md="12">
                <Card className="bg-secondary my-3 shadow border-0 w-100">
                  <CardHeader className="bg-transparent pb-5">
                    <div className="text-center mt-2 mb-3">
                      <h1 className="days-one">Data Sharing</h1>

                      <p className="text-left">
                        Here is where you can share your payroll data with other
                        Lightning Payroll customer accounts. Enter their email
                        and submit to add a user who will then be able to
                        download and restore a backup of your payroll data.{" "}
                        <b>
                          Please be very careful who you share your data with
                        </b>
                        .
                      </p>
                      <Form
                        onSubmit={submitEmail}
                        className="d-flex align-items-center ml-lg-auto mx-auto"
                      >
                        <FormGroup className="mx-auto width-40-on-lg  my-auto">
                          <InputGroup className="input-group-alternative">
                            <Input
                              value={newAccessorsEmail}
                              onChange={(e) => {
                                setNewAccessorsEmail(e.target.value);
                              }}
                              placeholder="Enter other user's email to share"
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                        <Button
                          className="btn btn-default semi-active-button width-40-on-lg"
                          disabled={
                            newAccessorsEmail === "" || emailAlreadyExists()
                          }
                        >
                          Submit
                        </Button>
                      </Form>
                    </div>
                    {errorMessage && (
                      <Alert color="warning" className="text-center">
                        <strong>{errorMessage}</strong>
                      </Alert>
                    )}
                    {successMessage && (
                      <Alert color="success" className="text-center">
                        <strong>{successMessage}</strong>
                      </Alert>
                    )}
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableWidget
                  label="Who can access your payroll data?"
                  rows={accessors}
                  columns={accessorColumns}
                  deleteRowCallback={deleteAccessor}
                  deleteConfirmationAttributes={[
                    "customer_id",
                    "company_name",
                    "email",
                  ]}
                />
              </Col>

              <Col>
                {" "}
                <TableWidget
                  label="Whose payroll data can be accessed by you?"
                  subtitle="Backups can be restored under Tools >> Restore Backup."
                  rows={providers}
                  columns={providerColumns}
                  deleteRowCallback={deleteProvider}
                  deleteConfirmationAttributes={[
                    "customer_id",
                    "company_name",
                    "email",
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </fieldset>
  );
};

export default DataSharing;
