import React, { useState, useEffect, useContext } from "react";
import { FormLabel } from "@mui/material";
import ComboBox from "./ComboBox";
import DurationLabel from "./DurationLabel";
import AdminContext from "../../AdminContext";

function LeaveTakenTypeComboBox(props) {
  const adminContext = useContext(AdminContext);

  const [currentBalance, setCurrentBalance] = useState(null);
  const [initialBalance, setInitialBalance] = useState(null);

  const isSystemBalancedLeave = () => {
    return ["holiday", "sick", "long service"].includes(
      props.selectedComboItem.toLowerCase()
    );
  };

  const isBalancedLeaveType = () => {
    // Check if any leave balance row matches the conditions
    const hasCustomLeave = adminContext.pay.leave_balance_rows.some((row) => {
      return (
        row.name === props.selectedComboItem &&
        row.leave_type.toLowerCase() === "custom"
      );
    });

    if (hasCustomLeave) {
      return true;
    }

    return isSystemBalancedLeave();
  };

  const getBalance = () => {
    if (isBalancedLeaveType()) {
      const balanceRow = adminContext.pay.leave_balance_rows.find((row) => {
        console.log(row);
        return (
          row.leave_type.toLowerCase() ===
            props.selectedComboItem.toLowerCase() ||
          (row.leave_type.toLowerCase() === "custom" &&
            row.name === props.selectedComboItem)
        );
      });
      return balanceRow ? balanceRow.amount : null;
    }
    console.log("Not a balanced leave type");
    return null;
  };

  const leaveDateIsOnOrBeforePayDate = () => {
    const leaveTakenDate = new Date(props.parentObj.date);
    const payDate = new Date(adminContext.pay.date);

    return leaveTakenDate <= payDate;
  };

  useEffect(() => {
    const initial = getBalance();
    if (!initial) {
      return;
    }
    // We do not want to alter the actual balance row object, so we create a new object
    const newInitial = { milliseconds: initial.milliseconds };

    if (
      initialBalance === null &&
      props.parentObj.id !== null &&
      leaveDateIsOnOrBeforePayDate()
    ) {
      newInitial.milliseconds += props.parentObj.time_taken.milliseconds;
    }
    setInitialBalance(newInitial);
    setCurrentBalance(newInitial);
  }, [props.selectedComboItem]);

  useEffect(() => {
    if (!isBalancedLeaveType() || initialBalance === null) {
      return;
    }

    if (leaveDateIsOnOrBeforePayDate()) {
      const currentMilliseconds = props.parentObj.time_taken.milliseconds;
      const balanceAdjustment =
        initialBalance.milliseconds - currentMilliseconds;
      setCurrentBalance((previous) => ({ milliseconds: balanceAdjustment }));
    } else {
      // Reset balance to initial balance if the leave date is after the pay date
      setCurrentBalance(initialBalance);
    }
  }, [
    props.parentObj.time_taken,
    props.parentObj.date,
    adminContext.pay.date,
    initialBalance,
  ]);

  if (props.selectedComboItem === "CUSTOM") {
    props.setSelectedComboItem(props.parentObj.name);
  }

  return (
    <>
      <ComboBox {...props} />
      {isBalancedLeaveType() && currentBalance !== null && (
        <>
          <FormLabel htmlFor="leaveBalance">
            Current{" "}
            {isSystemBalancedLeave()
              ? adminContext.toTitleCase(props.selectedComboItem)
              : props.selectedComboItem}{" "}
            Leave Balance
          </FormLabel>
          <br />
          <div key={currentBalance}>
            <DurationLabel
              className={"display-4 font-weight-bold"}
              value={currentBalance}
            />
          </div>
          <p className="text-small text-muted">
            <em>
              Note: Time taken will only affect this current leave balance if
              the leave taken date is on or before this pay's end date (
              {new Date(adminContext.pay.date).toLocaleDateString()}).
            </em>
          </p>
        </>
      )}
    </>
  );
}

export default LeaveTakenTypeComboBox;
