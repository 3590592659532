import TableWidget from "../../../components/Widgets/TableWidget";
import { BackPaymentFormSpec } from "../../../variables/ValidationFormSpecs";
import moment from "moment";

const EditPayBackPayments = ({
  adminContext,
  setSaving,
}) => {
  return (
    <>
      <TableWidget
        label="Back Payments"
        rows={adminContext.pay.back_payments}
        editRowSaveCallback={(newBackPayment) => {
          setSaving(true);
          let backPayments;
          if (newBackPayment.id === null) {
            backPayments = adminContext.pay.back_payments.concat([
              newBackPayment,
            ]);
          } else {
            backPayments = adminContext.pay.back_payments.map((backPayment) => {
              if (backPayment.id === newBackPayment.id) {
                return newBackPayment;
              }
              return backPayment;
            });
          }
          adminContext.setPay({
            ...adminContext.pay,
            back_payments: backPayments,
          });
        }}
        columns={[
          {
            label: "Description",
            accessor: "description",
          },
          {
            label: "Amount",
            accessor: "amount",
            widget: "MoneyLabel",
          },
          {
            label: "STP Category",
            accessor: "stp_category",
            widget: "Text",
          },
          {
            label: "Included In Super?",
            accessor: "is_included_in_super_calculations",
            widget: "BooleanLabel",
          },

          {
            label: "Tax Method",
            accessor: "tax_method_display",
          },
        ]}
        appendRowCallback={() => {
          let endDate = moment(adminContext.payRun.end_date).format(
            "YYYY-MM-DD"
          );
          let payPeriod = adminContext.payRun.pay_period;
          let startDate;
          if (payPeriod === "WEEKLY") {
            // deduct 7 days from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(7, "days")
              .format("YYYY-MM-DD");
          } else if (payPeriod === "FORTNIGHTLY") {
            // deduct 14 days from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(14, "days")
              .format("YYYY-MM-DD");
          } else if (payPeriod === "MONTHLY") {
            // deduct 1 month from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(1, "months")
              .format("YYYY-MM-DD");
          }
          return {
            id: null,
            pay_id: adminContext.pay.id,
            description: "Back Payment",
            stp_category: "Gross",
            is_included_in_super_calculations: true,
            amount: { _decimal: 0 },
            start_date: startDate,
            end_date: endDate,
            tax_method: "METHOD_B2",
          };
        }}
        deleteConfirmationAttributes={["description", "amount"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            back_payments: adminContext.pay.back_payments.filter(
              (backPayment) => {
                return backPayment.id !== idToDelete;
              }
            ),
          });
        }}
        formSpec={BackPaymentFormSpec()}
      />
    </>
  );
};
export default EditPayBackPayments;
