import React, { useState, useEffect, useContext } from "react";
import EditForm from "../../../../components/Widgets/EditForm";
import AdminContext from "../../../../AdminContext";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";

const EtpPayoutOptionsView = ({
  pay,
  payTermination,
  setPayTermination,
  refreshPayTermination,
}) => {
  const adminContext = useContext(AdminContext);
  const [etpAmount, setEtpAmount] = useState(payTermination.etpAmount);
  const [loading, setLoading] = useState(false);

  const [isManualLeaveAmounts, setIsManualLeaveAmounts] = useState(
    payTermination.isManualLeaveAmounts
  );

  useEffect(() => {
    refreshPayTermination(setLoading);
  }, []);

  useEffect(() => {
    payTermination.etpAmount = etpAmount;
    payTermination.isManualLeaveAmounts = isManualLeaveAmounts;
  }, [etpAmount, isManualLeaveAmounts]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <EditForm
          liveEdit={true}
          fixedTitle={true}
          formSpec={{
            title: "ETP Payout Options",
            shortDescription: (
              <>
                Payments that are included in an Employee Termination Payment
                for tax purposes include gratuities or golden handshakes,
                compensation for loss of job, compensation for wrongful
                dismissal, payments for loss of future super, and lump sum
                payments paid upon the death of an employee.
                <br />
                <br />
                If a notice period is applicable, the end of the notice period
                must be a whole number of weeks after the termination date. For
                example, if the termination date is 1 March 2014, a valid end of
                notice date could be 8 March 2014 or 15 March 2014.
                <br />
                <br />
                <span className="font-heavy">
                  If the Payment in Lieu of notice falls under a redundancy
                  tax-free component make sure to exclude it here and include it
                  in the redundancy payout amount.
                </span>
              </>
            ),
            fields: [
              {
                label: "Pay Out Wages in Lieu of Notice?",
                widget: "CheckBox",
                accessor: "is_payment_in_lieu_paid",
              },
              {
                label: "",
                widget: "GroupBox",
                accessor: "payment_in_lieu_type",
                args: {
                  visibleCallback: (modelObj) =>
                    modelObj.is_payment_in_lieu_paid,
                  checkable: true,
                  children: [
                    {
                      label: "Number of Hours Notice",
                      accessor: "NOTICE_HOURS",
                    },
                    {
                      label: "Notice Lump Sum Payment",
                      accessor: "NOTICE_LUMP_SUM",
                    },
                  ],
                },
              },
              {
                label: "Wages In Lieu (Number of hours notice)",
                widget: "DurationEdit",
                accessor: "notice_hours",
                args: {
                  strictLabel: true,
                  visibleCallback: (modelObj) =>
                    modelObj.is_payment_in_lieu_paid &&
                    modelObj.payment_in_lieu_type === "NOTICE_HOURS",
                },
              },
              {
                label: "Wages In Lieu Lump Sum Amount",
                widget: "MoneyEdit",
                accessor: "notice_lump_sum",
                args: {
                  visibleCallback: (modelObj) =>
                    modelObj.is_payment_in_lieu_paid &&
                    modelObj.payment_in_lieu_type === "NOTICE_LUMP_SUM",
                },
              },
              {
                label: "Pay Unused Sick Leave Label",
                widget: "Label",
                accessor: "pay_unused_sick_leave_label",
                args: {
                  wordWrap: true,
                },
              },
              {
                label: "Pay Unused Sick Leave?",
                widget: "CheckBox",
                accessor: "is_unused_sick_paid",
              },
              {
                label: "Other Pay Out Amount",
                widget: "MoneyEdit",
                accessor: "etp_amount",
              },
              {
                label: "Show Advanced Settings?",
                widget: "CheckBox",
                accessor: "is_manual_leave_amounts",
              },
            ],
          }}
          setModelObj={setPayTermination}
          modelObj={payTermination}
        />
      )}
    </div>
  );
};

export default EtpPayoutOptionsView;
