import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TableWidget from "../../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import DateEdit from "../../../components/Widgets/DateEdit";
import ComboBox from "../../../components/Widgets/ComboBox";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import BackButton from "../../../components/Widgets/BackButton";
import AdminContext from "../../../AdminContext";
import dayjs from "dayjs";

const SingleTouchMailboxView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [stpEventRows, setStpEventRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("All");
  const [selectedMessageStatus, setSelectedMessageStatus] = useState("All");
  const [availableEmployees, setAvailableEmployees] = useState({ All: "All" });
  const [availableStatuses, setAvailableStatuses] = useState({ All: "All" });
  const [initialising, setInitialising] = useState(false);

  const fetchSTPMailboxData = (filters = {}) => {
    setIsLoading(true);

    let queryParams = new URLSearchParams();
    queryParams.append("company_id", adminContext.company.id);
    if (filters.startDate) queryParams.append("start_date", filters.startDate);
    if (filters.endDate) queryParams.append("end_date", filters.endDate);
    if (filters.employeeId)
      queryParams.append("employee_id", filters.employeeId);
    if (filters.messageStatus)
      queryParams.append("status", filters.messageStatus);

    adminContext.getRequest(
      `${
        adminContext.constants.BASE_URL
      }/pays/single-touch/mailbox/init?${queryParams.toString()}`,
      (response) => {
        setStpEventRows(response.data.stp_event_rows);
        if (!filters.startDate) setStartDate(response.data.start_date);
        if (!filters.endDate) setEndDate(response.data.end_date);
        setAvailableEmployees(response.data.available_employees);
        setAvailableStatuses(response.data.available_statuses);
        setIsLoading(false);
        setInitialising(false);
      }
    );
  };

  useEffect(() => {
    setInitialising(true);
    fetchSTPMailboxData();
  }, []);

  const filterSTPMailbox = () => {
    fetchSTPMailboxData({
      startDate: startDate,
      endDate: endDate,
      employeeId: selectedEmployeeId,
      messageStatus: selectedMessageStatus,
    });
  };

  useEffect(() => {
    if (initialising) return;
    if (startDate && endDate && selectedEmployeeId && selectedMessageStatus) {
      filterSTPMailbox();
    }
  }, [startDate, endDate, selectedEmployeeId, selectedMessageStatus]);

  const handleDateChange = (dateTime, mode) => {
    const parsedDate = dayjs(dateTime);
  
    if (!parsedDate.isValid()) {
      return;
    }
  
    // Convert to ISO string and extract the "yyyy-mm-dd" part
    const formattedDate = parsedDate.toISOString().substring(0, 10);
  
    if (mode === "start") {
      setStartDate(formattedDate);
    } else {
      setEndDate(formattedDate);
    }
  };  

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <BackButton />
              <Row>
                <Col>
                  <h1 className="days-one">Single Touch Payroll Mailbox</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Use these filter boxes to search through your ATO Single
                    Touch Payroll submission history. Date fields are searching
                    by message sent date.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <DateEdit
                    label="Start Date"
                    value={startDate}
                    onChange={(dateTime) => handleDateChange(dateTime, "start")}
                  />
                </Col>
                <Col md="3">
                  <DateEdit
                    label="End Date"
                    value={endDate}
                    onChange={(dateTime) => handleDateChange(dateTime, "end")}
                  />
                </Col>
                <Col md="3">
                  <ComboBox
                    className="mx-3"
                    label="Filter By Employee"
                    stretch={true}
                    name="employee_id"
                    comboData={availableEmployees}
                    selectedComboItem={selectedEmployeeId}
                    setSelectedComboItem={(data) => {
                      setSelectedEmployeeId(
                        adminContext.getKeyByValue(availableEmployees, data)
                      );
                    }}
                  />
                </Col>
                <Col md="3">
                  <ComboBox
                    className="mx-3"
                    label="Filter By Status"
                    stretch={true}
                    name="status"
                    comboData={availableStatuses}
                    selectedComboItem={selectedMessageStatus}
                    setSelectedComboItem={setSelectedMessageStatus}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mr-3 ml-1 my-3">
                  {!saving ? (
                    <Row>
                      <Col>
                        <TableWidget
                          total={false}
                          orderBy="sent"
                          rows={stpEventRows}
                          columns={[
                            {
                              label: "",
                              accessor: "None",
                              widget: "Link",
                              args: {
                                label: "View",
                                href: "/admin/pays/stp_mailbox_event",
                                color: "secondary",
                                className: "btn btn-sm btn-secondary",
                              },
                            },
                            {
                              label: "Status",
                              accessor: "status",
                            },
                            {
                              label: "Final Pay?",
                              accessor: "final_pay",
                              widget: "BooleanLabel",
                            },
                            {
                              label: "FY",
                              accessor: "ato_financial_year",
                            },
                            {
                              label: "Event Type",
                              accessor: "readable_service_action",
                            },
                            {
                              label: "Processed Date",
                              accessor: "pay_date",
                              widget: "DateLabel",
                            },
                            {
                              label: "Pay Run End",
                              accessor: "pay_run_end_date",
                              widget: "DateLabel",
                            },
                            {
                              label: "Pay Run Type",
                              accessor: "pay_run_type",
                              widget: "QLabel",
                            },
                            {
                              label: "Num. Employees",
                              accessor: "employee_count",
                              widget: "QLabel",
                            },
                            {
                              label: "Sent",
                              accessor: "timestamp_local",
                              widget: "DateTimeLabel",
                            },
                            {
                              label: "YTD Timestamp",
                              accessor: "latest_pay_timestamp_local",
                              widget: "DateTimeLabel",
                            },
                            {
                              label: "Last Response Check",
                              accessor: "last_response_check_local",
                              widget: "DateTimeLabel",
                              calculatedValue: true,
                            },
                            {
                              label: "Tax Name",
                              accessor: "tax_name",
                              widget: "QLabel",
                            },
                            {
                              label: "ABN",
                              accessor: "abn",
                              widget: "QLabel",
                            },
                            {
                              label: "Submit As Supplier?",
                              accessor: "submit_as_supplier",
                              widget: "BooleanLabel",
                            },
                            {
                              accessor: "Submit As Intermediary?",
                              widget: "BooleanLabel",
                            },
                            {
                              accessor: "submit_zero_dollars",
                              label: "Submit Zero Dollars?",
                              widget: "BooleanLabel",
                            },
                            {
                              accessor: "document_id",
                              label: "Document ID",
                              widget: "QLabel",
                            },
                            {
                              accessor: "conversation_id",
                              label: "Conversation ID",
                              widget: "QLabel",
                            },
                            {
                              accessor: "credential_id",
                              label: "Credential ID",
                              widget: "QLabel",
                            },
                            {
                              accessor: "is_stp_phase_2",
                              label: "Is STP Phase 2?",
                              widget: "BooleanLabel",
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SingleTouchMailboxView;
