// Import useEffect from React
import React, { useState, useContext, useEffect } from "react";
// Import necessary reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Table,
} from "reactstrap";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import DateTimeLabel from "../../components/Widgets/DateTimeLabel";
import qs from "qs";
import moment from "moment";

const RevertLastRestored = () => {
  const adminContext = useContext(AdminContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Start with loading true
  const [mostRecentRestoreDate, setMostRecentRestoreDate] = useState("");

  const initView = async () => {
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/tools/last-restored/check`,
      (response) => {
        if (response.data.last_restored) {
          setMostRecentRestoreDate(response.data.last_restored);
        }
        setIsLoading(false);
      },
      (error) => {
        setErrorMessage(
          "Failed to check for last restored backup's existence. Please refresh the page and try again."
        );
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const revertBackup = async () => {
    setIsLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/tools/last-restored/revert`,
      (response) => {
        if (response.data.success) {
          adminContext.history.push("/admin/entities/select");
        } else {
          setErrorMessage(
            "Failed to undo the last restore. Please contact support."
          );
        }
        setIsLoading(false);
      },
      (error) => {
        setErrorMessage("Failed to undo the last restore.");
        setIsLoading(false);
      }
    );
  };
  return (
    <Container fluid className="mx-2 my-4 h-100">
      {!isLoading ? (
        <Card>
          <CardBody>
            <Row className="m-3">
              <Col xs={12}>
                <h1>Revert Last Restored Backup</h1>
                {mostRecentRestoreDate ? (
                  <>
                    <p>
                      You are about to revert the program data back to how it
                      looked before you last restored/synced.
                    </p>
                    <p>
                      The last backup prior to restore was made:{" "}
                      {moment(mostRecentRestoreDate).format(
                        "DD/MM/YYYY hh:mm A"
                      )}
                      .
                    </p>
                    <p>
                      This means any changes made to the current database will
                      be lost and overriden by the restored backup.
                    </p>
                    <p>Are you sure you would like to continue?</p>
                    <Button
                      color="primary"
                      className="semi-active-button width-40-on-lg"
                      onClick={revertBackup}
                    >
                      Yes, let's revert
                    </Button>
                  </>
                ) : (
                  <p>
                    There is no backup on file that can be reverted back to.
                  </p>
                )}
              </Col>
            </Row>
            {errorMessage && (
              <Row>
                <Col xs={12}>
                  <Alert color="danger">{errorMessage}</Alert>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default RevertLastRestored;
