const validateABN = (value) => {
  if (value === undefined || !value) {
    return false;
  }
  var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
    abn = value.replace(/[^\d]/, ""),
    result = false;

  // check length is 11 digits
  if (abn.length === 11) {
    // apply ato check method
    var sum = 0,
      weight;

    for (var index = 0; index <= weights.length - 1; index++) {
      let weight = weights[index];
      let digit = abn[index] - (index ? 0 : 1);
      sum += weight * digit;
    }

    result = sum % 89 === 0;
  }

  return result;
};
export default validateABN;
