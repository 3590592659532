import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import Wizard from "../../../components/Widgets/Wizard";
import PayDetailsView from "./PayDetailsView";

const PayDetailsWizard = () => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [wizardInfo, setWizardInfo] = useState({
    pays: adminContext.completedPayRows(),
  });

  const [rows, setRows] = useState({
    pays: adminContext.completedPayRows(),
  });

  const [selectedRows, setSelectedRows] = useState({
    pays: [],
  });

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      pays: rows.pays,
      selected_pays: selectedRows.pays,
    });
  }, [rows, selectedRows]);

  const handleNext = () => {
    if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    } else {
      adminContext.history.goBack();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setNextEnabled(true);
    }
  };

  const saveChanges = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/pay-details-report`,
      wizardInfo,
      (response) => {
        downloadPDF(response.data.pdf_file_data);
        setLoading(false);
      }
    );
  };

  const downloadPDF = async (base64ReportData) => {
    const fileName = `pay_details_${adminContext.payRun.end_date}.pdf`;

    await adminContext.handlePDFDownload(fileName, base64ReportData);
  };

  const wizardSteps = [
    <PayDetailsView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      loading={loading}
      setLoading={setLoading}
      saveChanges={saveChanges}
    />,
  ];

  return (
    <>
      <Wizard
        wizardSteps={wizardSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        buttonsVisible={buttonsVisible}
        cancelCallback={adminContext.history.goBack}
        nextEnabled={nextEnabled}
        step={step}
        setStep={setStep}
        forceUpdateButtons={forceUpdateButtons}
        setForceUpdateButtons={setForceUpdateButtons}
        isBackDisabled={() => {
          return step === 0;
        }}
        getLastStep={() => {
          return wizardSteps.length - 1;
        }}
      />
    </>
  );
};
export default PayDetailsWizard;
