import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import qs from "qs";
import moment from "moment";
import CheckBox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

const ReportableFringeBenefitsEdit = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fringeBenefits, setFringeBenefits] = useState([]);
  const [financialYear, setFinancialYear] = useState({});
  const [fbtStartDate, setFbtStartDate] = useState("");
  const [fbtEndDate, setFbtEndDate] = useState("");

  const empFinancialYearId = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const refreshPage = (data) => {
    if (data.fringe_benefits.length > 0) {
      setFringeBenefits(data.fringe_benefits);
    }
    if (data.financial_year) {
      setFinancialYear({
        ...data.financial_year,
        is_fbt_exempt: data.is_fbt_exempt,
      });
    }
    if (data.fbt_start) {
      setFbtStartDate(data.fbt_start);
    }
    if (data.fbt_end) {
      setFbtEndDate(data.fbt_end);
    }
  };

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/fringe-benefits/${empFinancialYearId}`,
      (response) => {
        refreshPage(response.data);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        adminContext.history.push(
          "/admin/employees/tax/reportable_fringe_benefits"
        );
      }
    );
  };

  const saveReportableFringeBenefit = (newFringeBenefit) => {
    setSaving(true);
    let newFringeBenefits = [];

    if (newFringeBenefit.id === null) {
      newFringeBenefits = [
        ...adminContext.currentEmployee.fringe_benefits,
        newFringeBenefit,
      ];
    } else {
      newFringeBenefits = adminContext.currentEmployee.fringe_benefits.map(
        (fb) => {
          if (fb.id === newFringeBenefit.id) {
            return newFringeBenefit;
          } else {
            return fb;
          }
        }
      );
    }

    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      fringe_benefits: newFringeBenefits,
    });
  };

  const updateFBTTaxExemption = () => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/fbt-tax-exemption/${empFinancialYearId}`,
      financialYear,
      (response) => {
        adminContext.history.push(
          "/admin/employees/tax/reportable_fringe_benefits"
        );
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
      setSaving(false);
    }
  }, [adminContext.currentEmployee.fringe_benefits]);

  useEffect(() => {
    if (saving) {
      updateFBTTaxExemption();
      setSaving(false);
    }
  }, [financialYear]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">
                    Reportable Fringe Benefits{" "}
                    {financialYear
                      ? `for ${financialYear.financial_year} financial year`
                      : ""}
                  </h1>
                  <h2>Fringe Benefits Tax Empemption</h2>

                  <FormControlLabel
                    key={financialYear.is_fbt_exempt}
                    control={
                      <CheckBox
                        checked={financialYear.is_fbt_exempt}
                        className="mx-2"
                        name="is_fbt_exempt"
                        onChange={(e) => {
                          setSaving(true);
                          setFinancialYear({
                            ...financialYear,
                            is_fbt_exempt: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      <>
                        Is {adminContext.currentEmployee.first_name}{" "}
                        {adminContext.currentEmployee.last_name}{" "}
                        <strong>exempt</strong> from paying fringe benefits tax
                        for the <strong>{financialYear.financial_year}</strong>{" "}
                        financial year?
                      </>
                    }
                  />
                  <p>
                    Because the fringe benefits tax year runs from 1 April to 31
                    March, fringe benefits paid during the FBT year are
                    reportable in the financial year ending 30 June. For
                    example, if you add a fringe benefit on 1 May 2014, it will
                    fall into the 2014-2015 financial year.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={adminContext.history.goBack}>
                    <i className="fa fa-arrow-left" /> Back
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div key={adminContext.currentEmployee.id} className="my-4">
                    <TableWidget
                      appendRowCallback={() => {
                        return {
                          name: "",
                          id: null,
                          date: new Date(),
                          amount: { _decimal: 0 },
                        };
                      }}
                      formSpec={{
                        title: "Reportable Fringe Benefit Item",
                        fields: [
                          {
                            label: "Date",
                            accessor: "date",
                            widget: "DateEdit",
                            args: {
                              validateCallback: (row) => {
                                if (!row.date) return "Date is required";
                                // convert row.date object to a yyyy-mm-dd string
                                let dateYMD = row.date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  dateYMD >= fbtStartDate &&
                                  dateYMD <= fbtEndDate
                                ) {
                                  return "";
                                } else {
                                  return `Date must be within the FBT year ${moment(
                                    fbtStartDate
                                  ).format("DD/MM/YYYY")} to ${moment(
                                    fbtEndDate
                                  ).format("DD/MM/YYYY")}`;
                                }
                              },
                            },
                          },
                          {
                            label: "Name",
                            accessor: "name",
                            widget: "Text",
                            required: true,
                          },
                          {
                            label: "Amount",
                            accessor: "amount",
                            widget: "MoneyEdit",
                          },
                        ],
                      }}
                      editRowSaveCallback={saveReportableFringeBenefit}
                      hideEdit={true}
                      deleteConfirmationAttributes={["name", "date", "amount"]}
                      deleteRowCallback={(idToDelete) => {
                        setSaving(true);
                        let newFringeBenefits =
                          adminContext.currentEmployee.fringe_benefits.filter(
                            (fb) => fb.id !== idToDelete
                          );
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          fringe_benefits: newFringeBenefits,
                        });
                      }}
                      rows={fringeBenefits}
                      columns={[
                        {
                          label: "Date",
                          accessor: "date",
                          widget: "DateLabel",
                        },
                        {
                          label: "Name",
                          accessor: "name",
                          widget: "Text",
                        },
                        {
                          label: "Amount",
                          accessor: "amount",
                          align: "right",
                          widget: "MoneyLabel",
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default ReportableFringeBenefitsEdit;
