import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import { FormControlLabel } from "@mui/material";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import CheckBox from "@mui/material/Checkbox";
import ComboBox from "../../../components/Widgets/ComboBox";

const PayslipOptions = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const comboData = {
    payslip_leave_units: {
      HOURS: "Hours",
      DAYS: "Days",
    },
  };

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };
  // Update the employee on data change
  const handleChange = (event) => {
    setSaving(true);
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
      setSaving(false);
    }
  }, [adminContext.currentEmployee]);

  return (
    <Container className="my-3" fluid>
      {!isLoading ? (
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <AvForm>
                <CardBody>
                  <Row>
                    <Col>
                      <h1 className="days-one">Payslip Options</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_holiday_leave_balance
                        }
                        control={
                          <CheckBox
                            name="payslip_show_holiday_leave_balance"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_holiday_leave_balance
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show holiday leave entitlement?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_sick_leave_balance
                        }
                        control={
                          <CheckBox
                            name="payslip_show_sick_leave_balance"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_sick_leave_balance
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show sick/personal leave entitlement?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee.payslip_show_lsl_balance
                        }
                        control={
                          <CheckBox
                            name="payslip_show_lsl_balance"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_lsl_balance
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show long service leave entitlement?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_custom_balances
                        }
                        control={
                          <CheckBox
                            name="payslip_show_custom_balances"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_custom_balances
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show custom leave balances?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_negative_leave_balances
                        }
                        control={
                          <CheckBox
                            name="payslip_show_negative_leave_balances"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_negative_leave_balances
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show negative leave balances?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <ComboBox
                          disabled={adminContext.currentEmployee.locked || !adminContext.editAccess("employees")}
                          className="mx-3"
                          label="Show leave entitlements in: "
                          name="payslip_leave_units"
                          onChange={handleChange}
                          comboData={comboData["payslip_leave_units"]}
                          selectedComboItem={
                            adminContext.currentEmployee.payslip_leave_units
                          }
                          setSelectedComboItem={(data) => {
                            setSaving(true);
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              payslip_leave_units: adminContext.getKeyByValue(
                                comboData["payslip_leave_units"],
                                data
                              ),
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee.payslip_time_non_decimal
                        }
                        control={
                          <CheckBox
                            name="payslip_time_non_decimal"
                            checked={
                              adminContext.currentEmployee
                                .payslip_time_non_decimal
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show time as hours/minutes?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_hours_and_rate
                        }
                        control={
                          <CheckBox
                            name="payslip_show_hours_and_rate"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_hours_and_rate
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show hours and rates of pay?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_allowance_units
                        }
                        control={
                          <CheckBox
                            name="payslip_show_allowance_units"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_allowance_units
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show allowance units?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_base_ordinary_rate
                        }
                        control={
                          <CheckBox
                            name="payslip_show_base_ordinary_rate"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_base_ordinary_rate
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show base ordinary rate?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee.payslip_show_super_ytd
                        }
                        control={
                          <CheckBox
                            name="payslip_show_super_ytd"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_super_ytd
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show super YTD?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={adminContext.currentEmployee.payslip_show_ytd}
                        control={
                          <CheckBox
                            name="payslip_show_ytd"
                            checked={
                              adminContext.currentEmployee.payslip_show_ytd
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show YTD totals?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .payslip_show_zero_dollar_leave
                        }
                        control={
                          <CheckBox
                            name="payslip_show_zero_dollar_leave"
                            checked={
                              adminContext.currentEmployee
                                .payslip_show_zero_dollar_leave
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show zero rate (non-unpaid) leave on payslips?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee
                            .show_roster_summary_on_payslip
                        }
                        control={
                          <CheckBox
                            name="show_roster_summary_on_payslip"
                            checked={
                              adminContext.currentEmployee
                                .show_roster_summary_on_payslip
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Show roster shift summary if applicable?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControlLabel
                        key={
                          adminContext.currentEmployee.show_position_on_payslip
                        }
                        control={
                          <CheckBox
                            name="show_position_on_payslip"
                            checked={
                              adminContext.currentEmployee
                                .show_position_on_payslip
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Include job position/role if applicable?"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div key="payslip_note">
                        <label
                          className="form-control-label"
                          htmlFor="payslip_note"
                        >
                          Payslip note (ongoing):
                        </label>
                        <textarea
                          onChange={(e) => {
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              payslip_note: e.target.value,
                            });
                          }}
                          value={adminContext.currentEmployee.payslip_note}
                          type="text"
                          className="form-control my-2 width-100 min-height-200"
                          name="payslip_note"
                        />
                      </div>
                    </Col>
                  </Row>
                  <SaveChangesMessageRow
                    saveChanges={saveChanges}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                  />
                </CardBody>
              </AvForm>
            </Card>
          </Col>
        </Row>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};
export default PayslipOptions;
