import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";

const CustomLeaveAccruals = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const leaveAccrualsComboData = () => {
    let leaveAccruals = {};
    adminContext.company.active_leave_balances.forEach((leaveAccrual) => {
      leaveAccruals[leaveAccrual.id] = leaveAccrual.name;
    });
    return leaveAccruals;
  };

  const payRatesComboData = () => {
    let payRates = {};
    adminContext.currentEmployee.pay_rates.forEach((payRate) => {
      payRates[payRate.id] = payRate.description;
    });
    return payRates;
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=leave_accruals`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const setCurrentLeaveAccrual = (leaveAccrual) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentLeaveAccrual: leaveAccrual,
    });
  };

  const createLeaveAccrual = () => {
    let defaultCompanyBalance = adminContext.company.active_leave_balances[0];
    return {
      id: null,
      accrual_type: defaultCompanyBalance.accrual_type,
      amount: defaultCompanyBalance.amount,
      is_deleted: false,
      company_leave_balance_id: defaultCompanyBalance.id,
      pay_rate: defaultCompanyBalance.pay_rate,
    };
  };

  const deleteLeaveAccrual = (idToDelete) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      active_leave_accruals:
        adminContext.currentEmployee.active_leave_accruals.filter(
          (leaveAccrual) => leaveAccrual.id !== idToDelete
        ),
      currentLeaveAccrual: false,
    });
    setSaving(true);
  };

  useEffect(() => {
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.active_leave_accruals]);

  const updateLeaveAccruals = (leaveAccrual) => {
    setSaving(true);
    let newLeaveAccruals = [];
    if (leaveAccrual.id === null) {
      newLeaveAccruals = [
        ...adminContext.currentEmployee.active_leave_accruals,
        leaveAccrual,
      ];
    } else {
      newLeaveAccruals = adminContext.currentEmployee.active_leave_accruals.map(
        (la) => {
          if (la.id === leaveAccrual.id) {
            return leaveAccrual;
          } else {
            return la;
          }
        }
      );
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      active_leave_accruals: newLeaveAccruals,
    });
  };

  const accrualTypeComboData = () => {
    return {
      ORDINARY_HOURS: "System Ordinary Hours",
      ORDINARY_OVERTIME: "System Overtime Hours",
      ORDINARY_ALL: "System Hours",
      ALL_HOURS: "All Hours",
      PERIOD: "Pay Period",
      PAY_RATE: "Pay Rate",
    };
  };

  const formSpec = {
    title: "Custom Leave Accruals",
    fields: [
      {
        label: "Company Leave Balance",
        accessor: "company_leave_balance_id",
        widget: "ComboBox",
        args: {
          comboDataCallback: leaveAccrualsComboData,
          idAlias: "leaveAccrualName",
          idAliasCallback: (id) => {
            let leaveAccrualName = "";
            adminContext.company.active_leave_balances.forEach(
              (leaveAccrual) => {
                if (Number(leaveAccrual.id) === Number(id)) {
                  leaveAccrualName = leaveAccrual.name;
                }
              }
            );
            return leaveAccrualName;
          },
          validateCallback: (value) => {
            if (value.company_leave_balance_id === "") {
              return "Please select a company leave balance";
            }
            return false;
          },
        },
      },
      {
        label: "Accrual Type",
        accessor: "accrual_type",
        widget: "ComboBox",
        args: {
          comboDataCallback: accrualTypeComboData,
        },
      },
      {
        label: "Pay Rate",
        accessor: "pay_rate_id",
        widget: "ComboBox",
        args: {
          visibleCallback: (row) => {
            return row.accrual_type === "PAY_RATE";
          },
          comboDataCallback: payRatesComboData,
          validateCallback: (value) => {
            if (value.accrual_type === "PAY_RATE" && isNaN(value.pay_rate_id)) {
              return "Pay rate is required.";
            }
            return "";
          },
          idAlias: "PayRateDescription",
          idAliasCallback: (id) => {
            let PayRateDescription = "";
            adminContext.currentEmployee.pay_rates.forEach((payRate) => {
              if (Number(payRate.id) === Number(id)) {
                PayRateDescription = payRate.description;
              }
            });
            return PayRateDescription;
          },
        },
      },
      {
        label: "Amount Accrued (p/unit - depends on accrual type)",
        accessor: "amount",
        widget: "DurationEdit",
      },
    ],
  };

  const getRows = () => {
    if (!adminContext.currentEmployee.active_leave_accruals) return [];
    let rows = [];
    let companyLeaveBalances = adminContext.company.active_leave_balances;
    adminContext.currentEmployee.active_leave_accruals.forEach(
      (leaveBalance) => {
        companyLeaveBalances.forEach((clb) => {
          if (clb.id === leaveBalance.company_leave_balance_id) {
            leaveBalance.leaveBalanceName = clb.name;
          }
        });
        rows.push(leaveBalance);
      }
    );

    return rows;
  };

  const columns = [
    {
      label: "Name",
      accessor: "leaveBalanceName",
      widget: "Text",
    },
    {
      label: "Accrual Type",
      accessor: "accrual_type_display",
      widget: "Text",
    },
    {
      label: "Amount Accrued (p/unit - depends on accrual type)",
      accessor: "amount",
      widget: "DurationLabel",
    },
    {
      label: "Pay Rate",
      accessor: "pay_rate",
      widget: "Text",
    },
    {
      label: "Is Time In Lieu?",
      accessor: "is_time_in_lieu",
      widget: "BooleanLabel",
    },
    {
      label: "Pay At Zero Dollars Per Unit?",
      accessor: "pay_at_zero_dollars_per_unit",
      widget: "BooleanLabel",
    },
    {
      label: "Has A Balance?",
      accessor: "has_a_balance",
      widget: "BooleanLabel",
    },
    {
      label: "Treat As Overtime?",
      accessor: "treat_as_overtime",
      widget: "BooleanLabel",
    },
  ];

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Custom Leave</h1>
                </Col>
              </Row>
              {!adminContext.currentEmployee.currentLeaveAccrual && (
                <Row>
                  <Col className="mt-3 mx-4">
                    <p>
                      Create custom leave accruals for an individual employee.
                      They'll first need to be setup under{" "}
                      <strong>{"Company >> Leave Balances"}</strong>.
                    </p>
                  </Col>
                </Row>
              )}
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={getRows()}
                      columns={columns}
                      selected={
                        adminContext.currentEmployee.currentLeaveAccrual
                      }
                      setSelected={setCurrentLeaveAccrual}
                      appendRowCallback={createLeaveAccrual}
                      appendRowCallbackEnabled={
                        adminContext.company.active_leave_balances &&
                        adminContext.company.active_leave_balances.length > 0
                      }
                      editRowSaveCallback={updateLeaveAccruals}
                      deleteRowCallback={deleteLeaveAccrual}
                      deleteConfirmationAttributes={[
                        "leaveBalanceName",
                        "accrual_type_display",
                      ]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default CustomLeaveAccruals;
