// Import useEffect from React
import React, { useState, useContext, useEffect } from "react";
// Import necessary reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Table,
} from "reactstrap";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import DateTimeLabel from "../../components/Widgets/DateTimeLabel";
import qs from "qs";

const AutoBackups = () => {
  const adminContext = useContext(AdminContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Start with loading true
  const [autoBackups, setAutoBackups] = useState([]);
  const [currentCustomerID, setCurrentCustomerID] = useState("");

  const downloadFile = (filename, content) => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/octet-stream;base64," + content
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const initView = async () => {
    var customerID =
      qs.parse(adminContext.location.search, {
        ignoreQueryPrefix: true,
      }).customer_id || adminContext.customerId;

    setCurrentCustomerID(customerID);

    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/tools/auto-backups/customer/${customerID}`,
      (response) => {
        if (response.data && response.data.auto_backups) {
          setAutoBackups(response.data.auto_backups);
        } else {
          setAutoBackups([]);
        }
        setIsLoading(false);
      },
      (error) => {
        setErrorMessage("Failed to load auto backups. Please try again.");
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, [currentCustomerID]);

  return (
    <Container fluid className="mx-2 h-100">
      {!isLoading ? (
        <>
          <Row className="m-3">
            <Col xs={12}>
              <h1>Automatic Backup Files</h1>
              <p>
                <strong>Customer ID: </strong>
                {currentCustomerID}
              </p>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col xs={12}>
                <Alert color="danger">{errorMessage}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <div className="table-responsive">
                <table className="table table-hover shadow-sm">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">FileName</th>
                      <th scope="col">Modified</th>
                      <th scope="col">Size (KB)</th>
                      <th scope="col" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {autoBackups.map((backup, index) => (
                      <tr key={index}>
                        <th className="align-middle" scope="row">
                          {index + 1}
                        </th>
                        <td className="align-middle">{backup.filename}</td>
                        <td className="align-middle">
                          <DateTimeLabel
                            label="Modified"
                            value={backup.modified_time}
                          />
                        </td>
                        <td className="align-middle">
                          {(backup.filesize / 1024).toFixed(2)}
                        </td>
                        <td className="align-middle text-center">
                          <Button
                            color="primary"
                            className="semi-active-button w-100"
                            onClick={() =>
                              adminContext.getRequest(
                                `${adminContext.constants.BASE_URL}/tools/auto-backups/customer/${currentCustomerID}/${backup.filename}`,
                                (response) => {
                                  if (
                                    response.data &&
                                    response.data.auto_backup
                                  ) {
                                    downloadFile(
                                      backup.filename,
                                      response.data.auto_backup
                                    );
                                  } else {
                                    setErrorMessage(
                                      "Failed to download file. Please try again."
                                    );
                                  }
                                },
                                (error) => {
                                  setErrorMessage(
                                    "Failed to download file. Please try again."
                                  );
                                }
                              )
                            }
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default AutoBackups;
