import TableWidget from "../../../components/Widgets/TableWidget";

const EditPayDepartments = ({ adminContext, setSaving }) => {
  return (
    <>
      <TableWidget
        label="Departments"
        rows={adminContext.pay.pay_departments}
        editRowSaveCallback={(newDepartment) => {
          setSaving(true);
          let departmentRows;
          if (newDepartment.id === null) {
            // New department
            departmentRows = adminContext.pay.pay_departments.concat([
              newDepartment,
            ]);
          } else {
            // Existing department
            departmentRows = adminContext.pay.pay_departments.map(
              (department) => {
                if (department.id === newDepartment.id) {
                  return newDepartment;
                }
                return department;
              }
            );
          }
          adminContext.setPay({
            ...adminContext.pay,
            pay_departments: departmentRows,
          });
        }}
        columns={[
          {
            label: "Department",
            accessor: "department",
            widget: "text",
            valueCallback: (row) => {
              if (!row.department || row.department === undefined) {
                return "";
              }
              return row.department.name;
            },
          },
          {
            label: "Percentage",
            accessor: "percentage",
            widget: "PercentageLabel",
          },
        ]}
        deleteConfirmationAttributes={["department", "percentage"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            pay_departments: adminContext.pay.pay_departments.filter(
              (department) => {
                return department.id !== idToDelete;
              }
            ),
          });
        }}
        appendRowCallback={() => {
          let empDepartment = adminContext.pay.employee.employee_departments[0];
          return {
            id: null,
            department_id: empDepartment.department_id,
            percentage: empDepartment.percentage,
            pay_id: adminContext.pay.id,
          };
        }}
        appendRowCallbackEnabled={adminContext.company.departments.length > 0}
        formSpec={{
          title: "Department",
          fields: [
            {
              label: "Leave Type",
              accessor: "department_id",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => {
                  let departmentComboData = {};
                  adminContext.company.departments.forEach((department) => {
                    departmentComboData[department.id] = department.name;
                  });

                  return departmentComboData;
                },
              },
            },
            {
              label: "Percentage",
              accessor: "percentage",
              widget: "PercentageEdit",
            },
          ],
        }}
      />
    </>
  );
};
export default EditPayDepartments;
