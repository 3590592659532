import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";

const Deductions = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=employee_deductions`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const setcurrentDeduction = (deduction) => {
    // Set the employees current deduction to the current deduction
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentDeduction: deduction,
    });
  };

  const createDeduction = () => {
    return {
      id: null,
      description: "",
      amount: { _decimal: 0 },
      auto_apply_to_pay: true,
      is_included_in_super_calculations: true,
      classification: "NORMAL",
      percentage: 0.0,
      is_percentage: false,
      based_on: "GROSS",
    };
  };

  const deleteDeduction = (idToDelete) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      deductions: adminContext.currentEmployee.deductions.filter(
        (deduction) => deduction.id !== idToDelete
      ),
      currentDeduction: false,
    });
    setSaving(true);
  };

  const updateErrorMessage = () => {
    if (adminContext.currentEmployee.deductions === undefined) {
      return;
    }
    let totalPercentage = adminContext.currentEmployee.deductions.reduce(
      (total, deduction) => {
        return total + Number(deduction.percentage);
      },
      0
    );

    if (totalPercentage > 1) {
      setErrorMessage(
        "The combined percentages of these deductions is greater than 100%"
      );
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    updateErrorMessage();
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.deductions]);

  const updateDeductions = (deduction) => {
    setSaving(true);
    let newDeductions = [];
    if (deduction.id === null) {
      newDeductions = [...adminContext.currentEmployee.deductions, deduction];
    } else {
      newDeductions = adminContext.currentEmployee.deductions.map((a) => {
        if (a.id === deduction.id) {
          return deduction;
        } else {
          return a;
        }
      });
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      deductions: newDeductions,
    });
  };

  const formSpec = {
    title: "Deduction",
    fields: [
      {
        label: "Description",
        accessor: "description",
        widget: "Text",
        required: true,
      },
      {
        label: "Amount",
        accessor: "amount",
        widget: "MoneyEdit",
        args: {
          visibleCallback: (deduction) => {
            return !deduction.is_percentage;
          },
        },
      },
      {
        label: "Classification",
        accessor: "classification",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              NORMAL: "Normal",
              "UNION FEES": "Union/Association Fees",
              CHARITY: "Charity/Workplace Giving",
              "CHILD SUPPORT GARNISHEE": "Child Support Garnishee",
              "CHILD SUPPORT DEDUCTION": "Child Support Deduction",
            };
          },
        },
      },
      {
        label: "Auto Apply to Pay?",
        accessor: "auto_apply_to_pay",
        widget: "CheckBox",
      },
      {
        label: "Included in Super Calculations?",
        accessor: "is_included_in_super_calculations",
        widget: "CheckBox",
      },
      {
        label: "Bank Acc. (optional)",
        accessor: "bank_account_id",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            let bankAccounts = { null: "" };
            adminContext.currentEmployee.bank_accounts.map((bankAccount) => {
              bankAccounts[bankAccount.id] =
                bankAccount.account_name +
                " - Acc: " +
                bankAccount.account_number +
                " - BSB:" +
                bankAccount.bsb;
            });
            return bankAccounts;
          },
        },
      },
      {
        label: "Is Percentage?",
        accessor: "is_percentage",
        widget: "CheckBox",
      },
      {
        label: "Percentage",
        accessor: "percentage",
        widget: "PercentageEdit",
        args: {
          visibleCallback: (deduction) => {
            return deduction.is_percentage;
          },
        },
      },
      {
        label: "% Based On",
        accessor: "based_on",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              GROSS: "Gross",
              NET: "Net",
            };
          },
          visibleCallback: (deduction) => {
            return deduction.is_percentage;
          },
        },
      },
    ],
  };

  const columns = [
    {
      label: "Description",
      accessor: "description",
      widget: "Text",
    },
    {
      label: "Amount",
      accessor: "value",
      widget: "Text",
      align: "right",
    },
    {
      label: "Classification",
      accessor: "readable_classification",
      widget: "Text",
    },
    {
      label: "Auto Apply to Pay?",
      accessor: "auto_apply_to_pay",
      widget: "BooleanLabel",
    },
    {
      label: "Connected To Bank Account?",
      accessor: "bank_account_id",
      widget: "BooleanLabel",
    },
    {
      label: "Included in Super Calculations?",
      accessor: "is_included_in_super_calculations",
      widget: "BooleanLabel",
    },
  ];
  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Deductions</h1>
                </Col>
              </Row>
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={
                        adminContext.currentEmployee.deductions
                          ? adminContext.currentEmployee.deductions
                          : []
                      }
                      columns={columns}
                      selected={adminContext.currentEmployee.currentDeduction}
                      setSelected={setcurrentDeduction}
                      appendRowCallback={createDeduction}
                      editRowSaveCallback={updateDeductions}
                      deleteRowCallback={deleteDeduction}
                      deleteConfirmationAttributes={["description", "value"]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default Deductions;
