import React from "react";
import { Card, CardHeader } from "reactstrap";

const TrialRestricted = ({ screen }) => {
  return (
    <Card className="bg-secondary mx-3 my-3 shadow border-0">
      <CardHeader className="bg-transparent pb-5">
        <div className="text-center mt-2 mb-3">
          <h1 className="days-one">{screen} Not Available On Trial</h1>
          <p>
            This option is restricted as you are currently using a trial
            license. Please{" "}
            <a
              href="https://www.lightningpayroll.com.au/buy-now"
              target="_blank"
              rel="noopener noreferrer"
            >
              purchase an annual subscription
            </a>{" "}
            to enable this.
          </p>
        </div>
      </CardHeader>
    </Card>
  );
};

export default TrialRestricted;
