import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import Wizard from "../../../components/Widgets/Wizard";
import DateRangeView from "../../../components/Widgets/WizardViews/DateRangeView";
import SelectEmployeesView from "../../../components/Widgets/WizardViews/SelectEmployeesView";
import PayslipNotesAndFormatView from "./PayslipNotesAndFormatView";
import SelectPaysView from "../../../components/Widgets/WizardViews/SelectPaysView";
import PayslipPrintView from "./PayslipPrintView";
import PayslipEmailView from "./PayslipEmailView";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";

const PayslipsWizard = () => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);

  const getExportOption = () => {
    let export_option = "print_email";
    if (
      adminContext.appConfig.email_payslips.value &&
      adminContext.appConfig.print_payslips.value
    ) {
      export_option = "print_email";
    } else if (adminContext.appConfig.email_payslips.value) {
      export_option = "email";
    } else if (adminContext.appConfig.print_payslips.value) {
      export_option = "print";
    }
    return export_option;
  };

  const convertDateFormat = (dateString) => {
    // Converts "dd/mm/yyyy" format to "yyyy-mm-dd" format
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const [wizardInfo, setWizardInfo] = useState({
    pays: [],
    selected_pays: [],
    employees: adminContext.company.employees.filter((e) => !e.is_terminated),
    selected_employees: adminContext.company.employees.filter(
      (e) => !e.is_terminated
    ),
    company: adminContext.company,
    multiple_pay_runs: false,
    export_option: getExportOption(),
    payslip_data: {},
    pdf_data: "",
    start_date: convertDateFormat(
      adminContext.appConfig.report_start_date.value
    ),
    end_date: convertDateFormat(adminContext.appConfig.report_end_date.value),
    payslip_notice: adminContext.appConfig.payslip_notice.value,
    payslip_theme: adminContext.appConfig.payslip_theme.value,
    payslip_report_type: adminContext.appConfig.payslip_report_type.value,
    payslip_themes_available: {
      "Original Style": "Original Style",
      "Bordered Table Style": "Bordered Table Style",
    },
    payslip_reports_available: {
      payslip_page: "Classic",
      payslip_window_page: "Classic for windowed envelopes",
    },
    show_terminated: false,
  });

  const [rows, setRows] = useState({
    pays: [],
  });

  const [selectedRows, setSelectedRows] = useState({
    pays: [],
  });

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      pays: rows.pays,
      selected_pays: selectedRows.pays,
    });
  }, [rows, selectedRows]);

  useEffect(() => {
    // Get the current route from the url.
    // E.g. http://localhost:3000/admin/pays/payslips_multiple_pay_runs => payslips_multiple_pay_runs
    const currentRoute = window.location.pathname.split("/").pop();
    if (currentRoute.includes("multiple")) {
      setWizardInfo({
        ...wizardInfo,
        multiple_pay_runs: true,
      });
      setStep(0);
    } else {
      let pays = [];
      adminContext.payRows.forEach((pay) => {
        if (pay.is_complete) {
          pays.push({
            id: pay.id,
            format_name: getFormatNameForPay(pay),
          });
        }
      });
      let selected_pays = [];
      adminContext.payRows.forEach((pay) => {
        selected_pays.push({
          id: pay.id,
          format_name: getFormatNameForPay(pay),
        });
      });
      setWizardInfo({
        ...wizardInfo,
        pays: pays,
        selected_pays: selected_pays,
      });
      setStep(2);
    }
  }, []);

  const getFormatNameForPay = (pay) => {
    return (
      <>
        Pay <MoneyLabel amount={pay.net._decimal} /> to{" "}
        {pay.employee.format_name} for {pay.date}
      </>
    );
  };

  const payslipSelectionFormSpec = {
    fixedTitle: true,
    title: "Select Pays",
    shortDescription:
      "Select the payslips you would like to print and/or email. Pays must be completed in order to be available for selection.",
    fields: [
      {
        label: "",
        accessor: "pays",
        widget: "ListWidget",
        validate: {
          required: false,
        },
        args: {
          rows: rows,
          setRows: setRows,
          selectedRows: selectedRows,
          setSelectedRows: setSelectedRows,
          skipFilter: true,
          noTitleCase: true,
        },
      },
    ],
  };

  const wizardSteps = [
    <DateRangeView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SelectEmployeesView
      setForceUpdateButtons={setForceUpdateButtons}
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <PayslipNotesAndFormatView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SelectPaysView
      setForceUpdateButtons={setForceUpdateButtons}
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      getFormatNameForPay={getFormatNameForPay}
      formSpec={payslipSelectionFormSpec}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      loading={loading}
      setLoading={setLoading}
      step={step}
    />,
    <PayslipPrintView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <PayslipEmailView
      setForceUpdateButtons={setForceUpdateButtons}
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
  ];

  const handleNext = () => {
    // We need to carefully set which step is appropriate as the next step, depending on values in the form
    if (wizardInfo.multiple_pay_runs) {
      if (step === 0) {
        // DateRangeView
        setStep(1); // SelectEmployeesView
      } else if (step === 1) {
        // SelectEmployeesView
        setStep(2); // PayslipNotesAndFormatView
      }
    }
    if (step === 2) {
      // PayslipNotesAndFormatView
      setStep(3); // PayslipSelectionView
    } else if (step === 3) {
      // PayslipSelectionView
      if (wizardInfo.export_option === "print_email") {
        setStep(4); // PayslipPrintView
      } else if (wizardInfo.export_option === "email") {
        setStep(5); // PayslipEmailView
      } else {
        setStep(4); // PayslipPrintView
      }
    } else if (step === 4) {
      // PayslipPrintView
      if (
        wizardInfo.export_option === "email" ||
        wizardInfo.export_option === "print_email"
      ) {
        setStep(5); // PayslipEmailView
      } else {
        adminContext.history.goBack();
      }
    } else if (step === 5) {
      // PayslipEmailView
      adminContext.history.goBack();
    }
  };

  const handleBack = () => {
    setNextEnabled(true);
    if (wizardInfo.multiple_pay_runs) {
      if (step === 1) {
        setStep(0); // DateRangeView
      } else if (step === 2) {
        setStep(1); // SelectEmployeesView
      }
    }
    if (step === 3) {
      setStep(2); // PayslipNotesAndFormatView
    } else if (step === 4) {
      setStep(3);
    } else if (step === 5) {
      if (wizardInfo.export_option === "email") {
        setStep(3); // PayslipSelectionView
      } else {
        setStep(4); // PayslipPrintView
      }
    }
  };

  return (
    <Wizard
      wizardSteps={wizardSteps}
      handleNext={handleNext}
      handleBack={handleBack}
      cancelCallback={adminContext.history.goBack}
      nextEnabled={nextEnabled}
      step={step}
      setStep={setStep}
      buttonsVisible={buttonsVisible}
      forceUpdateButtons={forceUpdateButtons}
      setForceUpdateButtons={setForceUpdateButtons}
      isBackDisabled={() =>
        (step === 2 && !wizardInfo.multiple_pay_runs) ||
        (step === 0 && wizardInfo.multiple_pay_runs)
      }
      getLastStep={() => {
        if (wizardInfo.export_option === "print_email") {
          return 5;
        } else if (wizardInfo.export_option === "email") {
          return 5;
        } else {
          return 4;
        }
      }}
    />
  );
};

export default PayslipsWizard;
