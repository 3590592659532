import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ComboBox from "../../../components/Widgets/ComboBox";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import AvForm from "availity-reactstrap-validation/lib/AvForm";
import FormControlLabel from "@mui/material/FormControlLabel";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import CheckBox from "@mui/material/Checkbox";

const SpecialTaxRates = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [comboData, setComboData] = useState({
    industry: {
      UNSPECIFIED: "Unspecified",
      HORTICULTURE: "Horticulture",
      SHEARING: "Shearing",
    },
    income_stream_country_code: adminContext.constants.COUNTRY_CODES,
  });

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <AvForm>
              <Row>
                <Col>
                  <h1 className="days-one">Special Tax Rates</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />
              <Row className="mx-2">
                <Col>
                  <h3>Horticulture/Shearing Industries</h3>
                  <p>
                    Special tax tables apply to workers in the Horticulture and
                    Shearing industries (see NAT 1013). These tables apply if
                    the employee:
                  </p>

                  <ul>
                    <li>works in the horticultural or shearing industries</li>
                    <li>
                      does not work for the same employer for a continuous
                      period exceeding six months
                    </li>
                    <li>
                      has given you a valid Tax file number declaration (NAT
                      3092) and has claimed the tax-free threshold.
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col className="mx-4 my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={adminContext.currentEmployee.locked || !adminContext.editAccess("employees")}
                      label="Select the applicable industry if these tables apply to this
                      employee."
                      stretch={true}
                      className="mx-3"
                      name="industry"
                      onChange={handleChange}
                      comboData={comboData["industry"]}
                      selectedComboItem={adminContext.currentEmployee.industry}
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          industry: adminContext.getKeyByValue(
                            comboData["industry"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mx-2">
                <Col>
                  <h3>Working Holiday Tax Scale</h3>
                  <p>
                    Employees who are on working holidays have special tax rates
                    if their employer is registered with the ATO to use it.
                  </p>
                  <p>
                    DO NOT tick the box below if you have not registered with
                    the ATO to use it.
                  </p>
                  <p>
                    For more information please see our{" "}
                    <a href="https://www.lightningpayroll.com.au/faq?selected=22">
                      FAQ
                    </a>
                    .
                  </p>
                  <FormControlLabel
                    control={
                      <CheckBox
                        checked={
                          adminContext.currentEmployee
                            .working_holiday_tax_scale_applies
                        }
                        onChange={handleChange}
                        name="working_holiday_tax_scale_applies"
                      />
                    }
                    label={"Use Working Holiday Tax Scale"}
                  />
                </Col>
              </Row>
              {adminContext.currentEmployee
                .working_holiday_tax_scale_applies ? (
                <Row className="mx-2">
                  <Col>
                    <ComboBox
                      disabled={adminContext.currentEmployee.locked || !adminContext.editAccess("employees")}
                      label="Home Country"
                      stretch={true}
                      className="mx-3"
                      name="income_stream_country_code"
                      onChange={handleChange}
                      comboData={comboData["income_stream_country_code"]}
                      selectedComboItem={
                        adminContext.currentEmployee.income_stream_country_code
                      }
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          income_stream_country_code:
                            adminContext.getKeyByValue(
                              comboData["income_stream_country_code"],
                              data
                            ),
                        })
                      }
                    />
                  </Col>
                </Row>
              ) : null}
            </AvForm>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SpecialTaxRates;
