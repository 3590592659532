import { useState, useEffect, useContext } from "react";
import AdminContext from "../../AdminContext";

function DurationEdit(props) {
  const [text, setText] = useState("");
  const numberRegex = new RegExp("^[+-]?[0-9]*\\.?[0-9]*$");
  const hmsRegex =
    /^(?:(?:(?:(\d+)h)?\s*(?:(\d+)m)?\s*(?:(\d+(?:\.\d+)?)s)?)|(?:(?:(\d+(?:\.\d+)?)s)?\s*(?:(\d+)m)?\s*(?:(\d+)h)?))$/;
  const delimitedRegex = new RegExp("[+-]?((\\d+[: ]?){1,3})");
  const adminContext = useContext(AdminContext);

  const isNumber = (value) => {
    return numberRegex.test(value);
  };

  function isHmsString(value) {
    const match = value.match(hmsRegex);
    return Boolean(match);
  }

  const isDelimitedString = (value) => {
    return delimitedRegex.test(value);
  };

  const getValue = () => {
    return props.value.milliseconds !== undefined
      ? props.value.milliseconds
      : props.value;
  };

  const isNegative = () => {
    return getValue() < 0;
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  const convertMsToHM = (milliseconds) => {
    let isNegative = false;
    if (milliseconds.milliseconds !== undefined) {
      milliseconds = milliseconds.milliseconds;
    }

    if (milliseconds < 0) {
      isNegative = true;
      milliseconds = Math.abs(milliseconds);
    }

    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;

    let timeStr = "";
    if (adminContext.appConfig.hours_as_decimal_widget.value) {
      const decimalHours = hours + minutes / 60 + seconds / 3600;
      timeStr = `${decimalHours.toFixed(2)}h`;
    } else {
      timeStr = `${padTo2Digits(hours)}h ${padTo2Digits(
        minutes
      )}m ${padTo2Digits(seconds)}s`;
    }

    return isNegative ? `-${timeStr}` : timeStr;
  };

  const processTime = () => {
    let isNegative = false;
    let newText = text.trim();

    if (newText.startsWith("-")) {
      isNegative = true;
      newText = newText.slice(1); // Remove the negative sign for further processing
    }

    let result = 0;

    if (!newText) {
      result = 0;
    } else if (isNumber(newText)) {
      result = Number(newText) * adminContext.constants.MILLISECONDS_PER_HOUR;
    } else if (isHmsString(newText) || isDelimitedString(newText)) {
      let timeStr = newText.toLowerCase();
      let millis = 0;
      let partRegex = /(\d+\.?\d*|\.\d+)([hms]|\s*)?/g;
      let part = [...timeStr.matchAll(partRegex)];
      for (let i = 0; i < part.length; i++) {
        let value = Number(part[i][1]);
        let unit = part[i][2] || "";
        switch (unit) {
          case "h":
          case " ":
            millis += value * adminContext.constants.MILLISECONDS_PER_HOUR;
            break;
          case "m":
            millis += value * adminContext.constants.MILLISECONDS_PER_MINUTE;
            break;
          case "s":
            millis += value * adminContext.constants.MILLISECONDS_PER_SECOND;
            break;
          default:
            break;
        }
      }
      result = millis;
    }

    if (isNegative) {
      result = -result;
    }

    props.setValue(
      props.value.milliseconds !== undefined ? { milliseconds: result } : result
    );
    setText(convertMsToHM(result));
  };

  // Initialise the text value using props.value milliseconds
  useEffect(() => {
    setText(convertMsToHM(getValue()));
  }, []);

  return (
    <input
      value={text}
      onBlur={processTime}
      placeholder={"00h 00m 00s"}
      className={
        isNegative()
          ? "form-control width-100 text-danger"
          : "form-control width-100"
      }
      onChange={(e) => setText(e.target.value)}
    />
  );
}

export default DurationEdit;
