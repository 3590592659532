import EditForm from "../../../../components/Widgets/EditForm";
import { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../../AdminContext";

const NonEtpPayoutOptionsView = ({ payTermination, setPayTermination }) => {
  const [loading, setLoading] = useState(false);
  const adminContext = useContext(AdminContext);

  const getRationaleLabel = async () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/pays/non-etp-rational-label/${adminContext.pay.id}`,
      payTermination,
      (response) => {
        setLoading(false);
        setPayTermination(response.data.pay_termination);
      }
    );
  };
  useEffect(() => {
    getRationaleLabel();
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Row>
          <Col>
            <EditForm
              liveEdit={true}
              fixedTitle={true}
              formSpec={{
                title: "Non ETP Payout Options",
                shortDescription: (
                  <>
                    According to rules from the Australian Tax Office, some
                    payout amounts cannot be included in an Employee Termination
                    Payment for tax purposes. These include items such as
                    compensation for personal injury, payment for restraint of
                    trade, foreign termination payments and employee share
                    scheme payments.
                    {payTermination.reason.toLowerCase().includes("death") &&
                    (payTermination.is_unused_holiday_paid ||
                      payTermination.is_unused_lsl_paid ||
                      payTermination.is_unused_leave_loading_paid) ? (
                      <>
                        <br />
                        <br />
                        <span className="text-danger">
                          When an employee is deceased these amounts are not to
                          be shown on their payment summary and should be paid
                          outside of payroll as described
                          <a
                            target="_blank"
                            href="https://www.lightningpayroll.com.au/faq?selected=218"
                          >
                            {" "}
                            here
                          </a>
                          .
                        </span>
                      </>
                    ) : null}
                  </>
                ),
                fixedTitle: true,
                fields: [
                  {
                    label: "Pay out unused holiday/annual leave",
                    accessor: "is_unused_holiday_paid",
                    widget: "CheckBox",
                  },
                  {
                    label: "Pay out unused leave loading",
                    accessor: "is_unused_leave_loading_paid",
                    widget: "CheckBox",
                  },
                  {
                    label: "Pay out unused long service leave",
                    accessor: "is_unused_lsl_paid",
                    widget: "CheckBox",
                  },
                  {
                    label: "Other payout amount",
                    accessor: "non_etp_amount",
                    widget: "MoneyEdit",
                  },
                  {
                    label: "",
                    accessor: "non_etp_rationale_label",
                    widget: "html",
                  },
                ],
              }}
              setModelObj={setPayTermination}
              modelObj={payTermination}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NonEtpPayoutOptionsView;
