import EditForm from "../../../components/Widgets/EditForm";
import { useEffect, useContext } from "react";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const SelectMessagesView = ({
  wizardInfo,
  setWizardInfo,
  formSpec,
  rows,
  setRows,
  selectedRows,
  setSelectedRows,
  setNextEnabled,
  loading,
  setLoading,
}) => {
  const adminContext = useContext(AdminContext);

  const refreshMessages = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/superstream/direct-entry/select-messages`,
      wizardInfo,
      (response) => {
        setLoading(false);
        setRows({
          ...rows,
          messages: response.data.wizard_info.messages,
        });
        setSelectedRows({
          ...selectedRows,
          messages: response.data.wizard_info.messages,
        });
      }
    );
  };

  useEffect(() => {
    refreshMessages();
  }, []);

  // setNextEnabled should be false if no messages are currently selected
  useEffect(() => {
    if (selectedRows.messages.length === 0) {
      setNextEnabled(false);
    } else {
      setNextEnabled(true);
    }
  }, [selectedRows.messages]);

  // Refresh the messages if the start date, end date or include_paid change in the wizardInfo
  useEffect(() => {
    refreshMessages();
  }, [wizardInfo.start_date, wizardInfo.end_date, wizardInfo.include_paid]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <EditForm
            liveEdit={true}
            formSpec={formSpec}
            setModelObj={setWizardInfo}
            modelObj={wizardInfo}
          />
        </div>
      )}
    </div>
  );
};

export default SelectMessagesView;
