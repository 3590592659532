import { Row, Container, Col, Button } from "reactstrap";
import React, { useState, useContext } from "react";
import AdminContext from "../../AdminContext";
import Tooltip from "./Tooltip";

const SingleSelectListWidget = (props) => {
  const adminContext = useContext(AdminContext);
  const rows = (props.rows || []).sort((a, b) => {
    if (props.noSort) return 0;
    if (a.format_name < b.format_name) {
      return -1;
    }
    if (a.format_name > b.format_name) {
      return 1;
    }
    return 0;
  });

  const getMenuClass = (item) => {
    let className = "";
    if (
      !adminContext.appConfig.collapse_pay_buttons_on_web.value ||
      !props.setIconsCallback
    ) {
      className += " width-100 ";
    }
    className += " btn my-1 mx-1  text-break ";
    if (
      props.selectedItem === item.id ||
      Number(props.selectedItem) === Number(item.id)
    ) {
      className += " active-button";
    }
    return className;
  };

  const getIconsOnlyButtonClass = () => {
    if (adminContext.appConfig.collapse_pay_buttons_on_web.value) {
      return "fa-chevrons-down";
    }
    return "fa-chevrons-up";
  };

  return (
    <Container
      className={
        props.className
          ? props.className + " mt-2 mx-0 px-1"
          : " mt-4 mx-0 px-1"
      }
    >
      <Row className="">
        <Col className="text-center m-20-on-sm  emp-nav-buttons px-0">
          {rows.length > 0 ? (
            // Loop through rows and render a row/col/button for each item
            // Add a <hr /> before the index of any props.dividerIndices
            // (if props.dividerIndices is defined)

            rows.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  {props.dividerIndices &&
                  props.dividerIndices.includes(index) ? (
                    <hr />
                  ) : null}
                  {props.infoLabels &&
                  props.infoLabels.hasOwnProperty(index) ? (
                    <h2 className="text-center days-one">
                      {props.infoLabels[index]}
                    </h2>
                  ) : null}
                  <div
                    key={item.id}
                    className={
                      adminContext.appConfig.collapse_pay_buttons_on_web.value
                        ? "d-inline"
                        : ""
                    }
                  >
                    <Button
                      color="secondary"
                      id={item.id}
                      className={getMenuClass(item)}
                      onClick={props.setSelectedItem}
                    >
                      <Tooltip
                        title={
                          adminContext.appConfig.collapse_pay_buttons_on_web
                            .value
                            ? item.format_name
                            : ""
                        }
                        innerContent={
                          <div>
                            {props.icon ? (
                              <div className="text-center">
                                <i className={props.icon}></i>
                                <br />
                              </div>
                            ) : null}
                            {item.icon ? (
                              <div className="text-center mx-auto">
                                <i className={item.icon + " mx-auto"}></i>
                                <br />
                              </div>
                            ) : null}

                            {!adminContext.appConfig.collapse_pay_buttons_on_web
                              .value || !props.setIconsCallback
                              ? item.format_name
                              : null}
                          </div>
                        }
                      />
                    </Button>
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <h3>No items found</h3>
          )}
        </Col>
      </Row>
      {props.showIconsOnlyButton ? (
        <Row>
          <Col>
            <div
              className="text-center mx-auto mt-3"
              onClick={() =>
                props.setIconsCallback(
                  !adminContext.appConfig.collapse_pay_buttons_on_web.value
                )
              }
            >
              <i
                className={
                  "fa-regular " + getIconsOnlyButtonClass() + " fa-2xl mx-auto"
                }
              ></i>
              <br />
            </div>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};
export default SingleSelectListWidget;
