const MoneyLabel = (props) => {
  const decimalPlaces = props.decimalPlaces || 2;
  let amount = props.amount;

  if (amount === null || amount === undefined || amount === "") {
    amount = 0;
  }

  if (amount._decimal !== undefined) {
    amount = amount._decimal;
  }
  const formatAmount = () => {
    if (amount === null || amount === undefined || amount === "") {
      return "";
    }
    let newAmount = Number(amount);

    newAmount = (Math.round(amount * 100) / 100).toFixed(decimalPlaces);
    newAmount = "$" + newAmount.toString();
    return newAmount;
  };
  const colourStyle = () => {
    let style = "";
    if (Number(amount) >= 0) {
      style = "no-position font-weight-bold";
    } else {
      style = "no-position font-weight-bold text-danger";
    }
    style += props.className ? " " + props.className : "";
    return style;
  };

  return <span className={colourStyle()}>{formatAmount()}</span>;
};
export default MoneyLabel;
