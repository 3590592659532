import React from "react";
import EditForm from "../../../../components/Widgets/EditForm";
import AdminContext from "../../../../AdminContext";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";
import { useEffect, useState, useContext } from "react";

const AdvancedSettingsView = ({ pay, payTermination, setPayTermination }) => {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(true);

  const refreshPayTermination = async () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/refresh-termination/${pay.id}`,
      payTermination,
      (response) => {
        setLoading(false);
        setPayTermination(response.data.pay_termination);
      }
    );
  };

  useEffect(() => {
    refreshPayTermination();
  }, []);
  const formSpec = {
    title: "Advanced Settings",
    fields: [
      {
        label: "Long Service Leave Settings",
        widget: "SectionHeader",
      },
      {
        label: "Amount of Long Service Leave accrued prior to August 1978",
        widget: "MoneyEdit",
        accessor: "unused_lsl_pre_august_1978",
      },
      {
        label:
          "Amount of Long Service Leave accrued between August 1978 and August 1993",
        widget: "MoneyEdit",
        accessor: "unused_lsl_august_1978_to_august_1993",
      },
      {
        label: "Amount of Long Service Leave accrued after August 1993",
        widget: "MoneyEdit",
        accessor: "unused_lsl_post_august_1993",
      },
      {
        label: "Remaining long service leave balance",
        widget: "MoneyEdit",
        accessor: "long_service_leave_balance",
      },
      {
        label: "Holiday Leave Settings",
        widget: "SectionHeader",
      },
      {
        label: "Amount of unused leave loading",
        widget: "MoneyEdit",
        accessor: "unused_leave_loading_amount",
      },
      {
        label: "Amount of unused holiday leave",
        widget: "MoneyEdit",
        accessor: "unused_holiday_amount",
      },
      {
        label: "Remaining holiday leave balance",
        widget: "MoneyEdit",
        accessor: "holiday_leave_balance",
      },
      {
        label: "Sick Leave Settings",
        widget: "SectionHeader",
      },
      {
        label: "Amount of unused sick leave",
        widget: "MoneyEdit",
        accessor: "unused_sick_amount",
      },
      {
        label: "Remaining sick leave balance",
        widget: "MoneyEdit",
        accessor: "sick_leave_balance",
      },
      {
        label: "Normal Earnings",
        widget: "MoneyEdit",
        accessor: "normal_earnings",
      },
    ],
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <EditForm
          liveEdit={true}
          fixedTitle={true}
          formSpec={formSpec}
          modelObj={payTermination}
          setModelObj={setPayTermination}
        />
      )}
    </>
  );
};

export default AdvancedSettingsView;
