import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Alert,
  Col,
  Spinner,
} from "reactstrap";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import { getBaseUrl } from "../../variables/Config";
import AuthContext from "../../AuthContext";

const Trial = ({ email, setEmail, gclid, referralCode, keyword }) => {
  const authLoading = useContext(AuthContext);
  const isMounted = useRef(true);
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const checkTokenAndRedirect = () => {
      if (token) {
        history.push("/admin/entities/select");
      }
    };

    // Call the function immediately in case the token is already set
    checkTokenAndRedirect();

    // Any time the token changes, this effect will rerun, causing a redirect if token is set
  }, [token, history]);

  useEffect(() => {
    isMounted.current = true;
    const savedEmail = localStorage.getItem("email");
    if (savedEmail) setEmail(savedEmail);

    return () => {
      isMounted.current = false;
    };
  }, []);

  const applyForTrial = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email) {
      setErrorMessage("Please enter your email.");
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return;
    }

    const opts = {
      email: email,
      gclid: gclid,
      referral_code: referralCode,
      keyword: keyword,
    };

    axios
      .post(getBaseUrl() + "/request-trial", opts)
      .then((response) => {
        if (!isMounted.current) return;
        if (response.data.success) {
          showSuccess(
            "Your trial is ready to go! Please check your email to get started."
          );
        } else {
          showError(response.data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error");
        console.log(error);
        if (!isMounted.current) return;
        setLoading(false);
        if (error.status === 429) {
          showError(
            "Too many login attempts. Please try again after 30 minutes."
          );
          return;
        }
        showError("Something went wrong. Please try again.");
      });
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 10000);
  };

  const showSuccess = (message) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage("");
    }, 10000);
  };

  return (
    <>
      <Col lg="8" md="8">
        {errorMessage && (
          <Row>
            <Col>
              <Alert color="warning">
                <strong>{errorMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}
        {successMessage && (
          <Row>
            <Col>
              <Alert color="success">
                <strong>{successMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12" md="12">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <p className="text-center">
                  Please enter your email address below to start your free 30
                  day trial
                </p>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={applyForTrial}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Enter registered email"
                        type="email"
                        className="text-center text-lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    {loading || authLoading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          color="success"
                          disabled={!email}
                          className="mx-auto d-block btn-lg text-lg width-100 m-2"
                        >
                          Start Free Trial
                        </Button>
                        <Button
                          onClick={() => {
                            history.push("/auth/login");
                          }}
                          color="primary"
                          className="mx-auto d-block btn-lg text-lg width-100 m-2"
                        >
                          Already have an account? Login here
                        </Button>
                      </>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Trial;
