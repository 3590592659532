import React, { useState, useEffect, useContext, useMemo } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import BackButton from "../../components/Widgets/BackButton";
import ComboBox from "../../components/Widgets/ComboBox";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import DateRangeEdit from "../../components/Widgets/DateRangeEdit";
import FontPicker from "../../components/Widgets/FontPicker";

const LeaveRequestsView = () => {
  const adminContext = useContext(AdminContext);

  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [vm, setVm] = useState({
    preview_font_family: "Arial",
    preview_font_size: 8,
    fonts: [],
  });
  const [pdfFileData, setPdfFileData] = useState(null);

  const activeLeaveRequestEmployees = useMemo(
    () =>
      adminContext.company.employees.filter(
        (employee) =>
          employee.employee_portal_active && employee.allow_edit_leave_requests
      ),
    [adminContext.company.employees]
  );

  // Fetch VM data from the server
  const fetchVMData = (filters = {}) => {
    if (
      !activeLeaveRequestEmployees ||
      activeLeaveRequestEmployees.length === 0
    ) {
      setLoading(false);
      return;
    }

    setLoading(true);
    adminContext.postRequest(
      `${adminContext.constants.BASE_URL}/rosters/${adminContext.company.id}/leave-requests-vm`,
      filters,
      (response) => {
        setLoading(false);
        if (
          response.data.vm &&
          JSON.stringify(vm) !== JSON.stringify(response.data.vm)
        ) {
          setVm(response.data.vm);
        }
        if (response.data.pdf_file_data !== pdfFileData) {
          setPdfFileData(response.data.pdf_file_data);
        }
      }
    );
  };

  // Update on significant VM or UI changes
  useEffect(() => {
    const newFilters = {
      start_date: vm.start_date,
      end_date: vm.end_date,
      selected_employee: vm.selected_employee,
      selected_status: vm.selected_status,
      selected_leave_type: vm.selected_leave_type,
      sort_by: vm.sort_by,
      sort_by_direction: vm.sort_by_direction,
      preview_font_family: vm.preview_font_family,
      preview_font_size: vm.preview_font_size,
      fonts: vm.fonts,
    };

    fetchVMData(newFilters);
  }, [vm]);

  const leaveRequestColumns = [
    {
      label: "Button",
      accessor: "None",
      widget: "Link",
      args: {
        label: "",
        href: "/admin/pays/leave-request",
        color: "secondary",
        icon: "fa fa-edit fa-xl",
        className: "btn btn-sm btn-secondary",
      },
    },
    {
      label: "Employee Name",
      accessor: "employee_name",
      widget: "Text",
    },
    {
      label: "Status",
      accessor: "status",
      widget: "Text",
    },
    {
      label: "Start Date",
      accessor: "start_date",
      widget: "DateLabel",
    },
    {
      label: "End Date",
      accessor: "end_date",
      widget: "DateLabel",
    },
    {
      label: "Leave Type",
      accessor: "leave_type",
      widget: "Text",
    },
    {
      label: "Total Duration",
      accessor: "total_duration",
      widget: "DurationLabel",
    },
    {
      label: "Num. Days",
      accessor: "num_days",
      widget: "Text",
    },
  ];

  const handleDateChange = (accessor, dateTime) => {
    const dateObj = new Date(dateTime);
    if (isNaN(dateObj.getTime())) {
      return;
    }
    setVm({
      ...vm,
      [accessor]: dateTime,
    });
  };

  const downloadPrintPreview = () => {
    const downloadLink = document.createElement("a");
    const fileName = "LeaveRequests.pdf";
    downloadLink.href = "data:application/pdf;base64," + pdfFileData;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // Render the component

  if (
    !activeLeaveRequestEmployees ||
    activeLeaveRequestEmployees.length === 0
  ) {
    return (
      <Container className="my-3 overflow-hidden" fluid>
        <Card>
          <CardBody>
            <Row className=" my-4">
              <Col md="3">
                <BackButton />
              </Col>
              <Col>
                <h1 className="days-one">Manage Leave Requests</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="text-left">
                  No employees are enabled for leave requests. You can enable
                  and manage online employee portal settings for your staff
                  under{" "}
                  <strong>
                    {" "}
                    Employees {">>"} Details {">>"} Online Portal
                  </strong>
                  . For this screen to unlock you need to have at least one
                  portal-active employee with leave requests allowed.
                </h3>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="my-3 overflow-hidden" fluid>
      <Card>
        <CardBody>
          {!loading && vm.selected_leave_type !== undefined ? (
            <>
              {!currentSelected ? (
                <>
                  <Row className=" my-4">
                    <Col md="3">
                      <BackButton />
                    </Col>
                    <Col>
                      <h1 className="days-one">Manage Leave Requests</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="7">
                      <DateRangeEdit
                        startDateProps={{
                          label: "Start Date",
                          value: vm.start_date,
                          onChange: (time) =>
                            handleDateChange("start_date", time),
                        }}
                        endDateProps={{
                          label: "End Date",
                          value: vm.end_date,
                          onChange: (time) =>
                            handleDateChange("end_date", time),
                        }}
                      />
                    </Col>

                    <Col md="5">
                      <ComboBox
                        className="mx-3"
                        label="Leave Type Filter"
                        stretch={true}
                        name="selected_leave_type"
                        comboData={vm.leave_type_dict_with_all}
                        selectedComboItem={vm.selected_leave_type}
                        setSelectedComboItem={(data) => {
                          setVm({
                            ...vm,
                            selected_leave_type: adminContext.getKeyByValue(
                              vm.leave_type_dict_with_all,
                              data,
                              true
                            ),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <ComboBox
                        className="mx-3"
                        label="Employee Filter"
                        stretch={true}
                        name="selected_employee"
                        comboData={vm.employees_dict_with_all}
                        selectedComboItem={vm.selected_employee}
                        setSelectedComboItem={(data) => {
                          setVm({
                            ...vm,
                            selected_employee: adminContext.getKeyByValue(
                              vm.employees_dict_with_all,
                              data,
                              true
                            ),
                          });
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <ComboBox
                        className="mx-3"
                        label="Status Filter"
                        stretch={true}
                        name="selected_status"
                        comboData={vm.statuses_dict_with_all}
                        selectedComboItem={vm.selected_status}
                        setSelectedComboItem={(data) => {
                          setVm({
                            ...vm,
                            selected_status: adminContext.getKeyByValue(
                              vm.statuses_dict_with_all,
                              data,
                              true
                            ),
                          });
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <ComboBox
                        className="mx-3"
                        label="Sort By"
                        stretch={true}
                        name="sort_by"
                        comboData={vm.sort_by_dict}
                        selectedComboItem={vm.sort_by}
                        setSelectedComboItem={(data) => {
                          setVm({
                            ...vm,
                            sort_by: adminContext.getKeyByValue(
                              vm.sort_by_dict,
                              data,
                              true
                            ),
                          });
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <ComboBox
                        className="mx-3"
                        label="Sort Direction"
                        stretch={true}
                        name="sort_direction"
                        comboData={vm.sort_by_direction_dict}
                        selectedComboItem={vm.sort_by_direction}
                        setSelectedComboItem={(data) => {
                          setVm({
                            ...vm,
                            sort_by_direction: adminContext.getKeyByValue(
                              vm.sort_by_direction_dict,
                              data,
                              true
                            ),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs="auto">Status Legend:</Col>
                    <Col>
                      {/* Use span with badge classes for each status, you might need to add custom className for exact color matching */}
                      <span className="badge badge-primary bg-primary-light mx-1">
                        {adminContext.constants.LEAVE_REQUEST_STATUSES.PENDING}
                      </span>
                      <span className="badge badge-success bg-success-light mx-1">
                        {adminContext.constants.LEAVE_REQUEST_STATUSES.APPROVED}
                      </span>
                      <span className="badge badge-danger bg-danger-light mx-1">
                        {adminContext.constants.LEAVE_REQUEST_STATUSES.DENIED}
                      </span>
                      <span className="badge badge-warning bg-warning-light mx-1">
                        {
                          adminContext.constants.LEAVE_REQUEST_STATUSES
                            .CANCELLED
                        }
                      </span>
                    </Col>
                  </Row>
                </>
              ) : null}
              <hr />

              <Row className="d-flex align-items-end">
                <Col md="2">
                  <Button
                    className=" my-3 w-100"
                    color="primary"
                    onClick={() =>
                      adminContext.history.push("/admin/pays/leave-request")
                    }
                  >
                    <i className="fa-regular fa-plus"></i> New Leave Request
                  </Button>
                </Col>
                <Col md="2">
                  <Button
                    className=" my-3 w-100"
                    color="secondary"
                    onClick={() =>
                      adminContext.history.push("/admin/reports/leave_calendar")
                    }
                  >
                    <i className="fa-regular fa-calendar"></i> Calendar
                  </Button>
                </Col>
                <Col md="2">
                  <Button
                    className=" my-3 w-100"
                    color="secondary"
                    onClick={downloadPrintPreview}
                  >
                    <i className="fa-regular fa-print"></i> Print/Preview
                  </Button>
                </Col>
                <Col md="3">
                  <FontPicker
                    fonts={vm.fonts}
                    fontFamily={vm.preview_font_family || "Arial"}
                    setFontFamily={(fontFamily) =>
                      setVm({ ...vm, preview_font_family: fontFamily })
                    }
                  />
                </Col>
                <Col md="1">
                  <ComboBox
                    className="mx-3"
                    label="Font Size"
                    stretch={true}
                    name="font_size"
                    selectedComboItem={vm.preview_font_size || 8}
                    setSelectedComboItem={(data) =>
                      setVm({ ...vm, preview_font_size: data })
                    }
                    comboData={adminContext.fontSizes()}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TableWidget
                    title=""
                    rows={vm.leave_request_rows}
                    columns={leaveRequestColumns}
                    setSelected={setSelectedRows}
                    currentSelected={currentSelected}
                    setCurrentSelected={setCurrentSelected}
                    selected={selectedRows}
                    rowStyleCallback={(row) => {
                      if (row.status === "Pending") {
                        return "bg-primary-light";
                      } else if (
                        row.status ===
                        adminContext.constants.LEAVE_REQUEST_STATUSES.APPROVED
                      ) {
                        return "bg-success-light";
                      } else if (row.status === "Denied") {
                        return "bg-danger-light";
                      } else if (row.status === "Cancelled") {
                        return "bg-warning-light";
                      }
                    }}
                    formSpec={{
                      title: "Leave Request",
                      fields: [
                        {
                          label: "Employee",
                          accessor: "employee_name",
                          widget: "Label",
                        },

                        {
                          label: "Total Duration",
                          accessor: "total_duration",
                          widget: "DurationLabel",
                          valueCallback: (row) => {
                            return row.leave_request_days.reduce(
                              (acc, lr) => acc + lr.duration.milliseconds,
                              0
                            );
                          },
                        },
                        {
                          label: "",
                          accessor: "public_holidays_notification",
                          widget: "Label",
                          args: {
                            visibleCallback: (row) => {
                              return row.public_holidays_notification !== "";
                            },
                          },
                        },
                        {
                          label: "Status",
                          accessor: "status",
                          widget: "ComboBox",
                          args: {
                            comboDataCallback: () => {
                              return vm.statuses_dict;
                            },
                          },
                        },
                        {
                          label: "Include Program-Generated Notes?",
                          accessor: "show_program_leave_request_notes",
                          widget: "CheckBox",
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default LeaveRequestsView;
