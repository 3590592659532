import { useContext, useState, useEffect } from "react";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import TableWidget from "../../components/Widgets/TableWidget.js";

import { Container, Card, CardBody, Row, Col, Button, Alert } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { AvField } from "availity-reactstrap-validation";
import BackButton from "../../components/Widgets/BackButton";

const CreateSuperFundOnlineSearch = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [usi, setUsi] = useState("");
  const [fund, setFund] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const searchByUSI = (e) => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/settings/super-fund/search?usi=${usi}`,
      (response) => {
        setIsLoading(false);
        if (response.data.funds_information.length > 0) {
          setFund(response.data.funds_information[0]);
        } else if (response.data.message != "") {
          setErrorMessage(response.data.message);
        }

        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    );
  };

  const saveFund = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/settings/super-fund`,
      fund,
      (response) => {
        setIsLoading(false);
        adminContext.history.goBack();
      }
    );
  };

  const usiAlreadyExists = (usi) => {
    let exists = false;
    adminContext.superFundProviders.forEach((provider) => {
      if (provider.usi == usi) {
        exists = true;
      }
    });
    return exists;
  };

  const notContributionReady = () => {
    let flag = "CONT";
    return !fund.conformance.includes(flag);
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">
                    Create Super Fund (Online Search)
                  </h1>
                </Col>
              </Row>
              {errorMessage ? (
                <Row>
                  <Col>
                    <Alert color="danger">{errorMessage}</Alert>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col>
                  <AvForm onValidSubmit={searchByUSI}>
                    <AvField
                      key="usi"
                      name="usi"
                      label="USI / Unique Superannuation Identifier (Case Sensitive)"
                      type="text"
                      disabled={fund}
                      value={usi}
                      onChange={(e) => setUsi(e.target.value)}
                    />
                    <BackButton className="btn-lg my-2 semi-active-button" />
                    <Button
                      color="primary"
                      className="btn-lg my-2 semi-active-button"
                    >
                      Search
                    </Button>
                  </AvForm>
                </Col>
              </Row>
              {fund && usi ? (
                <>
                  <Row>
                    <Col>
                      <h2 className="mt-3">Search Results for USI: {usi}</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TableWidget
                        columns={[
                          {
                            label: "Name",
                            accessor: "name",
                            widget: "Text",
                          },
                          {
                            label: "USI",
                            accessor: "usi",
                            widget: "Text",
                          },
                          {
                            label: "ABN",
                            accessor: "abn",
                            widget: "Text",
                          },
                          {
                            label: "Account Name",
                            accessor: "account_name",
                            widget: "Text",
                          },
                          {
                            label: "Account Number",
                            accessor: "account_number",
                            widget: "Text",
                          },
                          {
                            label: "Phone",
                            accessor: "phone",
                            widget: "Text",
                          },
                          {
                            label: "Start Date",
                            accessor: "start_date",
                            widget: "DateLabel",
                          },
                          {
                            label: "End Date",
                            accessor: "end_date",
                            widget: "DateLabel",
                          },
                        ]}
                        rows={[fund]}
                      />
                    </Col>
                  </Row>
                  {usiAlreadyExists(usi) ? (
                    <Row>
                      <Col>
                        <Alert color="danger">
                          A super fund with this USI already exists in the
                          system.
                        </Alert>
                      </Col>
                    </Row>
                  ) : notContributionReady() ? (
                    <Row>
                      <Col>
                        <Alert color="danger">
                          This fund is not contribution ready, meaning it cannot
                          receive Superstream contribution messages.
                          <br />
                          Please contact the fund on {fund.phone} to find out
                          how to best make contributions to them.
                        </Alert>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <>
                          <Button color="primary" onClick={saveFund}>
                            Save New Fund
                          </Button>
                          <p className="my-2">
                            After saving this new super fund you will then be
                            able to connect it to an employee from{" "}
                            <strong>
                              Employees {">>"}Super {">>"} Superannuation Fund
                            </strong>
                            .
                          </p>
                        </>
                      </Col>
                    </Row>
                  )}
                </>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default CreateSuperFundOnlineSearch;
