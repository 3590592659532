import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import AvForm from "availity-reactstrap-validation/lib/AvForm";

const JobKeeperHistory = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [jobKeeperHistory, setJobKeeperHistory] = useState([]);

  const getJobKeeperHistory = () => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/employees/${adminContext.currentEmployee.id}/jobkeeper-history`,
      (response) => {
        setIsLoading(false);
        setJobKeeperHistory(response.data.jobkeeper_history);
      }
    );
  };

  useEffect(() => {
    getJobKeeperHistory();
  }, []);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <AvForm>
              <Row>
                <Col>
                  <h1 className="days-one">
                    JobKeeper/JobMaker Submission History
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div key={"tfnd_signed_date"} className="my-4">
                    <TableWidget
                      rows={jobKeeperHistory ? jobKeeperHistory : []}
                      columns={[
                        {
                          label: "Active JobKeeper/JobMaker STP Codes",
                          accessor: "code",
                          widget: "Text",
                        },
                        {
                          label: "Date Reported",
                          accessor: "date_reported",
                          widget: "DateLabel",
                        },
                        {
                          label: "Pay Date",
                          accessor: "pay_date",
                          widget: "DateLabel",
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </AvForm>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default JobKeeperHistory;
