import { useState, useEffect, useContext } from "react";
import { Drawer, IconButton } from "@mui/material";
import { Row, Col, Card, CardBody, Modal, Button } from "reactstrap";
import MailIcon from "@mui/icons-material/Mail";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EggIcon from "@mui/icons-material/Egg";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import HelpIcon from "@mui/icons-material/Help";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import TableWidget from "../../components/Widgets/TableWidget";
import EditForm from "../../components/Widgets/EditForm";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";
import AdminContext from "../../AdminContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function SuperFundDeposits() {
  const adminContext = useContext(AdminContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [helpOpen, setHelpOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const reportsOpen = Boolean(anchorEl && anchorEl.id === "reports-button");

  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });

  const [selectedRows, setSelectedRows] = useState({
    employees: adminContext.company.employees,
  });

  const [form, setForm] = useState({
    super_fund_deposits: [],
    company_id: adminContext.company.id,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleHelp = () => {
    setHelpOpen(!helpOpen);
  };

  const deleteDeposit = (idToDelete) => {
    // Send a delete request to the server to delete the deposit
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL + `/super-fund-deposits/${idToDelete}`,
      (response) => {
        // Remove the deposit from the form
        setForm({
          ...form,
          super_fund_deposits: form.super_fund_deposits.filter(
            (deposit) => deposit.id !== idToDelete
          ),
        });
      }
    );
  };

  useEffect(() => {
    const init = () => {
      adminContext.getRequest(
        adminContext.constants.BASE_URL +
          `/super-fund-deposits/init?company_id=${adminContext.company.id}`,
        (response) => {
          if (response.data.form) {
            const newForm = {
              ...form,
              start_date: response.data.form.start_date,
              end_date: response.data.form.end_date,
              selected_employees: response.data.form.selected_employees,
              super_fund_deposits:
                Array.isArray(adminContext.newSuperFundDeposits) &&
                adminContext.newSuperFundDeposits.length > 0
                  ? adminContext.newSuperFundDeposits
                  : response.data.form.super_fund_deposits,
              search_mode: response.data.form.search_mode,
            };
            setForm(newForm);

            setSelectedRows({
              employees: response.data.form.selected_employees,
            });
          }
          setIsLoading(false);
        }
      );
    };

    if (
      adminContext.newSuperFundDeposits &&
      adminContext.newSuperFundDeposits.length > 0
    ) {
      adminContext.history.push(
        "/admin/super_fund_deposits/superstream_contribution"
      );
    } else {
      init();
    }
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      selected_employees: selectedRows.employees,
    });
  }, [selectedRows]);

  const searchForDeposits = () => {
    toggleDrawer();
    setIsLoading(true);

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/super-fund-deposits/search`,
      form,
      (response) => {
        if (response.data.super_fund_deposits) {
          setForm({
            ...form,
            super_fund_deposits: response.data.super_fund_deposits,
          });
        }
        setIsLoading(false);
      }
    );
  };

  // useEffect(() => {
  //   searchForDeposits();
  // }, [form]);

  const columns = [
    {
      label: "Edit",
      accessor: "edit",
      widget: "Link",
      args: {
        label: "",
        icon: "fa-regular fa-edit fa-xl",
        href: "/admin/super_fund_deposits/edit_deposit",
        color: "secondary",
        className: "btn btn-lg btn-secondary",
      },
    },
    {
      label: "Employee Last Name",
      accessor: "employee_last_name",
      widget: "Text",
    },
    {
      label: "Employee First Name",
      accessor: "employee_first_name",
      widget: "Text",
    },
    {
      label: "Status",
      accessor: "status",
      widget: "Text",
      titleCase: true,
      cellStyleCallback: (row) => {
        return row.status === "PAID" ? "font-weight-bold" : "font-italic";
      },
    },
    {
      label: "Change Status",
      accessor: "",
      align: "center",
      widget: "Button",
      args: {
        buttonIcon: "fa-regular fa-dollar-sign fa-lg",
        labelCallback: (row) => {
          return row.status === "PAID" ? "Mark Unpaid" : "Mark Paid";
        },
        colorCallback: (row) => {
          return row.status === "UNPAID" ? "info" : "secondary";
        },
        onClickCallback: (row) => {
          const newStatus = row.status === "PAID" ? "UNPAID" : "PAID";
          adminContext.postRequest(
            adminContext.constants.BASE_URL +
              `/super-fund-deposits/${row.id}/update-payment-status`,
            { status: newStatus },
            (response) => {
              const updatedRows = form.super_fund_deposits.map((deposit) => {
                if (deposit.id === row.id) {
                  return {
                    ...deposit,
                    status: newStatus,
                  };
                }
                return deposit;
              });

              setForm({
                ...form,
                super_fund_deposits: updatedRows,
              });
            }
          );
        },
        color: "info",
        className: "",
      },
    },
    {
      label: "In Message?",
      accessor: "attached_to_message",
      widget: "BooleanLabel",
    },
    {
      label: "Deposit Date",
      accessor: "paid_on",
      widget: "DateLabel",
    },
    {
      label: "Pays From",
      accessor: "pay_period_start_date",
      widget: "DateLabel",
    },
    {
      label: "Pays Until",
      accessor: "pay_period_end_date",
      widget: "DateLabel",
    },
    {
      label: "Total Amount",
      accessor: "total_amount",
      widget: "MoneyLabel",
    },
    {
      label: "Super Levy",
      accessor: "guarantee",
      widget: "MoneyLabel",
    },
    {
      label: "Member Contribution",
      accessor: "member_contribution",
      widget: "MoneyLabel",
    },
    {
      label: "Salary Sacrifice",
      accessor: "salary_sacrifice",
      widget: "MoneyLabel",
    },
    {
      label: "RESC (non-sacrifice, excess SG)",
      accessor: "resc",
      widget: "MoneyLabel",
    },
  ];

  const formSpec = {
    title: "Search Existing Super Deposits",
    fixedTitle: true,
    fields: [
      {
        label: "",
        accessor: "search_button",
        widget: "Button",
        args: {
          onClick: searchForDeposits,
          disabled: isLoading || !selectedRows.employees.length,
          text: "Search",
          color: "success",
          className: "my-1 mx-2 width-80-on-lg",
          icon: "fa-regular fa-search",
        },
      },
      {
        label: "",
        accessor: "close_button",
        widget: "Button",
        args: {
          onClick: toggleDrawer,
          text: "Close",
          color: "secondary",
          className: "my-1 mx-2 width-80-on-lg",
          icon: "fa-regular fa-arrow-left",
        },
      },

      {
        label: "Start Date",
        accessor: "start_date",
        widget: "DateEdit",
        required: true,
        delay: 10,
      },
      {
        label: "End Date",
        accessor: "end_date",
        widget: "DateEdit",
        required: true,
        delay: 10,
      },
      {
        label: "Search deposits by date, using:",
        widget: "GroupBox",
        accessor: "search_mode",
        args: {
          className: "heavy-font",
          children: [
            {
              label:
                "Deposit date (the date the funds were deposited into the fund)",
              accessor: "PAID",
            },
            {
              label: "Pay run end date",
              accessor: "EARNED",
            },
          ],
        },
      },
      {
        label: "Employees",
        accessor: "employees",
        widget: "EmployeeListWidget",
        args: {
          rows: rows,
          setRows: setRows,
          selectedRows: selectedRows,
          setSelectedRows: setSelectedRows,
        },
      },
    ],
  };

  return (
    <Card className="bg-secondary shadow ml-2 mr-3 ">
      {isLoading ? (
        <LoadingSpinner width="40%" height="40%" />
      ) : (
        <div className="width-100">
          <Row>
            <Col>
              <h1 className="days-one m-4">Super Fund Deposits</h1>
            </Col>
          </Row>
          <Row className="mx-2">
            <Col>
              <FormControlLabel
                label="Show search filter?"
                control={
                  <CheckBox
                    checked={drawerOpen}
                    color="primary"
                    id="mastercheck"
                    onChange={() => {
                      toggleDrawer();
                    }}
                  />
                }
              />
            </Col>
          </Row>
          <Card className="m-2 mr-3" style={{ minHeight: "100vh" }}>
            <CardBody>
              <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={toggleDrawer}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <EditForm
                  liveEdit={true}
                  modelObj={form}
                  setModelObj={setForm}
                  formSpec={formSpec}
                />
              </Drawer>
              <Row>
                <Col md="auto" className="my-2 px-1">
                  <IconButton
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/super_fund_deposits/create_deposits"
                      )
                    }
                    className="custom-icon-button semi-active-button btn-icon-clipboard"
                    color="primary"
                    size="large"
                    disabled={!adminContext.editAccess("super_fund_deposits")}
                  >
                    <GroupAddIcon fontSize="large" />
                    <p className="text-black days-one">Create Deposits</p>
                  </IconButton>
                </Col>
                <Col md="auto" className="my-2 px-1">
                  <IconButton
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/super_fund_deposits/create_blank_deposit"
                      )
                    }
                    disabled={!adminContext.editAccess("super_fund_deposits")}
                    className="custom-icon-button semi-active-button btn-icon-clipboard"
                    color="primary"
                    size="large"
                  >
                    <AddCircleIcon className="text-success" fontSize="large" />
                    <p className="text-black days-one">Create Blank Deposit</p>
                  </IconButton>
                </Col>
                <Col md="auto" className="my-2 px-1">
                  <IconButton
                    onClick={toggleHelp}
                    className="custom-icon-button semi-active-button btn-icon-clipboard"
                    color="primary"
                    size="large"
                  >
                    <HelpIcon className="text-info" fontSize="large" />
                    <p className="text-black days-one">Help</p>
                  </IconButton>
                </Col>
                <Col md="auto" className="my-2 px-1">
                  <IconButton
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/superstream/superstream_mailbox"
                      )
                    }
                    className="custom-icon-button semi-active-button btn-icon-clipboard"
                    color="primary"
                    size="large"
                  >
                    <MailIcon fontSize="large" />
                    <p className="text-black days-one">SuperStream Mailbox</p>
                  </IconButton>
                </Col>
                <Col md="auto" className="my-2 px-1">
                  <IconButton
                    onClick={() =>
                      adminContext.history.push(
                        "/admin/super_fund_deposits/superstream_contribution"
                      )
                    }
                    disabled={!adminContext.editAccess("super_fund_deposits")}
                    className="custom-icon-button semi-active-button btn-icon-clipboard"
                    color="primary"
                    size="large"
                  >
                    <EggIcon className="text-yellow" fontSize="large" />
                    <p className="text-black days-one">
                      SuperStream Contribution
                    </p>
                  </IconButton>
                </Col>
                <Col md="auto" className="my-2 px-1">
                  <IconButton
                    className="custom-icon-button semi-active-button btn-icon-clipboard"
                    id="reports-button"
                    aria-controls={reportsOpen ? "reports-menu" : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    aria-expanded={reportsOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <AnalyticsIcon className="text-danger" fontSize="large" />
                    <p className="text-black days-one">Reports</p>
                  </IconButton>
                  <Menu
                    id="reports-menu"
                    aria-labelledby="reports-button"
                    anchorEl={anchorEl}
                    open={reportsOpen}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-building-columns" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/reports/super_deposits";
                          adminContext.history.push(url);
                        }}
                      >
                        Super Fund Deposits
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <i className=" mx-auto fa-regular fa-xl fa-money-bill-transfer" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => {
                          let url = "/admin/reports/unpaid_super";
                          adminContext.history.push(url);
                        }}
                      >
                        Super Liability
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3 mb-0">
                  Please note: A deposit cannot be deleted from the table below
                  if it is marked as 'In Message'. This means the deposit has
                  already been sent in a SuperStream message. If you need to
                  resend any deposits you must first mark the associated message
                  as 'Manually Failed' within the SuperStream Mailbox.
                </Col>
              </Row>

              <div className="width-100" style={{ padding: "1rem" }}>
                <fieldset
                  disabled={!adminContext.editAccess("super_fund_deposits")}
                >
                  <TableWidget
                    autoMinWidth={true}
                    total={true}
                    order="Descending"
                    orderBy="paid_on"
                    rows={form.super_fund_deposits}
                    setRows={(rows) => {
                      setForm({
                        ...form,
                        super_fund_deposits: rows,
                      });
                    }}
                    columns={columns}
                    deleteRowCallback={deleteDeposit}
                    deleteRowCallbackEnabled={(row) => {
                      return row.attached_to_message === false;
                    }}
                    deleteConfirmationAttributes={[
                      "employee_first_name",
                      "employee_last_name",
                      "paid_on",
                      "total_amount",
                    ]}
                  />
                </fieldset>
              </div>
            </CardBody>
          </Card>
          <Modal isOpen={helpOpen} centered={true}>
            <div className="mx-3 my-4">
              <h3>Super Fund Deposits Overview</h3>
              <ul>
                <li>
                  The main <strong>Super Fund Deposits</strong> screen allows
                  you to browse, edit and create super deposits made to your
                  employees' super funds.
                </li>
                <li>
                  Creating deposits, sending of SuperStream messages and payment
                  of super should all occur on the same day.
                </li>
                <li>
                  If you cannot create a deposit for a certain date range it
                  means it already exists. See <strong>Search Filter</strong>{" "}
                  below to learn how to find it.{" "}
                  <strong>SuperStream Contribution </strong>
                  <br />
                  is where you can send already-created deposits through
                  SuperStream.
                </li>
              </ul>
              <h3>Buttons</h3>
              <ul>
                <li>
                  Click <strong>Create Deposits</strong> to begin the normal
                  super process, by collecting super from pays and preparing
                  them to be sent through SuperStream.
                </li>
                <li>
                  Click <strong>Create Blank Deposit</strong> to make a
                  miscellaneous super deposit item.
                </li>
                <li>
                  Click <strong>SuperStream Mailbox</strong> to view all super
                  message history sent to your employees' funds.
                </li>
                <li>
                  Click <strong>SuperStream Contribution</strong> to send
                  deposit information to super fund providers. The{" "}
                  <strong>Create Deposits</strong> process naturally leads into
                  this area, so it isn't usually necessary.
                </li>
              </ul>
              <h3>Table Columns</h3>
              <ul>
                <li>
                  <strong>Status</strong> lets you record whether a deposit has
                  been paid to its fund or not.
                </li>
                <li>
                  <strong>In Message?</strong> lets you know whether a deposit
                  has been sent through SuperStream. If you need to resend a
                  deposit which is <strong>In Message</strong> for whatever
                  reason it must be
                  <br /> released (marked as failed) from the original message
                  under{" "}
                  <strong>
                    SuperStream Mailbox {">>"} View (beside the original
                    message) {">>"}
                    Manually Fail
                  </strong>
                  .
                </li>
                <li>
                  <strong>Pays From</strong> is the start date of the earliest
                  pay contained within the deposit.
                </li>
                <li>
                  <strong>Pays Until</strong> is the end date of the latest pay
                  contained within the deposit.
                </li>
                <li>
                  <strong>Deposit Date</strong> allows you to record the date of
                  payment to the super fund.
                </li>
                <li>
                  <strong>Total Amount</strong> is the sum total of all super
                  items (SGC, RESC, etc.) contained within that deposit
                </li>
              </ul>
              <h3>Search Filter</h3>
              <ul>
                <li>
                  To search through existing deposits, tick the{" "}
                  <strong>Show Search Filter?</strong> box at the top of the
                  screen.
                </li>
                <li>
                  Select <strong>Deposit Date</strong> to show deposits by the
                  date of payment made to the super fund.
                </li>
                <li>
                  Select <strong>Pay Period End Date</strong> to show deposits
                  by the date of pays included.
                </li>
              </ul>
              <Button
                className="semi-active-button"
                color="secondary"
                onClick={toggleHelp}
              >
                Close
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </Card>
  );
}

export default SuperFundDeposits;
