import React, { useState, useContext, useEffect } from "react";
import EditForm from "../../../components/Widgets/EditForm";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const SuperDepositSelectView = ({
  wizardInfo,
  setWizardInfo,
  rows,
  setRows,
  selectedRows,
  setSelectedRows,
  setNextEnabled,
  loading,
  setLoading,
  setStep,
}) => {
  const adminContext = useContext(AdminContext);
  const [saving, setSaving] = useState(false);

  // Skip over the selection screen if adminContext.newSuperFundDeposits.length > 0
  useEffect(() => {
    if (adminContext.newSuperFundDeposits.length > 0) {
      setSaving(true);
    }
  }, []);

  const searchDeposits = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/superstream-contribution?company_id=${adminContext.company.id}`,
      {
        wizard_info: wizardInfo,
        new_deposits: adminContext.newSuperFundDeposits,
      },

      (response) => {
        if (response.data.wizard_info) {
          // Only update state if the new data is different from the current state
          if (
            JSON.stringify(response.data.wizard_info) !==
            JSON.stringify(wizardInfo)
          ) {
            setWizardInfo(response.data.wizard_info);
          }
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (saving) {
      setSaving(false);
      searchDeposits();
    }
  }, [saving]);

  useEffect(() => {
    if (
      wizardInfo.selected_deposits &&
      wizardInfo.selected_deposits.length > 0 &&
      adminContext.newSuperFundDeposits.length > 0
    ) {
      setStep(1);
    }
  }, [wizardInfo]);

  useEffect(() => {
    searchDeposits();
  }, [
    wizardInfo.include_paid_deposits,
    wizardInfo.search_mode,
    wizardInfo.min_date,
    wizardInfo.max_date,
  ]);

  // Update nextEnabled when the selectedRows changes
  useEffect(() => {
    if (selectedRows.deposits.length > 0) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
  }, [wizardInfo.selected_deposits]);

  // Update the depositRows when the wizardInfo deposits changes
  useEffect(() => {
    setRows({
      ...rows,
      deposits: wizardInfo.deposits,
    });
  }, [wizardInfo.deposits]);

  return (
    <>
      {loading || !wizardInfo.search_mode ? (
        <LoadingSpinner />
      ) : (
        <EditForm
          liveEdit={true}
          modelObj={wizardInfo}
          setModelObj={setWizardInfo}
          formSpec={{
            shortDescription:
              "Please select the super deposits you wish to pay. Zero dollar (or negative) deposits can not be added to a SuperStream Contribution and are listed in red.",
            fixedTitle: true,
            title: "Super Deposit Selection",
            fields: [
              {
                label: "Search deposits by date, using:",
                widget: "GroupBox",
                accessor: "search_mode",
                args: {
                  className: "heavy-font",
                  children: [
                    {
                      label:
                        "Deposit date (the date the funds were deposited into the fund)",
                      accessor: "PAID",
                    },
                    {
                      label: "Pay run end date",
                      accessor: "EARNED",
                    },
                  ],
                },
              },
              {
                label: "Start Date",
                accessor: "min_date",
                widget: "DateEdit",
              },
              {
                label: "End Date",
                accessor: "max_date",
                widget: "DateEdit",
              },
              {
                label: "Include Paid Deposits",
                accessor: "include_paid_deposits",
                widget: "CheckBox",
              },
              {
                label: "Deposits List",
                accessor: "deposits",
                widget: "ListWidget",
                args: {
                  rows: rows,
                  setRows: setRows,
                  selectedRows: selectedRows,
                  setSelectedRows: setSelectedRows,
                  rowStyleCallback: (row) => {
                    if (row.total_amount._decimal <= 0) {
                      return "text-danger";
                    } else {
                      return "";
                    }
                  },
                },
              },
            ],
          }}
        />
      )}
    </>
  );
};

export default SuperDepositSelectView;
