import AdminContext from "../../AdminContext";
import { useState, useEffect, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { Card, CardBody } from "reactstrap";

const SendBackupToSupport = () => {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(false);

  const sendBackup = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/tools/backup/support`,
      (response) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    sendBackup();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Card className="bg-secondary mx-3 my-3 shadow border-0">
            <CardBody className="width-100 text-center">
              Your log and backup have been sent to support.
            </CardBody>
          </Card>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default SendBackupToSupport;
