import { useEffect, useState, useContext } from "react";
import TableWidget from "../../components/Widgets/TableWidget";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import { Container, Card, CardBody, Row, Col, Alert } from "reactstrap";

const Banks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [banks, setBanks] = useState([]);
  const adminContext = useContext(AdminContext);

  const saveChanges = () => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/settings/banks`,
      banks,
      (response) => {
        if (response.data.banks) {
          setBanks(response.data.banks);
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      setSaving(false);
      saveChanges();
    }
  }, [banks]);

  useEffect(() => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/settings/banks`,
      (response) => {
        setIsLoading(false);
        setBanks(response.data.banks);
      }
    );
  }, []);

  const updateBanks = (bank) => {
    setSaving(true);
    var newRows = [];
    if (bank.id === null) {
      newRows = [...banks, bank];
    } else {
      newRows = banks.map((row) => {
        if (Number(row.id) === Number(bank.id)) {
          return bank;
        } else {
          return row;
        }
      });
    }
    setBanks(newRows);
  };

  const deleteBank = (idToDelete) => {
    setSaving(true);
    setBanks(banks.filter((bank) => Number(bank.id) !== Number(idToDelete)));
  };

  const columns = [
    {
      label: "Name",
      accessor: "name",
      widget: "Text",
    },
    {
      label: "Code",
      accessor: "code",
      widget: "Text",
    },
  ];

  const formSpec = {
    title: "Banks",
    fields: [
      {
        label: "Name",
        accessor: "name",
        widget: "Text",
        required: true,
        validate: {
          required: { value: true, message: "Name is required" },
          maxLength: {
            value: 50,
            message: "Name must be less than 50 characters.",
          },
        },
      },
      {
        label: "Code",
        accessor: "code",
        widget: "Text",
        required: true,
        validate: {
          required: { value: true, message: "Code is required" },
          pattern: {
            value: "^[A-Z]{3}$",
            errorMessage: "Code must be three upper case characters.",
          },
        },
      },
    ],
  };

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Banks</h1>
                  </Col>
                </Row>
                {!saving ? (
                  <>
                    <Row>
                      <Col>
                        <>
                          <TableWidget
                            rows={banks}
                            columns={columns}
                            editRowSaveCallback={updateBanks}
                            deleteRowCallback={deleteBank}
                            deleteConfirmationAttributes={["name", "code"]}
                            formSpec={formSpec}
                            appendRowCallback={() => {
                              return {
                                id: null,
                                name: "",
                                code: "",
                              };
                            }}
                          />
                        </>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default Banks;
