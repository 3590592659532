const validateTFN = (taxFileNumber) => {
  if (taxFileNumber === undefined) {
    return false;
  }
  if (taxFileNumber === "") {
    return true;
  }

  // If tfn is a special case, return true.

  if (
    taxFileNumber === "000000000" ||
    taxFileNumber === "111111111" ||
    taxFileNumber === "333333333" ||
    taxFileNumber === "444444444"
  ) {
    return true;
  }

  let tfn = taxFileNumber;
  //remove spaces and update
  tfn = tfn.replace(/\s+/g, "");

  //remove hyphens and update
  tfn = tfn.replace(/[-]/g, "");

  //validate only digits
  var isNumber = /^[0-9]+$/.test(tfn);
  if (!isNumber) {
    return false;
  }

  //validate length
  var length = tfn.length;
  if (length != 9) {
    return false;
  }

  var digits = tfn.split("");

  //do the calcs
  var sum =
    digits[0] * 1 +
    digits[1] * 4 +
    digits[2] * 3 +
    digits[3] * 7 +
    digits[4] * 5 +
    digits[5] * 8 +
    digits[6] * 6 +
    digits[7] * 9 +
    digits[8] * 10;

  var remainder = sum % 11;

  if (remainder == 0) {
    return true;
  } else {
    return false;
  }
};

export default validateTFN;
