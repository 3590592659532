import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import { useEffect, useContext, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";
import AdminContext from "../../../AdminContext";

const SuperStreamDetailsView = ({
  wizardInfo,
  setWizardInfo,
  loading,
  setLoading,
  setNextEnabled,
  setButtonsVisible,
  buttonsVisible,
}) => {
  const adminContext = useContext(AdminContext);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const init = () => {
    setNextEnabled(true);
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/superstream-details?company_id=${adminContext.company.id}`,
      wizardInfo,
      (response) => {
        setWizardInfo(response.data.wizard_info);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      wizardInfo.total_payable_amount &&
      wizardInfo.total_payable_amount._decimal <= 0
    ) {
      setNextEnabled(false);
    } else {
      setNextEnabled(true);
    }
  }, [wizardInfo]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-left">
            <h1 className="text-center days-one">Message Details</h1>
            {!selectedMessage && (
              <>
                {" "}
                <p>
                  Set the details for the individual messages for each Super
                  Fund.
                </p>
                <p>
                  Note: A single message is sent for each Super Fund that is
                  found for all the deposits.
                </p>
                <Row>
                  <Col>
                    Total Amount:{" "}
                    <MoneyLabel amount={wizardInfo.total_payable_amount} />
                  </Col>
                </Row>
              </>
            )}

            <TableWidget
              label=""
              setSelected={(newlySelected) => {
                setSelectedMessage(newlySelected);
                setButtonsVisible(!buttonsVisible);
              }}
              editRowSaveCallback={(superFund) => {
                // Loop through wizardInfo.contributions and update the aba_reference
                // for the super fund.
                const contributions = wizardInfo.contributions.map(
                  (contribution) => {
                    if (contribution.provider.id === superFund.provider.id) {
                      contribution.payment_reference =
                        superFund.payment_reference;
                    }
                    return contribution;
                  }
                );
                setWizardInfo({
                  ...wizardInfo,
                  contributions: contributions,
                });
              }}
              formSpec={{
                title: "Super Fund Provider",
                fields: [
                  {
                    label: "Super Fund",
                    accessor: "provider_name",
                    widget: "Label",
                  },
                  {
                    label: "ABA Transaction Reference",
                    accessor: "payment_reference",
                  },
                ],
              }}
              rows={wizardInfo.contributions || []}
              columns={[
                {
                  label: "Super Fund",
                  accessor: "provider_name",
                },
                {
                  label: "ABA Transaction Reference",
                  accessor: "payment_reference",
                },
                {
                  label: "Payable Amount",
                  accessor: "total_amount",
                  widget: "MoneyLabel",
                },
              ]}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default SuperStreamDetailsView;
