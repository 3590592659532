import { Container, Row, Col, Button } from "reactstrap";
import Modal from "react-modal";
import { FaWindowClose } from "react-icons/fa";
import { useEffect, useState, useContext, useCallback } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";
import EditForm from "../../../components/Widgets/EditForm.js";
import ComboBox from "../../../components/Widgets/ComboBox.js";
import FontPicker from "../../../components/Widgets/FontPicker.js";

function MonthlyTax(props) {
  const adminContext = useContext(AdminContext);
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [fonts, setFonts] = useState([]);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontSize, setFontSize] = useState(7);
  const [reactPdfDocument, setReactPdfDocument] = useState(null);
  const customStyle = {
    overlay: { zIndex: 1000 },
  };
  const [pdfB64Data, setPdfB64Data] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);

  function openModal() {
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
  }

  const initReport = useCallback(() => {
    // Download the default data for the initial report form
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/reports/init-report?report_type=monthly_tax&company_id=` +
        adminContext.company.id,
      (response) => {
        if (response.data.fonts) {
          setFonts(response.data.fonts);
          if (response.data.font_family) {
            setFontFamily(response.data.font_family);
          }
          if (response.data.font_size) {
            setFontSize(response.data.font_size);
          }
        }
        setForm(response.data);
        setIsLoading(false);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    initReport();
  }, [initReport]);

  const saveChanges = () => {
    setIsLoading(true);
    setPdfB64Data("");
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/reports/generate-report`,
      {
        ...form,
        font_size: fontSize,
        font_family: fontFamily,
      },
      (response) => {
        setPdfB64Data(
          "data:application/pdf;base64," + response.data.pdf_file_data
        );
        setReactPdfDocument(
          <div id="report-preview">
            <iframe
              title="report-preview"
              width="100%"
              height="100%"
              src={"data:application/pdf;base64," + response.data.pdf_file_data}
            />
          </div>
        );
        openModal();
        setIsLoading(false);
      }
    );
  };

  const downloadPDF = async () => {
    const fileName = props.name + ".pdf";
    await adminContext.handlePDFDownload(fileName, pdfB64Data);
  };

  Modal.setAppElement("#root");
  const fontSizes = () => {
    let sizes = {};
    for (let i = 5; i <= 34; i++) {
      sizes[i] = i;
    }
    return sizes;
  };

  return (
    <Container className="mt-4" fluid>
      {!isLoading ? (
        <>
          <Row className="mx-3">
            <Col md="8">
              <h1 className="mb-1 days-one text-center jumbo-text text-dark-blue">
                {props.name.toLowerCase().includes("report")
                  ? props.name
                  : props.name + " Report"}
              </h1>
            </Col>
            <Col>
              <FontPicker
                fonts={fonts}
                fontFamily={fontFamily}
                setFontFamily={setFontFamily}
              />
            </Col>
            <Col>
              <ComboBox
                className="mx-3"
                label="Font Size"
                stretch={true}
                name="font_size"
                selectedComboItem={fontSize}
                setSelectedComboItem={setFontSize}
                comboData={fontSizes()}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditForm
                cancelCallback={adminContext.history.goBack}
                saveChanges={saveChanges}
                liveEdit={true}
                buttonsUpTop={true}
                saveButtonText={
                  pdfB64Data ? "Regenerate Report" : "Generate Report"
                }
                formSpec={{
                  title: "",
                  fields: [
                    {
                      label: "",
                      accessor: "download_pdf",
                      widget: "Button",
                      args: {
                        onClick: downloadPDF,
                        text: "Download PDF",
                        color: "info",
                        className: "my-4 mx-auto",
                        icon: "fa fa-download",
                        visible: pdfB64Data,
                      },
                    },

                    {
                      label: "Start Date",
                      accessor: "start_date",
                      widget: "DateRangeEdit",
                      validate: {
                        required: true,
                      },
                      startLabel: "Start Date",
                      startAccessor: "start_date",
                      endLabel: "End Date",
                      endAccessor: "end_date",
                    },
                    {
                      label: "Use Processed Date?",
                      accessor: "use_processed_date",
                      widget: "Switch",
                      validate: {
                        required: false,
                      },
                    },
                  ],
                }}
                setModelObj={setForm}
                modelObj={form}
                siblings={props.rows} // Used for validation against other rows
                getComboData={props.getComboData}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {pdfB64Data && !adminContext.isNative && (
                <div>
                  <Modal
                    style={customStyle}
                    isOpen={modalIsOpen}
                    contentLabel="Lightning Payroll Report"
                  >
                    <Button
                      color="secondary"
                      onClick={closeModal}
                      className="float-right mb-2"
                    >
                      <FaWindowClose className="mr-3 mb-1" />
                      Close
                    </Button>
                    {reactPdfDocument}
                  </Modal>
                </div>
              )}
            </Col>
            <Col></Col>
          </Row>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
}
export default MonthlyTax;
