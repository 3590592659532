import { useEffect, useState, useContext } from "react";
import AdminContext from "../../../AdminContext";
import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const LeaveRecalcResultsView = ({
  wizardInfo,
  setWizardInfo,
  loading,
  setLoading,
}) => {
  const adminContext = useContext(AdminContext);

  // Format the start_date and end_date for use in the filename.
  const formatFilenameDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const downloadPDF = async () => {
    const fileName = `recalculate_leave_${formatFilenameDate(
      wizardInfo.start_date
    )}_to_${formatFilenameDate(wizardInfo.end_date)}.pdf`;
    await adminContext.handlePDFDownload(fileName, wizardInfo.pdf_data);
  };

  // Initialise the results screen, set up the PDF report and
  // collect values to display in the preview TableWidget.
  const initRecalcResults = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/tools/recalculate_leave/init`,
      wizardInfo,
      (response) => {
        setLoading(false);
        console.log("Response Data: ", response.data);
        setWizardInfo({
          ...wizardInfo,
          pdf_data:
            "data:application/pdf;base64," + response.data.pdf_file_data,
          employee_pay_run_data: response.data.employee_pay_run_data,
        });
        console.log("Wizard Info: ", wizardInfo);
      },
      (error) => {
        console.error("Error generating Leave Recalculation report:", error);
      }
    );
  };

  useEffect(() => {
    initRecalcResults();
  }, []);

  const generateCellStyleCallback = (
    incrementField,
    existingIncrementField
  ) => {
    return (row) => {
      const increment = row[incrementField]?.milliseconds;
      const existingIncrement = row[existingIncrementField]?.milliseconds;

      if (increment !== undefined && existingIncrement !== undefined) {
        if (increment > existingIncrement) {
          return "text-success";
        } else if (increment < existingIncrement) {
          return "text-decrease";
        } else {
          return "";
        }
      }
      return "";
    };
  };

  // Define columns for the TableWidget
  const columns = [
    {
      label: "End Date",
      accessor: "end_date",
      widget: "DateLabel",
    },
    {
      label: "Pay Period Type",
      accessor: "pay_period_type",
      widget: "text",
    },
    {
      label: "Employee Name",
      accessor: "employee_name",
      widget: "text",
    },

    ...(wizardInfo.HOLIDAY
      ? [
          {
            label: "Current Holiday Accrual",
            accessor: "existing_holiday_increment",
            widget: "DurationLabel",
          },
          {
            label: "Recalc. Holiday Accrual",
            accessor: "holiday_increment",
            widget: "DurationLabel",
            cellStyleCallback: generateCellStyleCallback(
              "holiday_increment",
              "existing_holiday_increment"
            ),
          },
        ]
      : []),
    ...(wizardInfo.SICK
      ? [
          {
            label: "Current Sick Accrual",
            accessor: "existing_sick_increment",
            widget: "DurationLabel",
          },
          {
            label: "Recalc. Sick Accrual",
            accessor: "sick_increment",
            widget: "DurationLabel",
            cellStyleCallback: generateCellStyleCallback(
              "sick_increment",
              "existing_sick_increment"
            ),
          },
        ]
      : []),
    ...(wizardInfo.LONG_SERVICE
      ? [
          {
            label: "Current LSL Accrual",
            accessor: "existing_lsl_increment",
            widget: "DurationLabel",
          },
          {
            label: "Recalc. LSL Accrual",
            accessor: "lsl_increment",
            widget: "DurationLabel",
            cellStyleCallback: generateCellStyleCallback(
              "lsl_increment",
              "existing_lsl_increment"
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1 className="mb-4 days-one text-center">
            Leave Recalculation Results
          </h1>
          <p className="my-2">
            Below are the tentative recalculated leave amounts for the
            previously selected pays. These recalculations are being based off
            of each employee's current leave settings, which may now be
            different to what they were when the pay was first processed. Green
            values indicate an increase in accrued leave for that pay, and red
            values indicate a decrease in accrued leave. Press Finish to save or
            Cancel to reject the changes.
          </p>
          <TableWidget
            rows={wizardInfo.employee_pay_run_data || []}
            columns={columns}
          />
          <button className="btn btn-primary" onClick={downloadPDF}>
            Print/Preview
          </button>
        </div>
      )}
    </div>
  );
};

export default LeaveRecalcResultsView;
