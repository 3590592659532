import React, { useState, useContext, useEffect } from "react";
import { Button } from "reactstrap";

const Wizard = ({
  wizardSteps,
  handleNext,
  handleBack,
  cancelCallback,
  nextEnabled,
  isBackDisabled,
  hideBack,
  step,
  getLastStep,
  forceUpdateButtons,
  setForceUpdateButtons,
  buttonsVisible,
}) => {
  const [showTopButtons, setShowTopButtons] = useState(false);

  useEffect(() => {
    updateButtonVisibility();
    window.addEventListener("resize", updateButtonVisibility);
    return () => window.removeEventListener("resize", updateButtonVisibility);
  }, []);

  // Add this useEffect to listen for forceUpdateButtons
  useEffect(() => {
    if (forceUpdateButtons) {
      updateButtonVisibility();
    }
  }, [forceUpdateButtons]);

  const updateButtonVisibility = () => {
    const screenHeight = window.innerHeight;
    const contentHeight = document.documentElement.scrollHeight;
    setShowTopButtons(contentHeight > 2 * screenHeight);

    // Reset the forceUpdateButtons state after updating the button visibility
    if (forceUpdateButtons && setForceUpdateButtons !== undefined) {
      setForceUpdateButtons(false);
    }
  };

  const renderButtons = () => {
    if (buttonsVisible !== undefined && !buttonsVisible) {
      return null;
    }
    return (
      <>
        {" "}
        <Button
          className="semi-active-button my-3 mx-3 btn-lg min-width-100px"
          onClick={cancelCallback}
        >
          Cancel
        </Button>
        {!hideBack && (
          <Button
            className="semi-active-button my-3 mx-3 btn-lg min-width-100px"
            color="primary"
            onClick={() => {
              handleBack();
              if (setForceUpdateButtons !== undefined) {
                setForceUpdateButtons(false);
              }
              updateButtonVisibility();
            }}
            disabled={isBackDisabled()}
          >
            <i className="faq-regular fa fa-arrow-left"></i> Back
          </Button>
        )}
        <Button
          className="semi-active-button my-3 mx-3 btn-lg min-width-100px"
          onClick={() => {
            handleNext();
            if (setForceUpdateButtons !== undefined) {
              setForceUpdateButtons(false);
            }
            updateButtonVisibility();
          }}
          color="primary"
          disabled={!nextEnabled}
        >
          {step === getLastStep() ? "Finish" : "Next"}{" "}
          {step !== getLastStep() && (
            <i className="faq-regular fa fa-arrow-right"></i>
          )}
        </Button>
      </>
    );
  };

  return (
    <div className="mx-auto my-3 width-90-on-lg">
      {showTopButtons && renderButtons()}
      {wizardSteps[step]}
      {renderButtons()}
    </div>
  );
};

export default Wizard;
