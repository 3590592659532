import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import { useEffect, useContext } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminContext from "../../AdminContext";

const TimeEdit = (props) => {
  const { label, value, onChange } = props;
  const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  const adminContext = useContext(AdminContext);

  // Sanitise the incoming value, on init
  useEffect(() => {
    if (value) {
      handleChange(stringToTime(value));
    }
  }, []);

  const handleChange = (newValue) => {
    if (newValue && newValue["$d"]) {
      newValue["$d"].setMilliseconds(0);
      newValue["$d"].setSeconds(0);
    }
    onChange(newValue);
  };

  const stringToTime = (inputValue) => {
    if (typeof inputValue === "string") {
      if (timeRegex.test(value)) {
        let time = inputValue.split(":");
        let dateTime = new Date();
        dateTime.setHours(time[0]);
        dateTime.setMinutes(time[1]);
        dateTime.setSeconds(time[2]);
        return dateTime;
      }
    } else if (typeof inputValue === "object") {
      return inputValue;
    }
    return null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        ampm={
          adminContext.appConfig.hours_display.value === "12 hr time"
            ? true
            : false
        }
        className={props.className ? props.className : ""}
        value={stringToTime(value)}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
export default TimeEdit;
