import { useState, useEffect } from "react";

function HoursEdit(props) {
  const [text, setText] = useState("");

  function convertMsToHours(milliseconds) {
    // Returns the number of hours to 5 decimal places
    return (milliseconds / 1000 / 60 / 60).toFixed(
      props.decimalPrecision !== undefined ? props.decimalPrecision : 5
    );
  }

  const isNegative = () => {
    return props.value < 0;
  };

  // Initialise the text value using props.value milliseconds
  useEffect(() => {
    setText(convertMsToHours(props.value));
  }, []);

  return (
    <input
      disabled={true}
      value={text}
      className={
        isNegative()
          ? "form-control width-100 text-danger"
          : "form-control width-100"
      }
    />
  );
}

export default HoursEdit;
