import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import EditForm from "../../../../components/Widgets/EditForm";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../../AdminContext";

const RedundancyView = ({
  pay,
  payTermination,
  setPayTermination,
  refreshPayTermination,
}) => {
  const [loading, setLoading] = useState(false);
  const adminContext = useContext(AdminContext);

  const updateRedundancyEstimation = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/redundancy-estimation/" + pay.id,
      payTermination,
      (response) => {
        setLoading(false);
        setPayTermination({
          ...payTermination,
          redundancy_estimation:
            response.data.pay_termination.redundancy_estimation,
        });
      }
    );
  };

  useEffect(() => {
    refreshPayTermination(setLoading);
    updateRedundancyEstimation();
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            <Col>
              <EditForm
                liveEdit={true}
                formSpec={{
                  title: "Redundancy Amount",
                  shortDescription: (
                    <>
                      Please enter below the payout amount for the redundancy or
                      early retirement scheme. A calculated estimation has been
                      provided to you below, which you may use if the figures
                      shown suit the situation. Please check the working values
                      before proceeding with the estimated amount.{" "}
                      <strong>
                        If you have a payment in lieu of notice that needs to
                        have a tax free component then you should include this
                        in the payout amount below.
                      </strong>
                    </>
                  ),
                  fixedTitle: true,
                  fields: [
                    {
                      label: "Redundancy Amount",
                      accessor: "redundancy_amount",
                      widget: "MoneyEdit",
                      validate: {
                        required: true,
                      },
                    },
                    {
                      label: "Redundancy Estimation",
                      accessor: "redundancy_estimation",
                      widget: "html",
                    },
                  ],
                }}
                setModelObj={setPayTermination}
                modelObj={payTermination}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <>
                <hr />
                <p>
                  Fair Work Australia describes redundancy pay amounts depending
                  on an employee's years of service{" "}
                  <a
                    href="https://www.fairwork.gov.au/tools-and-resources/fact-sheets/minimum-workplace-entitlements/notice-of-termination-and-redundancy-pay#redundancy-pay"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
                <p>
                  ATO taxation information for redundancies is available{" "}
                  <a
                    href="https://www.ato.gov.au/individuals/Jobs-and-employment-types/working-as-an-employee/leaving-your-job/redundancy-payments/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default RedundancyView;
