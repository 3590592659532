import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useContext, useState, useEffect } from "react";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";

const SBRSubmissionsView = () => {
  const adminContext = useContext(AdminContext);
  const [sbrSubmissions, setSBRSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/sbr_submissions`,
      (response) => {
        setIsLoading(false);
        setSBRSubmissions(response.data.sbr_submissions);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);
  const setCurrentSBRSubmission = (sbrSubmission) => {
    // Notice how this is different to the updateDepartments function as it only sets the current department, not the departments array
    // This is necessary in case edits get cancelled.
    adminContext.setCompany({
      ...adminContext.company,
      currentSBRSubmission: sbrSubmission,
    });
  };

  const saveChanges = (key, val) => {
    setIsLoading(true);

    // This variable will be true when the component is still mounted.
    let isMounted = true;

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/tools/app-config-item`,
      { key: key, value: val },
      (response) => {
        // Only update state if the component is still mounted.
        if (isMounted) {
          setIsLoading(false);
        }
      }
    );

    // Cleanup function that will be run when the component unmounts.
    return () => {
      isMounted = false;
    };
  };

  return (
    <Container className="mt-4" fluid>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Card className="bg-secondary shadow">
          <CardBody>
            <>
              <Row>
                <Col>
                  <h1 className="days-one">SBR Submissions History</h1>
                </Col>
              </Row>
              <Row>
                <Col className="mx-4 my-3">
                  <Row>
                    <Col>
                      <div key={adminContext.company.id}>
                        <label name="description">
                          Below are historical SBR Submissions (pre-STP) that
                          have been submitted for the company.
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mx-4 my-3">
                  <Row>
                    <Col>
                      <TableWidget
                        rows={sbrSubmissions || []}
                        columns={[
                          {
                            label: "Processed",
                            accessor: "processed",
                            widget: "DateTimeLabel",
                          },
                          {
                            label: "Service",
                            accessor: "service",
                            widget: "text",
                          },
                          {
                            label: "Receipt Identifier",
                            accessor: "receipt_identifier",
                            widget: "text",
                          },
                          {
                            label: "Button",
                            accessor: "None",
                            widget: "Link",
                            args: {
                              label: "View",
                              href: "/admin/company/sbr_submission",
                              color: "secondary",
                              icon: "fa fa-eye",
                              className: "btn btn-sm btn-secondary",
                            },
                          },
                        ]}
                        selected={adminContext.company.currentSBRSubmission}
                        setSelected={setCurrentSBRSubmission}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControlLabel
                    label="Remind me to send Single Touch?"
                    control={
                      <CheckBox
                        checked={
                          adminContext.appConfig.remind_send_single_touch.value
                        }
                        disabled={!adminContext.editAccess("company")}
                        color="primary"
                        id="mastercheck"
                        onChange={() => {
                          saveChanges(
                            "remind_send_single_touch",
                            !adminContext.appConfig.remind_send_single_touch
                              .value
                          );
                        }}
                      />
                    }
                  />
                </Col>
              </Row>
            </>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};
export default SBRSubmissionsView;
