import TableWidget from "../../../components/Widgets/TableWidget";

const EditPaySacrifices = ({ adminContext, setSaving }) => {
  const nonDeletedEmployeeSacrifices =
    adminContext.pay.employee.employee_sacrifices.filter((sacrifice) => {
      return sacrifice.is_deleted === false;
    });

  return (
    <>
      <TableWidget
        label="Salary/Wage Sacrifices"
        rows={adminContext.pay.pay_sacrifices}
        editRowSaveCallback={(newSacrifice) => {
          setSaving(true);
          let sacrificeRows;
          if (newSacrifice.id === null) {
            // New sacrifice
            sacrificeRows = adminContext.pay.pay_sacrifices.concat([
              newSacrifice,
            ]);
          } else {
            // Existing sacrifice
            sacrificeRows = adminContext.pay.pay_sacrifices.map((sacrifice) => {
              if (sacrifice.id === newSacrifice.id) {
                return newSacrifice;
              }
              return sacrifice;
            });
          }
          adminContext.setPay({
            ...adminContext.pay,
            pay_sacrifices: sacrificeRows,
          });
        }}
        columns={[
          {
            label: "Description",
            accessor: "description",
          },
          {
            label: "Employee Fund",
            accessor: "employee_fund_id",
            valueCallback: (row) => {
              if (row.employee_fund) {
                return row.employee_fund.format_name;
              }
              return "";
            },
          },

          {
            label: "Value",
            accessor: "value",
          },
          {
            label: "Is Super?",
            accessor: "is_super",
            widget: "BooleanLabel",
          },
          {
            label: "Is Included In Super Calculations?",
            accessor: "is_included_in_super_calculations",
            widget: "BooleanLabel",
          },
        ]}
        appendRowCallbackEnabled={nonDeletedEmployeeSacrifices.length > 0}
        appendRowCallback={() => {
          let empSacrifice = nonDeletedEmployeeSacrifices[0];
          return {
            id: null,
            amount: empSacrifice.amount,
            pay_id: adminContext.pay.id,
            is_included_in_super_calculations:
              empSacrifice.is_included_in_super_calculations,
            description: empSacrifice.description,
            is_percentage: empSacrifice.is_percentage,
            percentage: empSacrifice.percentage,
            is_super: empSacrifice.is_super,
            employee_sacrifice_id: empSacrifice.id,
          };
        }}
        deleteConfirmationAttributes={["description", "value"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            pay_sacrifices: adminContext.pay.pay_sacrifices.filter(
              (paySacrifice) => {
                return paySacrifice.id !== idToDelete;
              }
            ),
          });
        }}
        formSpec={{
          title: "Salary Sacrifice",
          fields: [
            {
              label: "Sacrifice",
              accessor: "employee_sacrifice_id",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => {
                  let sacrificeComboData = {};
                  nonDeletedEmployeeSacrifices.forEach((sacrifice) => {
                    sacrificeComboData[sacrifice.id] = sacrifice.description;
                  });
                  return sacrificeComboData;
                },
                updatedCallback: (row, setRow, value) => {
                  // Refresh all the attribute values based on the selected sacrifice
                  let empSacrifice = nonDeletedEmployeeSacrifices.find(
                    (sacrifice) => sacrifice.id === Number(value)
                  );

                  if (empSacrifice) {
                    setRow({
                      ...row,
                      amount: empSacrifice.amount,
                      is_included_in_super_calculations:
                        empSacrifice.is_included_in_super_calculations,
                      description: empSacrifice.description,
                      is_percentage: empSacrifice.is_percentage,
                      percentage: empSacrifice.percentage,
                      is_super: empSacrifice.is_super,
                      employee_sacrifice_id: empSacrifice.id,
                    });
                  }
                },
              },
            },
            {
              label: "Amount",
              accessor: "amount",
              widget: "MoneyEdit",
              args: {
                visibleCallback: (row) => {
                  return !row.is_percentage;
                },
              },
            },
            {
              label: "Percentage",
              accessor: "percentage",
              widget: "PercentageEdit",
              args: {
                visibleCallback: (row) => {
                  return row.is_percentage;
                },
              },
            },

            {
              label: "Is Percentage?",
              accessor: "is_percentage",
              widget: "BooleanLabel",
            },
            {
              label: "Is Super?",
              accessor: "is_super",
              widget: "BooleanLabel",
            },
            {
              label: "Is Included In Super Calculations?",
              accessor: "is_included_in_super_calculations",
              widget: "BooleanLabel",
            },
          ],
        }}
      />
    </>
  );
};
export default EditPaySacrifices;
