import EditForm from "../../../components/Widgets/EditForm";
import AdminContext from "../../../AdminContext";
import React, { useContext } from "react";

const EditPayslipInfo = (props) => {
  const adminContext = useContext(AdminContext);
  const formSpec = {
    title: "Payslip Info",
    fields: [
      {
        label: "Company Name",
        accessor: "company_name",
      },
      {
        label: "Company ABN",
        accessor: "company_abn",
      },
      {
        label: "Company Address1",
        accessor: "company_address1",
      },
      {
        label: "Company Address2",
        accessor: "company_address2",
      },
      {
        label: "Company City",
        accessor: "company_city",
      },
      {
        label: "Company State",
        accessor: "company_state",
      },
      {
        label: "Company Postcode",
        accessor: "company_postcode",
      },
      {
        label: "Employee Format Name",
        accessor: "employee_format_name",
      },
      {
        label: "Employee Address1",
        accessor: "employee_address1",
      },
      {
        label: "Employee Address2",
        accessor: "employee_address2",
      },
      {
        label: "Employee City",
        accessor: "employee_city",
      },
      {
        label: "Employee State",
        accessor: "employee_state",
      },
      {
        label: "Employee Postcode",
        accessor: "employee_postcode",
      },
      {
        label: "Employee Employment Type",
        accessor: "employee_employment_type",
      },
      {
        label: "Employee Employment Tenure",
        accessor: "employee_employment_tenure",
      },
      {
        label: "Employee Position",
        accessor: "employee_position",
      },
      {
        label: "Employee Award Name",
        accessor: "employee_award_name",
      },
      {
        label: "Employee Award Classification",
        accessor: "employee_award_classification",
      },
      {
        label: "Payslip Note",
        accessor: "payslip_note",
      },
    ],
  };

  return (
    <EditForm
      saveChanges={props.saveChanges}
      formSpec={formSpec}
      cancelCallback={props.cancelCallback}
      setModelObj={(payslipInfo) => {
        adminContext.setPay({
          ...adminContext.pay,
          payslip_info: payslipInfo,
        });
      }}
      modelObj={adminContext.pay.payslip_info}
    />
  );
};
export default EditPayslipInfo;
