import { useContext, useEffect } from "react";
import AdminContext from "../../AdminContext";

const AddEntity = () => {
  const adminContext = useContext(AdminContext);

  useEffect(() => {
    // Check if user has edit access
    if (!adminContext.editAccess("entities")) {
      adminContext.history.push("/admin/user-restricted");
    } else {
      // Set company to an empty object and redirect
      adminContext.setCompany({});
      adminContext.history.push("/admin/company/details");
    }
  }, []);

  return null;
};

export default AddEntity;
