import React, { useState, useContext, useEffect } from "react";
import { Container, Button, Modal } from "reactstrap";
import AdminContext from "../../AdminContext";
import EditForm from "../../components/Widgets/EditForm";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import QuestionModal from "../../components/Widgets/QuestionModal";

const ChangeHistoryView = () => {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultTitle, setResultTitle] = useState("");

  const [categoryDict, setCategoryDict] = useState({});

  const [modelObj, setModelObj] = useState({
    company: adminContext.company,
    selected_category: "",
    selected_sort_by: "date_time",
    start_date: new Date(),
    end_date: new Date(),
    ADD: true,
    DELETE: true,
    UPDATE: true,
  });

  // Format the start_date and end_date for use in the filename.
  const formatFilenameDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const downloadPDF = async () => {
    const fileName = `change_history_${formatFilenameDate(
      modelObj.start_date
    )}_to_${formatFilenameDate(modelObj.end_date)}.pdf`;

    await adminContext.handlePDFDownload(fileName, modelObj.pdf_data);
  };

  const initCategories = async () => {
    setLoading(true);
    const queryParams = `delete=${modelObj.DELETE}&update=${modelObj.UPDATE}`;
    const url = `${adminContext.constants.BASE_URL}/tools/change-history/init/${adminContext.company.id}?${queryParams}`;
    adminContext.getRequest(
      url,
      (response) => {
        if (response && response.data) {
          setCategoryDict(response.data.sorted_categories || {});
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    initCategories();
  }, []);

  // Define the history types.
  const historyTypes = {
    ADD: "Include ADD Changes?",
    DELETE: "Include DELETE Changes?",
    UPDATE: "Include UPDATE Changes?",
  };

  // Define the sort by options.
  const sortByDict = {
    date_time: "Date and Time",
    description: "Description",
    type: "Type",
  };

  // Generate the Change History report.
  const handlePrint = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/tools/change-history/report/${adminContext.company.id}`,
      modelObj,
      (response) => {
        setLoading(false);
        setModelObj((prevModelObj) => ({
          ...prevModelObj,
          pdf_data:
            "data:application/pdf;base64," + response.data.pdf_file_data,
        }));
      },
      (error) => {
        console.error("Error generating Change History report:", error);
      }
    );
  };

  useEffect(() => {
    if (modelObj.pdf_data) {
      downloadPDF();
    }
  }, [modelObj.pdf_data]);

  // Opens a confirmation modal when the user presses delete.
  const deleteHistory = () => {
    setConfirmationOpen(true);
  };

  // Delete change history if the user selects "Yes" on the
  // QuestionModal, opens a success message if it was successful.
  const handleConfirmation = async (confirmation) => {
    setConfirmationOpen(false);
    if (confirmation === "YES") {
      try {
        console.log("Sending request to change history with data:", modelObj);

        adminContext.postRequest(
          adminContext.constants.BASE_URL +
            `/tools/change-history/delete/${adminContext.company.id}`,
          modelObj,
          (response) => {
            console.log("Response Data: ", response.data);
            if (response.data.success) {
              setResultMessage(response.data.message);
              setResultTitle(response.data.title);
              setSuccessOpen(true);
            } else {
              console.error("API returned unsuccessful");
            }
          }
        );
      } catch (error) {
        console.error("Error during API call", error);
      }
    }
  };

  // Sends user back after closing the success message
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  // Define the form fields.
  const formSpec = {
    fixedTitle: true,
    title: "Change History",
    shortDescription: (
      <>
        This report shows changes that have been made to your payroll data.{" "}
        <br /> You can change the contents of the reports by selecting options
        from the boxes below and clicking the "Print Report" button.
      </>
    ),
    fields: [
      {
        accessor: "start_date",
        widget: "DateRangeEdit",
        validate: {
          required: true,
        },
        startLabel: "Start Date",
        startAccessor: "start_date",
        endLabel: "End Date",
        endAccessor: "end_date",
      },
      {
        label: "Category",
        accessor: "selected_category",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => categoryDict,
          idAliasCallback: adminContext.getKeyFromValue,
        },
        validate: {
          required: true,
        },
      },
      {
        label: "Sort By",
        accessor: "selected_sort_by",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => sortByDict,
          idAliasCallback: adminContext.getKeyFromValue,
        },
        validate: {
          required: true,
        },
      },
      ...Object.entries(historyTypes).map(([key, value]) => ({
        label: value,
        accessor: key,
        widget: "checkbox",
      })),
      {
        widget: "Button",
        accessor: "printReport",
        args: {
          color: "primary",
          onClick: handlePrint,
          text: "Print Report",
        },
      },
      {
        widget: "Button",
        accessor: "deleteHistory",
        args: {
          color: "danger",
          onClick: deleteHistory,
          text: "Delete Entire Change History?",
        },
      },
    ],
  };

  // Update categories based on DELETE, UPDATE, and ADD changes
  useEffect(() => {
    initCategories(); // Call initCategories again to refetch with new parameters
  }, [modelObj.DELETE, modelObj.UPDATE]);

  // Set the selected category to the first available category when the ComboBox
  // options change.
  useEffect(() => {
    if (categoryDict && Object.keys(categoryDict).length > 0) {
      // Check if the current selected category is valid in the updated categoryDict
      if (
        !modelObj.selected_category ||
        !categoryDict[modelObj.selected_category]
      ) {
        setModelObj((prevState) => ({
          ...prevState,
          selected_category: Object.keys(categoryDict)[0],
        }));
      }
    }
  }, [categoryDict]);

  return (
    <>
      <Container>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <EditForm
            liveEdit={true}
            formSpec={formSpec}
            modelObj={modelObj}
            setModelObj={setModelObj}
          />
        )}
      </Container>
      <QuestionModal
        isOpen={isConfirmationOpen}
        title="Delete Entire Change History?"
        content={`Change History often takes up a large part of your database. Clearing it may improve program \
                  performance, however it does make debugging and historical investigation trickier. \
                  Are you sure you would like to clear your entire change history?`}
        onConfirm={() => handleConfirmation("YES")}
        onDeny={() => handleConfirmation("NO")}
      />
      <Modal
        className="width-40-on-lg d-flex align-items-center"
        isOpen={isSuccessOpen}
        centered={true}
      >
        <div className="mx-4 my-4">
          <h2 className="text-center days-one">{`${resultTitle}`}</h2>
          {`${resultMessage}`}
          <Button
            color="warning"
            className="float-right mb-2 mx-2"
            onClick={handleSuccessClose}
          >
            {"Close"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ChangeHistoryView;
