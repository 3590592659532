import { Container, Row, Col, Card, CardBody, Alert, Button } from "reactstrap";
import { useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import DateEdit from "../../components/Widgets/DateEdit";
import ListWidget from "../../components/Widgets/ListWidget";
import BackButton from "../../components/Widgets/BackButton";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import dayjs from "dayjs";

const SetProcessedDateView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newProcessedDate, setNewProcessedDate] = useState(
    adminContext.payRun.end_date ? adminContext.payRun.end_date : new Date()
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const payRowText = (pay) => {
    // In some rare cases, a processed date may be null causing a crash
    let processedDateString = "N/A";
    if (pay.processed_date) {
      processedDateString = formatDate(pay.processed_date);
    }

    const payDate = formatDate(pay.date);

    return (
      `Pay to ${pay.employee.format_name_shorter} ` +
      `of net $${(Math.round(pay.net._decimal * 100) / 100).toFixed(2)} ` +
      `for pay date ${payDate} ` +
      `(${processedDateString} original processed/paid date)`
    );
  };

  const [rows, setRows] = useState({
    pays: adminContext.completedPayRows().map((pay) => {
      return { id: pay.id, format_name: payRowText(pay), selected: false };
    }),
  });

  const [selectedRows, setSelectedRows] = useState({
    pays: adminContext.completedPayRows().map((pay) => {
      return { id: pay.id, format_name: payRowText(pay), selected: false };
    }),
  });

  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/pay-runs/processed-date",
      {
        selected_pays: selectedRows.pays,
        new_processed_date: newProcessedDate,
      },
      (response) => {
        if (response.status === 200 && !response.data.error) {
          setSuccessMessage("Successfully updated processed date");
          adminContext.history.goBack();
        } else {
          setErrorMessage(response.data.error);
        }
        setTimeout(() => {
          setSuccessMessage("");
          setErrorMessage("");
        }, 5000);
      }
    );
  };

  const handleDateChange = (label, dateTime) => {
    if (!dayjs(dateTime).isValid()) {
      setErrorMessage(label + " is not valid");
      return;
    } else {
      setErrorMessage("");
    }
    setNewProcessedDate(dateTime);
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow overflow-card">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <BackButton />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1 className="days-one">Set Processed/Paid Date</h1>
                  <p>
                    Please choose a new processed date and the pays you wish to
                    apply the date to.
                  </p>
                </Col>
              </Row>
              {newProcessedDate && selectedRows.pays.length > 0 ? (
                <SaveChangesMessageRow
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                  saveChanges={saveChanges}
                />
              ) : null}
              <>
                <Row>
                  <Col>
                    <DateEdit
                      value={newProcessedDate}
                      label="New Processed Date"
                      onChange={(time) =>
                        handleDateChange("New Processed Date", time)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ListWidget
                      name="pays"
                      rows={rows}
                      setRows={setRows}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                  </Col>
                </Row>
              </>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SetProcessedDateView;
