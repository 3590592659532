import { Row, Col, Container } from "reactstrap";
import ComboBox from "../../../components/Widgets/ComboBox";
import MoneyEdit from "../../../components/Widgets/MoneyEdit";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

const EditPayTax = ({ adminContext, setSaving }) => {
  return (
    <AvForm>
      <Container>
        <Row>
          <Col>
            <h2 className="days-one">Edit Tax</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <>
              Note: Tax Amount <strong>is inclusive</strong> of any Study and
              Training Support Loan Withholding (I.e. HELP, VSL, SFSS, SSL,
              ABSTUDY SSL and TSL).
            </>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h3>PAYG Withholding</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <MoneyEdit
              label="Total Tax Amount"
              amount={adminContext.pay.tax}
              onBlur={(e) => {
                setSaving(true);
                adminContext.setPay({
                  ...adminContext.pay,
                  tax: e.target.value,
                  is_tax_locked: true,
                });
              }}
              handleChange={(e) => {
                adminContext.setPay({
                  ...adminContext.pay,
                  tax: e.target.value,
                  is_tax_locked: true,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MoneyEdit
              label="STSL Amount"
              amount={adminContext.pay.help}
              onBlur={(e) => {
                setSaving(true);
                adminContext.setPay({
                  ...adminContext.pay,
                  help: e.target.value,
                  is_tax_locked: true,
                });
              }}
              handleChange={(e) => {
                adminContext.setPay({
                  ...adminContext.pay,
                  help: e.target.value,
                  is_tax_locked: true,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ComboBox
              disabled={
                adminContext.pay.employee.locked ||
                !adminContext.editAccess("pays")
              }
              className="mx-3"
              label="STP Income Stream"
              skipSort={true}
              stretch={true}
              name="income_stream"
              comboData={adminContext.constants.STP_INCOME_STREAMS}
              selectedComboItem={adminContext.pay.income_stream}
              setSelectedComboItem={(data) => {
                let incomeStream = adminContext.getKeyByValue(
                  adminContext.constants.STP_INCOME_STREAMS,
                  data
                );
                setSaving(true);
                adminContext.setPay({
                  ...adminContext.pay,
                  income_stream: incomeStream,
                  is_tax_locked: false,
                });
              }}
            />
          </Col>
        </Row>
      </Container>
    </AvForm>
  );
};
export default EditPayTax;
