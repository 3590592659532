import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";
import UserHeader from "../../components/Headers/UserHeader.js";
import reportNames from "../../variables/reportNames.js";
import Tooltip from "@mui/material/Tooltip";

const Reports = () => {
  const categories = Object.keys(reportNames).map(
    (report) => reportNames[report].category
  );
  const categoriesWithoutDuplicates = [...new Set(categories)];
  const createReportColsForCategory = (category) => {
    // Loop through the array of categories to make sure each category is in a row/col
    return Object.keys(reportNames).map((report, key) => {
      if (reportNames[report].category === category) {
        return (
          <Col key={key} lg="6" xl="4">
            <Link
              to={"/admin/reports/" + report}
              className="btn-icon-clipboard border-radius-45 min-height-auto align-items-start semi-active-button"
            >
              {reportNames[report].short_description ? (
                <Tooltip
                  title={reportNames[report].short_description}
                  placement="bottom"
                >
                  <div>
                    <i className="fa-regular fa-lg fa-file-chart-column" />
                    <span className="text-wrap">
                      {reportNames[report]["name"]}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <div>
                  <i className="ni ni-archive-2" />
                  <span className="text-wrap">
                    {reportNames[report]["name"]}
                  </span>
                </div>
              )}
            </Link>
          </Col>
        );
      }
    });
  };
  const createReportCols = () => {
    return categoriesWithoutDuplicates.map((category, key) => {
      return (
        <Row key={key} className="width-100">
          <Col key={key} className="col-md-12">
            <h2 className="days-one mx-2">{category}</h2>
            <Row>{createReportColsForCategory(category)}</Row>
            <hr />
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row className="mx-0">
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h1 className="mb-0 mx-auto text-center days-one">Reports</h1>
              </CardHeader>
              <CardBody>
                <Row className="icon-examples">{createReportCols()}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
