import { useState, useEffect, useContext } from "react";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import ListWidget from "../../../components/Widgets/ListWidget";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { FormControlLabel } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";

const CurrencyRequirementsSelectionView = ({
  wizardInfo,
  setWizardInfo,
  rows,
  setRows,
  selectedRows,
  setSelectedRows,
  loading,
  setLoading,
  saveChanges,
}) => {
  const adminContext = useContext(AdminContext);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow overflow-card">
        <CardBody>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Currency Requirements</h1>
                  <p>
                    Select the pays you wish to be included in the currency
                    requirements report.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="semi-active-button"
                    color="primary"
                    onClick={saveChanges}
                  >
                    Print/Download Currency Requirements Report
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControlLabel
                    key={wizardInfo.include_century_notes}
                    control={
                      <CheckBox
                        name="include_century_notes"
                        checked={wizardInfo.include_century_notes === true}
                        onChange={(event) => {
                          setWizardInfo({
                            ...wizardInfo,
                            include_century_notes: event.target.checked,
                          });
                        }}
                      />
                    }
                    label="Include $100 Notes?"
                  />
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col>
                  <ListWidget
                    name="pays"
                    rows={rows}
                    setRows={setRows}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    formatNameCallback={(pay) => {
                      return (
                        `Pay to ${pay.employee.format_name_shorter} ` +
                        `of net $${(
                          Math.round(pay.net._decimal * 100) / 100
                        ).toFixed(2)} ` +
                        `for pay processed ${pay.processed_date} `
                      );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CurrencyRequirementsSelectionView;
