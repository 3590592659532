import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ComboBox from "../../../components/Widgets/ComboBox.js";
import moment from "moment";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

const KinTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };
  // Update the employee on data change
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else if (event.target.type === "date") {
      const newDate = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: newDate,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  return (
    <Container className="my-3" fluid>
      {!isLoading ? (
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <AvForm>
                <CardBody>
                  <Row>
                    <Col>
                      <h1 className="days-one">Next Of Kin</h1>
                    </Col>
                  </Row>
                  <SaveChangesMessageRow
                    saveChanges={saveChanges}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                  />
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <h2>Name/Relationship</h2>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Next Of Kin Name"
                              value={adminContext.currentEmployee.kin_name}
                              onChange={handleChange}
                              type="text"
                              name="kin_name"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Kin Relationship"
                              value={
                                adminContext.currentEmployee.kin_relationship
                              }
                              onChange={handleChange}
                              type="text"
                              name="kin_relationship"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h2>Contact</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Home Phone"
                              value={
                                adminContext.currentEmployee.kin_phone_home
                              }
                              onChange={handleChange}
                              type="text"
                              name="kin_phone_home"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Work Phone"
                              value={
                                adminContext.currentEmployee.kin_phone_work
                              }
                              onChange={handleChange}
                              type="text"
                              name="kin_phone_work"
                            />
                          </div>
                        </Col>

                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Mobile Phone"
                              value={
                                adminContext.currentEmployee.kin_phone_mobile
                              }
                              onChange={handleChange}
                              type="text"
                              name="kin_phone_mobile"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h2>Address</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Address Line 1"
                              value={adminContext.currentEmployee.kin_address1}
                              onChange={handleChange}
                              type="text"
                              name="kin_address1"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Address Line 2"
                              value={adminContext.currentEmployee.kin_address2}
                              onChange={handleChange}
                              type="text"
                              name="kin_address2"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="City"
                              value={adminContext.currentEmployee.kin_city}
                              onChange={handleChange}
                              type="text"
                              name="kin_city"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <ComboBox
                              disabled={
                                adminContext.currentEmployee.locked ||
                                !adminContext.editAccess("employees")
                              }
                              label="State"
                              stretch={true}
                              className="mx-3"
                              name="kin_state"
                              onChange={handleChange}
                              comboData={adminContext.constants.STATES}
                              selectedComboItem={
                                adminContext.currentEmployee.kin_state
                              }
                              setSelectedComboItem={(data) =>
                                adminContext.setCurrentEmployee({
                                  ...adminContext.currentEmployee,
                                  kin_state: data,
                                })
                              }
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div key={adminContext.currentEmployee.id}>
                            <AvField
                              label="Postcode"
                              type="text"
                              onChange={handleChange}
                              value={adminContext.currentEmployee.kin_postcode}
                              pattern="[0-9]{4}"
                              name="kin_postcode"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="my-3 mx-4">
                          <label htmlFor="kin_notes">
                            <strong>Notes</strong>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-4">
                          <div key={adminContext.currentEmployee.id}>
                            <textarea
                              disabled={
                                adminContext.currentEmployee.locked ||
                                !adminContext.editAccess("employees")
                              }
                              className="width-100 min-height-200"
                              onChange={handleChange}
                              value={
                                adminContext.currentEmployee.kin_notes
                                  ? adminContext.currentEmployee.kin_notes
                                  : ""
                              }
                              name="kin_notes"
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </AvForm>
            </Card>
          </Col>
        </Row>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};
export default KinTab;
