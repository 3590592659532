import React, { useState, useEffect, useContext } from "react";
import { Button, Card, CardBody } from "reactstrap";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const PreviewView = ({ wizardInfo, setWizardInfo, loading, setLoading }) => {
  const adminContext = useContext(AdminContext);

  const downloadPDF = async () => {
    const fileName = `direct_entry_show_report${adminContext.payRun.end_date}.pdf`;
    await adminContext.handlePDFDownload(fileName, wizardInfo.pdf_data);
  };

  const generateDirectPaysReportPdf = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/direct-entry/summary-report`,
      wizardInfo,
      (response) => {
        setWizardInfo({
          ...wizardInfo,
          pdf_data:
            "data:application/pdf;base64," + response.data.pdf_file_data,
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    generateDirectPaysReportPdf();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-center">
            <h1 className="text-center days-one">Summary</h1>
            <p>
              The direct entry process is now complete. You may click the{" "}
              <strong>"Show Report"</strong> button if you wish to see a summary
              of the direct entries made.
            </p>

            <Button
              onClick={downloadPDF}
              className="btn btn-lg semi-active-button width-40-on-lg btn-primary mx-auto text-center"
            >
              Show Report
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PreviewView;
