import { Row, Col, Button, Container } from "reactstrap";
import TableWidget from "../../../components/Widgets/TableWidget";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";
import MoneyEdit from "../../../components/Widgets/MoneyEdit";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

const EditPaySuper = ({ adminContext, setSaving }) => {
  return (
    <>
      <AvForm>
        <Container>
          <Row>
            <Col>
              {" "}
              <h2 className="days-one">Superannuation</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <MoneyEdit
                key="compulsory_super_amount"
                name="compulsory_super_amount"
                onBlur={(e) => {
                  setSaving(true);
                  adminContext.setPay({
                    ...adminContext.pay,
                    compulsory_super_amount: e.target.value,
                    is_super_locked: true,
                  });
                }}
                handleChange={(e) => {
                  adminContext.setPay({
                    ...adminContext.pay,
                    compulsory_super_amount: e.target.value,
                    is_super_locked: true,
                  });
                }}
                label={"Compulsory Superannuation"}
                amount={adminContext.pay.compulsory_super_amount}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <MoneyEdit
                key="resc_super_amount"
                name="resc_super_amount"
                onBlur={(e) => {
                  setSaving(true);
                  adminContext.setPay({
                    ...adminContext.pay,
                    resc_super_amount: e.target.value,
                    is_super_locked: true,
                  });
                }}
                handleChange={(e) => {
                  adminContext.setPay({
                    ...adminContext.pay,
                    resc_super_amount: e.target.value,
                    is_super_locked: true,
                  });
                }}
                label={"RESC Superannuation"}
                amount={adminContext.pay.resc_super_amount}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TableWidget
                label="Post Tax Voluntary Super"
                rows={adminContext.pay.pay_super}
                columns={[
                  {
                    label: "Description",
                    accessor: "description",
                    widget: "Text",
                  },
                  {
                    label: "Amount",
                    accessor: "amount",
                    widget: "MoneyLabel",
                  },
                ]}
                appendRowCallbackEnabled={
                  adminContext.pay.employee.employee_super.length > 0
                }
                appendRowCallback={() => {
                  let empSuper = adminContext.pay.employee.employee_super[0];
                  return {
                    id: null,
                    pay_id: adminContext.pay.id,
                    employee_super_id: empSuper.id,
                    amount: empSuper.amount,
                  };
                }}
                editRowSaveCallback={(row) => {
                  setSaving(true);
                  let superRows;
                  if (row.id === null) {
                    superRows = [...adminContext.pay.pay_super, row];
                  } else {
                    superRows = adminContext.pay.pay_super.map((paySuper) => {
                      if (paySuper.id === row.id) {
                        return row;
                      }
                      return paySuper;
                    });
                  }
                  adminContext.setPay({
                    ...adminContext.pay,
                    pay_super: superRows,
                  });
                }}
                deleteConfirmationAttributes={["description", "amount"]}
                deleteRowCallback={(idToDelete) => {
                  setSaving(true);
                  adminContext.setPay({
                    ...adminContext.pay,
                    pay_super: adminContext.pay.pay_super.filter((paySuper) => {
                      return paySuper.id !== idToDelete;
                    }),
                  });
                }}
                formSpec={{
                  title: "Personal (Post-tax) Super Contribution",
                  fields: [
                    {
                      label: "Description",
                      accessor: "employee_super_id",
                      widget: "ComboBox",
                      args: {
                        comboDataCallback: () => {
                          let superComboData = {};
                          adminContext.pay.employee.employee_super.forEach(
                            (empSuper) => {
                              superComboData[empSuper.id] =
                                empSuper.description;
                            }
                          );
                          return superComboData;
                        },
                      },
                    },
                    {
                      label: "Amount",
                      accessor: "amount",
                      widget: "MoneyEdit",
                    },
                  ],
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              Compulsory super guarantee for this pay is based on the{" "}
              <strong>
                {
                  adminContext.constants.SUPER_BASED_ON_DICT[
                    adminContext.pay.employee.super_based_on
                  ]
                }
              </strong>{" "}
              amount of{" "}
              <strong>
                <MoneyLabel
                  amount={adminContext.pay.super_levy_default_based_on._decimal}
                />
              </strong>
              .
            </Col>
          </Row>
        </Container>
      </AvForm>
    </>
  );
};
export default EditPaySuper;
