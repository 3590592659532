import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import qs from "qs";

const EditShift = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentShift, setCurrentShift] = useState(false);

  const [rows, setRows] = useState({
    pay_rates: [],
    allowances: [],
  });
  const [selectedRows, setSelectedRows] = useState({
    pay_rates: [],
    allowances: [],
  });

  const getMonthlyDays = () => {
    const monthlyDays = {};

    for (let i = 1; i <= 31; i++) {
      monthlyDays[i] = i.toString();
    }

    monthlyDays[-1] = "Last Day";
    monthlyDays[-2] = "Second Last Day";
    monthlyDays[-3] = "Third Last Day";

    return monthlyDays;
  };

  const getWeeklyDays = () => {
    return adminContext.constants.DAYS_OF_WEEK.reduce((acc, day) => {
      acc[day] = day;
      return acc;
    }, {});
  };

  const shiftId = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const init = () => {
    setIsLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/shifts/${shiftId}`,
      (response) => {
        if (response.data.employee_shift) {
          setCurrentShift(response.data.employee_shift);
        }
        if (response.data.all_pay_rates && response.data.employee_shift) {
          setRows({
            ...rows,
            pay_rates: response.data.all_pay_rates,
            allowances: response.data.all_allowances,
          });
          setSelectedRows({
            ...selectedRows,
            allowances: response.data.unavailable_allowances,
            pay_rates: response.data.unavailable_pay_rates,
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (shiftId) {
      init();
    }
  }, [shiftId]);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/shifts/${shiftId}`,
      {
        repeats: currentShift.repeats,
        pay_rates: selectedRows.pay_rates,
        allowances: selectedRows.allowances,
      },
      (response) => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
      setSaving(false);
    }
  }, [currentShift.repeats, selectedRows.pay_rates, selectedRows.allowances]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              {currentShift ? (
                <>
                  <Row>
                    <Col>
                      <h1 className="days-one">Edit Employee Shift</h1>
                      <p>
                        Please configure the settings to use whenever{" "}
                        <strong>
                          {adminContext.currentEmployee.first_name +
                            " " +
                            adminContext.currentEmployee.last_name}
                        </strong>{" "}
                        works the{" "}
                        <strong>
                          {currentShift ? currentShift.description : ""}
                        </strong>{" "}
                        shift.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button onClick={adminContext.history.goBack}>
                        <i className="fa fa-arrow-left" /> Back
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        key={adminContext.currentEmployee.id}
                        className="my-4"
                      >
                        <h2 className="days-one">Pay Rates</h2>
                        <p>
                          Each attached pay rate will be paid once for each hour
                          of this shift being worked.
                        </p>
                        <TableWidget
                          rows={selectedRows.pay_rates}
                          appendRowCallback={() => {
                            return {
                              pay_rates: rows.pay_rates,
                            };
                          }}
                          editRowSaveCallback={saveChanges}
                          hideEdit={true}
                          formSpec={{
                            title: "Select pay rate/s to include in this shift",
                            fields: [
                              {
                                label: "Pay Rate",
                                accessor: "pay_rates",
                                widget: "ListWidget",
                                args: {
                                  rows: rows,
                                  setRows: setRows,
                                  selectedRows: selectedRows,
                                  setSelectedRows: setSelectedRows,
                                  validateCallback: (row) => {
                                    // Make sure that only one system leave type is selected
                                    // System leave types being "Ordinary Time", "Time and a half" and "Double time"
                                    let systemTypesCount = 0;
                                    selectedRows.pay_rates.forEach((row) => {
                                      if (
                                        row.description === "Ordinary Time" ||
                                        row.description === "Time and a half" ||
                                        row.description === "Double time"
                                      ) {
                                        systemTypesCount++;
                                      }
                                    });
                                    if (systemTypesCount > 1) {
                                      return "You can only select a max of one system pay rate per shift (Ordinary Time, Time and a half or Double time)";
                                    }
                                    return "";
                                  },
                                },
                              },
                            ],
                          }}
                          deleteConfirmationAttributes={[
                            "description",
                            "amount_per_unit",
                          ]}
                          secondaryDeleteattribute="description"
                          deleteRowCallback={(idToDelete, description) => {
                            setSaving(true);
                            setSelectedRows({
                              ...selectedRows,
                              pay_rates: selectedRows.pay_rates.filter(
                                (row) =>
                                  row.id !== idToDelete &&
                                  row.description !== description
                              ),
                            });
                          }}
                          columns={[
                            {
                              label: "Description",
                              accessor: "description",
                            },
                            {
                              label: "Amount Per Unit",
                              accessor: "amount_per_unit",
                              widget: "MoneyLabel",
                            },
                          ]}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        key={adminContext.currentEmployee.id}
                        className="my-4"
                      >
                        <h2 className="days-one">Allowances</h2>
                        <p>
                          Each attached allowance will be paid once each time
                          this shift is worked.
                        </p>
                        <TableWidget
                          hideEdit={true}
                          rows={selectedRows.allowances}
                          columns={[
                            { label: "Description", accessor: "description" },
                            {
                              label: "Amount",
                              accessor: "amount",
                              widget: "MoneyLabel",
                            },
                          ]}
                          appendRowCallback={() => {
                            return {
                              allowances: rows.allowances,
                            };
                          }}
                          editRowSaveCallback={saveChanges}
                          formSpec={{
                            title:
                              "Select allowance/s to include in this shift",
                            fields: [
                              {
                                label: "Allowance",
                                accessor: "allowances",
                                widget: "ListWidget",
                                args: {
                                  rows: rows,
                                  setRows: setRows,
                                  selectedRows: selectedRows,
                                  setSelectedRows: setSelectedRows,
                                },
                              },
                            ],
                          }}
                          deleteConfirmationAttributes={[
                            "description",
                            "amount",
                          ]}
                          secondaryDeleteattribute="description"
                          deleteRowCallback={(idToDelete, description) => {
                            setSaving(true);
                            setSelectedRows({
                              ...selectedRows,
                              allowances: selectedRows.allowances.filter(
                                (row) =>
                                  row.id !== idToDelete &&
                                  row.description !== description
                              ),
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        key={adminContext.currentEmployee.id}
                        className="my-4"
                      >
                        <h2 className="days-one">Repeats</h2>
                        <p>
                          Repeats are regular working days to be rostered
                          automatically.
                        </p>
                        <TableWidget
                          hideEdit={true}
                          appendRowCallback={() => {
                            return {
                              id: null,
                              frequency: "Weekly",
                              day: "Monday",
                            };
                          }}
                          editRowSaveCallback={(repeat) => {
                            // Add the new repeat to the currentShift.repeats
                            setSaving(true);
                            setCurrentShift({
                              ...currentShift,
                              repeats: [...currentShift.repeats, repeat],
                            });
                          }}
                          formSpec={{
                            title: "",
                            fields: [
                              {
                                label: "Day",
                                widget: "ListWidget",
                                accessor: "day",
                                widget: "ComboBox",
                                required: true,
                                args: {
                                  sortCallback: (a, b) => {
                                    if (isNaN(a)) {
                                      return 1;
                                    } else if (isNaN(b)) {
                                      return -1;
                                    } else {
                                      return Number(a) - Number(b);
                                    }
                                  },
                                  validateCallback: (repeat) => {
                                    // Loop through all existing repeats and return an error if the day and frequency combo is already in use
                                    for (
                                      let i = 0;
                                      i < currentShift.repeats.length;
                                      i++
                                    ) {
                                      if (
                                        currentShift.repeats[i].day ===
                                          repeat.day &&
                                        currentShift.repeats[i].frequency ===
                                          repeat.frequency
                                      ) {
                                        return "This day and frequency combination already exists";
                                      }
                                    }
                                  },
                                  comboDataCallback: (repeat) => {
                                    if (repeat.frequency === "Monthly") {
                                      if (
                                        !getMonthlyDays().hasOwnProperty(
                                          repeat.day
                                        )
                                      ) {
                                        repeat.day = 1;
                                      }
                                      return getMonthlyDays();
                                    }
                                    if (
                                      !getWeeklyDays().hasOwnProperty(
                                        repeat.day
                                      )
                                    ) {
                                      repeat.day = "Monday";
                                    }
                                    return getWeeklyDays();
                                  },
                                },
                              },
                              {
                                label: "Repeat Frequency",
                                widget: "ListWidget",
                                accessor: "frequency",
                                widget: "ComboBox",
                                required: true,
                                args: {
                                  comboDataCallback: () => {
                                    return {
                                      Weekly: "Weekly",
                                      Fortnightly: "Fortnightly",
                                      Monthly: "Monthly",
                                    };
                                  },
                                },
                              },
                            ],
                          }}
                          deleteConfirmationAttributes={["day", "frequency"]}
                          deleteRowCallback={(idToDelete) => {
                            setSaving(true);
                            setCurrentShift({
                              ...currentShift,
                              repeats: currentShift.repeats.filter(
                                (row) => row.id !== idToDelete
                              ),
                            });
                          }}
                          rows={currentShift.repeats}
                          columns={[
                            {
                              label: "Day",
                              accessor: "day",
                            },
                            {
                              label: "Frequency",
                              accessor: "frequency",
                            },
                          ]}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <p>No shift selected</p>
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default EditShift;
