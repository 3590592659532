import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import Wizard from "../../../components/Widgets/Wizard";
import CurrencyRequirementsSelectionView from "./CurrencyRequirementsSelectionView";
import DateRangeView from "../../../components/Widgets/WizardViews/DateRangeView";

const CurrencyRequirementsWizard = () => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pdfB64Data, setPdfB64Data] = useState("");
  const [wizardInfo, setWizardInfo] = useState({
    pay_period: adminContext.payRun.pay_period,
    date_page: null,
    default_pays: adminContext.completedPayRows(),
    pays: adminContext.completedPayRows(),
    selectedPays: adminContext.completedPayRows(),
    pay_date: adminContext.payRun.end_date,
    include_century_notes: true,
    multiple_pay_runs: false,
  });

  const [rows, setRows] = useState({
    pays: adminContext.completedPayRows(),
  });

  const [selectedRows, setSelectedRows] = useState({
    pays: [],
  });

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      pays: rows.pays,
      selected_pays: selectedRows.pays,
    });
  }, [rows, selectedRows]);

  useEffect(() => {
    const currentRoute = window.location.pathname.split("/").pop();
    if (currentRoute.includes("multiple")) {
      setWizardInfo({
        ...wizardInfo,
        multiple_pay_runs: true,
      });
      setStep(0);
    } else {
      setStep(1);
    }
  }, []);

  const handleNext = () => {
    if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    } else {
      adminContext.history.goBack();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setNextEnabled(true);
    }
  };

  const saveChanges = () => {
    setLoading(true);
    setPdfB64Data("");
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/currency-requirements-report`,
      wizardInfo,
      (response) => {
        setPdfB64Data(
          "data:application/pdf;base64," + response.data.pdf_file_data
        );
        setLoading(false);
      }
    );
  };

  const downloadPDF = async () => {
    const fileName = "currency_requirements.pdf";
    await adminContext.handlePDFDownload(fileName, pdfB64Data);
  };

  // Download the pdf when it becomes set
  useEffect(() => {
    if (pdfB64Data !== "") {
      downloadPDF();
    }
  }, [pdfB64Data]);

  const wizardSteps = [
    <DateRangeView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <CurrencyRequirementsSelectionView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      loading={loading}
      setLoading={setLoading}
      saveChanges={saveChanges}
    />,
  ];

  return (
    <>
      <Wizard
        wizardSteps={wizardSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        buttonsVisible={buttonsVisible}
        cancelCallback={adminContext.history.goBack}
        nextEnabled={nextEnabled}
        step={step}
        setStep={setStep}
        forceUpdateButtons={forceUpdateButtons}
        setForceUpdateButtons={setForceUpdateButtons}
        isBackDisabled={() => {
          return (step <= 1 && !wizardInfo.multiple_pay_runs) || step === 0;
        }}
        getLastStep={() => {
          return wizardSteps.length - 1;
        }}
      />
    </>
  );
};
export default CurrencyRequirementsWizard;
