import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Input,
} from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FormControlLabel, Switch } from "@mui/material";

const EmailOptionsTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.awards]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <AvForm>
              <Row>
                <Col>
                  <h1 className="days-one">Email Options</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />
              <Row>
                <Col className="mx-4 my-3">
                  <Row>
                    <Col>
                      <p>
                        Employees can choose to have documents such as payslips
                        and payment summaries sent to them via email. In
                        addition, they can choose to have them sent to the email
                        address in their personal details or a different one.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mx-4 my-3">
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Email Address"
                          value={adminContext.currentEmployee.email_address}
                          onChange={handleChange}
                          type="text"
                          name="email_address"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mx-4 my-3">
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <AvField
                          label="Alt. Email Address"
                          value={adminContext.currentEmployee.alt_email_address}
                          onChange={handleChange}
                          type="text"
                          name="alt_email_address"
                        />
                        <p>
                          Note: If an alternate email address has been entered
                          it will become the new default email address used for
                          this employee.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col className="my-3 mx-4">
                  <FormControlLabel
                    key={adminContext.currentEmployee.email_payslip}
                    control={
                      <Switch
                        checked={adminContext.currentEmployee.email_payslip}
                        name="email_payslip"
                        color="primary"
                        onChange={handleChange}
                      />
                    }
                    label={"Email Payslips Enabled?"}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="my-3 mx-4">
                  <FormControlLabel
                    key={adminContext.currentEmployee.email_payment_summary}
                    control={
                      <Switch
                        checked={
                          adminContext.currentEmployee.email_payment_summary
                        }
                        name="email_payment_summary"
                        color="primary"
                        onChange={handleChange}
                      />
                    }
                    label={"Email Payment Summaries Enabled?"}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="my-3 mx-4">
                  <FormControlLabel
                    key={adminContext.currentEmployee.send_dual_emails}
                    control={
                      <Switch
                        checked={adminContext.currentEmployee.send_dual_emails}
                        name="send_dual_emails"
                        color="primary"
                        onChange={handleChange}
                      />
                    }
                    label={"Send To Both Addresses?"}
                  />
                </Col>
              </Row>
            </AvForm>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default EmailOptionsTab;
