import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext, forwardRef } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";

const GLCodes = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [glSettings, setGLSettings] = useState({});
  const url = `${adminContext.constants.BASE_URL}/company/${adminContext.company.id}/gl-settings`;
  const [saving, setSaving] = useState(false);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(url, (response) => {
      if (response.data.gl_settings) {
        setGLSettings(response.data.gl_settings);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    initView();
  }, []);

  useEffect(() => {
    if (glSettings.gl_settings !== undefined && saving) {
      adminContext.setAlertMessage("");
      adminContext.putRequest(url, glSettings, (response) => {
        adminContext.setAlertMessage("Changes have been saved successfully.");
        setSaving(false);
        setTimeout(() => {
          adminContext.setAlertMessage("");
        }, 5000);
      });
    }
  }, [glSettings]);

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">
                      General Ledger Codes & Export Settings
                    </h1>
                  </Col>
                </Row>

                {!adminContext.company.currentAward ? (
                  <Row>
                    <Col>
                      <p>
                        Below is where you can define general ledger codes for
                        your company. These codes are used to export your
                        payroll data to your accounting software.
                      </p>
                      <p>
                        The GL Postings report can be exported from the{" "}
                        <strong>Pays</strong> screen.
                      </p>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col className="mx-4 my-3">
                    {glSettings.gl_settings !== undefined ? (
                      <>
                        <Row>
                          <Col>
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="negate_credit_amounts"
                                  checked={glSettings.negate_credit_amounts}
                                  onChange={(e) => {
                                    setSaving(true);
                                    setGLSettings({
                                      ...glSettings,
                                      negate_credit_amounts: e.target.checked,
                                    });
                                  }}
                                />
                              }
                              label="Negate credit amounts? (Unticked will leave all amounts positive and add a debit/credit column)"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="include_date"
                                  checked={glSettings.include_date}
                                  onChange={(e) => {
                                    setSaving(true);
                                    setGLSettings({
                                      ...glSettings,
                                      include_date: e.target.checked,
                                    });
                                  }}
                                />
                              }
                              label="Include date in the export?"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="include_software_name"
                                  checked={glSettings.include_software_name}
                                  onChange={(e) => {
                                    setSaving(true);
                                    setGLSettings({
                                      ...glSettings,
                                      include_software_name: e.target.checked,
                                    });
                                  }}
                                />
                              }
                              label="Include software name (Lightning Payroll) in the export?"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="include_nil_amounts"
                                  checked={glSettings.include_nil_amounts}
                                  onChange={(e) => {
                                    setSaving(true);
                                    setGLSettings({
                                      ...glSettings,
                                      include_nil_amounts: e.target.checked,
                                    });
                                  }}
                                />
                              }
                              label="Include zero dollar amounts in the export?"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TableWidget
                              rows={glSettings.gl_settings}
                              setRows={(newRows) => {
                                setGLSettings({
                                  ...glSettings,
                                  gl_settings: newRows,
                                });
                              }}
                              columns={[
                                {
                                  label: "GL/Pay Category",
                                  accessor: "pay_item",
                                  widget: "text",
                                },
                                {
                                  label: "Label In Exported Journal Entry",
                                  accessor: "account_label",
                                  widget: "text",
                                },
                                {
                                  label: "General Ledger Code",
                                  accessor: "account_code",
                                  widget: "text",
                                },
                                {
                                  label:
                                    "Split By Department (When Applicable)?",
                                  accessor: "split_by_department",
                                  widget: "BooleanLabel",
                                },
                                {
                                  label: "Include In CSV Export?",
                                  accessor: "export_to_csv",
                                  widget: "BooleanLabel",
                                },
                                {
                                  label: "Category",
                                  accessor: "category",
                                  widget: "text",
                                },
                              ]}
                              editRowSaveCallback={(glSetting) => {
                                setSaving(true);
                                setGLSettings({
                                  ...glSettings,
                                  gl_settings: glSettings.gl_settings.map(
                                    (row) => {
                                      if (row.id === glSetting.id) {
                                        return glSetting;
                                      } else {
                                        return row;
                                      }
                                    }
                                  ),
                                });
                              }}
                              formSpec={{
                                title: "GL Codes",
                                fields: [
                                  {
                                    label: "GL/Pay Category",
                                    accessor: "pay_item",
                                    widget: "Label",
                                  },
                                  {
                                    label: "Label In Exported Journal Entry",
                                    accessor: "account_label",
                                    widget: "text",
                                  },
                                  {
                                    label: "General Ledger Code",
                                    accessor: "account_code",
                                    widget: "text",
                                  },
                                  {
                                    label:
                                      "Split By Department (When Applicable)?",
                                    accessor: "split_by_department",
                                    widget: "Switch",
                                  },
                                  {
                                    label: "Include In CSV Export?",
                                    accessor: "export_to_csv",
                                    widget: "Switch",
                                  },
                                  {
                                    label: "Is Debit?",
                                    accessor: "is_debit",
                                    widget: "Switch",
                                  },
                                ],
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default GLCodes;
