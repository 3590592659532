import moment from "moment";
import { useEffect, useState } from "react";

const DateLabel = (props) => {
  const value = props.value;
  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    if (value !== null) {
      // Interpret as UTC, then convert to local timezone
      setFormattedDate(
        moment
          .utc(value)
          .local()
          .format(props.format ? props.format : "DD/MM/YYYY")
      );
    }
  }, [value]);

  return (
    <span className={props.className ? props.className : ""}>
      {formattedDate}
    </span>
  );
};

export default DateLabel;
