import EditForm from "../../../components/Widgets/EditForm";

const LeaveTypeSelectionView = ({
  wizardInfo,
  setWizardInfo,
  shortDescription,
  leaveTypes,
}) => {
  const handleCheckboxChange = (leaveType) => {
    // Update the wizardInfo state with the changed checkbox value
    setWizardInfo((prevState) => ({
      ...prevState,
      [leaveType]: !prevState[leaveType],
    }));
  };

  const formSpec = {
    fixedTitle: true,
    title: "Select Leave Types",
    shortDescription: shortDescription,
    fields: Object.entries(leaveTypes).map(([leaveType, leaveLabel]) => ({
      label: leaveLabel || leaveType,
      accessor: leaveType,
      widget: "CheckBox",
      args: {
        additionalChangeCallback: () => handleCheckboxChange(leaveType),
      },
    })),
  };

  return (
    <EditForm
      liveEdit={true}
      formSpec={formSpec}
      setModelObj={setWizardInfo}
      modelObj={wizardInfo}
    />
  );
};

export default LeaveTypeSelectionView;
