import { useEffect, useState, useContext } from "react";
import EditForm from "../EditForm";
import { formSpecModelMap } from "../../../variables/ValidationFormSpecs";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../LoadingSpinner";

const ValidationFixView = ({
  selectedValidationModel,
  setSelectedValidationModel,
}) => {
  const filteredFormSpec = (startingSpec) => {
    let newFormSpec = {
      ...startingSpec,
      shortDescription: (
        <>
          Below are validation errors for {selectedValidationModel.format_name}
        </>
      ),
    };

    // Get a list of attributes from the validation_items
    const requiredAttributes = selectedValidationModel.validation_items.map(
      (item) => item.attribute
    );

    // Filter the fields array to keep only the fields with attributes in requiredAttributes
    newFormSpec.fields = newFormSpec.fields.filter((field) =>
      requiredAttributes.includes(field.accessor)
    );

    return newFormSpec;
  };

  const [modelObj, setModelObj] = useState({});
  const [loading, setLoading] = useState(false);
  const adminContext = useContext(AdminContext);
  const [formSpec, setFormSpec] = useState(
    filteredFormSpec(
      formSpecModelMap[selectedValidationModel.model_type].specCallback()
    )
  );

  const getModelObj = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/validation/model-obj/" +
        selectedValidationModel.model_type +
        "/" +
        selectedValidationModel.id,
      (response) => {
        setModelObj(response.data.model_obj);
        setLoading(false);

        // Some model objects need a helping hand to have the data they need
        // to render the form.  This is where we do that.
        // CompanyBankAccounts need bank data
        if (selectedValidationModel.model_type === "CompanyBankAccount") {
          adminContext.getRequest(
            adminContext.constants.BASE_URL + "/settings/banks",
            (response) => {
              if (response.data.banks) {
                const banksComboData = {};
                response.data.banks.forEach((bank) => {
                  banksComboData[bank.id] = `${bank.name} - ${bank.code}`;
                });
                setFormSpec(
                  filteredFormSpec(
                    formSpecModelMap[
                      selectedValidationModel.model_type
                    ].specCallback(banksComboData)
                  )
                );
              }
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    getModelObj();
  }, []);

  const saveChanges = () => {
    setLoading(true);
    let data = {
      model_type: selectedValidationModel.model_type,
      model_id: selectedValidationModel.id,
      model_data: modelObj,
      model_validation_items: selectedValidationModel.validation_items,
    };
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/validation/fix",
      data,
      (response) => {
        setSelectedValidationModel(null);
        setLoading(false);
      }
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner width="45%" height="45%" />
      ) : (
        <>
          <EditForm
            formSpec={formSpec}
            saveChanges={saveChanges}
            cancelCallback={() => setSelectedValidationModel(null)}
            modelObj={modelObj}
            setModelObj={setModelObj}
            liveEdit={true}
          />
        </>
      )}
    </>
  );
};
export default ValidationFixView;
