// reactstrap components
import { CardBody, Card, CardHeader, Alert } from "reactstrap";
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AdminContext from "../../AdminContext";
import TrialRestricted from "../../components/Widgets/TrialRestricted";

import FileUploadButton from "../../components/Widgets/FileUploadButton";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const RestoreBackup = () => {
  const adminContext = useContext(AdminContext);
  const [selectedFile, setSelectedFile] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleSubmission = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/backup/restore",
      formdata,
      (response) => {
        if (response.status === 200) {
          history.push("/admin/entities/select");
        } else {
          setErrorMessage("Something went wrong. Unable to restore backup.");
          setSuccessMessage("");
        }
        setIsLoading(false);

        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
          history.push("/admin/entities/select");
        }, 4000);
      }
    );
  };

  if (adminContext.isTrial) {
    return <TrialRestricted screen="Restore Backup" />;
  }

  return (
    <>
      {!isLoading ? (
        <>
          <div className="mx-3 my-3">
            {errorMessage && (
              <Alert color="warning">
                <strong>{errorMessage}</strong>
              </Alert>
            )}
            {successMessage && (
              <Alert color="success">
                <strong>{successMessage}</strong>
              </Alert>
            )}
          </div>

          <Card className="bg-secondary mx-3 my-3 shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-center mt-2 mb-3">
                <h1 className="days-one">Restore Local Backup</h1>
                <p>
                  Select a local backup file from your computer. This will
                  overwrite any existing company data.
                </p>
              </div>
            </CardHeader>
            <CardBody className="width-100">
              <FileUploadButton
                selectedFile={selectedFile}
                label="Upload & Restore"
                handleSubmission={handleSubmission}
                setSelectedFile={setSelectedFile}
              />
            </CardBody>
          </Card>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default RestoreBackup;
