import { useState, useContext, useEffect } from "react";
import EditForm from "../EditForm";
import AdminContext from "../../../AdminContext";

const SelectEmployeesView = ({
  wizardInfo,
  setWizardInfo,
  setForceUpdateButtons,
}) => {
  const adminContext = useContext(AdminContext);
  const [rows, setRows] = useState({
    employees: wizardInfo.employees,
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: wizardInfo.selected_employees,
  });

  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      employees: rows.employees,
      selected_employees: selectedRows.employees,
    });
    setForceUpdateButtons(true);
  }, [rows, selectedRows]);

  return (
    <div>
      <EditForm
        liveEdit={true}
        formSpec={{
          fixedTitle: true,
          title: "Select Employees",
          shortDescription: "Select the employees you'd like to include.",
          fields: [
            {
              label: "Show Terminated?",
              accessor: "show_terminated",

              args: {
                additionalChangeCallback: (event) => {
                  // If show_terminated is false, remove any terminated employees from the selected rows
                  if (!event.target.checked) {
                    let newSelectedRows = {
                      employees: selectedRows.employees.filter(
                        (row) => !row.is_terminated
                      ),
                    };
                    setSelectedRows(newSelectedRows);

                    // Do the same with the rows as we only need to see non terminated
                    let newRows = {
                      employees: adminContext.company.employees.filter(
                        (row) => !row.is_terminated
                      ),
                    };
                    setRows(newRows);
                  } else {
                    setRows({
                      employees: adminContext.company.employees,
                    });
                  }
                },
              },

              widget: "Switch",
              validate: {
                required: false,
              },
            },
            {
              label: "",
              accessor: "employees",
              widget: "ListWidget",
              validate: {
                required: false,
              },
              args: {
                rows: rows,
                setRows: setRows,
                selectedRows: selectedRows,
                setSelectedRows: setSelectedRows,
                skipFilter: true,
                noTitleCase: true,
              },
            },
          ],
        }}
        setModelObj={setWizardInfo}
        modelObj={wizardInfo}
      />
    </div>
  );
};

export default SelectEmployeesView;
