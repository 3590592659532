import { Table } from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import AdminContext from "../../AdminContext";

function ListWidget(props) {
  const adminContext = useContext(AdminContext);
  const [masterChecked, setmasterChecked] = useState(false);

  const setSelectedFromChecked = (row, checked) => {
    if (checked) {
      // Add to selectedRows
      if (!props.selectedRows[props.name].find((item) => item.id === row.id)) {
        props.selectedRows[props.name].push(row);
      }
    } else {
      // Remove from selectedRows
      if (props.selectedRows[props.name].find((item) => item.id === row.id)) {
        props.selectedRows[props.name] = props.selectedRows[props.name].filter(
          (item) => item.id !== row.id
        );
      }
    }
  };

  // Select/ UnSelect Table rows
  const onMasterCheck = (e) => {
    if (e.target.checked) {
      props.setSelectedRows({
        ...props.selectedRows,
        [props.name]: getRows(),
      });
    } else {
      props.setSelectedRows({
        ...props.selectedRows,
        [props.name]: [],
      });
    }
    setmasterChecked(e.target.checked);
    props.onChange && props.onChange();
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = props.rows[props.name];
    tempList.map((row) => {
      if (row.id === item.id) {
        setSelectedFromChecked(row, e.target.checked);
      }
      return row;
    });

    //To Control Master Checkbox State
    const totalItems = props.rows[props.name].length;
    const totalCheckedItems = tempList.filter((e) => isSelected(e)).length;

    // Update State
    setmasterChecked(totalItems === totalCheckedItems);
    props.setSelectedRows({
      ...props.selectedRows,
      [props.name]: props.rows[props.name].filter((e) => isSelected(e)),
    });

    props.onChange && props.onChange();
  };

  const updateMasterChecked = () => {
    // Initialise the master checkbox state
    let totalItems = getRows().length;
    let totalCheckedItems = getRows().filter((e) => isSelected(e)).length;
    setmasterChecked(totalItems === totalCheckedItems);
  };

  const checkItem = (row) => {
    // Check/uncheck the td input above
    let input = document.getElementById(`check_${row.id}`);
    input.checked = !input.checked;
    onItemCheck({ target: input }, row);
  };

  const getRows = () => {
    let filteredRows = [];
    if (props.filterCallback) {
      filteredRows = props.rows[props.name].filter(props.filterCallback);
    } else {
      filteredRows = props.rows[props.name];
    }

    if (!filteredRows) {
      return [];
    }

    if (props.skipFilter) {
      return filteredRows;
    }

    // Check if a custom sort callback is provided, use it for sorting
    if (props.sortCallback && typeof props.sortCallback === "function") {
      filteredRows.sort(props.sortCallback);
    }
    // Fallback to default sorting by format_name if sortCallback is not provided
    else if (
      filteredRows.length > 0 &&
      filteredRows[0].format_name !== undefined
    ) {
      filteredRows.sort((a, b) => {
        if (a.format_name.toLowerCase() < b.format_name.toLowerCase()) {
          return -1;
        }
        if (a.format_name.toLowerCase() > b.format_name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    return filteredRows || [];
  };

  const isSelected = (row) => {
    // return true if the row is in the selectedRows array
    if (
      props.selectedRows &&
      props.selectedRows[props.name] &&
      props.selectedRows[props.name].find((item) => item.id === row.id)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    return () => {
      setmasterChecked(false);
    };
  }, []);

  useEffect(() => {
    updateMasterChecked();
  }, [props.selectedRows]);

  function getFormatName(row, formatNameCallback) {
    let name;

    // Check if formatNameCallback is provided and is a function
    if (formatNameCallback && typeof formatNameCallback === "function") {
      name = formatNameCallback(row);
    } else {
      // Existing logic to determine the name format
      name =
        row.format_name !== undefined
          ? props.noTitleCase
            ? row.format_name
            : adminContext.toTitleCase(row.format_name)
          : adminContext.toTitleCase(row.last_name) +
            ", " +
            adminContext.toTitleCase(row.first_name);

      // Append "(Terminated)" if row is terminated
      if (row.is_terminated) {
        name += " (Terminated)";
      }
    }

    return name;
  }

  return (
    <div className="table-container">
      {props.selectedRows && props.selectedRows[props.name] ? (
        <Table className="list-widget-table width-100" striped>
          <thead>
            <tr>
              <td scope="col">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={props.disabled || false}
                      checked={masterChecked || false}
                      color="primary"
                      id="mastercheck"
                      onChange={(e) => onMasterCheck(e)}
                    />
                  }
                />
              </td>
              <td scope="col ">
                <div className="larger-text">
                  <strong>
                    SELECT ALL/NONE
                    <span className="float-right">
                      {`${Math.min(
                        props.selectedRows[props.name].length,
                        getRows().length
                      )}/${getRows().length}`}
                    </span>
                  </strong>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {getRows() &&
              getRows().map((row) => {
                // Determine if there's a callback and call it if it exists, else default to empty string
                const rowStyle =
                  props.rowStyleCallback !== undefined && props.rowStyleCallback
                    ? props.rowStyleCallback(row)
                    : "";

                return (
                  <tr
                    key={row.id}
                    className={`${
                      isSelected(row) ? "selected" : ""
                    } ${rowStyle}`}
                  >
                    <th scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={props.disabled || false}
                            checked={isSelected(row) || false}
                            color="primary"
                            className="text-blue"
                            id={`check_${row.id}`}
                            onChange={(e) => onItemCheck(e, row)}
                          />
                        }
                      />
                    </th>
                    <td className="word-break" onClick={() => checkItem(row)}>
                      <span
                        className={`larger-text ${
                          row.is_terminated || row.text_danger
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {getFormatName(row, props.formatNameCallback)}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : null}
    </div>
  );
}

export default ListWidget;
