import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";
import TableWidget from "../../../components/Widgets/TableWidget.js";

const SalarySacrifice = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);

    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=employee_sacrifices`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const columns = [
    {
      label: "Description",
      accessor: "description",
      widget: "Text",
    },
    {
      label: "Employee Fund",
      accessor: "employee_fund",
      widget: "Text",
      valueCallback: (employeeSacrifice) => {
        let fund = adminContext.currentEmployee.employee_funds.find(
          (ef) => Number(ef.id) === Number(employeeSacrifice.super_fund_id)
        );

        if (fund === undefined) {
          return "";
        }
        return fund.format_name;
      },
    },
    {
      label: "Value",
      accessor: "value",
      widget: "Text",
    },
    {
      label: "Is Super/RESC?",
      accessor: "is_super",
      widget: "BooleanLabel",
    },
    {
      label: "Is Included In Super Calculations (OTE)?",
      accessor: "is_included_in_super_calculations",
      widget: "BooleanLabel",
    },
  ];

  const createSacrifice = () => {
    let superFundId = null;
    if (adminContext.currentEmployee.employee_funds.length > 0) {
      superFundId = adminContext.currentEmployee.employee_funds[0].id;
    }
    return {
      id: null,
      description: "Salary Sacrifice",
      amount: { _decimal: 0 },
      is_super: true,
      is_included_in_super_calculations: true,
      is_deleted: false,
      is_percentage: false,
      percentage: 0,
      super_fund_id: superFundId,
    };
  };

  useEffect(() => {
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.employee_sacrifices]);

  const updateSacrifices = (sacrifice) => {
    // If the sacrifice is not super, employee_fund and super_fund_id should be null
    var newSacrifice = { ...sacrifice };
    if (!sacrifice.is_super) {
      newSacrifice = {
        ...sacrifice,
        employee_fund: null,
        super_fund_id: null,
      };
    }
    let newSacrifices = [];
    if (newSacrifice.id === null) {
      newSacrifices = [
        ...adminContext.currentEmployee.employee_sacrifices,
        newSacrifice,
      ];
    } else {
      newSacrifices = adminContext.currentEmployee.employee_sacrifices.map(
        (pr) => {
          if (pr.id === newSacrifice.id) {
            return newSacrifice;
          } else {
            return pr;
          }
        }
      );
    }
    setSaving(true);
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      employee_sacrifices: newSacrifices,
    });
  };

  const formSpec = {
    title: "Salary Sacrifice",
    fields: [
      {
        label: "Description",
        accessor: "description",
        widget: "Text",
        unique: true,
        validate: {
          required: true,
        },
      },
      {
        label: "Super Fund",
        accessor: "super_fund_id",
        widget: "ComboBox",
        args: {
          visibleCallback: (sacrifice) => {
            return sacrifice.is_super;
          },
          validateCallback: (sacrifice) => {
            if (
              sacrifice.is_super &&
              (sacrifice.super_fund_id === null || !sacrifice.super_fund_id)
            ) {
              return "A super fund is required for super sacrifices.";
            }
            return "";
          },
          idAlias: "employeeFundDescription",
          idAliasCallback: (id) => {
            let employeeFundDescription = "";
            adminContext.currentEmployee.employee_funds.forEach((fund) => {
              if (Number(fund.id) === Number(id)) {
                employeeFundDescription = fund.description;
              }
            });
            return employeeFundDescription;
          },
          comboDataCallback: () => {
            let comboData = {};
            adminContext.currentEmployee.employee_funds.forEach((fund) => {
              comboData[fund.id] = fund.format_name;
            });
            return comboData;
          },
        },
      },
      {
        label: "Amount",
        accessor: "amount",
        widget: "MoneyEdit",
        args: {
          visibleCallback: (sacrifice) => {
            return !sacrifice.is_percentage;
          },
        },
      },
      {
        label: "Percentage",
        accessor: "percentage",
        widget: "PercentageEdit",
        args: {
          visibleCallback: (sacrifice) => {
            return sacrifice.is_percentage;
          },
        },
      },
      {
        label: "Is Percentage?",
        accessor: "is_percentage",
        widget: "CheckBox",
      },
      {
        label: "Is Super?",
        accessor: "is_super",
        widget: "CheckBox",
      },
      {
        label: "Is Included In Super Calculations (OTE)?",
        accessor: "is_included_in_super_calculations",
        widget: "CheckBox",
      },
    ],
  };

  const deleteSacrifice = (idToDelete) => {
    let sacrificeToMarkDeleted =
      adminContext.currentEmployee.employee_sacrifices.find((sacrifice) => {
        return sacrifice.id === idToDelete;
      });

    updateSacrifices({
      ...sacrificeToMarkDeleted,
      is_deleted: true,
    });
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ||
          adminContext.currentEmployee.employee_sacrifices === undefined ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Salary Sacrifice</h1>
                </Col>
              </Row>
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={
                        adminContext.currentEmployee.employee_sacrifices ===
                        undefined
                          ? []
                          : adminContext.currentEmployee.employee_sacrifices.filter(
                              (sacrifice) => {
                                return !sacrifice.is_deleted;
                              }
                            )
                      }
                      columns={columns}
                      appendRowCallback={createSacrifice}
                      editRowSaveCallback={updateSacrifices}
                      deleteRowCallback={deleteSacrifice}
                      deleteConfirmationAttributes={["description", "value"]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SalarySacrifice;
