import { Container, Row, Col } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import EditForm from "../../../components/Widgets/EditForm";
import TableWidget from "../../../components/Widgets/TableWidget";

const SuperContributions = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
      setSaving(false);
    }
  }, [adminContext.currentEmployee.employee_super]);

  // Update the employee on data change
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };
  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const updatePostTaxSuper = (postTaxSuper) => {
    var newPostTaxSuper = { ...postTaxSuper };

    let newEmployeeSuper = [];
    if (newPostTaxSuper.id === null) {
      newEmployeeSuper = [
        ...adminContext.currentEmployee.employee_super,
        newPostTaxSuper,
      ];
    } else {
      newEmployeeSuper = adminContext.currentEmployee.employee_super.map(
        (pr) => {
          if (pr.id === newPostTaxSuper.id) {
            return newPostTaxSuper;
          } else {
            return pr;
          }
        }
      );
    }
    setSaving(true);
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      employee_super: newEmployeeSuper,
    });
  };

  return (
    <Container className="my-3" fluid>
      {!isLoading ? (
        <Row>
          <Col md="7">
            <Row>
              <Col>
                <EditForm
                  key={adminContext.currentEmployee.id}
                  saveChanges={saveChanges}
                  handleChange={handleChange}
                  modelObj={adminContext.currentEmployee}
                  setModelObj={adminContext.setCurrentEmployee}
                  formSpec={{
                    title: "Superannuation Settings",
                    fixedTitle: true,
                    fields: [
                      {
                        label: "Enabled?",
                        accessor: "is_super_enabled",
                        widget: "CheckBox",
                      },
                      {
                        label: "Compulsory super rate",
                        accessor: "compulsory_super_rate",
                        widget: "PercentageEdit",
                      },
                      {
                        label: "Total super rate",
                        accessor: "super_rate",
                        widget: "PercentageEdit",
                      },
                      {
                        label:
                          "Auto update super rates with first pay of new year?",
                        accessor: "auto_update_super_guarantee_rates",
                        widget: "CheckBox",
                      },
                      {
                        label: "Age threshold enabled?",
                        accessor: "is_super_age_threshold_enabled",
                        widget: "CheckBox",
                      },
                      {
                        label:
                          "Amount Threshold Enabled? (Applies to pre July 2022 only)",
                        accessor: "is_super_amount_threshold_enabled",
                        widget: "CheckBox",
                      },
                      {
                        label:
                          "Amount threshold ($) (Applies to pre July 2022 only)",
                        accessor: "super_amount_threshold",
                        widget: "MoneyEdit",
                      },
                      {
                        label: "Base super on:",
                        accessor: "super_based_on",
                        widget: "ComboBox",
                        args: {
                          comboDataCallback: () => {
                            return adminContext.constants.SUPER_BASED_ON_DICT;
                          },
                        },
                      },
                      {
                        label: "Alert when over concessional cap?",
                        accessor: "alert_when_over_concessional_cap",
                        widget: "CheckBox",
                      },
                      {
                        label: "Alert when over max contribution base?",
                        accessor:
                          "alert_when_super_above_max_contribution_base",
                        widget: "CheckBox",
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md="5">
            <>
              <h3>Post-Tax Additional Superannuation Contributions</h3>
              <p>
                Note: pre-tax super sacrifices should be entered on the{" "}
                <strong>
                  Employees {">>"} Pay Settings {">>"} Salary/Wage Sacrifice
                </strong>{" "}
                screen.
              </p>
              <TableWidget
                rows={
                  adminContext.currentEmployee.employee_super === undefined
                    ? []
                    : adminContext.currentEmployee.employee_super.filter(
                        (row) => row.is_deleted === false
                      )
                }
                columns={[
                  {
                    label: "Description",
                    accessor: "description",
                    widget: "Text",
                  },
                  {
                    label: "Amount",
                    accessor: "amount",
                    widget: "MoneyLabel",
                  },
                ]}
                formSpec={{
                  title: "",
                  fields: [
                    {
                      label: "Description",
                      accessor: "description",
                      widget: "Text",
                    },
                    {
                      label: "Amount",
                      accessor: "amount",
                      widget: "MoneyEdit",
                    },
                  ],
                }}
                appendRowCallback={() => {
                  return {
                    id: null,
                    employee_id: adminContext.currentEmployee.id,
                    description: "Voluntary Contribution",
                    amount: { _decimal: 0 },
                    is_deleted: false,
                  };
                }}
                editRowSaveCallback={updatePostTaxSuper}
                deleteRowCallback={(idToDelete) => {
                  let superToMarkDeleted =
                    adminContext.currentEmployee.employee_super.find(
                      (postTaxSuper) => {
                        return postTaxSuper.id === idToDelete;
                      }
                    );

                  updatePostTaxSuper({
                    ...superToMarkDeleted,
                    is_deleted: true,
                  });
                }}
                deleteConfirmationAttributes={["description", "amount"]}
              />
            </>
          </Col>
        </Row>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};
export default SuperContributions;
