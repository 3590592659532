import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";
import MoneyEdit from "../../../components/Widgets/MoneyEdit";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";

const AdvancedProRataLeaveSettings = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rows, setRows] = useState([]);

  const init = () => {
    setIsLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/additional-leave-settings`,
      (response) => {
        setIsLoading(false);
        setRows(response.data.additional_leave_settings);
      }
    );
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange = (event) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      [event.target.name]: event.target.value,
    });
  };

  // Update the API on submit
  const saveChanges = (e) => {
    e.preventDefault();
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading || !adminContext.currentEmployee ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Advanced Leave Settings</h1>
                </Col>
              </Row>
              {!adminContext.currentEmployee.currentDepartment && (
                <Row>
                  <Col className="mt-3 mx-4">
                    <p>
                      These additional leave accrual settings will only affect
                      employees who are currently set to accrue leave by actual
                      hours paid (pro rata), under{" "}
                      <strong>Leave Settings</strong>. You can utilise these
                      additional leave types via{" "}
                      <strong>
                        Pays {">>"} Edit Pay {">>"} leave taken in this pay
                      </strong>
                      .
                    </p>
                  </Col>
                </Row>
              )}
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              <AvForm>
                <SaveChangesMessageRow
                  saveChanges={saveChanges}
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                />
                <Row>
                  <Col>
                    <div key={adminContext.currentEmployee.id}>
                      <MoneyEdit
                        amount={
                          adminContext.currentEmployee
                            .hourly_amount_for_paid_parental_leave
                        }
                        handleChange={handleChange}
                        label="Hourly Rate Used For Paid Parental Leave ($)"
                        name="hourly_amount_for_paid_parental_leave"
                        step="0.01"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div key={adminContext.currentEmployee.id}>
                      <MoneyEdit
                        amount={
                          adminContext.currentEmployee
                            .hourly_amount_for_workers_comp_leave
                        }
                        handleChange={handleChange}
                        label="Hourly Rate Used For Workers Compensation Leave ($)"
                        name="hourly_amount_for_workers_comp_leave"
                        step="0.01"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TableWidget
                      rows={rows}
                      columns={[
                        {
                          label: "Hourly Accrual Setting",
                          accessor: "setting",
                          widget: "HTML",
                        },
                        {
                          label: "Enabled?",
                          accessor: "is_enabled",
                          widget: "CheckBox",
                          args: {
                            setRowCallback: (row, value) => {
                              setIsLoading(true);
                              adminContext.putRequest(
                                `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/additional-leave-settings/${row.id}`,
                                {
                                  is_enabled: value,
                                },
                                (response) => {
                                  setIsLoading(false);
                                  init();
                                },
                                (error) => {
                                  setIsLoading(false);
                                }
                              );
                            },
                          },
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </AvForm>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default AdvancedProRataLeaveSettings;
