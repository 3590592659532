import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import TableWidget from "../../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";

import AdminContext from "../../../AdminContext";
import qs from "qs";
import DateTimeLabel from "../../../components/Widgets/DateTimeLabel";
import { BiCloudDownload, BiArrowBack } from "react-icons/bi";
import { IconContext } from "react-icons";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";

const SingleTouchMailboxEvent = (props) => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSTPEvent, setCurrentSTPEvent] = useState(false);
  const [responses, setResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [pdfFileData, setPdfFileData] = useState(null);

  const currentSTPEventID = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/pays/single-touch/event/" +
        currentSTPEventID,
      (response) => {
        if (response.data.stp_event) {
          setCurrentSTPEvent(response.data.stp_event);
        }
        if (response.data.stp_response_rows) {
          setResponses(response.data.stp_response_rows);
        }
        if (response.data.selected_response) {
          setSelectedResponse(response.data.selected_response.response);
        }
        if (response.data.pdf_file_data) {
          setPdfFileData(response.data.pdf_file_data);
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const downloadPDF = async () => {
    const fileName = props.name + ".pdf";
    await adminContext.handlePDFDownload(fileName, pdfFileData);
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <>
                {currentSTPEvent ? (
                  <>
                    <Row>
                      <Col md="2">
                        <Button
                          onClick={() => {
                            adminContext.history.goBack();
                          }}
                          className="my-4 mx-auto"
                          color="secondary"
                          type="button"
                        >
                          <IconContext.Provider value={{ size: "30px" }}>
                            <BiArrowBack />
                          </IconContext.Provider>
                          <span className="ml-1">Back</span>
                        </Button>
                      </Col>
                      <Col>
                        {" "}
                        {pdfFileData && (
                          <Button
                            onClick={downloadPDF}
                            className="my-4 mx-auto"
                            color="info"
                            type="button"
                          >
                            <IconContext.Provider value={{ size: "30px" }}>
                              <BiCloudDownload />
                            </IconContext.Provider>
                            <span className="ml-1">Download PDF</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <h2 className="days-one">
                      {currentSTPEvent.service_action.toUpperCase()} submission,
                      sent{" "}
                      {
                        <DateTimeLabel
                          value={currentSTPEvent.timestamp_local}
                        ></DateTimeLabel>
                      }
                    </h2>
                    <p>
                      Note: This screen shows all relevant information
                      associated with this single touch event, including
                      response message information from the ATO. Only one
                      response should be expected from each submission, which
                      may take up to 72 hours. If no response has been received
                      after this time, you should resubmit the employees for the
                      same date via an <strong>Update</strong> event under{" "}
                      <strong>
                        Pays {">>"} Single Touch {">>"} Submit Payroll to ATO{" "}
                        {">>"}
                        Send updated/amended YTD info to the ATO
                      </strong>
                      .
                    </p>
                  </>
                ) : null}

                <Row>
                  <Col>
                    <TableWidget
                      title="Employee Records"
                      label="Employee Records"
                      rows={
                        currentSTPEvent
                          ? currentSTPEvent.stp_pay_event_employees
                          : []
                      }
                      columns={[
                        {
                          label: "Employee",
                          accessor: "format_name",
                        },
                        {
                          label: "STP ID",
                          accessor: "stp_id",
                        },
                        {
                          label: "Document ID",
                          accessor: "document_id",
                        },
                        {
                          label: "Tax File Number",
                          accessor: "tax_file_number",
                          widget: "text",
                        },
                        {
                          label: "Total Income",
                          accessor: "total_disaggregated_gross",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Total Tax",
                          accessor: "total_tax",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Super Guarantee",
                          accessor: "super_guarantee",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Ordinary Time",
                          accessor: "ordinary_time",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "RESC",
                          accessor: "resc",
                          widget: "MoneyLabel",
                        },
                        {
                          label: "Start Date",
                          accessor: "start_date",
                          widget: "DateLabel",
                        },
                        {
                          label: "Termination Date",
                          accessor: "termination_date",
                          widget: "DateLabel",
                        },
                        {
                          label: "Cessation Type Code",
                          accessor: "cessation_type_code",
                        },
                        {
                          label: "Final Pay Indicator",
                          accessor: "final_pay_indicator",
                          widget: "BooleanLabel",
                        },
                        {
                          label: "Income Stream Country Code",
                          accessor: "income_stream_country_code",
                        },
                        {
                          label: "All Income Streams",
                          accessor: "all_income_streams",
                        },
                        {
                          label: "Payee Employment Type Code",
                          accessor: "payee_employment_type_code",
                        },
                        {
                          label: "Tax Treatment Code",
                          accessor: "tax_treatment_code",
                        },
                      ]}
                    />{" "}
                  </Col>
                </Row>
              </>
              <Row>
                <Col>
                  <TableWidget
                    title="ATO Responses (Errors/Warnings)"
                    label="ATO Responses (Errors/Warnings)"
                    rows={responses || []}
                    columns={[
                      {
                        label: "Received On",
                        accessor: "received",
                        widget: "DateLabel",
                      },
                      {
                        label: "Total Information",
                        accessor: "total_information",
                      },
                      {
                        label: "Total Errors",
                        accessor: "total_errors",
                      },
                      {
                        label: "Total Warnings",
                        accessor: "total_warnings",
                      },
                      {
                        label: "Max Severity",
                        accessor: "max_severity",
                      },
                    ]}
                  />
                </Col>
              </Row>
              {selectedResponse && selectedResponse.event_items && (
                <>
                  <Row>
                    <Col>
                      <TableWidget
                        title="Response Details"
                        label="Response Details"
                        rows={selectedResponse.event_items || []}
                        columns={[
                          {
                            label: "Message",
                            accessor: "short",
                          },
                          {
                            label: "Code",
                            accessor: "error",
                          },
                          {
                            label: "Severity",
                            accessor: "severity",
                          },
                          {
                            label: "More Details",
                            accessor: "detailed",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SingleTouchMailboxEvent;
