import { Container, Row, Col, Card, CardBody, Alert, Button } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faHeartbeat, faTrophy } from '@fortawesome/free-solid-svg-icons';

import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";

const LeaveEntitlementAdjustments = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [leaveBalanceRows, setLeaveBalanceRows] = useState([]);
  const [adjustmentRows, setAdjustmentRows] = useState([]);
  const [hasPaysCompleted, setHasPaysCompleted] = useState(false);

  const init = () => {
    setIsLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/leave-adjustments`,
      (response) => {
        setIsLoading(false);
        updateRowsWithResponseData(response.data);
      }
    );
  };

  useEffect(() => {
    init();
  }, [
    adminContext.currentEmployee.leave_balance_rows,
    adminContext.currentEmployee.id,
  ]);

  const saveLeaveBalanceRow = (leaveBalanceRow) => {
    setSaving(true);
    let newLeaveBalanceRows = leaveBalanceRows.map((ba) => {
      if (ba.leave_type === leaveBalanceRow.leave_type) {
        return leaveBalanceRow;
      } else {
        return ba;
      }
    });

    setSaving(true);
    setLeaveBalanceRows(newLeaveBalanceRows);
  };

  const updateRowsWithResponseData = (responseData) => {
    if (responseData.has_pays_completed) {
      setHasPaysCompleted(true);
    }
    if (responseData.leave_balance_rows) {
      setLeaveBalanceRows(responseData.leave_balance_rows);
      setSuccessMessage("Leave balances/adjustments refreshed successfully");
    } else {
      setErrorMessage("Error saving leave balance rows");
    }
    if (responseData.adjustment_rows !== undefined) {
      setAdjustmentRows(responseData.adjustment_rows);
      setSuccessMessage(
        "Leave balances and adjustments refreshed successfully"
      );
    } else {
      setErrorMessage("Error refreshing leave balances/adjustments");
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 7000);
  };

  const saveBalanceRows = () => {
    setSaving(false);
    setIsLoading(true);
    adminContext.putRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/leave-balance-rows`,
      { leave_balance_rows: leaveBalanceRows },
      (response) => {
        updateRowsWithResponseData(response.data);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveBalanceRows();
    }
  }, [saving]);

  const deleteAdjustment = (idToDelete) => {
    setIsLoading(true);
    adminContext.deleteRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/leave-adjustment/${idToDelete}`,
      (response) => {
        setIsLoading(false);
        updateRowsWithResponseData(response.data);
      }
    );
  };

  const navigateToCalculator = (leaveType) => {
    adminContext.history.push(`/admin/employees/leave/${leaveType}_leave_calculator`);
  };  

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Leave Entitlement Adjustments</h1>
                </Col>
                <Col>
                  {errorMessage && (
                    <Alert color="warning" className="text-center">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  )}
                  {successMessage && (
                    <Alert color="success" className="text-center">
                      <strong>{successMessage}</strong>
                    </Alert>
                  )}
                </Col>
              </Row>
              {adminContext.currentEmployee && (
                <>
                  <Row className="justify-content-center">
                    <Col md="auto" className="my-2 px-1">
                      <IconButton
                        onClick={() => navigateToCalculator('holiday')}
                        className="custom-icon-button semi-active-button btn-icon-clipboard align-items-center"
                        size="large"
                        disabled={!hasPaysCompleted}
                      >
                        <FontAwesomeIcon 
                          icon={faPlane} 
                          className="mr-2 leave-calculator-button-holiday" 
                        />
                        <p className="text-black days-one mb-0">
                          Holiday Leave Calculator
                        </p>
                      </IconButton>
                    </Col>
                    <Col md="auto" className="my-2 px-1">
                      <IconButton
                        onClick={() => navigateToCalculator('sick')}
                        className="custom-icon-button semi-active-button btn-icon-clipboard align-items-center"
                        color="primary"
                        size="large"
                        disabled={!hasPaysCompleted}
                      >
                        <FontAwesomeIcon 
                          icon={faHeartbeat} 
                          className="mr-2 leave-calculator-button-sick" 
                        />
                        <p className="text-black days-one mb-0">
                          Sick Leave Calculator
                        </p>
                      </IconButton>
                    </Col>
                    <Col md="auto" className="my-2 px-1">
                      <IconButton
                        onClick={() => navigateToCalculator('long_service')}
                        className="custom-icon-button semi-active-button btn-icon-clipboard align-items-center"
                        size="large"
                        disabled={!hasPaysCompleted}
                      >
                        <FontAwesomeIcon 
                          icon={faTrophy} 
                          className="mr-2 leave-calculator-button-long-service" 
                        />
                        <p className="text-black days-one mb-0">
                          Long Service Leave<br />Calculator
                        </p>
                      </IconButton>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="days-one mt-4 mb-0">
                        Current Leave Balances
                      </h2>
                      <p>
                        Leave balances can be adjusted here for the most recent
                        pay run, or from a particular pay (under{" "}
                        <strong>Pays {">>"} Edit Pay</strong>) if for another
                        date.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TableWidget
                        rows={
                          !leaveBalanceRows
                            ? []
                            : leaveBalanceRows.map((row) => {
                                return {
                                  ...row,
                                  id: row.leave_type,
                                };
                              })
                        }
                        editRowSaveCallback={saveLeaveBalanceRow}
                        columns={[
                          {
                            label: "Leave Type",
                            accessor: "name",
                            widget: "Text",
                          },
                          {
                            label: "Balance",
                            accessor: "amount",
                            widget: "DurationLabel",
                          },
                        ]}
                        formSpec={{
                          title: "Leave Balances",
                          fields: [
                            {
                              label: "Leave Type",
                              accessor: "name",
                              widget: "ReadOnlyText",
                            },
                            {
                              label: "Balance",
                              accessor: "amount",
                              widget: "DurationEdit",
                            },
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="days-one mt-4 mb-0">
                        Manual Leave Adjustments
                      </h2>
                      <p>
                        Below are any past manual adjustments made to leave
                        balances.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TableWidget
                        rows={
                          !adjustmentRows
                            ? []
                            : adjustmentRows.filter((row) => {
                                return row.amount.milliseconds !== 0;
                              })
                        }
                        deleteRowCallback={deleteAdjustment}
                        deleteConfirmationAttributes={["description", "amount"]}
                        columns={[
                          {
                            label: "Date",
                            accessor: "date",
                            widget: "DateLabel",
                          },
                          {
                            label: "Leave Type",
                            accessor: "description",
                            widget: "Text",
                          },
                          {
                            label: "Adjustment",
                            accessor: "amount",
                            widget: "DurationLabel",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default LeaveEntitlementAdjustments;
