import { useContext } from "react";
import AdminContext from "../../AdminContext";

function DurationLabel(props) {
  const adminContext = useContext(AdminContext);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  const convertMsToHM = (value) => {
    let isNegative = false;
    let milliseconds =
      value.milliseconds !== undefined ? value.milliseconds : value;

    if (milliseconds < 0) {
      isNegative = true;
      milliseconds = Math.abs(milliseconds);
    }

    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    let timeStr = "";
    if (adminContext.appConfig.hours_as_decimal_widget.value) {
      const decimalHours = hours + minutes / 60 + seconds / 3600;
      timeStr = `${decimalHours.toFixed(2)}h`;
    } else {
      timeStr = `${padTo2Digits(hours)}h ${padTo2Digits(
        minutes
      )}m ${padTo2Digits(seconds)}s`;
    }

    return isNegative ? `-${timeStr}` : timeStr;
  };

  const getClassName = () => {
    if (
      props.value !== undefined &&
      props.value !== null &&
      props.value.milliseconds < 0
    ) {
      return props.className ? props.className + " text-danger" : "text-danger";
    }
    return props.className ? props.className : "";
  };

  return (
    <span className={getClassName() + " text-right"}>
      {props.value !== undefined && props.value !== null
        ? convertMsToHM(props.value)
        : "N/A"}
    </span>
  );
}

export default DurationLabel;
