import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Container,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const SelectEntity = (props) => {
  const history = useHistory();
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    adminContext.setCurrentEmployee(false);
    const company = adminContext.companies[e.currentTarget.value];
    adminContext.setCompany(company);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/company/${company.id}`
    );
    history.push("/admin/pays");
  };

  // Load up the companies on load
  useEffect(() => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/company/`,
      (response) => {
        // If no companies, redirect to create company
        if (!response.data.companies || response.data.companies.length === 0) {
          adminContext.history.push("/admin/no_companies_exist");
        }
        setLoading(false);
      }
    );
  }, []);

  const createCompanyCols = () => {
    return adminContext.companies.map((company, key) => {
      return (
        <Col md="4" key={key} className="my-3">
          <Button
            className={
              company.id === adminContext.company.id &&
              company.name === adminContext.company.name &&
              company.employees !== undefined
                ? "text-muted bg-gradient-white border-primary width-100 min-height-220"
                : "text-muted width-100 min-height-220"
            }
            id={company.id}
            value={key}
            onClick={
              props.handleClick !== undefined ? props.handleClick : handleClick
            }
          >
            <i className="fa-regular fa-2xl fa-building my-2" />
            <br />
            <h3 className="mt-2">{company.name}</h3>
            <br />
            <p>{"ABN: " + company.abn}</p>
            <br />
          </Button>
        </Col>
      );
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Row className="mx-0">
      <Col>
        <Card className="shadow m-md-4 m-xs-2 m-sm-2">
          <CardHeader className="bg-transparent">
            <h1 className="mb-0 days-one text-center">
              Please Select a Company/Business Entity to Continue
            </h1>
          </CardHeader>
          <CardBody>
            {adminContext.companies ? (
              <Row className="icon-examples">
                {createCompanyCols(adminContext.companies)}
              </Row>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default SelectEntity;
