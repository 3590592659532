import React, { useContext } from "react";
import AdminContext from "../../../../AdminContext";
import { Card, CardHeader, CardBody } from "reactstrap";

const IntroView = () => {
  const adminContext = useContext(AdminContext);

  return (
    <Card>
      <CardHeader>
        <h1 className="days-one text-center mx-2 my-2">Introduction</h1>
      </CardHeader>
      <CardBody className="mx-auto">
        <p>
          This assistant will guide you through the termination of an employee.
        </p>
        <p>
          Use the Back and Next buttons to navigate through the assistant. If
          you make a mistake, click the Back button to go back and edit your
          selection.
        </p>
        <p>
          The Cancel button will exit the assistant without terminating the
          employee.
        </p>
      </CardBody>
    </Card>
  );
};

export default IntroView;
