import { Container, Row, Col, Button, Alert, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";

import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

import AdminContext from "../../AdminContext";

const CompanyAllowances = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/allowances`,
      (response) => {
        if (response.data.allowances) {
          adminContext.setCompany({
            ...adminContext.company,
            allowances: response.data.allowances,
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const allowanceCategoryComboData = () => {
    return {
      CD: "Cents per KM",
      AD: "Award Transport",
      LD: "Laundry",
      MD: "Overtime Meals",
      RD: "Domestic & Overseas Travel/Accommodation",
      TD: "Tools",
      KN: "Tasks",
      QN: "Qualifications/Certificates",
      OD: "Other",
      Unclassified: "Unclassified",
    };
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    setSelectedRows({
      employees: [],
    });
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}?route=allowances`,
      adminContext.company,
      (response) => {
        setIsLoading(false);
        setSaving(false);
        initView();
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [adminContext.company.allowances]);

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Company Allowances</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-4 my-3">
                    {!saving ? (
                      <Row>
                        <Col>
                          <TableWidget
                            total={false}
                            rows={adminContext.company.allowances}
                            setRows={(allowances) => {
                              adminContext.setCompany({
                                ...adminContext.company,
                                allowances: allowances,
                              });
                            }}
                            columns={[
                              {
                                label: "Description",
                                accessor: "description",
                                widget: "text",
                              },
                              {
                                label: "Amount",
                                accessor: "amount",
                                widget: "MoneyLabel",
                                align: "right",
                              },
                              {
                                label: "Is Taxable",
                                accessor: "is_taxable",
                                widget: "BooleanLabel",
                              },
                              {
                                label: "Is Itemised",
                                accessor: "is_itemised",
                                widget: "BooleanLabel",
                              },
                              {
                                label: "Is Included In Super Calculations",
                                accessor: "is_included_in_super_calculations",
                                widget: "BooleanLabel",
                              },
                              {
                                label: "Employees",
                                accessor: "number_of_employees",
                                widget: "number",
                              },
                              {
                                label: "Allowance Category",
                                accessor: "allowance_category_string",
                                widget: "text",
                              },
                            ]}
                            selected={adminContext.company.currentAllowance}
                            setSelected={(allowance) => {
                              setSelectedRows({
                                employees: allowance.employees,
                              });
                              adminContext.setCompany({
                                ...adminContext.company,
                                currentAllowance: allowance,
                              });
                            }}
                            appendRowCallback={() => {
                              return {
                                id: null,
                                company_id: adminContext.company.id,
                                description: "",
                                amount: 0,
                                is_taxable: true,
                                is_itemised: false,
                                is_included_in_super_calculations: true,
                                auto_apply_to_pay: true,
                                allowance_category: "Unclassified",
                                employees: [],
                              };
                            }}
                            editRowSaveCallback={(inputAllowance) => {
                              setSaving(true);
                              let allowance = {
                                ...inputAllowance,
                                employees: selectedRows.employees,
                              };
                              let newAllowances = [];
                              if (allowance.id === null) {
                                newAllowances = [
                                  ...adminContext.company.allowances,
                                  allowance,
                                ];
                              } else {
                                newAllowances =
                                  adminContext.company.allowances.map((a) => {
                                    if (Number(a.id) === Number(allowance.id)) {
                                      return allowance;
                                    } else {
                                      return a;
                                    }
                                  });
                              }
                              adminContext.setCompany({
                                ...adminContext.company,
                                allowances: newAllowances,
                              });
                            }}
                            deleteRowCallback={(idToDelete) => {
                              adminContext.setCompany({
                                ...adminContext.company,
                                allowances:
                                  adminContext.company.allowances.filter(
                                    (allowance) => allowance.id !== idToDelete
                                  ),
                                currentAllowance: false,
                              });
                              setSaving(true);
                            }}
                            deleteConfirmationAttributes={[
                              "description",
                              "amount",
                            ]}
                            formSpec={{
                              title: "Allowance",
                              fields: [
                                {
                                  label: "Description",
                                  accessor: "description",
                                  widget: "text",
                                  required: true,
                                  validate: {
                                    required: true,
                                  },
                                },
                                {
                                  label: "Amount",
                                  accessor: "amount",
                                  widget: "MoneyEdit",
                                },
                                {
                                  label: "Is Taxable?",
                                  accessor: "is_taxable",
                                  widget: "Switch",
                                },
                                {
                                  label: "Is Itemised?",
                                  accessor: "is_itemised",
                                  widget: "Switch",
                                },
                                {
                                  label: "Included In Super Calculations?",
                                  accessor: "is_included_in_super_calculations",
                                  widget: "Switch",
                                },
                                {
                                  label: "Auto Apply To Pay?",
                                  accessor: "auto_apply_to_pay",
                                  widget: "Switch",
                                },
                                {
                                  label: "Allowance Category",
                                  accessor: "allowance_category",
                                  widget: "ComboBox",
                                  required: true,
                                  args: {
                                    comboDataCallback:
                                      allowanceCategoryComboData,
                                    validateCallback: (allowance) => {
                                      let value = allowance.allowance_category;
                                      if (value === "") {
                                        return "Required";
                                      } else if (value == "Unclassified") {
                                        return "Please select a valid category from the dropdown";
                                      } else {
                                        return "";
                                      }
                                    },
                                  },
                                },
                                {
                                  label: "Employees",
                                  accessor: "employees",
                                  widget: "EmployeeListWidget",
                                  args: {
                                    rows: rows,
                                    setRows: setRows,
                                    selectedRows: selectedRows,
                                    setSelectedRows: setSelectedRows,
                                  },
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default CompanyAllowances;
