import { useContext, useState, useEffect } from "react";
import { Button, Modal } from "reactstrap";
import AdminContext from "../../../AdminContext";
import DateRangeView from "../../../components/Widgets/WizardViews/DateRangeView";
import SelectEmployeesView from "../../../components/Widgets/WizardViews/SelectEmployeesView";
import LeaveTypeSelection from "./LeaveTypeSelectionView";
import SelectPaysView from "../../../components/Widgets/WizardViews/SelectPaysView";
import LeaveRecalcResultsView from "./LeaveRecalcResultsView";
import Wizard from "../../../components/Widgets/Wizard";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";
import QuestionModal from "../../../components/Widgets/QuestionModal";

const LeaveRecalcWizardTool = () => {
  const adminContext = useContext(AdminContext);

  // useState hooks
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [adjustmentsMessage, setAdjustmentsMessage] = useState("");

  const convertDateFormat = (dateString) => {
    // Converts "dd/mm/yyyy" format to "yyyy-mm-dd" format
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  // Leave related constants
  const leaveTypes = {
    HOLIDAY: "Annual",
    SICK: "Sick",
    LONG_SERVICE: "Long Service",
  };

  const initialLeaveTypes = Object.keys(leaveTypes).reduce((obj, leaveType) => {
    obj[leaveType] = false;
    return obj;
  }, {});

  // useState hooks that depend on leaveTypes and initialLeaveTypes
  const [wizardInfo, setWizardInfo] = useState({
    ...initialLeaveTypes,
    multiple_pay_runs: true,
    pays: [],
    pdf_data: "",
    company: adminContext.company,
    employees: adminContext.company.employees.filter((e) => !e.is_terminated),
    selected_employees: adminContext.company.employees.filter(
      (e) => !e.is_terminated
    ),
    start_date: convertDateFormat(
      adminContext.appConfig.report_start_date.value
    ),
    end_date: convertDateFormat(adminContext.appConfig.report_end_date.value),
    selected_leave_types: [],
    selected_pays: [],
    show_terminated: false,
  });

  const [rows, setRows] = useState({ pays: [] });
  const [selectedRows, setSelectedRows] = useState({ pays: [] });

  //Helper functions
  const getFormatNameForPay = (pay) => {
    return (
      <>
        Pay <MoneyLabel amount={pay.net._decimal} /> to{" "}
        {pay.employee.format_name} for {pay.date}
      </>
    );
  };

  const getFilteredPays = (pays) => {
    console.log("Pays:", pays);
    return pays;
  };

  // useEffect hooks
  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      pays: rows.pays,
      selected_pays: selectedRows.pays,
    });
  }, [rows, selectedRows]);

  // Update 'nextEnabled' based on whether any leave type is selected when on
  // the leave type selection step.
  useEffect(() => {
    if (step === 3) {
      const hasSelectedLeaveType = Object.keys(leaveTypes).some(
        (leaveType) => wizardInfo[leaveType]
      );
      setNextEnabled(hasSelectedLeaveType);
    }
  }, [wizardInfo, leaveTypes, step]);

  const recalcLeavePaySelectionFormSpec = {
    fixedTitle: true,
    title: "Select Pays",
    shortDescription: "Select the pays to recalculate leave accruals on.",
    fields: [
      {
        label: "",
        accessor: "pays",
        widget: "ListWidget",
        validate: {
          required: false,
        },
        args: {
          rows: rows,
          setRows: setRows,
          selectedRows: selectedRows,
          setSelectedRows: setSelectedRows,
          skipFilter: true,
          noTitleCase: true,
        },
      },
    ],
  };

  const wizardSteps = [
    <DateRangeView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      shortDescription="Please specify the earliest and latest pay dates
                        that you wish to recalculate leave accruals for."
      loading={loading}
      setLoading={setLoading}
    />,
    <SelectEmployeesView
      setForceUpdateButtons={setForceUpdateButtons}
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SelectPaysView
      setForceUpdateButtons={setForceUpdateButtons}
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      getFormatNameForPay={getFormatNameForPay}
      formSpec={recalcLeavePaySelectionFormSpec}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      loading={loading}
      setLoading={setLoading}
      getFilteredPays={getFilteredPays}
    />,
    <LeaveTypeSelection
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      shortDescription="Select the leave types for which you
                        want to recalculate accruals."
      setNextEnabled={setNextEnabled}
      leaveTypes={leaveTypes}
    />,
    <LeaveRecalcResultsView
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
  ];

  const handleNext = () => {
    if (step === getLastStep()) {
      setConfirmationOpen(true);
    } else if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    }
  };

  // Recalculate the leave if the user selects "Yes" on the
  // QuestionModal, opens a success message if it was successful.
  const handleConfirmation = async (confirmation) => {
    setConfirmationOpen(false);
    if (confirmation === "YES") {
      try {
        console.log(
          "Sending request to recalculate leave with data:",
          wizardInfo
        );

        adminContext.postRequest(
          adminContext.constants.BASE_URL +
            "/tools/recalculate_leave/recalculate",
          wizardInfo,
          (response) => {
            console.log("Response Data: ", response.data);
            if (response.data.success) {
              setAdjustmentsMessage(response.data.message);
              setSuccessOpen(true);
            } else {
              console.error("API returned success: false");
            }
          }
        );
      } catch (error) {
        console.error("Error during API call", error);
      }
    }
  };

  // Sends user back after closing the success message
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    adminContext.history.goBack();
  };

  // Navigate to the previous step and enable the 'Next' button
  // to avoid being stuck if navigating back from LeaveTypeSelectionView.
  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setNextEnabled(true);
    }
  };

  const isBackDisabled = () => {
    return step === 0;
  };

  const getLastStep = () => {
    return wizardSteps.length - 1;
  };

  return (
    <>
      <Wizard
        wizardSteps={wizardSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        cancelCallback={adminContext.history.goBack}
        nextEnabled={nextEnabled}
        step={step}
        setStep={setStep}
        buttonsVisible={buttonsVisible}
        forceUpdateButtons={forceUpdateButtons}
        setForceUpdateButtons={setForceUpdateButtons}
        isBackDisabled={isBackDisabled}
        getLastStep={getLastStep}
      />
      <QuestionModal
        isOpen={isConfirmationOpen}
        title="Confirm Leave Recalculation"
        content="Please confirm that you would like to update all previously selected existing leave accruals to the recalculated values. Are you sure you wish to continue?"
        onConfirm={() => handleConfirmation("YES")}
        onDeny={() => handleConfirmation("NO")}
      />
      <Modal
        className="width-40-on-lg d-flex align-items-center"
        isOpen={isSuccessOpen}
        centered={true}
      >
        <div className="mx-4 my-4">
          <h2 className="text-center days-one">Success</h2>
          {`${adjustmentsMessage}`}
          <Button
            color="warning"
            className="float-right mb-2 mx-2"
            onClick={handleSuccessClose}
          >
            {"Close"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default LeaveRecalcWizardTool;
