import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import ComboBox from "../../components/Widgets/ComboBox";
import { CompanyBankAccountFormSpec } from "../../variables/ValidationFormSpecs";

const BankAccountsView = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  const [bankAccounts, setBankAccounts] = useState({});
  const [banks, setBanks] = useState({});
  const [currentRoute, setCurrentRoute] = useState("bank_accounts");

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/company/${adminContext.company.id}/banks`,
      (response) => {
        if (response.data.banks) {
          const banksComboData = {};
          response.data.banks.forEach((bank) => {
            banksComboData[bank.id] = `${bank.name} - ${bank.code}`;
          });
          setBanks(banksComboData);
        }
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}?route=${currentRoute}`,
      adminContext.company,
      (response) => {
        setCurrentRoute("bank_accounts");
        setIsLoading(false);
        setSaving(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
    }
  }, [
    adminContext.company.bank_accounts,
    adminContext.company.super_bank_account_id,
    adminContext.company.pays_bank_account_id,
  ]);

  useEffect(() => {
    let preppedBankAccounts = {};
    adminContext.company.bank_accounts.forEach((bankAccount) => {
      preppedBankAccounts[
        bankAccount.id
      ] = `${bankAccount.bsb} - ${bankAccount.account_number} - ${bankAccount.account_name}`;
    });
    setBankAccounts(preppedBankAccounts);
  }, [adminContext.company.bank_accounts]);

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                {!selected ? (
                  <>
                    <Row>
                      <Col>
                        <h1 className="days-one">Bank Accounts</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          You can use different bank accounts to pay employees
                          and to pay super.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mx-4 my-3">
                        <ComboBox
                          className="mx-3"
                          label="Bank account for pays"
                          skipSort={true}
                          disabled={!adminContext.editAccess("company")}
                          name="pays_bank_account_id"
                          comboData={bankAccounts}
                          selectedComboItem={
                            adminContext.company.pays_bank_account_id
                          }
                          setSelectedComboItem={(data) => {
                            setCurrentRoute("attrs_only");
                            let bankAccountId = adminContext.getKeyByValue(
                              bankAccounts,
                              data
                            );
                            setSaving(true);
                            adminContext.setCompany({
                              ...adminContext.company,
                              pays_bank_account_id: bankAccountId,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mx-4 my-3">
                        <ComboBox
                          disabled={!adminContext.editAccess("company")}
                          className="mx-3"
                          label="Bank account for super"
                          skipSort={true}
                          name="super_bank_account_id"
                          comboData={bankAccounts}
                          selectedComboItem={
                            adminContext.company.super_bank_account_id
                          }
                          setSelectedComboItem={(data) => {
                            setCurrentRoute("attrs_only");
                            let bankAccountId = adminContext.getKeyByValue(
                              bankAccounts,
                              data
                            );
                            setSaving(true);
                            adminContext.setCompany({
                              ...adminContext.company,
                              super_bank_account_id: bankAccountId,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row>
                  <Col>
                    <TableWidget
                      rows={adminContext.company.bank_accounts}
                      setRows={(newRows) => {
                        adminContext.setCompany({
                          ...adminContext.company,
                          bank_accounts: newRows,
                        });
                      }}
                      columns={[
                        {
                          label: "Account Name",
                          accessor: "account_name",
                          widget: "text",
                        },
                        {
                          label: "BSB",
                          accessor: "bsb",
                          widget: "text",
                        },
                        {
                          label: "Account Number",
                          accessor: "account_number",
                          widget: "text",
                        },
                        {
                          label: "User ID / APCA Code",
                          accessor: "user_id",
                          widget: "text",
                        },
                      ]}
                      appendRowCallback={() => {
                        let bank_id = null;

                        if (Object.keys(banks).length > 0) {
                          bank_id = Object.keys(banks)[0];
                        }
                        return {
                          id: null,
                          bsb: "",
                          account_number: "",
                          account_name: "",
                          user_id: "000000",
                          company_id: adminContext.company.id,
                          notes: "",
                          bank_id: bank_id,
                        };
                      }}
                      setSelected={setSelected}
                      deleteRowCallback={(idToDelete) => {
                        setSaving(true);
                        let newAccounts =
                          adminContext.company.bank_accounts.filter(
                            (bankAccount) => {
                              return bankAccount.id !== idToDelete;
                            }
                          );
                        adminContext.setCompany({
                          ...adminContext.company,
                          bank_accounts: newAccounts,
                        });
                      }}
                      deleteConfirmationAttributes={[
                        "account_name",
                        "account_number",
                        "bsb",
                      ]}
                      editRowSaveCallback={(editedBankAccount) => {
                        setSaving(true);

                        let newBankAccounts = [];
                        if (!editedBankAccount.id) {
                          newBankAccounts = [
                            ...adminContext.company.bank_accounts,
                            editedBankAccount,
                          ];
                        } else {
                          newBankAccounts =
                            adminContext.company.bank_accounts.map(
                              (bankAccount) => {
                                if (bankAccount.id === editedBankAccount.id) {
                                  return editedBankAccount;
                                } else {
                                  return bankAccount;
                                }
                              }
                            );
                        }
                        adminContext.setCompany({
                          ...adminContext.company,
                          bank_accounts: newBankAccounts,
                        });
                      }}
                      formSpec={CompanyBankAccountFormSpec(banks)}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default BankAccountsView;
