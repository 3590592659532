import { useState, useEffect } from "react";

function FloatEdit(props) {
  const [tempValue, setTempValue] = useState(props.value);

  useEffect(() => {
    setTempValue(props.value);
  }, [props.value]);

  const isNegative = () => {
    return tempValue < 0;
  };

  const handleChange = (event) => {
    setTempValue(event.target.value);
    if (props.handleChange) {
      props.handleChange(event);
    }
  };

  const handleBlur = (event) => {
    if (props.handleBlur) {
      props.handleBlur(event);
    }
  };

  return (
    <input
      value={tempValue}
      name={props.name}
      onChange={handleChange}
      onBlur={handleBlur}
      className={
        isNegative()
          ? "form-control width-100 text-danger"
          : "form-control width-100"
      }
    />
  );
}

export default FloatEdit;
