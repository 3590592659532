import { Container, Row, Col, Card, CardBody, Alert, Button } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";
import TableWidget from "../../../components/Widgets/TableWidget.js";

const SuperFund = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);

    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=employee_funds`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };
  const columns = [
    {
      label: "Super Provider",
      accessor: "format_name",
      widget: "Text",
    },
    {
      label: "Member Number",
      accessor: "member_number",
      widget: "Text",
    },
    {
      label: "ABN",
      accessor: "abn",
      widget: "Text",
    },
    {
      label: "USI",
      accessor: "usi",
      widget: "Text",
    },
    {
      label: "Primary?",
      accessor: "is_default",
      widget: "BooleanLabel",
    },
  ];

  const createSuperFund = () => {
    return {
      id: null,
      description: "",
      is_deleted: false,
      is_default: true,
      registration_date: new Date(),
      super_fund_provider_id:
        adminContext.superFundProviders.length > 0
          ? adminContext.superFundProviders[0].id
          : null,
    };
  };

  useEffect(() => {
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.employee_funds]);

  const updateSuperFunds = (superFund) => {
    var newSuperFund = { ...superFund };

    // If the new super fund is marked as primary, then we need to mark all other super funds as not primary

    let newSuperFunds = [];
    if (newSuperFund.id === null) {
      newSuperFunds = [
        ...adminContext.currentEmployee.employee_funds.map((pr) => {
          return {
            ...pr,
            is_default: newSuperFund.is_default ? false : pr.is_default,
          };
        }),
        newSuperFund,
      ];
    } else {
      newSuperFunds = adminContext.currentEmployee.employee_funds.map((pr) => {
        if (pr.id === newSuperFund.id) {
          return newSuperFund;
        } else {
          if (newSuperFund.is_default) {
            return {
              ...pr,
              is_default: false,
            };
          } else {
            return pr;
          }
        }
      });
    }

    setSaving(true);
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      employee_funds: newSuperFunds,
    });
  };

  const formSpec = {
    title: "Super Fund",
    fields: [
      {
        label: "Super Fund",
        required: true,
        accessor: "super_fund_provider_id",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            let comboData = {};
            adminContext.superFundProviders.forEach((fund) => {
              comboData[fund.id] = fund.fund_text;
            });
            return comboData;
          },
        },
      },
      {
        label: "Member Number",
        accessor: "member_number",
        widget: "Text",
      },
      {
        label: "Primary?",
        accessor: "is_default",
        widget: "CheckBox",
      },
    ],
  };

  const deleteSuperFund = (idToDelete) => {
    let superFundToMarkDeleted =
      adminContext.currentEmployee.employee_funds.find((superFund) => {
        return superFund.id === idToDelete;
      });
    superFundToMarkDeleted.is_deleted = true;
    updateSuperFunds(superFundToMarkDeleted);
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ||
          adminContext.currentEmployee.employee_funds === undefined ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Super Fund Provider Information</h1>
                </Col>
              </Row>
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <>
                  <Row>
                    <Col>
                      <TableWidget
                        rows={
                          adminContext.currentEmployee.employee_funds ===
                          undefined
                            ? []
                            : adminContext.currentEmployee.employee_funds.filter(
                                (superFund) => {
                                  return !superFund.is_deleted;
                                }
                              )
                        }
                        columns={columns}
                        appendRowCallback={createSuperFund}
                        editRowSaveCallback={updateSuperFunds}
                        deleteRowCallback={deleteSuperFund}
                        deleteConfirmationAttributes={[
                          "format_name",
                          "member_number",
                        ]}
                        formSpec={formSpec}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        color="primary"
                        className="btn btn-lg my-2"
                        onClick={() => {
                          adminContext.history.push(
                            "/admin/create_fund_online_search"
                          );
                        }}
                      >
                        Create New Super Fund (Online Search)
                      </Button>
                    </Col>
                    <Col></Col>
                    <Col>
                      {/* <Button color="primary" className="btn btn-lg">
                        Request New Super Member Registration
                      </Button> */}
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SuperFund;
