import React from "react";
import { AvField } from "availity-reactstrap-validation";

export default function PercentageEdit(props) {
  const handleChange = (e) => {
    const value = e.target.value;

    // Make sure the entered value is either empty (allowing for backspacing), a number, or a decimal number
    if (value === "" || !isNaN(Number(value))) {
      props.setPercentage(value);
    }
  };

  return (
    <>
      <AvField
        name="percentage"
        label={
          props.label
            ? props.label.includes("%")
              ? props.label
              : props.label + " (%)"
            : "Percentage (%)"
        }
        type="text"
        value={props.getPercentage() || "0"}
        onChange={handleChange}
        className="form-control mb-0"
        disabled={props.disabled}
        pattern="^[0-9.]*$"
      />
      <input
        value={props.getPercentage()}
        type="range"
        onChange={handleChange}
        step="0.01"
        className="width-100 mt-0 pt-0 mb-4"
        disabled={props.disabled}
      />
    </>
  );
}
