import AdminContext from "../../AdminContext";
import { useContext } from "react";
import { Button } from "reactstrap";

const BackButton = (props) => {
  const adminContext = useContext(AdminContext);

  return (
    <Button
      color="secondary"
      type="button"
      className={props.className ? props.className : "mb-2"}
      onClick={props.goBack ? props.goBack : adminContext.history.goBack}
    >
      {!props.hideArrow && (
        <>
          <i className="fa-regular fa-arrow-left" />{" "}
        </>
      )}
      {props.label ? props.label : "Back"}
    </Button>
  );
};

export default BackButton;
