import React, { useState, useEffect, useContext } from "react";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AvForm } from "availity-reactstrap-validation";

const PreviewView = ({ wizardInfo, setWizardInfo, loading, setLoading }) => {
  const adminContext = useContext(AdminContext);
  const [saving, setSaving] = useState(false);

  const downloadABA = async () => {
    const fileName = `direct_entry_${adminContext.payRun.end_date}.aba`;

    await adminContext.handleFileDownload(fileName, wizardInfo.aba_data);
  };

  const generateDirectPaysReportAba = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/direct-entry/file`,
      wizardInfo,
      (response) => {
        setSaving(true);
        setWizardInfo({
          ...wizardInfo,
          aba_data: "data:text/plain;base64," + response.data.aba_file_data,
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (saving) {
      downloadABA();
    }
  }, [wizardInfo.aba_data]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-center">
            <Row>
              <Col>
                <h1 className="text-center days-one">Save Direct Entry File</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-left">
                  Some banks require a debit entry to be included in the ABA
                  file. If you receive an error message when the ABA file is
                  submitted to the bank, create an ABA file with included debit
                  entry by checking the <strong>'Include Debit Entry'</strong>{" "}
                  checkbox here.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <AvForm>
                  <FormControlLabel
                    control={
                      <CheckBox
                        infotext="Some banks require a debit/balancing entry to be included in the ABA file."
                        checked={wizardInfo.include_debit_entry}
                        className="mx-2"
                        name="include_debit_entry"
                        onChange={(event) => {
                          setWizardInfo({
                            ...wizardInfo,
                            include_debit_entry: event.target.checked,
                          });
                        }}
                      />
                    }
                    label="Include Debit Entry?"
                  />
                </AvForm>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={generateDirectPaysReportAba}
                  className="btn btn-lg semi-active-button width-40-on-lg btn-primary mx-auto text-center"
                >
                  Download Direct Entry File
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PreviewView;
