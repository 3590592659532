import { useContext } from "react";
import AdminContext from "../../AdminContext";
import { Button, Modal, Table } from "reactstrap";
import DateLabel from "./DateLabel";

const LicenceDetails = ({ showLicenceDetails, setShowLicenceDetails }) => {
  const adminContext = useContext(AdminContext);
  return (
    <Modal centered={true} isOpen={showLicenceDetails}>
      <div className="my-4">
        <h2 className="text-center days-one">
          {adminContext.isTrial ? "Trial " : ""}Licence/Account Details
        </h2>
        <div className="mx-2">
          {adminContext.user && adminContext.user.username && (
            <>
              <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
                <span className="text-muted text-sm font-weight-bold">
                  Logged In As Username:
                </span>
                <span>{adminContext.user.username}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
                <span className="text-muted text-sm font-weight-bold">
                  Email Address:
                </span>
                <span>{adminContext.user.email}</span>
              </div>
              <hr />
            </>
          )}
          <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
            <span className="text-muted text-sm font-weight-bold">
              Account/Customer ID:
            </span>
            <span>{String(adminContext.customerId).padStart(5, "0")}</span>
          </div>
          {adminContext.primarySubscriptionId && (
            <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
              <span className="text-muted text-sm font-weight-bold">
                Primary Subscription ID:
              </span>
              <span>
                {String(adminContext.primarySubscriptionId).padStart(6, "0")}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
            <span className="text-muted text-sm font-weight-bold">
              Primary Subscription Email:
            </span>
            <span>{adminContext.primarySubscriptionEmail}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
            <span className="text-muted text-sm font-weight-bold">
              Primary Subscription Expiry:
            </span>
            <span>
              {new Date(adminContext.primarySubscriptionExpiry) > new Date() ? (
                <>
                  <DateLabel value={adminContext.primarySubscriptionExpiry} /> (
                  {Math.ceil(
                    (new Date(adminContext.primarySubscriptionExpiry) -
                      new Date()) /
                      (1000 * 60 * 60 * 24)
                  )}{" "}
                  days left)
                </>
              ) : null}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2 mx-1">
            <span className="text-muted text-sm font-weight-bold">
              Total Employees:
            </span>
            <span>
              Currently using{" "}
              <strong>{adminContext.numberActiveEmployees}</strong> out of{" "}
              <strong>
                {adminContext.maxAllowedEmployees === 50000
                  ? "unlimited"
                  : adminContext.maxAllowedEmployees}
              </strong>{" "}
              employees
            </span>
          </div>
          <div className="text-center mb-2">
            <span className="text-muted text-sm font-weight-bold">
              Authenticator App OTP QR Code
            </span>
          </div>
          <div className="text-center">
            <img
              src={
                "data:image/png;base64," +
                adminContext.base64EncodedOtpSecretImg
              }
              alt="Authenticator QR Code"
              className="img-fluid w-25 h-25"
            />
            <br />
            Key: {adminContext.authenticatorSecret}
          </div>
        </div>
        {adminContext.secondaryActiveSubscriptions &&
          adminContext.secondaryActiveSubscriptions.length > 0 && (
            <>
              <hr />
              <div className="text-center mb-2">
                <span className="text-muted text-sm font-weight-bold">
                  Other Active Subscriptions
                </span>
              </div>
              <div className="mx-2 w-90 mx-auto">
                <Table>
                  <thead>
                    <tr>
                      <th className="text-center">Subscription ID</th>
                      <th className="text-center">Expiry</th>
                      <th className="text-center">Total Employees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminContext.secondaryActiveSubscriptions.map((sub) => (
                      <tr key={sub.subscription_id}>
                        <td className="text-center">{sub.subscription_id}</td>
                        <td className="text-center">
                          <DateLabel value={sub.expiry} />
                        </td>
                        <td className="text-center">
                          {sub.total_employees === 0
                            ? "Unlimited"
                            : sub.total_employees}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

        <br />
        <Button
          color="secondary"
          className="float-right mb-2 mx-2"
          onClick={() => setShowLicenceDetails(false)}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default LicenceDetails;
