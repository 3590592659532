import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import DurationLabel from "../../components/Widgets/DurationLabel";
import ComboBox from "../../components/Widgets/ComboBox";
import { useEffect, useState, useContext } from "react";
import AdminContext from "../../AdminContext";
import { FormControlLabel } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import qs from "qs";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import BackButton from "../../components/Widgets/BackButton";

const LeaveRequestView = () => {
  const adminContext = useContext(AdminContext);

  const [isLoading, setIsLoading] = useState(true);
  const [currentLeaveRequest, setCurrentLeaveRequest] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState({});
  const [employeesDict, setEmployeesDict] = useState({});
  const [statuses, setStatuses] = useState({});
  const [currentLeaveRequestDay, setCurrentLeaveRequestDay] = useState(null);
  const [currentLeaveRequestNote, setCurrentLeaveRequestNote] = useState(null);
  const [saving, setSaving] = useState(false);
  const [rows, setRows] = useState({
    working_days: adminContext.constants.WORKING_DAYS,
  });
  const [showProgramGeneratedNotes, setShowProgramGeneratedNotes] =
    useState(false);

  const [selectedRows, setSelectedRows] = useState({
    working_days: [],
  });

  const currentLeaveRequestID = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/rosters/${adminContext.company.id}/leave-request/${
          currentLeaveRequestID || 0
        }`,
      (response) => {
        if (response.data.leave_request) {
          setCurrentLeaveRequest(response.data.leave_request);
          setLeaveTypes(response.data.leave_type_dict);
          setStatuses(response.data.statuses_dict);
          setEmployeesDict(response.data.employees_dict);
          if (response.data.leave_request.employee) {
            let employee = response.data.leave_request.employee;
            let workingDays = rows.working_days.filter((day) =>
              employee.working_days.includes(day.id)
            );

            setSelectedRows({
              working_days: workingDays,
            });
          }
        } else {
          adminContext.history.push("/pays/leave-requests");
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const isEmptyOrFalse = (obj) => {
    if (obj === undefined || obj === null || obj === false) {
      return true;
    }
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    if (saving) {
      setSaving(false);

      setIsLoading(true);
      adminContext.putRequest(
        adminContext.constants.BASE_URL +
          `/rosters/${adminContext.company.id}/leave-request`,
        currentLeaveRequest,
        (response) => {
          setIsLoading(false);
          if (response.data.error) {
            adminContext.setAlertMessage(
              adminContext.stripHtmlTags(response.data.error)
            );
            adminContext.setAlertColor("warning");
          } else if (response.data.errors) {
            adminContext.setAlertMessage(response.data.errors);
            adminContext.setAlertColor("warning");
          } else {
            if (response.data.leave_request) {
              setCurrentLeaveRequest(response.data.leave_request);
            }
            adminContext.setAlertMessage("Changes saved successfully.");
            adminContext.setAlertColor("success");
          }
        }
      );
    }
  }, [currentLeaveRequest]);

  const saveLeaveRequestDay = (row) => {
    setSaving(true);
    let newDays = [];
    if (row.id === null) {
      newDays = [...currentLeaveRequest.leave_request_days, row];
    } else {
      newDays = currentLeaveRequest.leave_request_days.map((lr) => {
        if (lr.id === row.id) {
          return row;
        }
        return lr;
      });
    }
    setCurrentLeaveRequest({
      ...currentLeaveRequest,
      leave_request_days: newDays,
    });
  };

  const saveLeaveRequestNote = (row) => {
    setSaving(true);
    let newNotes = [];
    if (row.id === null) {
      newNotes = [...currentLeaveRequest.leave_request_notes, row];
    } else {
      newNotes = currentLeaveRequest.leave_request_notes.map((lr) => {
        if (lr.id === row.id) {
          return row;
        }
        return lr;
      });
    }
    setCurrentLeaveRequest({
      ...currentLeaveRequest,
      leave_request_notes: newNotes,
    });
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isEmptyOrFalse(currentLeaveRequestDay) &&
            isEmptyOrFalse(currentLeaveRequestNote) && (
              <Row>
                <Col>
                  <BackButton />
                </Col>
                <Col>
                  <h1 className="days-one">Edit Leave Request</h1>
                </Col>
              </Row>
            )}
          {!isLoading ? (
            <>
              {!currentLeaveRequest.id &&
                isEmptyOrFalse(currentLeaveRequestDay) &&
                isEmptyOrFalse(currentLeaveRequestNote) && (
                  <>
                    <ComboBox
                      label="Employee"
                      name="employee"
                      comboData={employeesDict}
                      selectedComboItem={
                        currentLeaveRequest.employee
                          ? currentLeaveRequest.employee.id
                          : null
                      }
                      setSelectedComboItem={(data) => {
                        setSaving(true);

                        setCurrentLeaveRequest({
                          ...currentLeaveRequest,
                          employee: adminContext.getKeyByValue(
                            employeesDict,
                            data
                          ),
                        });
                      }}
                    />
                  </>
                )}

              <hr />
              {currentLeaveRequest ? (
                <>
                  {isEmptyOrFalse(currentLeaveRequestNote) && (
                    <TableWidget
                      label="Leave Request Days"
                      rows={
                        currentLeaveRequest
                          ? currentLeaveRequest.leave_request_days.sort(
                              (a, b) =>
                                new Date(a.start_date) - new Date(b.start_date)
                            )
                          : []
                      }
                      appendRowCallback={() => {
                        return {
                          id: null,
                          leave_type:
                            currentLeaveRequest.selected_leave_type ||
                            "HOLIDAY",
                          start_date: new Date().toISOString().slice(0, 10),
                          end_date: new Date().toISOString().slice(0, 10),
                          duration: { milliseconds: 27360000 },
                        };
                      }}
                      editRowSaveCallback={saveLeaveRequestDay}
                      deleteRowCallback={(idToDelete) => {
                        setSaving(true);
                        setCurrentLeaveRequest({
                          ...currentLeaveRequest,
                          leave_request_days:
                            currentLeaveRequest.leave_request_days.filter(
                              (lr) => lr.id !== idToDelete
                            ),
                        });
                      }}
                      deleteConfirmationAttributes={[
                        "start_date",
                        "displayed_leave_type",
                      ]}
                      currentSelected={currentLeaveRequestDay}
                      setCurrentSelected={setCurrentLeaveRequestDay}
                      formSpec={{
                        title: "Leave Request Day",
                        fields: [
                          {
                            label: "Date",
                            accessor: "start_date",
                            widget: "DateLabel",
                          },
                          {
                            label: "Leave Type",
                            accessor: "leave_type",
                            widget: "ComboBox",
                            args: {
                              comboDataCallback: () => {
                                return leaveTypes;
                              },
                            },
                          },
                          {
                            label: "Duration",
                            accessor: "duration",
                            widget: "DurationEdit",
                          },
                        ],
                      }}
                      newFormSpec={{
                        title: "Leave Request Day",
                        fields: [
                          {
                            label: "Leave Type",
                            accessor: "leave_type",
                            widget: "ComboBox",
                            args: {
                              comboDataCallback: () => {
                                return leaveTypes;
                              },
                            },
                          },
                          {
                            label: "First Day Of Leave",
                            accessor: "start_date",
                            widget: "DateEdit",
                          },
                          {
                            label: "Last Day Of Leave",
                            accessor: "end_date",
                            widget: "DateEdit",
                            args: {
                              validateCallback: (lrd) => {
                                if (lrd.end_date < lrd.start_date) {
                                  return "End date must be greater than or equal to start date";
                                }

                                // Check for overlapping leave request days
                                let overlappingLeaveRequestDays =
                                  currentLeaveRequest.leave_request_days.filter(
                                    (lr) =>
                                      lr.leave_type === lrd.leave_type &&
                                      lr.start_date >= lrd.start_date &&
                                      lr.start_date <= lrd.end_date
                                  );

                                if (overlappingLeaveRequestDays.length >= 1) {
                                  return "Leave request days cannot overlap. This leave request already has an existing leave request day with the same leave type that overlaps with this entry.";
                                }

                                // Check if the start or end date is set to a day the employee does not usually work
                                const isNotWorkingDay = (date) => {
                                  const dayName = date.toLocaleDateString(
                                    "en-US",
                                    { weekday: "long" }
                                  );
                                  return !currentLeaveRequest.employee.working_days.includes(
                                    dayName
                                  );
                                };

                                if (isNotWorkingDay(new Date(lrd.start_date))) {
                                  const startDay = new Date(
                                    lrd.start_date
                                  ).toLocaleDateString("en-US", {
                                    weekday: "long",
                                  });
                                  return `${currentLeaveRequest.employee.format_name_shorter} does not usually work on ${startDay}s. You will either need to change the start date or tick ${startDay} in the list above to include this leave request.`;
                                }

                                if (isNotWorkingDay(new Date(lrd.end_date))) {
                                  const endDay = new Date(
                                    lrd.end_date
                                  ).toLocaleDateString("en-US", {
                                    weekday: "long",
                                  });
                                  return `${currentLeaveRequest.employee.format_name_shorter} does not usually work on ${endDay}s. You will either need to change the end date or tick ${endDay} in the list above to include this leave request.`;
                                }

                                return "";
                              },
                            },
                          },
                          {
                            label: "Duration",
                            accessor: "duration",
                            widget: "DurationEdit",
                            args: {
                              validateCallback: (lrd) => {
                                if (
                                  !lrd.duration.milliseconds ||
                                  lrd.duration.milliseconds < 0
                                ) {
                                  return "Duration must be greater than 0";
                                }
                                return "";
                              },
                            },
                          },

                          {
                            label:
                              "Standard Working Days (Days that this employee can potentially take as leave)",
                            accessor: "working_days",
                            widget: "ListWidget",
                            args: {
                              rows: rows,
                              setRows: setRows,
                              selectedRows: selectedRows,
                              setSelectedRows: setSelectedRows,
                              disabled: true,
                              skipFilter: true,
                              sortCallback: (a, b) => {
                                const daysOrder = [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ];
                                return (
                                  daysOrder.indexOf(a.id) -
                                  daysOrder.indexOf(b.id)
                                );
                              },
                            },
                          },
                        ],
                      }}
                      columns={[
                        {
                          label: "Start Date",
                          accessor: "start_date",
                          widget: "DateLabel",
                        },
                        {
                          label: "Leave Type",
                          accessor: "displayed_leave_type",
                          widget: "Text",
                        },
                        {
                          label: "Daily Duration",
                          accessor: "duration",
                          widget: "DurationLabel",
                        },
                      ]}
                    />
                  )}
                  <hr />
                  {isEmptyOrFalse(currentLeaveRequestDay) &&
                    isEmptyOrFalse(currentLeaveRequestNote) && (
                      <>
                        <div className="mt-3 mb-1">
                          <label>Total Duration:</label>
                        </div>

                        <DurationLabel
                          className={"display-4 font-weight-bold"}
                          value={currentLeaveRequest.leave_request_days.reduce(
                            (acc, lr) => acc + lr.duration.milliseconds,
                            0
                          )}
                        />

                        {currentLeaveRequest.public_holidays_notification !==
                          "" &&
                          currentLeaveRequest.public_holidays_notification !==
                            null && (
                            <Alert color="warning" className="my-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    currentLeaveRequest.public_holidays_notification,
                                }}
                              />
                            </Alert>
                          )}
                        <ComboBox
                          label="Status"
                          name="status"
                          comboData={statuses}
                          selectedComboItem={currentLeaveRequest.status}
                          setSelectedComboItem={(data) => {
                            setSaving(true);
                            setCurrentLeaveRequest({
                              ...currentLeaveRequest,
                              status: adminContext.getKeyByValue(
                                statuses,
                                data
                              ),
                            });
                          }}
                        />
                      </>
                    )}

                  <hr />
                  {isEmptyOrFalse(currentLeaveRequestDay) && (
                    <>
                      {!currentLeaveRequestNote && (
                        <FormControlLabel
                          key={"show_program_generated_notes"}
                          control={
                            <CheckBox
                              checked={showProgramGeneratedNotes}
                              className="mx-2"
                              name="show_program_generated_notes"
                              onChange={(e) => {
                                setShowProgramGeneratedNotes(e.target.checked);
                              }}
                            />
                          }
                          label={"Show Program Generated Notes?"}
                        />
                      )}

                      <TableWidget
                        label="Leave Request Notes"
                        appendRowCallback={() => {
                          return {
                            id: null,
                            created_by: "Approver",
                            created: new Date(),
                            message: "",
                          };
                        }}
                        rows={
                          currentLeaveRequest
                            ? currentLeaveRequest.leave_request_notes
                                .filter(
                                  (lr) =>
                                    showProgramGeneratedNotes ||
                                    lr.created_by !== "Program"
                                )
                                .sort(
                                  (a, b) =>
                                    new Date(b.created) - new Date(a.created)
                                )
                            : []
                        }
                        currentSelected={currentLeaveRequestNote}
                        setCurrentSelected={setCurrentLeaveRequestNote}
                        editRowSaveCallback={saveLeaveRequestNote}
                        formSpec={{
                          title: "Leave Request Note",
                          fields: [
                            {
                              label: "Message",
                              accessor: "message",
                              widget: "TextArea",
                            },
                          ],
                        }}
                        columns={[
                          {
                            label: "Created",
                            accessor: "created",
                            widget: "DateTimeLabel",
                          },
                          {
                            label: "Note Made By",
                            accessor: "created_by",
                            widget: "Text",
                          },
                          {
                            label: "Message",
                            accessor: "message",
                            widget: "Text",
                          },
                        ]}
                      />
                    </>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default LeaveRequestView;
