import { useContext } from "react";
import AdminContext from "../AdminContext";
import validateTFN from "../validators/validateTFN";
import validateABN from "../validators/validateABN";

export const CompanyBankAccountFormSpec = (banks) => {
  return {
    title: "Bank Account",

    fields: [
      {
        label: "Bank",
        accessor: "bank_id",
        widget: "ComboBox",
        required: true,
        infoText: (
          <>
            Select the bank for this account. Banks are managed under{" "}
            <strong>Settings {">>"} Banks</strong>
          </>
        ),
        args: {
          comboDataCallback: () => banks,
        },
      },
      {
        label: "BSB",
        accessor: "bsb",
        widget: "text",
        required: true,
        validate: {
          required: { value: true, message: "BSB is required" },
          pattern: {
            value: "^[0-9]{3}-?[0-9]{3}$",
            errorMessage: "Invalid BSB format",
          },
          minLength: { value: 6 },
          maxLength: { value: 7 },
        },
      },
      {
        label: "Account Number",
        accessor: "account_number",
        widget: "text",
        required: true,
        validate: {
          required: { value: true, message: "Account number is required" },
          pattern: {
            value: "^[0-9]+$",
            errorMessage: "Invalid account number format",
          },
          minLength: { value: 5 },
          maxLength: { value: 18 },
        },
      },
      {
        label: "Account Name",
        accessor: "account_name",
        widget: "Text",
        required: true,
        validate: {
          required: { value: true, message: "Account name is required" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "User ID / APCA Code",
        accessor: "user_id",
        widget: "Text",
        required: true,
        validate: {
          pattern: {
            value: "^[0-9]{6}$",
            errorMessage: "Invalid format. Must be a six digit number.",
          },
        },
        args: {
          validateCallback: (bankAccount) => {
            // Ensure the user_id is a six digit number
            let value = bankAccount.user_id;
            if (!value || value === "") {
              return "User ID is required";
            } else if (value.length !== 6) {
              return "User ID must be a six digit number.";
            } else {
              return "";
            }
          },
        },
        infoText: (
          <>
            {" "}
            Must be a six digit number. Please see{" "}
            <a
              href="https://www.lightningpayroll.com.au/faq?selected=17"
              target="_blank"
            >
              our FAQs page
            </a>{" "}
            for information on direct entry user ID numbers.
          </>
        ),
      },
      {
        label: "Notes",
        accessor: "notes",
        widget: "textarea",
      },
    ],
  };
};

export const EmployeeBankAccountFormSpec = () => {
  return {
    title: "Add / Edit Bank Account",
    fields: [
      {
        label: "Account Name",
        widget: "Text",
        accessor: "account_name",
        required: true,
        validate: {
          required: { value: true, message: "Account name is required" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "BSB",
        widget: "Text",
        accessor: "bsb",
        required: true,
        validate: {
          required: { value: true, message: "BSB is required" },
          pattern: {
            value: "^[0-9]{3}-?[0-9]{3}$",
            errorMessage: "Invalid BSB format",
          },
          minLength: { value: 6 },
          maxLength: { value: 7 },
        },
      },
      {
        label: "Account Number",
        widget: "Text",
        accessor: "account_number",
        required: true,
        validate: {
          required: { value: true, message: "Account number is required" },
          pattern: {
            value: "^[0-9]+$",
            message: "Invalid account number format",
          },
          minLength: { value: 5 },
          maxLength: { value: 18 },
        },
      },
      {
        label: "Amount",
        widget: "Number",
        accessor: "amount",
        args: {
          step: ".01",
          min: 0,
        },
        validate: {
          pattern: {
            value: "^[0-9]+([.][0-9]{1,2})?$",
            message: "Invalid amount format",
          },
          minLength: { value: 0 },
          maxLength: { value: 10 },
        },
      },
      {
        label: "Alt. Transaction Reference",
        widget: "Text",
        accessor: "alt_transaction_reference",
        validate: {
          minLength: { value: 0 },
          maxLength: { value: 18 },
        },
      },
    ],
  };
};

const EmployeeFormSpec = () => {
  const adminContext = useContext(AdminContext);
  return {
    title: "Employee",
    fields: [
      {
        label: "Tax File Number",
        widget: "Text",
        accessor: "tax_file_number",
        required: true,
        validate: { customValidation: validateTFN },
      },
      {
        label: "First Name",
        widget: "Text",
        accessor: "first_name",
        required: true,
        validate: {
          required: { value: true, message: "This is a required field" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "Middle Name",
        widget: "Text",
        accessor: "middle_name",
        validate: {
          maxLength: { value: 32 },
        },
      },
      {
        label: "Last Name",
        widget: "Text",
        accessor: "last_name",
        required: true,
        validate: {
          required: { value: true, message: "This is a required field" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "Date of Birth",
        widget: "DateEdit",
        accessor: "date_of_birth",
        required: true,
      },
      {
        label: "Address1",
        widget: "Text",
        accessor: "address1",
        required: true,
        validate: {
          required: { value: true, message: "This is a required field" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "Address2",
        widget: "Text",
        accessor: "address2",
        validate: {
          maxLength: { value: 32 },
        },
      },
      {
        label: "City",
        widget: "Text",
        accessor: "city",
        required: true,
        validate: {
          required: { value: true, message: "This is a required field" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "State",
        widget: "ComboBox",
        accessor: "state",
        required: true,
        args: {
          comboDataCallback: () => adminContext.constants.STATES,
        },
      },
      {
        label: "Postcode",
        widget: "Text",
        accessor: "postcode",
        required: true,
        validate: {
          required: { value: true, message: "This is a required field" },
          minLength: { value: 4 },
          maxLength: { value: 32 },
          pattern: {
            value: "^[0-9]+$",
            message: "Invalid postcode format",
          },
        },
      },
      {
        label: "Country",
        widget: "ComboBox",
        accessor: "country",
        required: true,
        args: {
          comboDataCallback: () => adminContext.constants.COUNTRY_CODES,
        },
      },
      {
        label: "Mobile Phone",
        widget: "Text",
        accessor: "phone_mobile",
        validate: {
          minLength: { value: 9 },
          maxLength: { value: 11 },
          pattern: {
            value: "^[0-9]{9,11}$",
            errorMessage: "Invalid phone format, must be 9-11 numbers only",
          },
        },
      },
      {
        label: "Home Phone",
        widget: "Text",
        accessor: "phone_home",
        validate: {
          minLength: { value: 9 },
          maxLength: { value: 11 },
          pattern: {
            value: "^[0-9]{9,11}$",
            errorMessage: "Invalid phone format, must be 9-11 numbers only",
          },
        },
      },
      {
        label: "Start Date",
        widget: "DateEdit",
        accessor: "start_date",
        required: true,
      },
      {
        label: "TFN Dec. Signed Date",
        widget: "DateEdit",
        accessor: "tfnd_signed_date",
        required: true,
      },
      {
        accessor: "stp_employment_status",
        label: "STP Employment Status",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => adminContext.constants.STP_EMPLOYMENT_STATUS,
        },
      },
    ],
  };
};

// Basic Company FormSpec
export const CompanyBasicFormSpec = () => {
  const adminContext = useContext(AdminContext);
  return {
    title: "Company",
    fields: [
      {
        label: "Tax Name",
        widget: "Text",
        accessor: "tax_name",
        required: true,
      },
      {
        label: "Contact First Name",
        widget: "Text",
        accessor: "contact_first_name",
        required: true,
        validate: {
          // Add the specific validation function for contact_first_name
        },
      },
      {
        label: "Contact Middle Name",
        widget: "Text",
        accessor: "contact_middle_name",
        validate: {
          // Add the specific validation function for contact_middle_name
        },
      },
      {
        label: "Contact Last Name",
        widget: "Text",
        accessor: "contact_last_name",
        required: true,
        validate: {
          // Add the specific validation function for contact_last_name
        },
      },
      {
        label: "Email",
        widget: "Text",
        accessor: "email",
        required: true,
        validate: {
          // Add the specific validation function for email
        },
      },
      {
        label: "ABN",
        widget: "Text",
        accessor: "abn",
        required: true,
        validate: {
          // Add the specific validation function for abn
        },
      },
      {
        label: "Phone",
        widget: "Text",
        accessor: "phone",
        required: true,
        validate: {
          minLength: { value: 9 },
          maxLength: { value: 11 },
          pattern: {
            value: "^[0-9]{9,11}$",
            errorMessage: "Invalid phone format, must be 9-11 numbers only",
          },
        },
      },
    ],
  };
};

// STP Company FormSpec
export const CompanySTPFormSpec = () => {
  const adminContext = useContext(AdminContext);
  return {
    title: "Company (STP)",
    fields: [
      ...CompanyBasicFormSpec().fields,
      {
        label: "Address1",
        widget: "Text",
        accessor: "address1",
        required: true,
        validate: {
          // Add the specific validation function for address1
        },
      },
      {
        label: "Address2",
        widget: "Text",
        accessor: "address2",
        validate: {
          // Add the specific validation function for address2
        },
      },
      {
        label: "City",
        widget: "Text",
        accessor: "city",
        required: true,
        validate: {
          // Add the specific validation function for city
        },
      },
      {
        label: "State",
        widget: "ComboBox",
        accessor: "state",
        required: true,
        args: {
          comboDataCallback: () => adminContext.constants.STATES,
          validateCallback: (allowance) => {
            let value = allowance.allowance_category;
            if (value === "") {
              return "Required";
            } else if (value == "Unclassified") {
              return "Please select a valid category from the dropdown";
            } else {
              return "";
            }
          },
        },
      },
      {
        label: "Postcode",
        widget: "Text",
        accessor: "postcode",
        required: true,
        validate: {
          pattern: {
            value: "^[0-9]{4}$",
            message: "Postcode must be 4 digits",
          },
        },
      },
      {
        label: "Location ID",
        widget: "Text",
        accessor: "location_id",
        required: true,
        validate: {
          required: { value: true, message: "Location ID is required" },
          pattern: {
            value: "^[0-9]{1,3}$",
            errorMessage:
              "Location ID must be a number between 1 & 999, inclusive.",
          },
        },
      },
    ],
  };
};

export const SupplierInformationFormSpec = () => {
  const adminContext = useContext(AdminContext); // Use context if needed

  return {
    title: "Supplier Information",
    fields: [
      {
        label: "ABN",
        widget: "Text", // Assume using a generic Text input unless a custom one is specified
        accessor: "abn",
        required: true,
        args: {
          validateCallback: (supplier) => {
            if (!validateABN(supplier.abn)) {
              return "Invalid ABN";
            }
            return "";
          },
        },
      },
      {
        label: "Name",
        widget: "Text",
        accessor: "name",
        required: true,
        validate: {
          required: { value: true, message: "Name is required" },
        },
      },
      {
        label: "Phone",
        widget: "Text", // Assume using a generic Text input unless a custom one is specified
        accessor: "phone",
        required: true,
        validate: {
          minLength: { value: 9 },
          maxLength: { value: 11 },
          pattern: {
            value: "^[0-9]{9,11}$",
            errorMessage: "Invalid phone format, must be 9-11 numbers only",
          },
        },
      },
      {
        label: "Email",
        widget: "Email", // Assuming using a generic Email input type
        accessor: "email",
        required: true,
        validate: {
          pattern: {
            value: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
            message: "Invalid email format",
          },
        },
      },
      {
        label: "Registered Agent Number",
        widget: "Text",
        accessor: "tax_agent_number",
      },
    ],
  };
};

export const SuperFundProviderFormSpec = () => {
  const adminContext = useContext(AdminContext);
  return {
    title: "Super Fund Provider",
    fields: [
      {
        label: "Is Active?",
        accessor: "is_active",
        widget: "CheckBox",
      },
      {
        label: "Type",
        accessor: "provider_type",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              SUPER_FUND: "Super Fund",
              SMSF: "Self Managed Super Fund",
              CLEARING_HOUSE: "Clearing House",
              NON_COMPLIANT: "Non-compliant Super Fund",
            };
          },
        },
      },
      {
        label: "Name",
        accessor: "name",
        widget: "Text",
        validate: {
          required: true,
        },
      },
      {
        label: "ABN",
        accessor: "abn",
        widget: "Text",
        args: {
          validateCallback: (superFund) => {
            if (superFund.is_active && !validateABN(superFund.abn)) {
              return "Invalid ABN";
            }
            return "";
          },
        },
      },
      {
        label: "USI",
        accessor: "usi",
        widget: "Text",
      },
      {
        label: "Electronic Address",
        accessor: "eaddress",
        widget: "Text",
        args: {
          visibleCallback: (superFund) => {
            return superFund.provider_type === "SMSF";
          },
        },
      },
      {
        label: "Phone",
        accessor: "phone",
        widget: "Text",
      },
      {
        label: "Payment Method",
        accessor: "payment_method",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              DirectCredit: "Direct Credit",
              DirectDebit: "Direct Debit",
              Bpay: "Bpay",
              EFT: "EFT",
              CreditCard: "Credit Card",
              Cheque: "Cheque",
              InPerson: "In Person",
            };
          },
        },
      },
      {
        label: "BPay Biller Code",
        accessor: "bpay_biller_code",
        widget: "Text",
      },
      {
        label: "Account Name",
        widget: "Text",
        accessor: "account_name",
        required: true,
        validate: {
          required: { value: true, message: "Account name is required" },
          minLength: { value: 1 },
          maxLength: { value: 32 },
        },
      },
      {
        label: "BSB",
        widget: "Text",
        accessor: "bsb",
        required: true,
        validate: {
          required: { value: true, message: "BSB is required" },
          pattern: {
            value: "^[0-9]{3}-?[0-9]{3}$",
            errorMessage: "Invalid BSB format",
          },
          minLength: { value: 6 },
          maxLength: { value: 7 },
        },
      },
      {
        label: "Account Number",
        widget: "Text",
        accessor: "account_number",
        required: true,
        validate: {
          required: { value: true, message: "Account number is required" },
          pattern: {
            value: "^[0-9]+$",
            message: "Invalid account number format",
          },
          minLength: { value: 5 },
          maxLength: { value: 18 },
        },
      },
    ],
  };
};

export const EmployeeSuperFundFormSpec = () => {
  const adminContext = useContext(AdminContext);
  return {
    title: "Employee Super Fund",
    fields: [
      {
        label: "Member/Account Number",
        accessor: "member_number",
        widget: "Text",
        required: true,
        validate: {
          required: { value: true, message: "Super member number is required" },
          minLength: { value: 1 },
          maxLength: { value: 20 },
        },
      },
    ],
  };
};

export const BackPaymentFormSpec = () => {
  const adminContext = useContext(AdminContext);
  return {
    title: "Back Payment",
    shortDescription: (
      <>
        Below is where you can define a back payment for{" "}
        <strong>
          {adminContext.pay.employee.first_name}{" "}
          {adminContext.pay.employee.last_name}.
        </strong>
      </>
    ),
    validateFormCallback: (back_payment) => {
      if (!back_payment.description) {
        return "Please enter a description.";
      }

      if (
        !back_payment.amount ||
        typeof back_payment.amount._decimal === "undefined" ||
        isNaN(back_payment.amount._decimal)
      ) {
        return "Please enter a valid amount.";
      }

      if (Number(back_payment.amount._decimal) === 0) {
        return "Please enter a valid amount.";
      }

      if (
        back_payment.tax_method !== "NORMAL_EARNINGS" &&
        back_payment.amount._decimal < 0
      ) {
        return "Please choose 'Normal Earnings' for the tax method when entering a negative amount.";
      }

      if (
        ["METHOD_A_WHOLE_YEAR", "NORMAL_EARNINGS", "METHOD_B2"].includes(
          back_payment.tax_method
        )
      ) {
        return "";
      }
    
      let dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (
        !dateRegex.test(back_payment.start_date) ||
        !dateRegex.test(back_payment.end_date)
      ) {
        return "Please enter a valid start and end date.";
      }
    
      if (back_payment.start_date > back_payment.end_date) {
        return "The start date must be before the end date.";
      }
    
      const today = new Date().toISOString().split("T")[0];
      if (back_payment.start_date > today) {
        return "The start date must not be in the future.";
      }
      if (back_payment.end_date > today) {
        return "The end date must not be in the future.";
      }
    
      return "";
    },    
    fields: [
      {
        label: "Description",
        accessor: "description",
        widget: "Text",
      },
      {
        label: "Included In Super Calculations?",
        accessor: "is_included_in_super_calculations",
        widget: "CheckBox",
      },
      {
        label: "STP Category",
        accessor: "stp_category",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              "Bonus/Commission (Non Director's Fees)":
                "Bonus/Commission (Non Director's Fees)",
              "Director's Fees": "Director's Fees",
              Gross: "Gross",
              "Itemised AllowancesAD": "Itemised Allowances - Award Transport",
              "Itemised AllowancesCD": "Itemised Allowances - Cents per KM",
              "Itemised AllowancesKN": "Itemised Allowances - Tasks",
              "Itemised AllowancesLD": "Itemised Allowances - Laundry",
              "Itemised AllowancesMD": "Itemised Allowances - Overtime Meals",
              "Itemised AllowancesOD": "Itemised Allowances - Other",
              "Itemised AllowancesQN":
                "Itemised Allowances - Qualifications/Certificates",
              "Itemised AllowancesRD":
                "Itemised Allowances - Domestic & Overseas Travel/Accommodation",
              "Itemised AllowancesTD": "Itemised Allowances - Tools",
              LeaveA: "Leave - Ancillary",
              LeaveC: "Leave - Cash Out",
              LeaveO: "Leave - Other/General",
              LeaveP: "Leave - Paid Parental",
              LeaveU: "Leave - Unused On Termination",
              LeaveW: "Leave - Workers Comp",
              Overtime: "Overtime",
              Unclassified: "Unclassified",
            };
          },
        },
      },
      {
        label: "Amount",
        accessor: "amount",
        widget: "MoneyEdit",
      },
      {
        label: "Tax Method",
        accessor: "tax_method",
        widget: "ComboBox",
        args: {
          comboDataCallback: () => {
            return {
              NORMAL_EARNINGS: "Normal Earnings",
              METHOD_B2: "Method B (ii) (Whole Year)",
              METHOD_A_WHOLE_YEAR: "Method A (Whole Year)",
              METHOD_A_SPECIFIC_RANGE: "Method A (Use Date Range)",
              METHOD_B1: "Method B (i) (Use Date Range)",
            };
          },
        },
      },
      {
        label: "",
        accessor: "tax_method_faq",
        widget: "Label",
        valueCallback: (row) => {
          return (
            <>
              Click{" "}
              <a href="https://www.lightningpayroll.com.au/faq?selected=208">
                <strong>here</strong>
              </a>{" "}
              for more information on tax methods.
            </>
          );
        },
      },
      {
        label: "Start Date",
        accessor: "start_date",
        widget: "DateEdit",
        args: {
          visibleCallback: (row) => {
            return (
              !row.is_return_to_work &&
              (row.tax_method == "METHOD_A_SPECIFIC_RANGE" ||
                row.tax_method == "METHOD_B1")
            );
          },
        },
      },
      {
        label: "End Date",
        accessor: "end_date",
        widget: "DateEdit",
        args: {
          visibleCallback: (row) => {
            return (
              !row.is_return_to_work &&
              (row.tax_method == "METHOD_A_SPECIFIC_RANGE" ||
                row.tax_method == "METHOD_B1")
            );
          },
        },
      },
    ],
  };
};

export const formSpecModelMap = {
  BankAccount: {
    specCallback: EmployeeBankAccountFormSpec,
  },
  CompanyBankAccount: {
    specCallback: CompanyBankAccountFormSpec,
  },
  Employee: {
    specCallback: EmployeeFormSpec,
  },
  Company: {
    specCallback: CompanySTPFormSpec,
  },
  CompanySTP: {
    specCallback: CompanySTPFormSpec,
  },
  SuperFundProvider: {
    specCallback: SuperFundProviderFormSpec,
  },
  EmployeeSuperFund: {
    specCallback: EmployeeSuperFundFormSpec,
  },
  SupplierInformation: {
    specCallback: SupplierInformationFormSpec,
  },
  BackPayment: {
    specCallback: BackPaymentFormSpec,
  }
};
