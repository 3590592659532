const logs = [];

const overrideConsoleMethods = () => {
  const originalLog = console.log;
  console.log = (...args) => {
    logs.push(args.join(" "));
    originalLog.apply(console, args);
  };

  const originalError = console.error;
  console.error = (...args) => {
    logs.push(args.join(" "));
    originalError.apply(console, args);
  };

  const originalWarn = console.warn;
  console.warn = (...args) => {
    logs.push(args.join(" "));
    originalWarn.apply(console, args);
  };

  const originalInfo = console.info;
  console.info = (...args) => {
    logs.push(args.join(" "));
    originalInfo.apply(console, args);
  };
};

const getLogs = () => logs;

export { overrideConsoleMethods, getLogs };
