import { useState, useEffect } from "react";
import EditForm from "../../../components/Widgets/EditForm";

const PayslipNotesAndFormatView = ({ wizardInfo, setWizardInfo }) => {
  return (
    <div>
      <EditForm
        liveEdit={true}
        formSpec={{
          title: "Create Payslips",
          fixedTitle: true,
          fields: [
            {
              label:
                "Add the following note to all the generated payslips (optional).",
              accessor: "payslip_notice",
              widget: "TextArea",
            },
            {
              label: "Payslip Theme",
              accessor: "payslip_theme",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => wizardInfo.payslip_themes_available,
                showBlank: false,
              },
              validate: {
                required: false,
              },
            },
            {
              label: "Payslip Format",
              accessor: "payslip_report_type",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => wizardInfo.payslip_reports_available,
                showBlank: false,
              },
              validate: {
                required: false,
              },
            },

            {
              label: "Export Options",
              widget: "GroupBox",
              accessor: "export_option",
              args: {
                checkable: true,
                children: [
                  {
                    label: "Print and email payslips",
                    accessor: "print_email",
                  },
                  {
                    label: "Print payslips",
                    accessor: "print",
                  },
                  {
                    label: "Email payslips",
                    accessor: "email",
                  },
                ],
              },
            },
          ],
        }}
        setModelObj={setWizardInfo}
        modelObj={wizardInfo}
      />
    </div>
  );
};

export default PayslipNotesAndFormatView;
