import React, { useContext, useState } from "react";
import { Row, Col } from "reactstrap";
import EditForm from "../../../../components/Widgets/EditForm";
import AdminContext from "../../../../AdminContext";

const DateOfBirthView = ({
  pay,
  payTermination,
  setPayTermination,
  setNextStep,
  nextEnabled,
  setNextEnabled,
}) => {
  const adminContext = useContext(AdminContext);
  const { employee } = adminContext;

  const [form, setForm] = useState({
    date_of_birth: employee.date_of_birth,
  });

  // ... other necessary code

  return (
    <div>
      <p>
        The date of birth for this employee needs to be set in order to
        calculate the Employee Termination Payment tax amount correctly.
      </p>

      <Row>
        <Col>
          <EditForm
            liveEdit={true}
            formSpec={{
              title: "",
              fields: [
                {
                  label: "Date of Birth",
                  accessor: "date_of_birth",
                  widget: "DateEdit",
                  validate: {
                    required: true,
                  },
                },
                // ... other necessary fields and logic
              ],
            }}
            setModelObj={setForm}
            modelObj={form}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DateOfBirthView;
