import { AvField } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";

const MoneyEdit = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const propsAmount =
    props.amount && !isNaN(Number(props.amount._decimal))
      ? Number(props.amount._decimal)
      : 0;
  const [inputValue, setInputValue] = useState(
    Number(props.amount._decimal) || ""
  );
  const [amount, setAmount] = useState(Number(props.amount._decimal) || "");

  const handleChange = (e) => {
    let newValue = e.target.value.replace(/[^\-0-9.]/g, "");
    const decimalPointIndex = newValue.indexOf(".");

    while (newValue.indexOf(".", decimalPointIndex + 1) > -1) {
      newValue =
        newValue.slice(0, newValue.indexOf(".", decimalPointIndex + 1)) +
        newValue.slice(newValue.indexOf(".", decimalPointIndex + 1) + 1);
    }

    if (decimalPointIndex > -1 && decimalPointIndex < newValue.length - 3) {
      newValue = newValue.slice(0, decimalPointIndex + 3);
    }

    setInputValue(newValue);

    setIsEditing(true);
    setTimeout(() => {
      setIsEditing(false);
    }, 8000);
    setAmount(newValue);

    props.handleChange({
      target: {
        name: e.target.name,
        value: { _decimal: newValue },
      },
    });
  };

  const onFocus = (e) => {
    if (
      Number(e.target.value) == 0 ||
      e.target.value === "" ||
      !e.target.value
    ) {
      e.target.value = "";
    }
    setAmount(e.target.value);
  };

  const onBlur = (e) => {
    if (e.target.value === "") {
      e.target.value = "0";
      setAmount(0);
    }
    setIsEditing(false);
    setAmountText();
    if (props.onBlur !== undefined) {
      props.onBlur(e);
    }
  };

  const setAmountText = () => {
    let decimalPrecision = props.decimalPrecision ? props.decimalPrecision : 2;
    setAmount(propsAmount.toFixed(decimalPrecision));
  };

  useEffect(() => {
    if (!isEditing) {
      setAmountText();
    }
  }, [propsAmount]);

  const negativeStyle = () => {
    if (Number(amount) < 0) {
      return "text-danger";
    }
    return "";
  };

  return (
    <AvField
      onChange={handleChange}
      onFocus={onFocus}
      type="text"
      onBlur={onBlur}
      min={0}
      className={negativeStyle()}
      value={amount}
      name={props.name ? props.name : "amount"}
      label={
        props.label
          ? props.label.includes(" ($)")
            ? props.label
            : props.label + " ($)"
          : "Amount ($)"
      }
      validate={{
        required: {
          value: true,
          errorMessage: "Please enter an amount",
        },
        pattern: {
          value: "^-?[0-9]+([.][0-9]{1,6})?$",
          errorMessage: "Invalid amount",
        },
      }}
      disabled={props.disabled}
    />
  );
};

export default MoneyEdit;
