import { useState, useEffect } from "react";
import AvField from "availity-reactstrap-validation/lib/AvField";

const MultiplierEdit = (props) => {
  const [text, setText] = useState(props.value);

  const handleChange = (e) => {
    props.setValue(e.target.value);
  };

  return (
    <AvField
      key={props.name}
      onBlur={(e) => {
        setText(props.value);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      onChange={handleChange}
      name={props.name}
      label={
        props.label +
        (props.strictLabel
          ? ""
          : " (of the ordinary pay rate. E.g. 2.0 is equal to double time)")
      }
      type={"text"}
      value={text}
      pattern="^\d+(\.\d{1,6})?$"
      errorMessage="Please enter a valid decimal number (up to 6 decimal places)."
    />
  );
};
export default MultiplierEdit;
