import React, { useRef, useState } from "react";
import Lottie from "lottie-react";
import lightningAnimation from "../../assets/lightning_animation.json";

export default function LoadingSpinner(props) {
  const lottieRef = useRef();
  const [currentDirection, setCurrentDirection] = useState(1);

  const handleComplete = () => {
    lottieRef.current.setSpeed(2);
    if (currentDirection === 1) {
      lottieRef.current.setDirection(-1);
      lottieRef.current.play();
    } else {
      lottieRef.current.setDirection(1);
      lottieRef.current.play();
    }

    setCurrentDirection(currentDirection * -1);
  };

  return (
    <>
      <div
        className={
          props.className
            ? props.className
            : props && props.fit
            ? "d-flex align-items-center mx-auto text-center"
            : "d-flex align-items-center full-height mx-auto text-center"
        }
      >
        <Lottie
          lottieRef={lottieRef}
          animationData={lightningAnimation}
          style={{
            maxWidth: props.width ? props.width : "20vw",
            maxHeight: props.height ? props.height : "20vh",
            width: props.width ? props.width : "20vw",
            height: props.height ? props.height : "20vh",
          }}
          className="mx-auto my-auto"
          loop={false}
          autoplay={true}
          onComplete={handleComplete}
        />
      </div>
    </>
  );
}
