import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../../AdminContext";
import Wizard from "../../../../components/Widgets/Wizard";
import SingleTouchSelectionView from "./SingleTouchSelectionView";
import SingleTouchPreviewView from "./SingleTouchPreviewView";
import ValidationPage from "../../../../components/Widgets/WizardViews/ValidationPage";
import QuestionModal from "../../../../components/Widgets/QuestionModal";

const SingleTouchWizard = () => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationItems, setValidationItems] = useState([]);
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [webAppAtoModalOpen, setWebAppAtoModalOpen] = useState(false);
  const [finalPayModalOpen, setFinalPayModalOpen] = useState(false);
  const [finalPayModalShown, setFinalPayModalShown] = useState(false);

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  const submitAs = adminContext.appConfig.submit_as_business_intermediary.value
    ? "business_intermediary"
    : "registered_agent";

  const [wizardInfo, setWizardInfo] = useState({
    final_pay_indicator: false,
    submit_as_business_intermediary:
      adminContext.appConfig.submit_as_business_intermediary.value,
    submit_as: submitAs,
    submit_as_supplier: adminContext.appConfig.submit_as_supplier.value,
    pay_run: adminContext.payRun,
    service_action: "",
    emp_ytd: {},
    supplier_info: {},
    processed_date: new Date(),
    financial_year: {},
    company: adminContext.company,
    employees: [],
    selected_employees: [],
    new_event_employees: [],
    update_event_employees: [],
    processed_date_rationale: "",
    likely_last_pay_of_fy: false,
    company_declaration_required: true,
    supplier_declaration_required: true,
    web_app_ato_declaration_required: true,
    submit_zero_dollars: false,
    gross_adj: 0,
    tax_adj: 0,
  });

  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });

  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      employees: rows.employees,
      selected_employees: selectedRows.employees,
    });
  }, [rows, selectedRows]);

  const handleNext = () => {
    if (step === 0) {
      const {
        final_pay_indicator,
        likely_last_pay_of_fy,
        company_declaration_required,
        supplier_declaration_required,
        web_app_ato_declaration_required,
        submit_as_supplier,
      } = wizardInfo;

      if (
        !final_pay_indicator &&
        likely_last_pay_of_fy &&
        !finalPayModalShown
      ) {
        setFinalPayModalOpen(true);
        setFinalPayModalShown(true);
        return;
      }

      if (submit_as_supplier && supplier_declaration_required) {
        setSupplierModalOpen(true);
        return;
      }

      if (!submit_as_supplier && company_declaration_required) {
        setCompanyModalOpen(true);
        return;
      }

      if (web_app_ato_declaration_required) {
        setWebAppAtoModalOpen(true);
        return;
      }
    }
    if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    } else {
      adminContext.history.goBack();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setNextEnabled(true);
    }
  };

  const validate = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        "/pays/single-touch/validate" +
        `?company_id=${adminContext.company.id}&pay_run_date=${
          adminContext.payRun.end_date
        }&pay_period=${adminContext.payRun.pay_period.toUpperCase()}`,
      wizardInfo,
      (response) => {
        if (response.data.validation_items) {
          if (response.data.validation_items.length === 0) {
            handleNext();
            return;
          }
          setValidationItems(response.data.validation_items);
        }
        setLoading(false);
      }
    );
  };

  const wizardSteps = [
    <SingleTouchSelectionView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      loading={loading}
      setLoading={setLoading}
    />,

    <ValidationPage
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      handleNext={handleNext}
      loading={loading}
      setLoading={setLoading}
      validationItems={validationItems}
      validate={validate}
    />,
    <SingleTouchPreviewView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      loading={loading}
      setLoading={setLoading}
    />,
  ];

  return (
    <>
      <Wizard
        wizardSteps={wizardSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        buttonsVisible={buttonsVisible}
        cancelCallback={adminContext.history.goBack}
        nextEnabled={nextEnabled}
        step={step}
        setStep={setStep}
        forceUpdateButtons={forceUpdateButtons}
        setForceUpdateButtons={setForceUpdateButtons}
        isBackDisabled={() => {
          return step === 0;
        }}
        getLastStep={() => {
          return wizardSteps.length - 1;
        }}
      />
      <QuestionModal
        isOpen={supplierModalOpen}
        title="Declaration"
        content={
          <>
            <p>
              It appears that this is your first time submitting STP within
              Lightning Payroll. To proceed you must agree to the following, as
              required by the ATO.
            </p>
            <p>
              "I have prepared this payroll report in accordance with the
              information supplied by the entity. I have received a declaration
              from the entity stating that the information provided to me is
              true and correct. I am authorised by the entity to give this
              document to the Commissioner."
            </p>
          </>
        }
        onConfirm={() => {
          setSupplierModalOpen(false);
          setWizardInfo({
            ...wizardInfo,
            supplier_declaration_required: false,
          });
        }}
        onDeny={() => setSupplierModalOpen(false)}
      />
      <QuestionModal
        isOpen={companyModalOpen}
        title="Declaration"
        content={
          <>
            <p>
              It appears that this is your first time submitting STP within
              Lightning Payroll. To proceed you must agree to the following, as
              required by the ATO.
            </p>
            <p>
              "I declare the information transmitted in this payroll report is
              true and correct and I am authorised to make this declaration."
            </p>
          </>
        }
        onConfirm={() => {
          setCompanyModalOpen(false);
          setWizardInfo({
            ...wizardInfo,
            company_declaration_required: false,
          });
        }}
        onDeny={() => setCompanyModalOpen(false)}
      />
      <QuestionModal
        isOpen={webAppAtoModalOpen}
        title="Cloud Software Authentication & Authorisation Declaration"
        content={
          <>
            <p>
              To continue with an STP submission via this web application, the
              ATO requires you to agree to the following declaration:
            </p>
            <p>
              "I acknowledge that Intellitron Pty Ltd, through the use of
              Lightning Payroll, is not providing an agent service and is not
              responsible for the preparation of any taxation, superannuation or
              other related documents on behalf of my business/entity. It can,
              however, submit transmissions (e.g. lodgements and prefill)
              through the SBR channel that my business/entity chooses to make
              through Lightning Payroll."
            </p>
          </>
        }
        onConfirm={() => {
          setWebAppAtoModalOpen(false);
          setWizardInfo({
            ...wizardInfo,
            web_app_ato_declaration_required: false,
          });
        }}
        onDeny={() => setWebAppAtoModalOpen(false)}
      />

      <QuestionModal
        isOpen={finalPayModalOpen}
        title="Final Pay Of Financial Year?"
        content={
          <p>
            Is this the last pay you will be making to these employees for the
            financial year?
          </p>
        }
        onConfirm={() => {
          setFinalPayModalOpen(false);
          setWizardInfo({
            ...wizardInfo,
            final_pay_indicator: true,
          });
        }}
        onDeny={() => {
          setFinalPayModalOpen(false);
          setWizardInfo({
            ...wizardInfo,
            final_pay_indicator: false,
          });
        }}
      />
    </>
  );
};
export default SingleTouchWizard;
