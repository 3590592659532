import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import Wizard from "../../../components/Widgets/Wizard";
import SelectMessagesView from "./SelectMessagesView";
import ValidationPage from "../../../components/Widgets/WizardViews/ValidationPage";
import SuperStreamPreviewView from "./SuperStreamPreviewView";
import SuperStreamSaveFileView from "./SuperStreamSaveFileView";
import SuperStreamDirectEntrySummaryView from "./SuperStreamDirectEntrySummaryView";

const SuperStreamDirectEntryWizard = () => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationItems, setValidationItems] = useState([]);

  const [wizardInfo, setWizardInfo] = useState({
    company: adminContext.company,
    messages: adminContext.superStreamMessages || [],
    deposits: [],
    processing_date: new Date(),
    file_description: "Superstream Contributions",
    debit_lodgement_reference: "SuperStream Contributions",
    default_filename: "superstream",
    show_processing_date: true,
    company_bank_account: adminContext.company.super_bank_account,
    include_debit_entry: adminContext.company.include_debit_entry,
    include_paid: true,
    start_date: new Date(),
    end_date: new Date(),
  });

  const [saving, setSaving] = useState(false);

  const [rows, setRows] = useState({
    messages: [],
  });

  const [selectedRows, setSelectedRows] = useState({
    messages: [],
  });

  useEffect(() => {
    // Update the messages in wizardInfo when selectedRows changes
    setWizardInfo({
      ...wizardInfo,
      messages: selectedRows.messages,
    });
  }, [selectedRows]);

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  const messageSelectionFormSpec = {
    fixedTitle: true,
    title: "Message Selection",
    shortDescription: (
      <>
        Select the messages that you wish to include in the Direct Entry file.
        You can select messages based on Sent Date, Status, and whether they
        have previously been included in a Direct Entry file.
        <br />
        Note: For Progressive messages, the failed deposits are <b>not</b>{" "}
        included within the file.
      </>
    ),
    fields: [
      {
        label: "Start Date",
        accessor: "start_date",
        widget: "DateEdit",
      },
      {
        label: "End Date",
        accessor: "end_date",
        widget: "DateEdit",
      },
      {
        label: "Processing Date",
        accessor: "processing_date",
        widget: "DateLabel",
      },
      {
        label: "Include Paid Deposits?",
        accessor: "include_paid",
        widget: "CheckBox",
      },
      {
        label: "Messages",
        accessor: "messages",
        widget: "ListWidget",
        validate: { required: false },
        args: {
          rows: rows,
          setRows: setRows,
          selectedRows: selectedRows,
          setSelectedRows: setSelectedRows,
          noTitleCase: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (
      (adminContext.superStreamMessages &&
        adminContext.superStreamMessages.length > 0 &&
        step === 0)
    ) {
      setStep(1);
    }
  }, [wizardInfo.messages]);  
  
  const handleNext = () => {
    if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    } else {
      setSaving(true);
      adminContext.setNewSuperFundDeposits([]);
      adminContext.setSuperStreamMessages([]);
    }
  };

  useEffect(() => {
    if (saving) {
      adminContext.history.push("/admin/super_fund_deposits");
    }
  }, [adminContext.newSuperFundDeposits]);

  const validate = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/direct-entry-superstream",
      wizardInfo,
      (response) => {
        if (response.data.validation_items) {
          if (response.data.validation_items.length === 0) {
            handleNext();
            return;
          }
          setValidationItems(response.data.validation_items);
        }
        setLoading(false);
      }
    );
  };

  const wizardSteps = [
    <SelectMessagesView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setForceUpdateButtons={setForceUpdateButtons}
      formSpec={messageSelectionFormSpec}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setButtonsVisible={setButtonsVisible}
      loading={loading}
      setLoading={setLoading}
    />,
    <ValidationPage
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      handleNext={handleNext}
      loading={loading}
      setLoading={setLoading}
      validate={validate}
      validationItems={validationItems}
    />,
    <SuperStreamPreviewView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      setForceUpdateButtons={setForceUpdateButtons}
      loading={loading}
      setLoading={setLoading}
    />,
    <SuperStreamSaveFileView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
    />,
    <SuperStreamDirectEntrySummaryView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      loading={loading}
      setLoading={setLoading}
    />,
  ];

  return (
    <Wizard
      wizardSteps={wizardSteps}
      handleNext={handleNext}
      hideBack={true}
      buttonsVisible={buttonsVisible}
      cancelCallback={() => {
        adminContext.setNewSuperFundDeposits([]);
        adminContext.history.push("/admin/super_fund_deposits");
      }}
      nextEnabled={nextEnabled}
      step={step}
      setStep={setStep}
      forceUpdateButtons={forceUpdateButtons}
      setForceUpdateButtons={setForceUpdateButtons}
      isBackDisabled={() => {
        return step === 0 || (step === 2 && !wizardInfo.multiple_pay_runs);
      }}
      getLastStep={() => {
        return wizardSteps.length - 1;
      }}
    />
  );
};
export default SuperStreamDirectEntryWizard;
