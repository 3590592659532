import React from "react";
import { FormControl } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";

function InfoLabel(props) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const tooltipCloseTimeout = React.useRef(null);

  const showTooltip = () => {
    clearTimeout(tooltipCloseTimeout.current);
    setTooltipOpen(true);
  };

  const hideTooltip = () => {
    tooltipCloseTimeout.current = setTimeout(() => {
      setTooltipOpen(false);
    }, 3000); // Delay before the tooltip disappears
  };

  const tooltipId = `tooltip_${JSON.stringify(props.label)
    .replace(/[^\w]/g, "")
    .replace(/\s+/g, "_")}`;

  return (
    <FormControl fullWidth>
      {props.label && (
        <>
          <label className="form-label font-heavy">
            {props.label}
            {props.infoText && (
              <>
                {" "}
                <FontAwesomeIcon
                  id={tooltipId}
                  icon={faInfoCircle}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={tooltipId}
                  toggle={hideTooltip}
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                >
                  {props.infoText}
                </Tooltip>
              </>
            )}
          </label>
          <br />
        </>
      )}
    </FormControl>
  );
}
export default InfoLabel;
