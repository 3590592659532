import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";

const Bonuses = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=employee_bonuses`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const setCurrentBonus = (bonus) => {
    // Set the employees current bonus to the current bonus
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentBonus: bonus,
    });
  };

  const createBonus = () => {
    return {
      id: null,
      amount: { _decimal: 0 },
      is_deleted: false,
      description: "",
      is_included_in_super_calculations: true,
      is_directors_fees: false,
      auto_apply_to_pay: true,
      tax_method: "NORMAL_EARNINGS",
    };
  };

  const deleteBonus = (idToDelete) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      bonuses: adminContext.currentEmployee.bonuses.filter(
        (bonus) => bonus.id !== idToDelete
      ),
      currentBonus: false,
    });
    setSaving(true);
  };

  const updateErrorMessage = () => {
    let totalPercentage = adminContext.currentEmployee.bonuses.reduce(
      (total, bonus) => {
        return total + Number(bonus.percentage);
      },
      0
    );

    if (totalPercentage > 1) {
      setErrorMessage(
        "The combined percentages of these bonuses is greater than 100%"
      );
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    updateErrorMessage();
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.bonuses]);

  const updateBonuses = (bonus) => {
    setSaving(true);
    let newBonuses = [];
    if (bonus.id === null) {
      newBonuses = [...adminContext.currentEmployee.bonuses, bonus];
    } else {
      newBonuses = adminContext.currentEmployee.bonuses.map((a) => {
        if (a.id === bonus.id) {
          return bonus;
        } else {
          return a;
        }
      });
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      bonuses: newBonuses,
    });
  };

  const columns = [
    {
      label: "Description",
      accessor: "description",
      widget: "Text",
    },
    {
      label: "Amount",
      accessor: "amount",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Tax Method",
      accessor: "tax_method_display",
      widget: "Text",
    },
    {
      label: "Is Directors Fees?",
      accessor: "is_directors_fees",
      widget: "BooleanLabel",
    },
    {
      label: "Included in Super Calculations?",
      accessor: "is_included_in_super_calculations",
      widget: "BooleanLabel",
    },
    {
      label: "Auto Apply to Pay?",
      accessor: "auto_apply_to_pay",
      widget: "BooleanLabel",
    },
  ];

  const formSpec = {
    title: "Bonus",
    fields: [
      {
        label: "Description",
        accessor: "description",
        widget: "Text",
        required: true,
      },
      {
        label: "Amount",
        accessor: "amount",
        widget: "MoneyEdit",
      },
      {
        label: "Auto Apply to Pay?",
        accessor: "auto_apply_to_pay",
        widget: "CheckBox",
      },
      {
        label: "Is Directors Fees?",
        accessor: "is_directors_fees",
        widget: "CheckBox",
      },
      {
        label: "Included in Super Calculations?",
        accessor: "is_included_in_super_calculations",
        widget: "CheckBox",
      },
    ],
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Bonuses</h1>
                </Col>
              </Row>
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={
                        adminContext.currentEmployee.bonuses
                          ? adminContext.currentEmployee.bonuses
                          : []
                      }
                      columns={columns}
                      selected={adminContext.currentEmployee.currentBonus}
                      setSelected={setCurrentBonus}
                      appendRowCallback={createBonus}
                      editRowSaveCallback={updateBonuses}
                      deleteRowCallback={deleteBonus}
                      deleteConfirmationAttributes={["description", "amount"]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default Bonuses;
