import React, { useState, useEffect, useContext } from "react";
import TableWidget from "../TableWidget";
import LoadingSpinner from "../LoadingSpinner";
import { Card, CardBody } from "reactstrap";
import ValidationFixView from "./ValidationFixView";

const ValidationPage = ({
  setNextEnabled,
  setButtonsVisible,
  loading,
  validate,
  validationItems,
}) => {
  const [selectedValidationModel, setSelectedValidationModel] = useState(null);

  useEffect(() => {
    setButtonsVisible(selectedValidationModel === null);
  }, [selectedValidationModel]);

  useEffect(() => {
    validate();
  }, [selectedValidationModel]);

  useEffect(() => {
    setNextEnabled(validationItems.length === 0);
  }, [validationItems]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          {!selectedValidationModel ? (
            <CardBody className="text-center">
              <h1 className="text-center days-one">Validation</h1>
              <p>
                Below are errors and recommended settings encountered. Any
                changes made will be saved for the future.
              </p>
              <TableWidget
                label="Please fix the following errors:"
                rows={validationItems}
                columns={[
                  {
                    label: "Object",
                    accessor: "format_name",
                  },
                  {
                    label: "Errors",
                    accessor: "validation_errors_count",
                  },
                  {
                    label: "Fix",
                    accessor: "fix",
                    widget: "Button",
                    args: {
                      buttonIcon: "fa-regular fa-screwdriver-wrench",
                      text: "Fix",
                      onClickCallback: (validationModel) => {
                        setSelectedValidationModel(validationModel);
                      },
                    },
                  },
                ]}
              />
            </CardBody>
          ) : (
            <ValidationFixView
              selectedValidationModel={selectedValidationModel}
              setSelectedValidationModel={setSelectedValidationModel}
            />
          )}
        </Card>
      )}
    </>
  );
};
export default ValidationPage;
