import EmployeesNav from "../EmployeesNav";

const PaySettingsNav = (props) => {
  return (
    <EmployeesNav
      subComponent={props.subComponent}
      routesSubPath="pay_settings"
    />
  );
};
export default PaySettingsNav;
