import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import { Button, Card, CardBody, Alert } from "reactstrap";
import { useState, useEffect, useContext } from "react";
import AdminContext from "../../../AdminContext";
import { isPlatform } from "@ionic/react";

const PayslipPrintView = ({
  wizardInfo,
  setWizardInfo,
  loading,
  setLoading,
}) => {
  const adminContext = useContext(AdminContext);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const downloadPDF = async () => {
    const fileName = `payslips_export_${adminContext.payRun.end_date}.pdf`;
    await adminContext.handlePDFDownload(fileName, wizardInfo.pdf_data);
  };

  const generatePayslipPdf = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/payslips/pdf`,
      wizardInfo,
      (response) => {
        setWizardInfo({
          ...wizardInfo,
          pdf_data:
            "data:application/pdf;base64," + response.data.pdf_file_data,
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    generatePayslipPdf();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-center">
            <h1 className="text-center days-one">Print/Preview Payslips</h1>
            <Button
              onClick={downloadPDF}
              className="btn btn-lg semi-active-button width-40-on-lg btn-primary mx-auto text-center"
            >
              <i className="fas fa-download"></i>
              <br />
              Download
            </Button>
            {isPlatform("hybrid") && (
              <Button
                onClick={() => downloadPDF(true)}
                className="btn btn-lg semi-active-button width-40-on-lg btn-primary mx-auto text-center"
              >
                <i className="fas fa-share"></i>
                <br />
                Share
              </Button>
            )}

            <Alert
              color="info"
              isOpen={alertVisible}
              className="my-2"
              toggle={() => setAlertVisible(false)}
            >
              {alertMessage}
            </Alert>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default PayslipPrintView;
