import React, { useState, useContext, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Alert,
  Col,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router";
import axios from "axios";
import { getBaseUrl } from "../../variables/Config";
import AuthContext from "../../AuthContext";

const ResetPassword = ({ email, setEmail }) => {
  const authLoading = useContext(AuthContext);
  const isMounted = useRef(true);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();

  // Before proceeding we need to check if a code query string is present in the URL
  // If it is Notification, redirect to the login page
  const resetCode = new URLSearchParams(window.location.search).get("code");
  const customerId = new URLSearchParams(window.location.search).get("customer_id");
  const userId = new URLSearchParams(window.location.search).get("user_id");
  
  if (!resetCode) {
    history.push("/auth/login");
  }

  const submitNewPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (password1 === "" || password2 === "") {
      setErrorMessage("Please fill in both password fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      setLoading(false);
      return;
    }

    if (password1 !== password2) {
      setErrorMessage("Passwords do not match.");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      setLoading(false);

      return;
    }

    const opts = {
      password1: password1,
      password2: password2,
      code: resetCode,
      customer_id: customerId,
      user_id: userId,
    };

    axios
      .post(getBaseUrl() + "/validate-reset-password", opts)
      .then((response) => {
        setLoading(false);

        if (!isMounted.current) return;

        if (response.data.error) {
          showError(response.data.error);
          return;
        }

        if (response.data.success) {
          showSuccess();
        }
      })
      .catch((error) => {
        console.log("Error");
        console.log(error);
        if (!isMounted.current) return;
        setLoading(false);
        if (error.status === 429) {
          showError("Too many attempts. Please try again after 30 minutes.");
          return;
        }
        showError("Incorrect details. Please try again.");
      });
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const showSuccess = () => {
    setSuccessMessage("Success! Redirecting to login page...");
    // Save the password to local storage
    localStorage.setItem("password", password1);
    setTimeout(() => {
      setSuccessMessage("");
      // Redirect to login page
      history.push("/auth/login?email=" + email);
    }, 5000);
  };

  return (
    <>
      <Col lg="8" md="8">
        {errorMessage && (
          <Row>
            <Col>
              <Alert color="warning">
                <strong>{errorMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}
        {successMessage && (
          <Row>
            <Col>
              <Alert color="success">
                <strong>{successMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg="12" md="12">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <p className="text-center">
                  Please enter your new password below
                </p>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={submitNewPassword}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Enter new password"
                        type="password"
                        className="text-center text-lg"
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm new password"
                        type="password"
                        className="text-center text-lg"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    {loading || authLoading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          color="primary"
                          className="mx-auto d-block btn-lg text-lg width-100 m-2"
                        >
                          Save Password
                        </Button>
                      </>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ResetPassword;
