import TableWidget from "../../../components/Widgets/TableWidget";

const EditPayLeaveTaken = ({
  adminContext,
  setSaving,
  getLeaveAmount,
  getLeaveLoadingAmount,
  getTotalAmount,
}) => {
  const saveLeaveRow = (leaveRow) => {
    setSaving(true);
    let newLeaveRows = [];
    if (leaveRow.id === null) {
      newLeaveRows = [...adminContext.pay.leave, leaveRow];
    } else {
      newLeaveRows = adminContext.pay.leave.map((ba) => {
        if (ba.id === leaveRow.id) {
          return leaveRow;
        } else {
          return ba;
        }
      });
    }
    adminContext.setPay({
      ...adminContext.pay,
      leave: newLeaveRows,
    });
  };

  return (
    <TableWidget
      label="Leave Taken In This Pay"
      rows={adminContext.pay.leave}
      total={false}
      appendRowCallback={() => {
        return {
          id: null,
          leave_type: "HOLIDAY",
          description: "",
          time_taken: {
            milliseconds: adminContext.pay.employee.award_hours_day,
          },
          rate: adminContext.pay.employee.pay_rate_per_hour,
          date: new Date(),
          leave_loading: adminContext.pay.employee.leave_loading_percentage,
          leave_loading_manually_edited: false,
          leave_loading_amount: { _decimal: 0 },
          pay_id: adminContext.pay.id,
        };
      }}
      columns={[
        {
          label: "Description",
          accessor: "name",
        },
        {
          label: "Date Taken",
          accessor: "date",
          widget: "DateLabel",
        },
        {
          label: "Time Taken",
          accessor: "time_taken",
          widget: "DurationLabel",
        },
        {
          label: "Amount",
          accessor: "amount",
          align: "right",
          widget: "MoneyLabel",
          valueCallback: getLeaveAmount,
        },
        {
          label: "Leave Loading",
          accessor: "leave_loading_amount",
          align: "right",
          widget: "MoneyLabel",
          valueCallback: getLeaveLoadingAmount,
        },
        {
          label: "Total Amount",
          align: "right",
          accessor: "total_amount",
          widget: "MoneyLabel",
          valueCallback: getTotalAmount,
        },
      ]}
      editRowSaveCallback={saveLeaveRow}
      formSpec={{
        title: "Leave Taken",
        fields: [
          {
            label: "Leave Type",
            accessor: "leave_type",
            widget: "LeaveTakenTypeComboBox",
            required: true,
            args: {
              updatedCallback: (newModelObj, setTempModelObj, keyFromVal) => {
                // We need to update leave loading and rates when leave types changes to help prevent mistakes.
                if (
                  !["holiday", "custom"].includes(
                    newModelObj.leave_type.toLowerCase()
                  )
                ) {
                  newModelObj.leave_loading = 0;
                  setTempModelObj(newModelObj);
                } else if (newModelObj.leave_type.toLowerCase() == "holiday") {
                  newModelObj.leave_loading =
                    adminContext.pay.employee.leave_loading_percentage;
                  setTempModelObj(newModelObj);
                }

                // Also update the pay rates
                if (newModelObj.leave_type.toLowerCase() == "paid parental") {
                  newModelObj.rate =
                    adminContext.pay.employee.hourly_amount_for_paid_parental_leave;
                  setTempModelObj(newModelObj);
                } else if (
                  newModelObj.leave_type.toLowerCase() == "workers comp"
                ) {
                  newModelObj.rate =
                    adminContext.pay.employee.hourly_amount_for_workers_comp_leave;
                  setTempModelObj(newModelObj);
                } else {
                  newModelObj.rate =
                    adminContext.pay.employee.pay_rate_per_hour;
                  setTempModelObj(newModelObj);
                }
              },
              comboDataCallback: () => {
                let leaveComboData = {};
                if (!adminContext.pay.available_leave_types) {
                  return leaveComboData;
                }
                adminContext.pay.available_leave_types.forEach((leaveType) => {
                  leaveComboData[leaveType[0]] = leaveType[1];
                });
                return leaveComboData;
              },
            },
          },
          {
            label: "Date",
            accessor: "date",
            widget: "DateEdit",
          },
          {
            label: "Is Cash Out?",
            accessor: "is_cash_out",
            widget: "CheckBox",
          },
          {
            label: "Time Taken",
            accessor: "time_taken",
            widget: "DurationEdit",
          },
          {
            label: "Rate p/hr ($)",
            accessor: "rate",
            widget: "MoneyEdit",
          },

          {
            label: "Description",
            accessor: "description",
            widget: "textarea",
          },
          {
            label: "Leave Loading Percentage",
            accessor: "leave_loading",
            widget: "PercentageEdit",
            enabledCallback: (row) => {
              return !row.leave_loading_manually_edited;
            },
          },
          {
            label: "Leave Loading Amount ($)",
            accessor: "leave_loading_amount",
            widget: "MoneyEdit",
            setAdditionalEditAttributesCallback: () => {
              return { leave_loading_manually_edited: true };
            },

            valueCallback: (row) => {
              return { _decimal: getLeaveLoadingAmount(row) };
            },
          },
          {
            label: "",
            accessor: "",
            widget: "Button",
            args: {
              onClick: (event, row, setRow) => {
                setRow({
                  ...row,
                  leave_loading_manually_edited: false,
                });
              },
              text: "Undo Manual Leave Loading Edit?",
              color: "info",
              className: "my-4 mx-auto",
              icon: "fas fa-sync-alt",
              visibleCallback: (row) => {
                return row.leave_loading_manually_edited;
              },
            },
          },
          {
            label: "Total Amount ($)",
            accessor: "amount",
            align: "left",
            widget: "MoneyLabel",
            valueCallback: getTotalAmount,
          },
        ],
      }}
      deleteRowCallback={(idToDelete) => {
        let newLeave = adminContext.pay.leave.filter((leave) => {
          return leave.id !== idToDelete;
        });
        setSaving(true);
        adminContext.setPay({
          ...adminContext.pay,
          leave: newLeave,
        });
      }}
      deleteConfirmationAttributes={["name", "time_taken", "total_amount"]}
    />
  );
};
export default EditPayLeaveTaken;
