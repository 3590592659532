import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Alert,
  Col,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router";
import axios from "axios";
import qs from "qs";
import { getBaseUrl } from "../../variables/Config";
import AuthContext from "../../AuthContext";

const Login = ({ email, setEmail, isIOS }) => {
  const authLoading = useContext(AuthContext);
  const isMounted = useRef(true);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const history = useHistory();

  useEffect(() => {
    const checkTokenAndRedirect = () => {
      if (token) {
        history.push("/admin/entities/select");
      }
    };

    // Call the function immediately in case the token is already set
    checkTokenAndRedirect();

    // Any time the token changes, this effect will rerun, causing a redirect if token is set
  }, [token, history]);

  useEffect(() => {
    isMounted.current = true;
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail) setEmail(savedEmail);
    if (savedPassword) setPassword(savedPassword);

    return () => {
      isMounted.current = false;
    };
  }, []);

  const login = (e) => {
    e.preventDefault();
    setOtp("");
    setLoading(true);
    if (email === "" || password === "") {
      setErrorMessage("Please fill in all the fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }

    const opts = {
      username: email,
      password: password,
    };

    console.log("Base URL is: " + getBaseUrl());

    axios
      .post(getBaseUrl() + "/login", qs.stringify(opts))
      .then((response) => {
        if (!isMounted.current) return;
        if (response.data.access_token) {
          setTokenAndRedirect(response.data.access_token);
        } else if (response.data.success) {
          setOtpSent(true);
          showSuccess(response.data.message);
        } else {
          setLoading(false);
          if (response.data.message) {
            showError(response.data.message);
          } else {
            showError("Incorrect login details. Please try again.");
          }
        }
      })
      .catch((error) => {
        console.log("Error");
        console.log(error);
        if (!isMounted.current) return;
        setLoading(false);
        if (error.status === 429) {
          showError(
            "Too many login attempts. Please try again after 30 minutes."
          );
          return;
        }
        showError("Incorrect login details. Please try again.");
      });
  };

  const setTokenAndRedirect = (accessTokenData) => {
    const minutes = 30;
    const oneDay = 24 * 60;
    const expires = minutes / oneDay;

    const tokenData = JSON.stringify({
      token: accessTokenData,
      expires: expires,
    });

    localStorage.setItem("token", tokenData);
    setToken(localStorage.getItem("token")); // This will trigger the useEffect

    showSuccess("Login successful. Redirecting...");
  };

  const verifyLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === "" || password === "") {
      setErrorMessage("Please fill in all the fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }

    const opts = {
      username: email,
      password: password,
      otp: otp,
    };

    axios
      .post(getBaseUrl() + "/otp/verify", opts)
      .then((response) => {
        if (response.data.access_token) {
          setTokenAndRedirect(response.data.access_token);
        } else {
          setLoading(false);
          if (response.data.message) {
            showError(response.data.message);
          } else {
            showError("Incorrect login code. Please try again.");
          }
        }
      })
      .catch((error) => {
        if (!isMounted.current) return;
        setLoading(false);
        showError("Incorrect login code. Please try again.");
      });
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const showSuccess = (message) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage("");
    }, 15000);
  };

  return (
    <>
      <Col lg="8" md="8">
        {errorMessage && (
          <Row>
            <Col>
              <Alert color="warning">
                <strong>{errorMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}
        {successMessage && (
          <Row>
            <Col>
              <Alert color="success">
                <strong>{successMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}
        {otpSent ? (
          <Row>
            <Col lg="12" md="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent">
                  <p className="text-center">
                    Please enter your login code below
                  </p>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={verifyLogin}>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Enter your code here..."
                          type="number"
                          className="text-center text-lg"
                          autoComplete="new-password"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col>
                        {" "}
                        <Button
                          type="submit"
                          color="primary"
                          className="mx-auto d-block btn-lg text-lg width-100"
                        >
                          Submit
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            setOtpSent(false);
                            setOtp("");
                            setLoading(false);
                          }}
                          className="mx-auto d-block btn-lg btn-secondary text-lg width-100"
                        >
                          Restart
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg="12" md="12">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent">
                  <p className="text-center my-1">
                    Please enter your credentials below.
                  </p>
                  <p className="text-center my-1">
                    <b>Admin</b> users, please use your <b>email address</b>.{" "}
                    <b>Other users</b>, please use your <b>username</b>.
                  </p>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={login}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Enter email/username"
                          type="text"
                          className="text-center text-lg"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Enter password"
                          type="password"
                          className="text-center text-lg"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      {loading || authLoading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            color="primary"
                            className="mx-auto d-block btn-lg text-lg width-100 m-2"
                          >
                            Sign In
                          </Button>
                          {isIOS ? null : (
                            <Button
                              onClick={() => {
                                history.push(`/auth/trial?email=${email}`);
                              }}
                              color="success"
                              className="mx-auto d-block btn-lg text-lg width-100 m-2"
                            >
                              Start A 30 Day Free Trial
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
};

export default Login;
