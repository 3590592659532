import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import TableWidget from "../../../components/Widgets/TableWidget";

function ShiftsTab() {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeShifts, setEmployeeShifts] = useState([]);
  const init = () => {
    setIsLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/shifts`,
      (response) => {
        setIsLoading(false);
        if (response.data.employee_shifts) {
          setEmployeeShifts(response.data.employee_shifts);
        }
      }
    );
  };

  useEffect(() => {
    init();
  }, [adminContext.currentEmployee.id]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Shift Settings</h1>
                  <p>
                    Employee shifts can be defined below. These allow the
                    employee to be set to a roster which can pre-fill their
                    pays, saving time. Before you can edit a particular
                    employee\'s shifts you need to create the shift under{" "}
                    <strong>Company {">>"} Shifts</strong>.
                  </p>
                  <p>
                    To remove a shift from this screen you need to remove the
                    employee from the shift under{" "}
                    <strong>Company {">>"} Shifts</strong>.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TableWidget
                    rows={employeeShifts || []}
                    columns={[
                      {
                        label: "Edit",
                        accessor: "edit_employee_shift",
                        widget: "Button",
                        args: {
                          buttonIcon: "fas fa-edit",
                          color: "primary",
                          onClickCallback: (row) => {
                            adminContext.history.push(
                              `/admin/employees/shifts/edit_shift?id=${row.id}`
                            );
                          },
                        },
                      },
                      {
                        label: "Description",
                        accessor: "description",
                      },
                      {
                        label: "Start Time",
                        accessor: "start_time",
                      },
                      {
                        label: "End Time",
                        accessor: "end_time",
                      },
                      {
                        label: "Break Duration",
                        accessor: "break_duration",
                        widget: "DurationLabel",
                      },
                      {
                        label: "Duration",
                        accessor: "duration",
                        widget: "DurationLabel",
                      },
                      {
                        label: "Repeat (auto-rostered)?",
                        accessor: "repeat",
                        widget: "BooleanLabel",
                      },
                      {
                        label: "Department",
                        accessor: "department_name",
                      },
                      {
                        label: "Shift Total",
                        accessor: "shift_total_preview",
                        widget: "MoneyLabel",
                      },
                    ]}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
}
export default ShiftsTab;
