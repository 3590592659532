import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  CustomInput,
} from "reactstrap";
import ComboBox from "../../components/Widgets/ComboBox.js";
import TableWidget from "../../components/Widgets/TableWidget";
import BackButton from "../../components/Widgets/BackButton";
import TimeclockRoundingOptions from "./TimeclockRoundingOptions";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import moment from "moment";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const TimeclockSyncView = () => {
  const adminContext = useContext(AdminContext);

  // States for form controls and data
  const [loading, setLoading] = useState(true);
  const [vm, setVm] = useState({});
  const [vmReady, setVmReady] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [timeclockRoundingEditing, setTimeclockRoundingEditing] =
    useState(false);

  useEffect(() => {
    const initForm = {
      pay_run: adminContext.payRun,
      company_id: adminContext.company.id,
    };
    adminContext.postRequest(
      `${adminContext.constants.BASE_URL}/rosters/timeclock-portal-sync/init`,
      initForm,
      (response) => {
        setLoading(false);
        if (response.data.vm) {
          setVm(response.data.vm);
          console.log("SHift rows are ");
          console.log(response.data.vm.timeclock_shift_rows);
          setVmReady(true);
        }
      }
    );
  }, []);

  const updateVm = () => {
    const updateForm = {
      pay_run: adminContext.payRun,
      company_id: adminContext.company.id,
      ...vm,
    };
    setLoading(true);
    adminContext.postRequest(
      `${adminContext.constants.BASE_URL}/rosters/timeclock-portal-sync/update`,
      updateForm,
      (response) => {
        setLoading(false);
        if (response.data.vm) {
          const vmUpdates = {};
          let isVmUpdated = false;

          const keysToCheck = [
            "include_rostered_shifts",
            "include_unrostered_shifts",
            "show_synced",
            "show_new",
            "prefer_rostered_start_time",
            "prefer_rostered_start_time_if_later_than_timeclock",
            "prefer_rostered_end_time",
            "prefer_rostered_end_time_if_earlier_than_timeclock",
            "prefer_rostered_break_duration",
            "visible_date",
            "visible_employee",
            "mode_button",
            "sort_by",
            "timeclock_shift_rows",
            "visible_dates_dict",
            "visible_employees_dict",
            "types_dict",
            "sort_by_dict",
          ];

          keysToCheck.forEach((key) => {
            if (vm[key] !== response.data.vm[key]) {
              vmUpdates[key] = response.data.vm[key];
              isVmUpdated = true;
            }
          });

          // Update vm.timeclock_shift_rows if other items change and cause an update
          if (
            isVmUpdated &&
            vm["timeclock_shift_rows"] ===
              response.data.vm["timeclock_shift_rows"]
          ) {
            vmUpdates["timeclock_shift_rows"] = {
              ...response.data.vm["timeclock_shift_rows"],
            };
          }

          if (isVmUpdated) {
            setVm({ ...vm, ...vmUpdates });
          }
        }
      }
    );
  };

  // Process the update based on user changes
  useEffect(() => {
    // Update vm on server with changes
    if (!vmReady) return;
    updateVm();
  }, [
    vm.include_rostered_shifts,
    vm.include_unrostered_shifts,
    vm.show_synced,
    vm.show_new,
    vm.prefer_rostered_start_time,
    vm.prefer_rostered_start_time_if_later_than_timeclock,
    vm.prefer_rostered_end_time,
    vm.prefer_rostered_end_time_if_earlier_than_timeclock,
    vm.prefer_rostered_break_duration,
    vm.visible_date,
    vm.visible_employee,
    vm.mode_button,
    vm.sort_by,
  ]);

  // Update the VM if saving
  useEffect(() => {
    if (saving) {
      setSaving(false);
      updateVm();
    }
  }, [vm.timeclock_shift_rows]);

  // useEffect for handling changes in vm.timeclock_shift_rows. This is used to update the selected rows.
  useEffect(() => {
    if (vm.timeclock_shift_rows && selectedRows !== undefined) {
      const updatedSelectedRows = selectedRows.filter((row) =>
        vm.timeclock_shift_rows.some((shiftRow) => shiftRow.id === row.id)
      );
      setSelectedRows(updatedSelectedRows);
    }
  }, [vm.timeclock_shift_rows]);

  // Update the vm on data change
  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setVm({ ...vm, [event.target.name]: event.target.checked });
    } else if (event.target.type === "date") {
      const newDate = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      setVm({ ...vm, [event.target.name]: newDate });
    } else {
      setVm({ ...vm, [event.target.name]: event.target.value });
    }
  };

  const timeclockShiftColumns = [
    {
      label: "Employee Name",
      accessor: "employee_name",
      widget: "Text",
    },
    {
      label: "Shift",
      accessor: "description",
      widget: "Text",
    },
    {
      label: "Start Date",
      accessor: "start_date",
      widget: "DateLabel",
    },
    {
      label: "Employee Reported Start",
      accessor: "rounded_start_time",
      widget: "TimeLabel",
    },
    {
      label: "Employee Reported End",
      accessor: "rounded_end_time",
      widget: "TimeLabel",
    },
    {
      label: "Employee Reported Break",
      accessor: "rounded_break_duration",
      widget: "DurationLabel",
    },
    {
      label: "Paid Duration",
      accessor: "duration",
      widget: "DurationLabel",
    },
    {
      label: "Rostered Start",
      accessor: "rostered_start_time",
      widget: "TimeLabel",
    },
    {
      label: "Rostered End",
      accessor: "rostered_end_time",
      widget: "TimeLabel",
    },
    {
      label: "Rostered Break",
      accessor: "rostered_break_duration",
      widget: "DurationLabel",
    },
  ];

  const syncSelectedShifts = () => {
    setLoading(true);
    const syncForm = {
      pay_run: adminContext.payRun,
      company_id: adminContext.company.id,
      selected_shifts: selectedRows,
      timeclock_shift_rows: vm.timeclock_shift_rows,
    };
    adminContext.postRequest(
      `${adminContext.constants.BASE_URL}/rosters/timeclock-portal-sync/sync`,
      syncForm,
      (response) => {
        setLoading(false);
        adminContext.history.push("/admin/pays");
      }
    );
  };

  if (timeclockRoundingEditing) {
    return (
      <TimeclockRoundingOptions
        finishedEditing={() => {
          setTimeclockRoundingEditing(false);
          updateVm();
        }}
      />
    );
  }

  // Render the component
  return (
    <Container className="my-3 overflow-hidden" fluid>
      <Card>
        <CardBody>
          {!loading && vm.include_rostered_shifts !== undefined ? (
            <>
              {!currentSelected && (
                <>
                  {" "}
                  <Row>
                    <Col md="2">
                      <BackButton />
                    </Col>
                    <Col>
                      <h1 className="days-one">Timeclock/Portal Shift Sync</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        Below are timeclocked shifts and timesheet entries
                        reported for this pay period by your employees.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="mx-2 height-80 width-100">
                          Sort shifts by
                        </Label>
                        <ComboBox
                          className="mx-2 height-80 width-100"
                          name="sort_by"
                          comboData={vm["sort_by_dict"]}
                          selectedComboItem={vm["sort_by"]}
                          setSelectedComboItem={(item) => {
                            setVm({
                              ...vm,
                              sort_by: item,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Show shifts for (Dates)</Label>
                        <ComboBox
                          className="mx-2 height-80 width-100"
                          name="visible_date"
                          titleCase={true}
                          comboData={vm["visible_dates_dict"]}
                          selectedComboItem={vm["visible_date"]}
                          setSelectedComboItem={(item) => {
                            setVm({
                              ...vm,
                              visible_date: adminContext.getKeyByValue(
                                vm["visible_dates_dict"],
                                item,
                                true
                              ),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Show shifts for (Employees)</Label>
                        <ComboBox
                          className="mx-2 height-80 width-100"
                          name="visible_employee"
                          titleCase={true}
                          comboData={vm["visible_employees_dict"]}
                          selectedComboItem={vm["visible_employee"]}
                          setSelectedComboItem={(item) => {
                            setVm({
                              ...vm,
                              visible_employee: adminContext.getKeyByValue(
                                vm["visible_employees_dict"],
                                item,
                                true
                              ),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Show shifts for (Type)</Label>
                        <ComboBox
                          className="mx-2 height-80 width-100"
                          name="mode_button"
                          comboData={vm["types_dict"]}
                          selectedComboItem={vm["mode_button"]}
                          setSelectedComboItem={(item) => {
                            setVm({
                              ...vm,
                              mode_button: adminContext.getKeyByValue(
                                vm["types_dict"],
                                item
                              ),
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        These options help filter shifts shown by their rostered
                        status. For example, if you only want to see shifts that
                        have been rostered, you can select "Show Rostered
                        Shifts" and unselect "Show Unrostered Shifts". To see
                        all shifts, you would select both. Once a shift is
                        synced, it will be considered rostered.
                      </p>
                      <FormControlLabel
                        control={
                          <CheckBox
                            color="primary"
                            className="mx-2"
                            name="include_rostered_shifts"
                            checked={vm.include_rostered_shifts}
                            onChange={handleChange}
                          />
                        }
                        label={"Show Rostered Shifts?"}
                      />
                      <FormControlLabel
                        control={
                          <CheckBox
                            color="primary"
                            className="mx-2"
                            name="include_unrostered_shifts"
                            checked={vm.include_unrostered_shifts}
                            onChange={handleChange}
                          />
                        }
                        label={"Show Unrostered Shifts?"}
                      />
                      <FormControlLabel
                        control={
                          <CheckBox
                            color="primary"
                            className="mx-2"
                            name="show_synced"
                            checked={vm.show_synced}
                            onChange={handleChange}
                          />
                        }
                        label={"Show Previously Imported/Synced Shifts"}
                      />
                      <FormControlLabel
                        control={
                          <CheckBox
                            color="primary"
                            className="mx-2"
                            name="show_new"
                            checked={vm.show_new}
                            onChange={handleChange}
                          />
                        }
                        label={"Show New Shifts (Not Imported/Synced)"}
                      />
                    </Col>
                    <Col>
                      <p>
                        These options give right-of-way to already rostered
                        shifts, as opposed to the employee entered times, upon
                        syncing.
                      </p>
                      <Row>
                        Start Time Preferences (Only applicable if rostered):
                      </Row>
                      <Row>
                        <Col>
                          <FormControlLabel
                            control={
                              <CheckBox
                                color="primary"
                                className="mx-2"
                                name="prefer_rostered_start_time"
                                checked={vm.prefer_rostered_start_time}
                                onChange={handleChange}
                              />
                            }
                            label={"Prefer Rostered Start Times"}
                          />
                        </Col>
                        {vm.prefer_rostered_start_time && (
                          <Col>
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="prefer_rostered_start_time_if_later_than_timeclock"
                                  checked={
                                    vm.prefer_rostered_start_time_if_later_than_timeclock
                                  }
                                  onChange={handleChange}
                                />
                              }
                              label={"...But Only When Clocking-In Early"}
                            />{" "}
                          </Col>
                        )}
                      </Row>
                      <Row>
                        End Time Preferences (Only applicable if rostered):
                      </Row>
                      <Row>
                        <Col>
                          <FormControlLabel
                            control={
                              <CheckBox
                                color="primary"
                                className="mx-2"
                                name="prefer_rostered_end_time"
                                checked={vm.prefer_rostered_end_time}
                                onChange={handleChange}
                              />
                            }
                            label={"Prefer Rostered End Times"}
                          />
                        </Col>
                        {vm.prefer_rostered_end_time && (
                          <Col>
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="prefer_rostered_end_time_if_earlier_than_timeclock"
                                  checked={
                                    vm.prefer_rostered_end_time_if_earlier_than_timeclock
                                  }
                                  onChange={handleChange}
                                />
                              }
                              label={"...But Only When Clocking-Out Late"}
                            />{" "}
                          </Col>
                        )}
                      </Row>
                      <FormControlLabel
                        control={
                          <CheckBox
                            color="primary"
                            className="mx-2"
                            name="prefer_rostered_break_duration"
                            checked={vm.prefer_rostered_break_duration}
                            onChange={handleChange}
                          />
                        }
                        label={"Prefer Rostered Break Durations"}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <p>
                      Please select which shifts you wish to import from the
                      employee portal.
                    </p>
                    <p>
                      Note: Selected shifts which match employee, shift and day
                      will overwrite existing rostered shifts, whereas selected,
                      non-matching shifts will create new rostered shifts.
                    </p>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="semi-active-button"
                        onClick={() => setTimeclockRoundingEditing(true)}
                      >
                        <i className="text-center m-auto fa-regular fa-gear fa-xl" />
                        <br />
                        Timeclock Rounding Options
                      </Button>
                      {/* <Button>
                    <i className="text-center m-auto fa-regular fa-clipboard-list-check fa-xl" />
                    <br />
                    Show Incomplete/Underway Shifts
                  </Button> */}
                      <Button
                        disabled={selectedRows.length === 0}
                        className="semi-active-button"
                        color="primary"
                        onClick={syncSelectedShifts}
                      >
                        <i className="fa-regular text-center m-auto fa-check fa-xl" />
                        <br />
                        Download/Sync Selected Shifts
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  <TableWidget
                    title="Shifts For This Pay Run"
                    rows={vm.timeclock_shift_rows}
                    columns={timeclockShiftColumns}
                    multiSelectsEnabled={true}
                    selectsDisplayed={true}
                    setSelected={setSelectedRows}
                    currentSelected={currentSelected}
                    setCurrentSelected={setCurrentSelected}
                    selected={selectedRows}
                    editRowSaveCallback={(row) => {
                      setSaving(true);
                      // Find the matching row in the timeclock_shift_rows and selectedRows arrays and update them
                      const updatedTimeclockShiftRows =
                        vm.timeclock_shift_rows.map((timeclockShiftRow) => {
                          if (timeclockShiftRow.id === row.id) {
                            return row;
                          } else {
                            return timeclockShiftRow;
                          }
                        });

                      const updatedSelectedRows = selectedRows.map(
                        (selectedRow) => {
                          if (selectedRow.id === row.id) {
                            return row;
                          } else {
                            return selectedRow;
                          }
                        }
                      );

                      setVm({
                        ...vm,
                        timeclock_shift_rows: updatedTimeclockShiftRows,
                      });
                      setSelectedRows(updatedSelectedRows);
                    }}
                    formSpec={{
                      title: "Shift Details",
                      fields: [
                        {
                          label: "Employee",
                          accessor: "employee_name",
                          widget: "Label",
                        },
                        {
                          label: "Shift Description",
                          accessor: "description",
                          widget: "Label",
                        },
                        {
                          label: "Shift Start Date",
                          accessor: "start_date",
                          widget: "DateLabel",
                          required: true,
                        },
                        {
                          label: "Employee Reported Start",
                          accessor: "start_time",
                          widget: "TimeEdit",
                        },
                        {
                          label: "Employee Reported End",
                          accessor: "end_time",
                          widget: "TimeEdit",
                        },
                        {
                          label: "Break Duration",
                          accessor: "break_duration",
                          widget: "DurationEdit",
                        },
                        {
                          label: "Rounded Start Time",
                          accessor: "rounded_start_time",
                          widget: "TimeLabel",
                        },
                        {
                          label: "Rounded End Time",
                          accessor: "rounded_end_time",
                          widget: "TimeLabel",
                        },
                        {
                          label: "Rounded Break Duration",
                          accessor: "rounded_break_duration",
                          widget: "DurationLabel",
                        },
                        {
                          label: "Paid Duration",
                          accessor: "duration",
                          widget: "DurationLabel",
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>{" "}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default TimeclockSyncView;
