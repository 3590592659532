import { useState, useContext } from "react";
import { Container, Card, CardBody } from "reactstrap";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import TableWidget from "../../components/Widgets/TableWidget";
import AdminContext from "../../AdminContext";
import BackButton from "../../components/Widgets/BackButton";

const DeletePaysView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPays, setSelectedPays] = useState([]);
  const adminContext = useContext(AdminContext);

  const deleteSelectedPays = () => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/",
      {
        pays: selectedPays,
      },
      (response) => {
        setIsLoading(false);
        adminContext.history.push("/admin/pays");
      }
    );
  };

  return (
    <Container className="my-3 overflow-hidden" fluid>
      <Card>
        <CardBody>
          {!isLoading ? (
            <>
              <BackButton />
              <h1 className="mb-0 days-one">Delete Pays</h1>
              <p>
                Below is where you can delete completed pays from within this
                pay run. You may only delete pays which do not have an ATO
                Single Touch Payroll history attached to them. If a pay has been
                lodged through STP it may be edited, but not deleted.
              </p>
              <TableWidget
                selected={selectedPays}
                setSelected={setSelectedPays}
                multiSelectsEnabled={true}
                selectsDisplayed={true}
                deleteSelectedEnabled={true}
                deleteSelectedCallback={deleteSelectedPays}
                rows={adminContext.completedPayRows()}
                total={false}
                columns={[
                  {
                    label: "Employee",
                    accessor: "employee_format_name_shorter",
                    widget: "Text",
                  },
                  {
                    label: "End Date",
                    accessor: "date",
                    widget: "DateLabel",
                  },
                  {
                    label: "Processed Date",
                    accessor: "nullable_processed_date",
                    widget: "DateLabel",
                  },
                  {
                    label: "Gross",
                    accessor: "gross",
                    widget: "MoneyLabel",
                    align: "right",
                  },
                  {
                    label: "Tax",
                    accessor: "tax",
                    widget: "MoneyLabel",
                    align: "right",
                  },
                  {
                    label: "Net",
                    accessor: "net",
                    widget: "MoneyLabel",
                    align: "right",
                  },
                ]}
              />
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default DeletePaysView;
