import { default as TT } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const Tooltip = (props) => {
  return (
    <TT
      enterDelay={1000}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 500 }}
      title={props.title}
      arrow
    >
      {props.innerContent}
    </TT>
  );
};
export default Tooltip;
