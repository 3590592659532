import TableWidget from "../../../components/Widgets/TableWidget";
import moment from "moment";

const EditPayBonuses = ({
  adminContext,
  setSaving,
  getBonusTaxMethodComboData,
  getBonusSacrificeList,
  selectedRows,
  setSelectedRows,
}) => {
  return (
    <>
      <TableWidget
        label="Bonus/Commission"
        rows={adminContext.pay.bonuses}
        editRowSaveCallback={(currentBonus) => {
          setSaving(true);
          let bonusRows;
          // get all the pay.pay_sacrifices whose ID is in a selected row object
          let sacrificeRows = adminContext.pay.pay_sacrifices.filter(
            (sacrifice) => {
              let found = false;
              selectedRows.sacrifices.forEach((row) => {
                if (row.id === sacrifice.id) {
                  found = true;
                }
              });
              return found;
            }
          );

          // add the pay.pay_sacrifices to the current bonus
          currentBonus.sacrifices = sacrificeRows;

          if (currentBonus.id === null) {
            bonusRows = [...adminContext.pay.bonuses, currentBonus];
          } else {
            bonusRows = adminContext.pay.bonuses.map((bonus) => {
              if (Number(bonus.id) === Number(currentBonus.id)) {
                return currentBonus;
              } else {
                return bonus;
              }
            });
          }

          adminContext.setPay({
            ...adminContext.pay,
            bonuses: bonusRows,
          });
        }}
        deleteConfirmationAttributes={["description", "amount"]}
        deleteRowCallback={(idToDelete) => {
          setSaving(true);
          adminContext.setPay({
            ...adminContext.pay,
            bonuses: adminContext.pay.bonuses.filter((bonus) => {
              return bonus.id !== idToDelete;
            }),
          });
        }}
        columns={[
          {
            label: "Description",
            accessor: "description",
          },
          {
            label: "Amount",
            accessor: "amount",
            widget: "MoneyLabel",
          },
          {
            label: "Directors Fees?",
            accessor: "is_directors_fees",
            widget: "BooleanLabel",
          },
          {
            label: "Included In Super?",
            accessor: "is_included_in_super_calculations",
            widget: "BooleanLabel",
          },
          {
            label: "Tax Method",
            accessor: "tax_method_display",
          },
        ]}
        appendRowCallbackEnabled={
          adminContext.pay.employee.bonuses &&
          adminContext.pay.employee.bonuses.length > 0
        }
        appendRowCallback={() => {
          let endDate = moment(adminContext.payRun.end_date).format(
            "YYYY-MM-DD"
          );
          let payPeriod = adminContext.payRun.pay_period;
          let startDate;
          if (payPeriod === "WEEKLY") {
            // deduct 7 days from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(7, "days")
              .format("YYYY-MM-DD");
          } else if (payPeriod === "FORTNIGHTLY") {
            // deduct 14 days from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(14, "days")
              .format("YYYY-MM-DD");
          } else if (payPeriod === "MONTHLY") {
            // deduct 1 month from the end date
            startDate = moment(adminContext.payRun.end_date)
              .subtract(1, "months")
              .format("YYYY-MM-DD");
          }

          let empBonus = adminContext.pay.employee.bonuses[0];
          return {
            id: null,
            employee_bonus_id: empBonus.id,
            pay_id: adminContext.pay.id,
            is_return_to_work: false,
            is_directors_fees: empBonus.is_directors_fees,
            is_included_in_super_calculations:
              empBonus.is_included_in_super_calculations,
            amount: empBonus.amount,
            start_date: startDate,
            end_date: endDate,
            tax_method: "NORMAL_EARNINGS",
            sacrifices: [],
          };
        }}
        formSpec={{
          title: "Bonus/Commission",
          shortDescription: (
            <>
              Below is where you can define a bonus, commission, directors fees
              or other miscellaneous pay item for{" "}
              <strong>
                {adminContext.pay.employee.first_name}{" "}
                {adminContext.pay.employee.last_name}.
              </strong>
            </>
          ),
          validateFormCallback: (bonus) => {
            if (
              bonus.tax_method !== "NORMAL_EARNINGS" &&
              bonus.amount._decimal < 0
            ) {
              return "Please choose 'Normal Earnings' for the tax method when entering a negative amount.";
            }
            if (
              !bonus.is_return_to_work &&
              bonus.tax_method === "METHOD_A_SPECIFIC_RANGE"
            ) {
              let dateRegex = /^\d{4}-\d{2}-\d{2}$/;
              if (
                !dateRegex.test(bonus.start_date) ||
                !dateRegex.test(bonus.end_date)
              ) {
                return "Please enter a valid start and end date.";
              }
              if (bonus.start_date > bonus.end_date) {
                return "The start date must be before the end date.";
              }
            }
            if (bonus.is_return_to_work && bonus.is_directors_fees) {
              return "You cannot have a return to work bonus and directors fees at the same time.";
            }
            if (Number(bonus.amount._decimal) === 0) {
              return "Please enter a valid amount.";
            }

            return "";
          },
          fields: [
            {
              label: "Description",
              accessor: "employee_bonus_id",
              widget: "ComboBox",
              args: {
                comboDataCallback: () => {
                  let bonusComboData = {};

                  adminContext.pay.employee.bonuses.forEach((bonus) => {
                    bonusComboData[bonus.id] = bonus.description;
                  });
                  return bonusComboData;
                },
              },
            },
            {
              label: "Included In Super Calculations?",
              accessor: "is_included_in_super_calculations",
              widget: "CheckBox",
            },
            {
              label: "Directors Fees?",
              accessor: "is_directors_fees",
              widget: "CheckBox",
              args: {
                visibleCallback: (row) => {
                  return !row.is_return_to_work;
                },
              },
            },
            {
              label: "Is Return To Work Payment?",
              accessor: "is_return_to_work",
              widget: "CheckBox",
            },

            {
              label: "Amount",
              accessor: "amount",
              widget: "MoneyEdit",
            },
            {
              label: "Tax Method",
              accessor: "tax_method",
              widget: "ComboBox",
              args: {
                comboDataCallback: getBonusTaxMethodComboData,
                visibleCallback: (row) => {
                  return !row.is_return_to_work;
                },
              },
            },
            {
              label: "",
              accessor: "tax_method_faq",
              widget: "Label",
              valueCallback: (row) => {
                return (
                  <>
                    Click{" "}
                    <a href="https://www.lightningpayroll.com.au/faq?selected=208">
                      <strong>here</strong>
                    </a>{" "}
                    for more information on tax methods.
                  </>
                );
              },
            },

            {
              label: "Method A Start Date",
              accessor: "start_date",
              widget: "DateEdit",
              args: {
                visibleCallback: (row) => {
                  return (
                    !row.is_return_to_work &&
                    row.tax_method == "METHOD_A_SPECIFIC_RANGE"
                  );
                },
              },
            },
            {
              label: "Method A End Date",
              accessor: "end_date",
              widget: "DateEdit",
              args: {
                visibleCallback: (row) => {
                  return (
                    !row.is_return_to_work &&
                    row.tax_method == "METHOD_A_SPECIFIC_RANGE"
                  );
                },
              },
            },
            {
              label: "Sacrifices",
              accessor: "sacrifices",
              widget: "ListWidget",
              args: {
                shortDescription:
                  "Sacrifices can be connected to a bonus/commission to potentially reduce the amount of tax payable on the payment, due to the ordering of multiple tax methods within the pay.",
                visibleCallback: (row) => {
                  return adminContext.pay.pay_sacrifices.length > 0;
                },
                rows: getBonusSacrificeList(),
                setRows: () => {
                  return;
                },
                selectedRows: selectedRows,
                setSelectedRows: setSelectedRows,
              },
            },
          ],
        }}
      />
    </>
  );
};
export default EditPayBonuses;
