import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import TableWidget from "../../../components/Widgets/TableWidget";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

const TaxOffsets = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/employees/${adminContext.currentEmployee.id}/init`,
      (response) => {
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    initView();
  }, []);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=tax_offsets`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const saveTaxOffsets = (newTaxOffset) => {
    setSaving(true);
    let newTaxOffsets = [];

    if (newTaxOffset.id === null) {
      newTaxOffsets = [
        ...adminContext.currentEmployee.tax_offsets,
        newTaxOffset,
      ];
    } else {
      newTaxOffsets = adminContext.currentEmployee.tax_offsets.map((tos) => {
        if (tos.id === newTaxOffset.id) {
          return newTaxOffset;
        } else {
          return tos;
        }
      });
    }

    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      tax_offsets: newTaxOffsets,
    });
  };

  const deleteTaxOffset = (idToDelete) => {
    let taxOffsets = adminContext.currentEmployee.tax_offsets;
    taxOffsets = taxOffsets.filter((item) => item.id !== idToDelete);
    setSaving(true);
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      tax_offsets: taxOffsets,
    });
  };

  useEffect(() => {
    if (saving) {
      saveChanges();
      setSaving(false);
    }
  }, [adminContext.currentEmployee.tax_offsets]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Tax Offsets</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div key={"tfnd_signed_date"} className="my-4">
                    <TableWidget
                      appendRowCallback={() => {
                        return {
                          name: "",
                          id: null,
                          employee_id: adminContext.currentEmployee.id,
                          is_senior_pensioner_offset: false,
                          amount: { _decimal: 0 },
                        };
                      }}
                      formSpec={{
                        title: "Tax Offset",
                        fields: [
                          {
                            label: "Name",
                            accessor: "name",
                            widget: "Text",
                            required: true,
                          },
                          {
                            label: "Offset per Annum",
                            accessor: "amount",
                            widget: "MoneyEdit",
                          },
                          {
                            label: "Is Senior/Pensioner (SAPTO) Offset?",
                            accessor: "is_senior_pensioner_offset",
                            widget: "CheckBox",
                          },
                        ],
                      }}
                      editRowSaveCallback={saveTaxOffsets}
                      deleteRowCallback={deleteTaxOffset}
                      deleteConfirmationAttributes={["name", "amount"]}
                      rows={
                        adminContext.currentEmployee.tax_offsets
                          ? adminContext.currentEmployee.tax_offsets
                          : []
                      }
                      columns={[
                        {
                          label: "Name",
                          accessor: "name",
                          widget: "Text",
                        },
                        {
                          label: "Is Senior/Pensioner (SAPTO) Offset?",
                          accessor: "is_senior_pensioner_offset",
                          widget: "BooleanLabel",
                        },
                        {
                          label: "Offset per Annum",
                          accessor: "amount",
                          align: "right",
                          widget: "MoneyLabel",
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default TaxOffsets;
