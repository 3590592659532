import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Text,
} from "reactstrap";
import AdminContext from "../../AdminContext";
import DateEdit from "../../components/Widgets/DateEdit";
import BackButton from "../../components/Widgets/BackButton";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import TableWidget from "../../components/Widgets/TableWidget";
import MoneyLabel from "../../components/Widgets/MoneyLabel";
import ComboBox from "../../components/Widgets/ComboBox";
import qs from "qs";
import moment from "moment";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import EditForm from "../../components/Widgets/EditForm";

const EditSuperFundDeposit = ({ deposit }) => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);

  const currentSuperFundDepositID = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const [currentSuperDeposit, setCurrentSuperDeposit] = useState({});
  const [availableEmpFunds, setAvailableEmpFunds] = useState({});
  const [superDepositItems, setSuperDepositItems] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [fundAccount, setFundAccount] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [errorText, setErrorText] = useState("");
  const [employeeFundEnabled, setEmployeeFundEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [editingAmount, setEditingAmount] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/super-fund-deposits/edit-deposit/" +
        currentSuperFundDepositID,
      (response) => {
        if (response.data.results) {
          setCurrentSuperDeposit(response.data.results.super_fund_deposit);
          setIsLocked(response.data.results.super_fund_deposit.is_paid);
          setAvailableEmpFunds(response.data.results.available_emp_funds);
          setSuperDepositItems(response.data.results.super_deposit_items);
          setEmployeeName(response.data.results.employee_name);
          setFundAccount(response.data.results.fund_account);
          setTotalAmount(response.data.results.total_amount);
          setErrorText(response.data.results.error_text);
          setEmployeeFundEnabled(response.data.results.employee_fund_enabled);
        }

        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, [currentSuperFundDepositID]);

  // Update the API on submit
  const saveChanges = (close) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        "/super-fund-deposits/edit-deposit/" +
        currentSuperFundDepositID,
      currentSuperDeposit,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
        if (close) {
          adminContext.history.push("/admin/super_fund_deposits");
        } else {
          initView();
        }
      }
    );
  };

  const saveAndClose = () => {
    saveChanges(true);
  };

  const saveAmountEdit = () => {
    saveChanges();
    setEditingAmount(false);
  };

  const formSpec = {
    title: "Payslip Info",
    fields: [
      {
        label: "Super Guarantee",
        accessor: "guarantee",
        widget: "MoneyEdit",
      },
      {
        label: "Salary Sacrifice",
        accessor: "salary_sacrifice",
        widget: "MoneyEdit",
      },
      {
        label: "Voluntary Contribution",
        accessor: "member_contribution",
        widget: "MoneyEdit",
      },
      {
        label: "Excess SG/RESC",
        accessor: "resc",
        widget: "MoneyEdit",
      },
    ],
  };

  const deleteDepositItem = (depositItem) => {
    setIsLoading(true);
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL +
        "/super-fund-deposits/delete-deposit-item/" +
        depositItem.id,
      (response) => {
        setIsLoading(false);
        initView();
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  return (
    <Container fluid>
      {isLoading || !currentSuperDeposit ? (
        <LoadingSpinner />
      ) : editingAmount ? (
        <EditForm
          saveChanges={saveAmountEdit}
          formSpec={formSpec}
          cancelCallback={() => {
            setEditingAmount(false);
          }}
          modelObj={currentSuperDeposit}
          setModelObj={setCurrentSuperDeposit}
          liveEdit={true}
        />
      ) : (
        <Card>
          <CardBody>
            <BackButton />
            <AvForm>
              <Row>
                <Col>
                  {" "}
                  <h1 className="days-one m-2">
                    <strong>Edit Super Fund Deposit</strong>
                  </h1>
                  <SaveChangesMessageRow
                    saveChanges={saveAndClose}
                    disabledCallback={() =>
                      currentSuperDeposit.attachedToMessage ? true : false
                    }
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                  />
                </Col>
                {currentSuperDeposit.is_paid && (
                  <Col className="ml-3 mb-3 d-flex align-items-center">
                    <Button
                      className="btn semi-active-button"
                      onClick={() => {
                        setIsLocked(!isLocked);
                      }}
                    >
                      {isLocked ? (
                        <>
                          <i className="fa-regular fa-lock" /> Unlock
                        </>
                      ) : (
                        <>
                          <i className="fa-regular fa-unlock" /> Lock
                        </>
                      )}
                    </Button>
                    <em>
                      <strong>
                        {isLocked
                          ? "This super deposit has been PAID and has been locked from editing. Unlock to edit."
                          : "This PAID super deposit is unlocked and editable."}
                      </strong>
                    </em>
                  </Col>
                )}
              </Row>
              <fieldset disabled={isLocked}>
                <Row>
                  <Col>{errorText}</Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col className="ml-3">
                        <h2>
                          <strong>Employee: </strong>
                        </h2>
                      </Col>
                      <Col className="text-right">
                        <h2>{employeeName}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-3">
                        <h2>
                          <strong>Total Amount: </strong>
                        </h2>
                      </Col>
                      <Col className="text-right">
                        <h2>
                          <MoneyLabel amount={totalAmount} />
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-3">
                        <h2>
                          <strong>Fund Member/Account Number: </strong>
                        </h2>
                      </Col>
                      <Col className="text-right">
                        <h2>{currentSuperDeposit.account_number}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-3">
                        <h2>
                          <strong>Payment Method: </strong>
                        </h2>
                      </Col>
                      <Col className="text-right">
                        <h2>{currentSuperDeposit.payment_method}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        {" "}
                        <Button
                          onClick={() => setEditingAmount(true)}
                          className="btn semi-active-button width-80-on-lg"
                        >
                          <h2>Edit Amount</h2>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <DateEdit
                        name="paid_on"
                        label="Date of Deposit (Payment Made On)"
                        value={currentSuperDeposit.paid_on}
                        onChange={(date) => {
                          setCurrentSuperDeposit({
                            ...currentSuperDeposit,
                            paid_on: date,
                          });
                        }}
                        align="left"
                        disabled={isLocked}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ComboBox
                        className="mx-3"
                        label="Payment Status"
                        stretch={true}
                        name="status"
                        disabled={isLocked}
                        comboData={adminContext.constants.SUPER_DEPOSIT_STATUS}
                        selectedComboItem={currentSuperDeposit.status}
                        setSelectedComboItem={(data) => {
                          const newStatus = adminContext.getKeyByValue(
                            adminContext.constants.SUPER_DEPOSIT_STATUS,
                            data
                          );
                          setCurrentSuperDeposit({
                            ...currentSuperDeposit,
                            status: newStatus,
                            is_paid: newStatus === "PAID",
                          });
                        }}
                      />
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <fieldset disabled={!employeeFundEnabled}>
                      <FormGroup>
                        <ComboBox
                          className="mx-3"
                          label="Fund Provider"
                          stretch={true}
                          disabled={!employeeFundEnabled}
                          name="employee_super_fund_id"
                          comboData={availableEmpFunds}
                          selectedComboItem={
                            currentSuperDeposit.employee_super_fund_id
                          }
                          setSelectedComboItem={(data) => {
                            const fundID = adminContext.getKeyByValue(
                              availableEmpFunds,
                              data
                            );
                            setCurrentSuperDeposit({
                              ...currentSuperDeposit,
                              employee_super_fund_id: fundID,
                            });
                          }}
                        />
                      </FormGroup>
                    </fieldset>
                  </Col>
                  <Col>
                    <FormGroup>
                      <AvField
                        value={currentSuperDeposit.transaction_reference}
                        onChange={(event) => {
                          setCurrentSuperDeposit({
                            ...currentSuperDeposit,
                            transaction_reference: event.target.value,
                          });
                        }}
                        type="text"
                        name="transaction_reference"
                        label="Transaction Reference (Optional - Internal purposes only)"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TableWidget
                      label="Pays/Adjustments"
                      total={true}
                      rows={superDepositItems}
                      deleteRowCallbackEnabled={(row) => {
                        return (
                          employeeFundEnabled && !currentSuperDeposit.is_paid
                        );
                      }}
                      deleteRowCallback={deleteDepositItem}
                      columns={[
                        {
                          label: "Description",
                          accessor: "description",
                          widget: "Text",
                        },
                        {
                          label: "Guarantee",
                          accessor: "amount",
                          widget: "MoneyLabel",
                          align: "right",
                        },
                        {
                          label: "Voluntary Contribution",
                          accessor: "member_contribution",
                          widget: "MoneyLabel",
                          align: "right",
                        },
                        {
                          label: "Salary Sacrifice",
                          accessor: "salary_sacrifice",
                          widget: "MoneyLabel",
                          align: "right",
                        },
                        {
                          label: "Excess SG/RESC",
                          accessor: "resc",
                          widget: "MoneyLabel",
                          align: "right",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </fieldset>
            </AvForm>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default EditSuperFundDeposit;
