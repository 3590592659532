import { useState, useContext, useEffect } from "react";
import { Container, Card, CardBody } from "reactstrap";
import AdminContext from "../../AdminContext";
import DateEdit from "../../components/Widgets/DateEdit";
import ComboBox from "../../components/Widgets/ComboBox";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import BackButton from "../../components/Widgets/BackButton";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import CheckBox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

const BlankSuperFundDeposit = ({ deposit }) => {
  const adminContext = useContext(AdminContext);
  const [paidOn, setPaidOn] = useState(new Date());
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [showTerminated, setShowTerminated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const saveChanges = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/create-blank-deposit?company_id=${adminContext.company.id}`,
      {
        employee_id: selectedEmployeeId,
        paid_on: paidOn,
      },
      (response) => {
        if (response.data.new_deposit) {
          adminContext.history.push(
            "/admin/super_fund_deposits/edit_deposit?id=" +
              response.data.new_deposit.id
          );
        } else {
          setErrorMessage(
            "Failed to create deposit. Please try again later and contact support if the error continues."
          );
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        }

        setIsLoading(false);
      }
    );
  };

  const filteredEmployees = () => {
    if (!activeEmployees) return [];
    // Depending on showTerminated we need to modify the combo data
    if (showTerminated) {
      return activeEmployees;
    } else {
      // active_employees is an object keyed by id: format_name. Check if the format_name contains "(terminated)"
      const filtered = Object.entries(activeEmployees).filter(
        ([id, name]) => !name.includes("(terminated)")
      );
      return Object.fromEntries(filtered);
    }
  };

  const employeesComboData = () => {
    let empCombo = {};
    const employees = filteredEmployees();

    Object.entries(employees).forEach(([id, name]) => {
      let formattedName = name;

      // Check if the name contains "(terminated)" or "(no active fund)"
      if (name.includes("(terminated)") || name.includes("(no active fund)")) {
        formattedName = <span className="text-danger">{name}</span>;
      }

      empCombo[id] = formattedName;
    });

    return empCombo;
  };

  useEffect(() => {
    const initEmployees = () => {
      setIsLoading(true);
      adminContext.getRequest(
        adminContext.constants.BASE_URL +
          `/super-fund-deposits/init-blank-deposit?company_id=${adminContext.company.id}`,
        (response) => {
          // Convert the object of employees to an array if necessary
          const employeesArray = Object.entries(response.data.employees).map(
            ([id, name]) => ({ id, name })
          );

          // Sort the employees alphabetically in lower case
          employeesArray.sort((a, b) =>
            b.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );

          setActiveEmployees(response.data.employees);

          if (employeesArray.length > 0) {
            // Set the first employee's ID as the default selected employee. Must be a non-terminated employee
            employeesArray.forEach((employee) => {
              if (!employee.name.includes("(terminated)")) {
                setSelectedEmployeeId(employee.id);
                return;
              }
            });
          }

          setIsLoading(false);
        }
      );
    };

    initEmployees();
  }, []);

  const handleDateChange = (dateTime) => {
    const dateObj = new Date(dateTime);
    if (isNaN(dateObj.getTime())) {
      setErrorMessage("Deposit date is invalid");
      return;
    }
    setPaidOn(dateObj);
  };

  if (!activeEmployees)
    return (
      <div className="m-3">
        <BackButton />
        <h2>No employees available for deposit creation.</h2>
      </div>
    );

  if (isLoading || !selectedEmployeeId) return <LoadingSpinner />;

  return (
    <Container fluid>
      <Card>
        <CardBody>
          <div className="m-4">
            <BackButton />
          </div>
          <h1 className="days-one">Create Blank Super Deposit</h1>

          <ComboBox
            label="Employee"
            stretch={true}
            className="mx-3"
            name="employee"
            sortCallback={(a, b) => {
              let aValue = filteredEmployees()[a];
              let bValue = filteredEmployees()[b];
              if (aValue < bValue) {
                return -1;
              }
              if (aValue > bValue) {
                return 1;
              }
              return 0;
            }}
            comboData={employeesComboData()}
            selectedComboItem={selectedEmployeeId ? selectedEmployeeId : null}
            setSelectedComboItem={(data) => {
              let keyFromVal = adminContext.getKeyByValue(
                filteredEmployees(),
                data
              );

              if (keyFromVal) {
                setSelectedEmployeeId(keyFromVal);
              }
            }}
          />
          <FormControlLabel
            label="Show terminated employees?"
            control={
              <CheckBox
                checked={showTerminated}
                className="mx-2"
                onChange={(e) => {
                  setShowTerminated(e.target.checked);
                }}
                type="checkbox"
                name="show_terminated"
              />
            }
          />
          <DateEdit
            label={"Date Of Deposit (Payment Made On)"}
            value={paidOn}
            onChange={handleDateChange}
          />
          <SaveChangesMessageRow
            errorMessage={errorMessage}
            successMessage={successMessage}
            saveChanges={saveChanges}
            label={"Create Deposit"}
            disabledCallback={() => {
              let invalidEmpSelected = false;
              if (!showTerminated) {
                invalidEmpSelected = Object.entries(activeEmployees).find(
                  ([id, name]) =>
                    id === selectedEmployeeId &&
                    (name.includes("(terminated)") ||
                      name.includes("(no active fund)"))
                );
              }

              return !!(!selectedEmployeeId || !paidOn || invalidEmpSelected);
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default BlankSuperFundDeposit;
