import { Container, Row, Col, Button, Alert, Card, CardBody } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";

import moment from "moment";

import AdminContext from "../../AdminContext";

import qs from "qs";
import DateTimeLabel from "../../components/Widgets/DateTimeLabel";
import { BiCloudDownload } from "react-icons/bi";
import { IconContext } from "react-icons";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import BackButton from "../../components/Widgets/BackButton";

const SBRSubmissionView = (props) => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSBRSubmission, setCurrentSBRSubmission] = useState(false);

  const currentSBRSubmissionID = qs.parse(adminContext.location.search, {
    ignoreQueryPrefix: true,
  }).id;

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/sbr_submissions/${currentSBRSubmissionID}`,
      (response) => {
        setCurrentSBRSubmission(response.data.sbr_submission);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const downloadPDF = async () => {
    const fileName = props.name + ".pdf";
    await adminContext.handlePDFDownload(
      fileName,
      currentSBRSubmission.pdf_file_data
    );
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          <BackButton />
          {!isLoading ? (
            <>
              {currentSBRSubmission ? (
                <>
                  <h2 className="days-one">
                    {currentSBRSubmission.service.toUpperCase()} submission,
                    sent{" "}
                    {
                      <DateTimeLabel
                        value={currentSBRSubmission.processed}
                      ></DateTimeLabel>
                    }
                  </h2>
                  <Button
                    onClick={downloadPDF}
                    className="my-4 mx-auto"
                    color="info"
                    type="button"
                  >
                    <IconContext.Provider value={{ size: "30px" }}>
                      <BiCloudDownload />
                    </IconContext.Provider>
                    <span className="ml-1">Download PDF</span>
                  </Button>
                </>
              ) : null}
              <TableWidget
                rows={
                  currentSBRSubmission ? currentSBRSubmission.request_items : []
                }
                columns={[
                  {
                    label: "Tfn",
                    accessor: "tfn",
                    widget: "text",
                  },
                  {
                    label: "First Name",
                    accessor: "first_name",
                    widget: "text",
                  },
                  {
                    label: "Last Name",
                    accessor: "last_name",
                    widget: "text",
                  },
                  {
                    label: "Gross",
                    accessor: "gross",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Total Tax",
                    accessor: "total_tax",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Allowance",
                    accessor: "allowance",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Lump Sum A",
                    accessor: "lump_sum_a",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Lump Sum A Code",
                    accessor: "lump_sum_a_code",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Lump Sum B",
                    accessor: "lump_sum_b",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Lump Sum D",
                    accessor: "lump_sum_d",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Lump Sum E",
                    accessor: "lump_sum_e",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Cdep",
                    accessor: "cdep",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Fringe Benefits",
                    accessor: "fringe_benefits",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Resc",
                    accessor: "resc",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Workplace Giving",
                    accessor: "workplace_giving",
                    widget: "MoneyLabel",
                  },
                  {
                    label: "Union Fees",
                    accessor: "union_fees",
                    widget: "MoneyLabel",
                  },
                ]}
              />{" "}
              <hr />
              <h2 className="days-one">ATO Responses</h2>
              <Row>
                <Col className="mx-4 my-1">
                  <Row>
                    <Col>
                      <TableWidget
                        rows={
                          currentSBRSubmission
                            ? currentSBRSubmission.response_items
                            : []
                        }
                        columns={[
                          {
                            label: "Error",
                            accessor: "error",
                            widget: "text",
                          },
                          {
                            label: "Identifier",
                            accessor: "identifier",
                            widget: "text",
                          },
                          {
                            label: "Severity",
                            accessor: "severity",
                            widget: "text",
                          },
                          {
                            label: "Short",
                            accessor: "short",
                            widget: "text",
                          },
                          {
                            label: "Detailed",
                            accessor: "detailed",
                            widget: "text",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default SBRSubmissionView;
