import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import TableWidget from "../../../components/Widgets/TableWidget.js";

const NotesTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    setSaving(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/update-employee?route=notes`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const setCurrentNote = (note) => {
    // Set the employees current note to the current note
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      currentNote: note,
    });
  };

  const createNote = () => {
    return {
      date: new Date().toISOString().slice(0, 10),
      id: null,
      text: "",
    };
  };

  const deleteNote = (idToDelete) => {
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      notes: adminContext.currentEmployee.notes.filter(
        (note) => note.id !== idToDelete
      ),
      currentNote: false,
    });
    setSaving(true);
  };

  const updateErrorMessage = () => {
    if (adminContext.currentEmployee.notes === undefined) {
      return;
    }
    let totalPercentage = adminContext.currentEmployee.notes.reduce(
      (total, note) => {
        return total + Number(note.percentage);
      },
      0
    );

    if (totalPercentage > 1) {
      setErrorMessage(
        "The combined percentages of these notes is greater than 100%"
      );
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    updateErrorMessage();
    // Save the changes if the save flag is set
    if (saving) {
      saveChanges();
    }
  }, [adminContext.currentEmployee.notes]);

  const updateNotes = (note) => {
    setSaving(true);
    let newNotes = [];
    if (note.id === null) {
      newNotes = [...adminContext.currentEmployee.notes, note];
    } else {
      newNotes = adminContext.currentEmployee.notes.map((n) => {
        if (n.id === note.id) {
          return note;
        } else {
          return n;
        }
      });
    }

    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      notes: newNotes,
    });
  };

  const formSpec = {
    title: "Note",
    fields: [
      {
        label: "Date",
        accessor: "date",
        widget: "DateEdit",
        required: true,
      },
      {
        label: "Text",
        accessor: "text",
        widget: "textarea",
        required: true,
      },
    ],
  };

  const columns = [
    {
      label: "Date",
      accessor: "date",
      widget: "DateLabel",
    },
    {
      label: "Text",
      accessor: "text",
      widget: "text",
    },
  ];

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {isLoading || adminContext.currentEmployee.notes === undefined ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Notes</h1>
                </Col>
              </Row>
              {!adminContext.currentEmployee.currentNote && (
                <Row>
                  <Col className="mt-3 mx-4">
                    <p>
                      NOTE: If you want to add this employee to a note that is
                      not listed below, you need to create a new note first
                      under <strong>{"Company >> Notes"}</strong>.
                    </p>
                    <p>
                      Notes cannot be deleted if they have employee or pay
                      history.
                    </p>
                  </Col>
                </Row>
              )}
              {errorMessage && (
                <Row>
                  <Col>
                    <Alert color="warning">
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Col>
                </Row>
              )}
              {!saving ? (
                <Row>
                  <Col>
                    <TableWidget
                      rows={adminContext.currentEmployee.notes}
                      columns={columns}
                      selected={adminContext.currentEmployee.currentNote}
                      setSelected={setCurrentNote}
                      appendRowCallback={createNote}
                      editRowSaveCallback={updateNotes}
                      deleteRowCallback={deleteNote}
                      deleteConfirmationAttributes={["date", "text"]}
                      formSpec={formSpec}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default NotesTab;
